import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useState } from 'react';
import Button from '@material-ui/core/Button';
import { AppBar } from '@material-ui/core';
import { Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CallIcon from '@material-ui/icons/Call';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { getAnalytics, logEvent } from "firebase/analytics";
import { useLocation } from 'react-router-dom';

function callTsc () {
    window.location.href=`tel:+1-303-499-7111`;
}

const useStyles = makeStyles((theme) => ({
    '@global': {
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
      flexWrap: 'wrap',
    },
    toolbarTitle: {
      flexGrow: 1,
      textAlign: 'center'
    },
    backbutton: {
      margin: theme.spacing(0,0,0,1)
    },
    paper: {
      position: 'absolute',
      width: '100vw',
      backgroundColor: "white",
      textAlign: "center",
      padding: theme.spacing(0, 1, 1)
    }
}));

const Topbar = () => {
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const [showBack, setShowBack] = useState(true);
    const location = useLocation();

    useEffect(() => {
        console.log("Location changed: " + location.pathname);
        const analytics = getAnalytics();
        logEvent(analytics, 'screen_view', {
            firebase_screen: location.pathname,
            firebase_screen_class: location.pathname
        });
    }, [location]);

    const width = useMediaQuery(theme.breakpoints.down(550));

    // useEffect(() => {
    // }, [])

    history.listen((location, action) => {
      if(location.pathname === '/login'){
        setShowBack(false);
      }else{
        setShowBack(true);
      }
    })

    function backAction() {
      history.goBack();
    }

    if(!width) {
      return(
        <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <img alt="Kubota Logo" src="/logo.png" height="64" />
          { showBack &&
            <IconButton aria-label="Back" color="inherit" onClick={backAction} className={classes.backbutton}>
              <ArrowBackIcon />
            </IconButton>
          }

          <Typography variant="h5" color="inherit" noWrap className={classes.toolbarTitle}>
          </Typography>

        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<CallIcon />}
          onClick={callTsc}
        >
          Call TSC
        </Button>
        </Toolbar>

        
      </AppBar>
    );
  }else{
    return(
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        { showBack &&
          <IconButton aria-label="Back" color="inherit" onClick={backAction}  >
            <ArrowBackIcon />
          </IconButton>
        }
        <Typography variant="h5" color="inherit" noWrap className={classes.toolbarTitle} height="64">
          K-Service
        </Typography>
      <Button
      variant="contained"
      color="primary"
      className={classes.button}
      onClick={callTsc}
      style={{ borderRadius: 24, minWidth: 30, width: 36}}
      >
        <CallIcon fontSize="small"/>
      </Button>
      </Toolbar>
    </AppBar>
  );
  }
}

export default Topbar;
