import React, { useReducer } from 'react';
import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DescriptionIcon from '@material-ui/icons/Description';
import InfoIcon from '@material-ui/icons/Info';
import SecurityIcon from '@material-ui/icons/Security';

import axios from 'axios';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import { removeUserType } from '../state/actions';
import { useHistory } from "react-router-dom";
import ApiManager from '../apiservice';
import { getAnalytics, logEvent } from "firebase/analytics";
import { b2cLogout} from './B2CLoginPage';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
    width: '90%'
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  button: {
    margin: theme.spacing(5, 0, 0)
  },

}));

const SettingsPage = ({ onUserTypeLogout }) => {
  const classes = useStyles();
  const [userLogin, setUserLogin] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userPhone, setUserPhone] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const [userDealerName, setUserDealerName] = useState(null);
  const [userDealerNo, setUserDealerNo] = useState(null);
  const history = useHistory();



  useEffect(() => {
    // On Component Load
    console.log('UserInfo');

    let userQuery = "SELECT Contact.Email, Contact.Phone, Username FROM User WHERE Id = \'"+Cookies.get('user_id')+"\'";
    //let userQuery = "SELECT Contact.Email, Contact.Phone, Username FROM User WHERE Id = \'0058D000004HbIP\'";
    userQuery = encodeURIComponent(userQuery);
    let userURL = process.env.REACT_APP_API + 'salesforce/kservice/get?param=query/?q=' +userQuery;

    let userReq = {
      method: "GET",
      url: userURL,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + Cookies.get("token"),
        'X-SalesForce-Token' : Cookies.get('sf_cookie'),
        'X-Id-Token' : Cookies.get('idToken')
      }
    };

    axios(userReq).then((userRes) => {
      setUserLogin(userRes.data.records[0].Username.substring(0, userRes.data.records[0].Username.indexOf('@')));
      if(userRes.data.records[0].Contact){
        setUserEmail(userRes.data.records[0].Contact.Email);
        setUserPhone(userRes.data.records[0].Contact.Phone);
      }
    }).catch((error) => {
      console.log(error);
    })

  }, []);
  console.log(userLogin)

  function onLogout() {

    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    // Dump Local Storage
    localStorage.clear();
    // Remove all saved data from sessionStorage
    sessionStorage.clear();
    // Dump the redux store User Type
    onUserTypeLogout();
    //window.location.href = "/login";
    history.push("/login");
  }

  function UserInfo() {
    return (
      <React.Fragment>
        <Typography component='h2' variant='h5'>{userFirstName} {userLastName}</Typography>
        <Typography variant='subtitle1'>{userDealerName}</Typography>
        {userLogin &&
          <Typography variant='body2'>Username: {userLogin}</Typography>
        }
        {userEmail &&
          <Typography variant='body2'>Email: {userEmail}</Typography>
        }
        {userPhone &&
          <Typography variant='body2'>Phone Number: {userPhone}</Typography>
        }
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.heroContent}>
        <Grid container spacing={2}>
          <Grid item md={2} xs={false}></Grid>
          <Grid item md={4} xs={12}>
            <Paper elevation={3} className={classes.heroPaper} justify="center">
              <Typography component="h1" variant="h5">Settings</Typography>
              <List>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText primary="Version 1.0" />
                </ListItem>
                <Divider />
                <ListItem button={true} onClick={() => window.open('https://www.kubotausa.com/privacy-policy', '_blank')}>
                  <ListItemIcon>
                    <SecurityIcon />
                  </ListItemIcon>
                  <ListItemText primary="Privacy Policy" />
                </ListItem>
                <Divider />
              </List>
            </Paper>
          </Grid>


          <Grid item md={4} xs={12}>
            <Paper elevation={3} className={classes.heroPaper} justify="center">
              <UserInfo />
              {/* <Divider /> */}
              <Button variant="contained" color="primary"
                fullWidth={true} onClick={b2cLogout}
                className={classes.button}>Log Out</Button>
            </Paper>
          </Grid>
          <Grid item md={2} xs={false}></Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

const mapDispatchToProps = dispatch => ({
  onUserTypeLogout: text => dispatch(removeUserType(text)),
})

export default connect(null, mapDispatchToProps)(SettingsPage);
