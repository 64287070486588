import React, { useReducer } from 'react';
import { useEffect, useState } from 'react';
import SFContainer from '../components/SFContainer';
import axios from 'axios';
import recordLayout from '../helpers/recordLayout'
import {useParams} from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cookies from 'js-cookie';
import useWindowDimensions from '../helpers/windowDimensions';
import depGraphHelper from '../helpers/depGraphHelper';
import { forInStatement } from '@babel/types';


import SalesForceConstants from '../SalesForceConstants';



const useStyles = makeStyles((theme) => ({
  heroContent: {
    width: "90%",
    padding: theme.spacing(8, 0, 6),
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  divider: {
    margin: theme.spacing(0,0,2)
  },
  form: {
    margin: theme.spacing(0, 0, 5)
  },
  loaderCenter: {
    marginLeft: '50%',
  },
  loadingCenterInside: {
    left: '-20px',
    position: 'relative'
  },
  listItem: {
    width: '90%',
    display: "inline-block"
  }
}));

export function SFFormTSCPage ({setStep, editValues, setEditValues, objectInfo, setObjectInfo, DTC, subject, machineDetails, createCase, ErrorStates, 
                                setErrorStates, RequiredFields, setRequiredFields, formStates, caseOrReport, fieldOverrides, preFillAccountContact, 
                                hiddenSections, hiddenFields}) {
  
  const [fetchResults, setFetchResults] = useState(false);
  const [loading, setLoading] = useReducer((loading) => !loading, false);
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const [layoutType, setLayoutType] = useState("");
  const [PickLists, setPickLists] = useState();
  // const [editValues, setEditValues] = useState([]);
  const [dependantFields, setDependantFields] = useState([]);
  const [DEPStates, setDEPStates] = useState();
  let serialNumber = null;
  let modelNumber = null;
  let pin = null;

  let params = useParams();
  if(params.param1){
    assignParams(params.param1);
  }

  if(params.param2){
    assignParams(params.param2);
  }

  function assignParams(value){
    if(value.substring(0,2) == "sn"){
      serialNumber = value.substring(3);
    }

    if(value.substring(0,2) == "mn"){
      modelNumber = value.substring(3);
    }

    if(value.substring(0,3) == "pin"){
      pin = value.substring(4);
    }
  }

  useEffect(() => {
    // On Component Load
    fetchLayout();
  }, []);

  function prefillWrapper(editValuesFromSF){
    if(preFillAccountContact){
      //Reports will have AccountContact prefill from previous screen
      var preFillFields = [];
      preFillFields.push(["AccountId", preFillAccountContact.AccountId.id, preFillAccountContact.AccountId.name]);
      preFillFields.push(["ContactId", preFillAccountContact.ContactId.id, preFillAccountContact.ContactId.name]);
      setEditValues(preFillFieldsFromPreviousScreens(editValuesFromSF, preFillFields));
    } else {
      //Cases wont have AccountContact prefill from previous screen
      queryUserInfoCase().then((response) => {
        var preFillFields = [];
        preFillFields.push(["AccountId", response.data.records[0].AccountId, response.data.records[0].Account.Name]);
        preFillFields.push(["ContactId", response.data.records[0].ContactId, response.data.records[0].Contact.Name]);
        setEditValues(preFillFieldsFromPreviousScreens(editValuesFromSF, preFillFields));
      }).catch((error) => {
        setEditValues(preFillFieldsFromPreviousScreens(editValuesFromSF, []));
      })
    }
    
  }

  function preFillFieldsFromPreviousScreens(editValuesFromSF, preFillFields) {
    // Job of this function is to inject pre-filled fields into current set of values.
    // Then return it so editValues can be set.
    //const preFillFields = [];
    if(DTC) {
      preFillFields.push(["DTC_Error_Codes__c", DTC]);
    }
    if(subject) {
      preFillFields.push(["Subject", subject]);
      preFillFields.push(["Description", subject]);
    }
    if(machineDetails.serialNumber) {
      preFillFields.push(["Serial_Number__c", machineDetails.serialNumber]);
    }
    if(machineDetails.modelNumber) {
      preFillFields.push(["Model_Number__c", machineDetails.modelNumber]);
    }
    if(formStates.ktcAssembly) {
      preFillFields.push(["Assembly__c", formStates.ktcAssembly]);
    }
    if(formStates.ktcSubAssembly) {
      preFillFields.push(["Sub_Assembly__c", formStates.ktcSubAssembly]);
    }
    if(formStates.ktcKeypartName) {
      //The Casual Part from the selection is the Part# + " " + PartName ex '05411-00510 PIN, SPRING'
      //The first space seperates the Part# from the PartName
      const partNumber = formStates.ktcKeypartName.split(" ")[0];
      const indexOfFirstSpace = formStates.ktcKeypartName.indexOf(" ");
      const partName = formStates.ktcKeypartName.substring(indexOfFirstSpace+1);

      preFillFields.push(["Causal_Part_Number__c", partNumber]);
      preFillFields.push(["Causal_Part__c", partName]);
    }
    preFillFields.forEach((fieldToAdd) => {
      if(editValuesFromSF[fieldToAdd[0]]) {
        var defaultValue = editValuesFromSF[fieldToAdd[0]].original;
        if(fieldToAdd.length == 3){ // Check if field has label
          editValuesFromSF = {
            ...editValuesFromSF,
            [fieldToAdd[0]]: {
              current: fieldToAdd[1],
              label: fieldToAdd[2],
              original: defaultValue
            }
          };
        }
        else{
          editValuesFromSF = {
            ...editValuesFromSF,
            [fieldToAdd[0]]: {
              current: fieldToAdd[1],
              original: defaultValue
            }
          };
        }
      }
    })
    return editValuesFromSF;
  }

  function queryUserInfoCase(){
    return new Promise(function(resolve, reject) {
      let qUserCase = "SELECT AccountId, Account.Name, ContactId, Contact.Name, Contact.Email, Contact.Phone, Account_Record_Type__c FROM User WHERE Id = \'"+Cookies.get('user_id')+"\'";
      qUserCase = encodeURIComponent(qUserCase);
      let urlUserCase = process.env.REACT_APP_API + 'salesforce/kservice/get?param=query/?q=' + qUserCase;

      let reqUserCase = {
        method: "GET",
        url: urlUserCase,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + Cookies.get("token"),
          'X-SalesForce-Token' : Cookies.get('sf_cookie'),
          'X-Id-Token' : Cookies.get('idToken')
        }
      };

      axios(reqUserCase).then((resUserCase) => {
        resolve(resUserCase);
      }).catch((error) => {
        reject(error);
      })
    })
  }
  const fetchLayout = (refresh = false) => {
      console.log("loading is: " + loading);
      if(refresh == false)
        setLoading();
      var qs = require('qs');
      var layoutMode;
      var recordTypeId = (caseOrReport === "report") ? SalesForceConstants.ReportRecordTypeID : SalesForceConstants.CaseRecordTypeID;
      //Not sure if Full/Compact exists for create layout??
      // (width > 720) ? layoutMode = "Full" : layoutMode = "Compact";
      // setLayoutType(layoutMode);
      var config = {
          method: 'get',
          // url: 'https://kubotaservice--ksdev.sandbox.my.salesforce.com' + '/services/data/v54.0/ui-api/record-defaults/create/Case' + '?recordTypeId=' + recordTypeId,
          url: process.env.REACT_APP_API + 'salesforce/kservice/Get?param=ui-api/record-defaults/create/Case' + '%3FrecordTypeId=' + recordTypeId,
          headers: { 
              'Authorization': 'Bearer ' + Cookies.get("token"),
              'X-SalesForce-Token' : Cookies.get('sf_cookie'),
              'X-Id-Token' : Cookies.get('idToken')
              }
          };
 
      axios(config)
      .then(function (response) {
        /*
          This section is a mess, but it kicks off all essential states that need building
        */
          var JSONResponse = response.data;
          var TransformedJSON = recordLayout.getLayoutModelForDefaults(JSONResponse);
          TransformedJSON = applySectionOverrides(TransformedJSON);
          TransformedJSON = applyFieldOverrides(TransformedJSON);
          TransformedJSON = removeHiddenFields(TransformedJSON);
          
          //Pending for removal. Not used anymore
          var depList = flattenJSON(response.data.objectInfos.Case.dependentFields);
          setDependantFields(depList);
          //Clean up occurs inside here
          //DEP States control which DEP drowndowns are active/disabled
          fetchDEPStates(response.data.objectInfos.Case.dependentFields);
          //Picklists is both edit and read picklist values. Edit are the visible values given to drop downs, read are ALL of the possible values.
          fetchPicklists(JSONResponse.record.recordTypeId, depList);
          //EditValues is a list of current/default values for every field. This is used to generate the payload.
          if(!editValues) {
            prefillWrapper(TransformedJSON.editValues);
            //setEditValues(prefillWrapper(TransformedJSON.editValues));
          }
          //FetchResults is just used to single out objectInfo down below. Not too useful
          setFetchResults(TransformedJSON);
          //This objectInfo state is shared with the Parent, while fetchResults is shared with the children.Would be worth a refactor...
          setObjectInfo(TransformedJSON.objectInfo);
          //Error states is an bool array used to track a fields error status
          setErrorStates(fetchErrorState(TransformedJSON.objectInfo.fields));
          setRequiredFields(fetchRequiredFields(response.data.layout.sections));
      })
      .catch((error) => {
          if (error.response) {
                if(error.response.status == "401")
                {
                  // Handle this later through middleware.
                  // Token expired, fetch a new one.
                }
              }
      });

  }
  const setSerialModel = (editValues) => {
    //Clone Editvalues
    // var cloneEditValues = JSON.parse(JSON.stringify(editValues))
    // //Add Serial Model from params
    // if(serialNumber)
    // {
    //   cloneEditValues['Engine_SN__c'].current = serialNumber
    // }
    // if(modelNumber)
    // {
    //   cloneEditValues['Model_Number__c'].current = modelNumber;
    // }
    // //Set editvalues
    // setEditValues(cloneEditValues);
  }
  const fetchErrorState = (fields) => {
    var errorState = {};
    for(var key in fields)
    {
      errorState[key] = false;
    }
    return errorState;
  }
  const fetchRequiredFields = (fields) => {
    var reqFields = new Map();
    fields.forEach((section) => {
      section.layoutRows.forEach((layoutRow) => {
        layoutRow.layoutItems.forEach((layoutItem) => {
          //Checks SF Definition AND Firebase Definition
          if(layoutItem.required || RequiredFields.includes(layoutItem.layoutComponents[0].apiName))
          {
            var apiName = layoutItem.layoutComponents[0].apiName;
            reqFields.set(apiName, true);
          } 
        });
      });
    });

    

    return reqFields;
  }
  const fetchDEPStates = (dependantFields) => {
    //Generate default DEP state. By default only top level drop downs should be active.
    var StateArray = {};
    for(var field in dependantFields)
    {
      //This is the name that identifies a DEP tree/group, all items in a DEP tree will have this name
      var concatName = fetchDEPConcatName(dependantFields[field], field);
      StateArray[concatName] = [false];
      //By default all levels other than the top level will be disabled(false)
      var size = concatName.split(" ").length;
      for(var i = 1;i<size;i++)
      {
        StateArray[concatName].push(true);
      }
    }
    setDEPStates(StateArray);
  }
  const fetchDEPConcatName = (root, parent) => {
    var leaf = true;
    for(var child in root)
    {
      leaf = false;
      return parent + " " + fetchDEPConcatName(root[child], child);
    }
    if(leaf)
      return parent;
  }
  const fetchPicklists = (recordTypeID, depList) => {
    var config = {
    method: 'get',
    // url: 'https://kubotaservice--ksdev.sandbox.my.salesforce.com' + '/services/data/v54.0/ui-api/object-info/Case/picklist-values/' + recordTypeID,
    url: process.env.REACT_APP_API + 'salesforce/kservice/Get?param=ui-api/object-info/Case/picklist-values/' + recordTypeID,
    headers: { 
        'Authorization': 'Bearer ' + Cookies.get("token"),
        'X-SalesForce-Token' : Cookies.get('sf_cookie'),
        'X-Id-Token' : Cookies.get('idToken')
        }
    };
    axios(config)
          .then(function (response) {
            //Clean up DEP picklists
            //Clone the read side for edit side
            //LEFT OFF HERE
            var editSide = JSON.parse(JSON.stringify(response.data.picklistFieldValues));
            // for(var index in depList)
            // {
            //   editSide[depList[index]].values = null;
            // }
            setPickLists({
              read : response.data.picklistFieldValues,
              edit : editSide
            });
            console.log("Fetched picklists");
            setLoading(); 
          })
          .catch(function (error) {
            console.log(error);
          });
  }
  const flattenJSON = (root, array=[], topLevel=true) => {
    for(var propName in root) {
      if(root.hasOwnProperty(propName)) {
        if(!topLevel)
        {
          array.push(propName);
        }
        flattenJSON(root[propName], array, false);
        }
    }
    return array;
  };
  const handleDEPClick = (picklists, modalFields, editValues, e, data_dependency, data_dependency_level) => {
    var cloneEditValues = JSON.parse(JSON.stringify(editValues))
    //Call DEP Helper
    var deps = depGraphHelper.getParentToChildPicklistValues(picklists, modalFields, cloneEditValues);
    //Set picklist values
    for (const [key, value] of deps.entries()) {
      //Change the availible options 
      picklists.edit[key].values = value;
      //Current value, checking if this exists in the new acceptable options
      var target = cloneEditValues[key].current;
      var newValue = "";
      value.forEach((opt) => {
        if(target === opt.value)
          newValue = target;
      });
      //If value wasn't in our list of acceptable values, assume the 0th index as new value
      if(newValue === "")
      {
        //If list of acceptable values is empty, leave drop down empty
        if(value.length > 0)
          newValue = value[0].value;
        else
          newValue = null;
      }
      //Update our editValues array to reflect new current val
      cloneEditValues[key].current = newValue;
      console.log("Edit values changed");
    }

    //Any dropdown more than 1 level under should be disabled, directly under should be enabled

    //Since we moved DEP picklist over to MUI NativeSelect, the event target is no longer the select component with the element attributes.
    //Target is now a child element, so just grab the parent for these attributes
    //Applied for data-dependency and data-dependency-level
    var concatNameFromParent =  data_dependency;
    var concatName = concatNameFromParent;
    var level = Number(data_dependency_level);
    var newDEPState = JSON.parse(JSON.stringify(DEPStates));
    var maxLength = newDEPState[concatName].length;
    var fieldsToClear = concatName.split(" ");
    if(level < maxLength -1)
    {
      //Only enable if the value is useful. If it is "-None-" keep it disabled
      if(cloneEditValues[fieldsToClear[level+1]].current === "-None-" || cloneEditValues[fieldsToClear[level+1]].current === null)
        newDEPState[concatName][level+1] = true;
      else
        newDEPState[concatName][level+1] = false;
    }
    for(var i = level+2;i<maxLength;i++)
    {
      newDEPState[concatName][i] = true;
      //Also wipe its value in editValues
      cloneEditValues[fieldsToClear[i]].current = "-None-";
    }
    //End of dropdown enable/disable logic
    setEditValues(cloneEditValues);
    setDEPStates(newDEPState);
  }
  const handleDEPClickOriginal = (picklists, modalFields, editValues, e) => {
    var cloneEditValues = JSON.parse(JSON.stringify(editValues))
    //Call DEP Helper
    var deps = depGraphHelper.getParentToChildPicklistValues(picklists, modalFields, cloneEditValues);
    //Set picklist values
    for (const [key, value] of deps.entries()) {
      //Change the availible options 
      picklists.edit[key].values = value;
      //Current value, checking if this exists in the new acceptable options
      var target = cloneEditValues[key].current;
      var newValue = "";
      value.forEach((opt) => {
        if(target === opt.value)
          newValue = target;
      });
      //If value wasn't in our list of acceptable values, assume the 0th index as new value
      if(newValue === "")
      {
        //If list of acceptable values is empty, leave drop down empty
        if(value.length > 0)
          newValue = value[0].value;
        else
          newValue = null;
      }
      //Update our editValues array to reflect new current val
      cloneEditValues[key].current = newValue;
      console.log("Edit values changed");
    }

    //Any dropdown more than 1 level under should be disabled, directly under should be enabled

    //Since we moved DEP picklist over to MUI NativeSelect, the event target is no longer the select component with the element attributes.
    //Target is now a child element, so just grab the parent for these attributes
    //Applied for data-dependency and data-dependency-level
    var concatNameFromParent =  e.currentTarget.parentElement.attributes.getNamedItem('data-dependency').value;
    var concatName = concatNameFromParent;
    var level = Number(e.currentTarget.parentElement.attributes.getNamedItem('data-dependency-level').value);
    var newDEPState = JSON.parse(JSON.stringify(DEPStates));
    var maxLength = newDEPState[concatName].length;
    var fieldsToClear = concatName.split(" ");
    if(level < maxLength -1)
    {
      //Only enable if the value is useful. If it is "-None-" keep it disabled
      if(cloneEditValues[fieldsToClear[level+1]].current === "-None-" || cloneEditValues[fieldsToClear[level+1]].current === null)
        newDEPState[concatName][level+1] = true;
      else
        newDEPState[concatName][level+1] = false;
    }
    for(var i = level+2;i<maxLength;i++)
    {
      newDEPState[concatName][i] = true;
      //Also wipe its value in editValues
      cloneEditValues[fieldsToClear[i]].current = "-None-";
    }
    //End of dropdown enable/disable logic
    setEditValues(cloneEditValues);
    setDEPStates(newDEPState);
  }
  const onFieldValueUpdate = (field, value, e, isDEP = false, modalFields = null, data_dependency, data_dependency_level  ) => {
    //Whenever editValues needs to be updated, I run into a bug where React will not re-render
    //To force this I just clone editValues so when its time to setEditValues() react see's a 'new' value
    //And rerenders : )
    var cloneEditValues = JSON.parse(JSON.stringify(editValues))
    var dataType = fetchResults.objectInfo.fields[field].dataType;
    if(isValidInput(dataType,value)) {
      if(fieldWasRequiredAndPreviouslyEmpty(field)) {
        //Field was marked as error since required && empty. Unmark field as error.
        setErrorStates(prevState => ({
            ...prevState,
            [field]: false,
          }));
      }
      cloneEditValues[field].current = value;
    }
    else {
      e.currentTarget.value = editValues[field].current;
    }

    if(isDEP) {
      handleDEPClick(PickLists, modalFields, cloneEditValues,e, data_dependency, data_dependency_level );
    }
    else {
      setEditValues(cloneEditValues);
    }
  }
  const fieldWasRequiredAndPreviouslyEmpty = (field) => {
    var isRequired = RequiredFields.get(field);
    var previouslyEmpty = editValues[field].current;
    if(isRequired && !previouslyEmpty)
    {
      return true;
    }
    return false;
  }
  const isValidInput = (dataType, input) => {
    /*
      This function is used to validate inputs across different inputTypes
      EX: Currency or Number fields only accept numeric inputs, all others are rejected
      Can be expanded by adding switch cases, each case can have its own regex. Should be future proof.

      Base case is true
    */
    var regex;
    switch (dataType)
    {
      case "Double":
      case "Percent":
      case "Currency":
        regex = new RegExp(/^\d+$/);
        return regex.test(input);
      case "Phone":
        return true;
      default:
        return true;
    }
  }
  const applySectionOverrides = (TransformedJSON) => {
    var sections = TransformedJSON.layouts.Full.Create;
    sections = sections.filter(x => !hiddenSections.includes(x.heading))
    TransformedJSON.layouts.Full.Create = sections;
    return TransformedJSON;
  }
  const removeHiddenFields = (TransformedJSON) => {
    var sections = TransformedJSON.layouts.Full.Create;
    sections.forEach((section) => { 
      var rowsToRemove = [];
      section.rows.forEach((row, rowIndex) => {
        // Check row for hidden Fields, if found remove from row
        let itemsToRemove = [];
        row.items.forEach((rowItem, index) => {
          if(rowItem.values[0]) {
            if(hiddenFields.includes(rowItem.values[0].fieldInfo.apiName)) {
              itemsToRemove.push(index);
            }
          }
        });
        if(itemsToRemove.length > 0) {
          if(row.items.length == itemsToRemove.length) {
            rowsToRemove.push(rowIndex);
          } else {
            //Remove index from row
            itemsToRemove.forEach((indexToRemove) => {
              row.items.splice(indexToRemove,1)
            });
          }
        }
      });
      var section = section;
      if(rowsToRemove.length > 0) {
        rowsToRemove.reverse().forEach((indexToRemove) => {
          section.rows.splice(indexToRemove,1);
        });
      }
    });
    return TransformedJSON;
  }
  //Uses fieldOverrides(from RemoteConfig) array to manually set any present field as editable, regardless of its SF definition.
  const applyFieldOverrides = (TransformedJSON) => {
    TransformedJSON.layouts.Full.Create.forEach((section) => {
      section.rows.forEach((row) => {
        row.items.forEach((item) => {
          if(item.values[0]) {
            let apiName = item.values[0].fieldInfo.apiName;
            if(fieldOverrides.readonly.includes(apiName)) {
              item.values[0].editableForUpdate = false;
              item.values[0].editableForNew = false;
            } else if(fieldOverrides.editable.includes(apiName)) {
              item.values[0].editableForUpdate = true;
              item.values[0].editableForNew = true;
            }
          }

        });
      });
    });
    
    return TransformedJSON;
  }

     return ( 
        <div>
            {loading &&
                <div className={classes.loaderCenter}>
                <CircularProgress className={classes.loadingCenterInside} />
                </div>
            }
            {fetchResults && editValues && PickLists && !loading && ErrorStates && RequiredFields &&
                <SFContainer
                RecordID = {0}
                Record = {fetchResults}
                layoutMode = {"Full"}
                Action = {'Create'}
                allowEdit = {true}
                objectInfo={fetchResults.objectInfo}
                editValues={editValues}
                picklists={PickLists}
                onFieldValueUpdate={onFieldValueUpdate}
                DEPStates={DEPStates}
                ErrorStates = {ErrorStates}
                reqFields = {RequiredFields}
                setErrorStates = {setErrorStates}
                setStep={setStep}
                createCase={createCase}
                caseOrReport={caseOrReport}
                defaultExpanded={true}
                />
            }
        </div> 
  );
}