import React from 'react'
import TextField from '@material-ui/core/TextField';


function SFPercent({component, currentVal, onFieldValueUpdate, itemLabel, i, ErrorStates, classes}) {
  const onBlur = (e) => {
    //OnBlur reapply the % to the input field(not the react component)
    if(e.currentTarget.value)
      e.currentTarget.value = e.currentTarget.value + '%';
  }

  const onFocus = (e) => {
    //On focus, remove the % in the input field(not the react component)
    var value = e.currentTarget.value;
    if(value.includes('%'))
    {
      e.currentTarget.value = value.replace('%','');
    }
  }
  return (
    <TextField
        className={classes.outlineInput}
        variant="outlined"
        id={component.label}
        name={component.label}
        label={component.label}
        fullWidth
        autoComplete={component.label}
        onChange={(event) => onFieldValueUpdate(component.field, event.target.value, event)}
        defaultValue={currentVal}
        key={'componentInput' + itemLabel + ',' + i}
        data-isnull={component.isNull}
        data-datatype={component.fieldInfo.dataType}
        onFocus={(event) => onFocus(event)}
        onBlur = {(event) => onBlur(event)}
        error={ErrorStates[component.field]}
    />

    //  <input
    //     type={"text"}
    //     onFocus={(event) => onFocus(event)}
    //     onBlur = {(event) => onBlur(event)}
    //     className="fieldEdit"
    //     name={component.field}
    //     value={currentVal}
    //     onChange={(event) => onFieldValueUpdate(component.field, event.target.value, event)}
    //     key={'componentInput' + itemLabel + ',' + i}
    //     data-isnull={component.isNull}
    //     data-datatype={component.fieldInfo.dataType}/>
  )
}

export default SFPercent