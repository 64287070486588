
export default class ApiManager {
    
    // //TST1 REPORT ID's
    static contactReportId = 102751;    
    static casesReportId = 102756;
    static incidentAttachments = 102764;
    static incidentThreads = 102763;
    static notificationsReportId = 102755;
    static getIncidentID = 102760;

    //Dealer Lookup reports
    static getDealerByCity = 102758;
    static getDealerByState = 102766;
    static getDealerByName = 102767;
    static getDealerByCityState = 102765;
    static getDealerByCityName = 102770;
    static getDealerByStateName = 102769;
    static getDealerByCityStateName = 102768;
    
    // PROD
    // static contactReportId = 102764;    
    // static casesReportId = 102767;
    // static casesReportId = 102778;
    // static incidentAttachments = 102766;
    // static incidentThreads = 102768;
    // static notificationsReportId = 102769;
    // static getIncidentID = 102771;

    // //Dealer Lookup reports
    // static getDealerByCity = 102770;
    // static getDealerByState = 102772;
    // static getDealerByName = 102773;
    // static getDealerByCityState = 102774;
    // static getDealerByCityName = 102775;
    // static getDealerByStateName = 102776;
    // static getDealerByCityStateName = 102777;


    static csvToJSON(response) {
        var list = [];
        var columnNames = response.columnNames;
        var rows = response.rows;

        rows.forEach((rowItem) => {
            var edata = {};
            for (let col = 0; col < columnNames.length; col++) {
                let key = columnNames[col];
                edata[key] = rowItem[col];
            }
            list.push(edata);
        });
        return list;
    }
}
