  import React from 'react'
import { useEffect } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@material-ui/core';


function SFDateTime({component, editValues, field, classes}) {
    const [Date, setDate] = useState();
    const Time =  "T12:00:00Z";

    useEffect(() => {
        changeDate();
    }, [Date]);

    const changeDate = () => {
        if(Date)
        {
            var newDate = Date.format('YYYY-MM-DD') + Time;
            editValues[field].current = newDate;
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                        inputFormat="MM/DD/YYYY"
                        error={true}
                        helperText={"Test error"}
                        value={Date}
                        onChange={ (newDate) => {
                            setDate(newDate);
                        }}
                        label={component.label}
                        className={classes.halfWidth}
                        renderInput={(params) => {
                            return (
                                <TextField 
                                    {...params}
                                    variant = {"outlined"} 
                                />
                            )
                        }}
                    />
        </LocalizationProvider>
    )
}

export default SFDateTime