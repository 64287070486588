import React from 'react'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import NativeSelect from '@mui/material/NativeSelect';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Typography } from '@mui/material';




function SFPicklistTest({component,  itemLabel, i, editValues, columnSize, picklists, objectInfo,onFieldValueUpdate, DEPStates, classes, ErrorStates}) {
    let currPicklistValue = "";
    let picklistValues = [];
    currPicklistValue = editValues[component.field].current;
    if (picklists && picklists.read[component.field]) {
      picklistValues = picklists.read[component.field].values;
    } else {
      // picklist collection fetch has not completed yet, or the field is somehow missing from its results.
      // add the current value so that it shows nicely in the UI.
      picklistValues.push({value: component.value, label: component.displayValue})
    }
  if (objectInfo
    && ((component.field && component.field in objectInfo.dependentFields)
       || (component.fieldInfo && component.fieldInfo.controllingFields.length > 0))) {
          //If in a DEP change picklist to use the EDIT side of picklist values. These are dynamic and will be updated.
          picklistValues = picklists.edit[component.field].values;
          // last field in controlling fields is the root field
          let lastControllingIndex =  component.fieldInfo.controllingFields.length - 1
          let rootField = null
          if (component.field in objectInfo.dependentFields){
            rootField = component.field
          } else {
            rootField = component.fieldInfo.controllingFields[lastControllingIndex]
          }
          // retrieve the picklist fields that need to show up
          const subfieldTree = objectInfo.dependentFields[rootField]
          const modalFields = getFlattenedTree(objectInfo, subfieldTree, rootField)

          //Create a name for the dependency tree. Format is each node concat with " " delimit
          var concatName = "";
          //We also need the level within the DEP tree
          var level;
          modalFields.forEach((field, index) => {
            concatName += field.apiName + " ";
            if(component.field === field.apiName)
              level = index;
          });
          concatName = concatName.slice(0, -1);//Remove trailing space
          // open a modal on click of input field
          let fieldTree = {}
          fieldTree[rootField] = subfieldTree;
          var options = [];
          if(picklistValues)
          {
            picklistValues.map((picklistValue) => {
              options.push({
                label : picklistValue.label,
                value : picklistValue.value
              })
            });
          }
        return (
          <FormControl fullWidth variant={"outlined"} error={ErrorStates[component.field]}>
            <InputLabel shrink={(currPicklistValue != null && currPicklistValue !== "-None-") ? true : false} id="demo-simple-select-autowidth-label">{component.label}</InputLabel>
            <Select
              className={classes.outlineInput}
              disabled={DEPStates[concatName][level]}
              variant={"outlined"}
              data-dependency={concatName}
              data-dependency-level={level}
              key={"picklist-" + i}
              isOptionDisabled = {true}
              value={currPicklistValue}
              onChange={(e) => onFieldValueUpdate(component.field, e.target.value, e, true, modalFields,concatName, level)}
              >
              
              { picklistValues.map((picklistValue, index) => {
                return (
                  <MenuItem 
                    value={picklistValue.value}
                    key={"MenuItem"+i+index}
                  >
                      {picklistValue.label}
                  </MenuItem>
                );
              })
              }
          </Select>
        </FormControl>
      );
  }
  //Non dep picklist
  else {
    return (
      <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{component.label}</InputLabel>
            <Select
            labelId={"picklist-" + i}
            className={classes.outlineInput}
            id={"picklist-" + i}
            value={currPicklistValue}
            label={component.label}
            variant={'outlined'}
            onChange={(e) => onFieldValueUpdate(component.field, e.target.value, e)}
            >
            { picklistValues.map((picklistValue) => {
              return(
                <MenuItem value = {picklistValue.value} key={'MenuItem' + itemLabel + ',' + i}>
                  {picklistValue.label}
                </MenuItem>
              );
            })}
          </Select>
      </FormControl>    
          );
    }
      
}

const getFlattenedTree = (objectInfo, fieldTree, rootField) => {
  const flattenedTree = []
  flattenedTree.push(objectInfo.fields[rootField])
  doFlattenTree(objectInfo, fieldTree, flattenedTree)
  return flattenedTree
}

const doFlattenTree = (objectInfo, tree, flattenedTree) => {
  if (tree) {
    let treeKeys = Object.keys(tree)
    for (var treeKey in treeKeys){
      flattenedTree.push(objectInfo.fields[treeKeys[treeKey]])
      doFlattenTree(objectInfo, tree[treeKeys[treeKey]], flattenedTree)
    }
  }
}

export default SFPicklistTest