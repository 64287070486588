import { Box, Container, Divider, FormControl, Grid, MenuItem, Paper, Select, Tab, Tabs, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react'
import AdminNotificationHistoryTable from '../components/AdminNotificationHistoryTable';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import AdminNotificationForm from '../components/AdminNotificationForm';

const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 500,
      backgroundColor: "white",
      padding: theme.spacing(0, 1, 1)
    },
    heroContent: {
      padding: theme.spacing(8, 0, 6),
      width: "90%",
    },
    heroPaper: {
      padding: theme.spacing(1, 2, 2),
      margin: theme.spacing(0, 0, 4)
    }
  }));

function NotificationDashboard() {
    const classes = useStyles();
    useEffect(() => {
       fetchFromFirebase();
    }, []);

    function fetchFromFirebase(){
        let fetchNotifRequest = {
            url: process.env.REACT_APP_API + 'firebase/kservice/FetchPushNotifications',
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + Cookies.get("token")
              }
        }
        axios(fetchNotifRequest)
            .then((req) => {
                let notifs = req.data.notifications;
                setNotificationHistory(notifs.reverse());
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const [selectedTab, setSelectedTab] = useState("Send");
    const [notificationHistory, setNotificationHistory] = useState();
    return (
            <Container maxWidth="lg" className={classes.heroContent}>
                <Paper elevation={3}>
                    <Grid container>
                        <Grid item sx={{display: "flex", justifyContent: "center", }}>
                            {/* <Typography variant='h3'>
                                Notification Dashboard
                            </Typography> */}
                        </Grid>
                        <Grid item md={12}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs 
                                    value={selectedTab}
                                    indicatorColor="primary"
                                    textColor="primary" 
                                    onChange={(e, v) => {
                                        setSelectedTab(v);
                                    }} 
                                    aria-label="select article type"
                                    centered
                                >
                                    <Tab value="Send" label="Send" />    
                                    <Tab value="History" label="History" />                        
                    
                                </Tabs>
                                <Divider/>
                            </Box>
                        </Grid>
                        <Grid item md={12}>
                            { selectedTab == "History" &&
                                <AdminNotificationHistoryTable
                                    notifications={notificationHistory}
                                />
                            }
                            { selectedTab == "Send" &&
                                <AdminNotificationForm
                                    refetchFromFirebase={fetchFromFirebase}
                                />
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
    )
}

export default NotificationDashboard