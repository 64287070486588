import React, { useReducer } from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Alert from '@material-ui/lab/Alert';
import Pagination from '@material-ui/lab/Pagination';
import { flexbox, spacing } from '@material-ui/system';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Fab } from '@material-ui/core';
import CropFreeIcon from '@material-ui/icons/CropFreeSharp';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import ApiManager from '../apiservice';
import Cookies from 'js-cookie';

const cancelToken = axios.CancelToken;
const cancel = cancelToken.source();

const useStyles = makeStyles((theme) => ({
  fab: {

  },
  horizFlex: {
    // display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
  },
  flexbox: {
    display: 'flex',
    position: 'fixed',
    width: '100%',
    justifyContent: 'center',
    zIndex: '1',
    bottom: theme.spacing(10)
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
    width: '90%'
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  button: {
    margin: theme.spacing(0, 0, 3)
  },
  cancelButton: {
    margin: theme.spacing(1, 0, 1)
  },
  eyebrow: {
    margin: theme.spacing(0, 0, 3)
  },
  center: {
    textAlign: 'center'
  },
  left: {
    textAlign: 'left'
  },
  loaderCenter: {
    marginTop: '10%',
    marginLeft: '42%',
  }

}));

export const MasterCodeLookupPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const [serialNumber, setSerialNumber] = useState('');
  const [results, setResults] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchError, setSearchError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  
 
  
  // We only need if we have a submit button
  const handleSubmit = (e) => {
    e.preventDefault();
    if(serialNumber != '' && errorMessage == ''){
      setLoading(true);
      var masterKeyReq = {
        method : "post",
        url : process.env.REACT_APP_API + "custom/masterkey/MasterKey",
        headers : {
          'Authorization': 'Bearer ' + Cookies.get("token")
        },
        data : {
          "platform" : "k-service_android",
          "sn" : serialNumber,
          "dealership" : '',
          "user" : ""
        }
      }
      axios(masterKeyReq).then((resp) => {
        var test = resp;
        debugger;
        if(resp.data.status === "Success.")
        {
          setResults(resp.data.masterKey);
          setLoading(false);
        }
        else{
          setLoading(false);
        }

      }).catch((error) => {
        console.log(error);
        setLoading(false);
        setErrorMessage(error);
      });
    }
  };

  const validate = (e) => {
    var sn = e.target.value;
    if(sn.length != 0)
    {
      if(sn.length < 6 || sn.length > 11)
      {
        setErrorMessage("Serial Number must be between 6-11 digits");
      }
      //Regex for a string being entirely composed of numbers
      else if(sn.match('^[0-9]*$') == null)
      {
        setErrorMessage("Serial Number only accepts numbers. No symbols, spaces or alphabetical characters are allowed.");
      }
      else
      {
        setErrorMessage('');
      }
    }
  }

  const handleReset = () => {
    setSerialNumber("");
    setResults("");
  }

  

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.heroContent}>
      <Grid container spacing={2}>
      <Grid item md={4} xs={false}></Grid>
        <Grid item md={4} xs={12}>
          <Paper elevation={3} className={classes.heroPaper} justify="center">
           
            <Typography component="h1" variant="h5">
              Keyless Master Password
            </Typography>
            <Divider class={classes.eyebrow}/>
              <React.Fragment>
              <div className={classes.center}>
            </div>
            {!results && 
            <React.Fragment>
                <Typography variant="subtitle2" className={classes.center}>
                Enter the DCU module serial number 
                </Typography>
                <form className={classes.form} noValidate onSubmit={e => { e.preventDefault(); }}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="serialNumber"
                    label="Serial Number"
                    name="serialNumber"
                    value={serialNumber}
                    autoFocus
                    helperText={errorMessage === "" ? '' : errorMessage}
                    onChange={(e) => {
                      setSerialNumber(e.target.value);
                      validate(e);
                      }}
                  onBlur={(e) => { validate(e); }}

                />
                <Button type="submit" fullWidth variant="contained" color="primary" size="medium" className={classes.submit} onClick={handleSubmit} >Submit</Button>
                </form>
                
                {searchError &&
                <Alert severity="error">{searchError}</Alert>
                }
                
            </React.Fragment>
        }
        {results &&
            <React.Fragment>
                <Typography variant="subtitle1" className={classes.left}>
                    Serial Number: {serialNumber} 
                </Typography>
                <Typography variant="subtitle1" className={classes.left}>
                    Master Password: {results}
                </Typography>

                <form className={classes.form} noValidate onSubmit={e => { e.preventDefault(); }}>
                    <Button type="submit" fullWidth variant="contained" color="primary" size="medium" className={classes.submit} onClick={handleReset} >Back to Lookup Form</Button>
                </form>
            </React.Fragment>
        }
              </React.Fragment>
              {loading &&
                <div className={classes.loaderCenter}>
                  <CircularProgress className={classes.loadingCenterInside} />
                </div>
              }
          </Paper>
        </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
