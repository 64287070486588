import React, { useReducer } from 'react';
import { useEffect, useState } from 'react';
import SFContainer from '../components/SFContainer';
import axios from 'axios';
import recordLayout from '../helpers/recordLayout'
import {useParams} from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, ThemeProvider, createTheme} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { saveAs } from 'file-saver';
import heic2any from "heic2any";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GetAppIcon from '@material-ui/icons/GetApp';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { IconButton } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import { TextField, MenuItem, InputLabel, Select, Link, ImageList, ImageListItem } from '@material-ui/core';
import { Card, CardHeader, CardActionArea, CardMedia, CardContent } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { useHistory } from "react-router-dom";


import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cookies, { set } from 'js-cookie';
import useWindowDimensions from '../helpers/windowDimensions';
import { typography } from '@mui/system';
import { blue, red } from '@material-ui/core/colors';
import getFileIconByExtn from '../helpers/files/getFileIconByExtn';
import getMimeTypeByExtn from '../helpers/files/getMimeTypeByExtn';
import { convertPPDSchemaIntoJson } from '../helpers/ppd/convertPPDSchemaIntoJson';


const useStyles = makeStyles((theme) => ({
  heroContent: {
    width: "90%",
    padding: theme.spacing(8, 0, 6),
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  divider: {
    margin: theme.spacing(0,0,2)
  },
  form: {
    margin: theme.spacing(0, 0, 5)
  },
  loaderCenter: {
    marginLeft: '50%',
  },
  loadingCenterInside: {
    left: '-20px',
    position: 'relative'
  },
  listItem: {
    width: '90%',
    display: "inline-block"
  },
  update: {
    margin: theme.spacing(4,0,0,0),
  },
  upload: {
    margin: theme.spacing(1,0,0,0)
  },
  marginAuto: {
    margin: theme.spacing(0)
  },
  closeCaseText: {
    margin: theme.spacing(0,0,0,1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  heading: {
    width: '100%',
    padding: theme.spacing(0, 1, 0, 0)
  },
  bold: {
    fontWeight: '800'
  },
  floatRight: {
    float: 'right'
  },
  bottomMargin: {
    marginBottom: "10px"
  },
  additionalInfoHeader: {
    margin: theme.spacing(1,0)
  }
}));

const CaseViewPage = () => {
    const id = useParams();
    const history = useHistory();
    const classes = useStyles();
    const [searchResults, setSearchResults] = useState(false);
    const [ID, setID] = useState(0);
    const [loading, setLoading] = useState(false);
    const { height, width } = useWindowDimensions();
    const [layoutType, setLayoutType] = useState("");
    const [threads, setThreads] = useState();
    const [caseImages, setCaseImages] = useState();
    const [caseAttachments, setAttachments] = useState();
    const [open, setOpen] = useState(false);
    const [modalContent, setModalContent] = useState();
    const [imageState, updateImageState] = useState(false);
    const [formData, updateFormData] = useState('');
    const [response, updateResponse] = useState();
    const [closeCase,setCloseCase] = useState(false);
    const [caseNotFound, setCaseNotFound] = useState(false);
    const [caseErrorMessage, setCaseErrorMessage] = useState('');
    const [tavant, setTavant] = useState();
    const [predictionResults, setPredictionResults] = useState();
    const [machineInformation, setMachineInformation] = useState();

    useEffect(() => {
        // On Component Load
        setLoading(true);
        fetchRecord();
        fetchAttachments();
        getThreads('');
        fetchPredictionData();
    }, []);

    useEffect(() => {
      if(searchResults && !tavant) {
        getTavant();
        extractMachineInformation();
      }
    }, [searchResults]);


    function getTavant(){
      setLoading(true);
      var fields = searchResults.record.fields;
      let serialNumber = (fields.Serial_Number__c) ? fields.Serial_Number__c.value : null;
      let modelNumber = (fields.Model_Number__c) ? fields.Model_Number__c.value : null;
      if(!serialNumber || !modelNumber) {
        setLoading(false);
        return;
      }
      const url = process.env.REACT_APP_API + 'Salesforce/kwarranty/SearchInventory';
      const req = {
        method: "post",
        url: url,
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("token"),
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          "materialNumber": modelNumber,
          "serialNumber": serialNumber,
          "productIdentificationNo": ""
        })
      }
      
      axios(req)
        .then((response) => {
          //  console.log("Get Tavant Response");
          //  console.log(response.data);
          console.log("Tavant being set");
          setTavant(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        })
      }
    function extractMachineInformation(){
      if(!machineInformation) {
        let serial = searchResults.record.fields.Serial_Number__c.value;
        let model = searchResults.record.fields.Model_Number__c.value;
        setMachineInformation({
          model: model,
          serial: serial,
        });
      }
    }
    const fetchRecord = () => {
      var qs = require('qs');
      var layoutMode;
      // (width > 720) ? layoutMode = "Full" : layoutMode = "Compact";
      layoutMode = "Full"
      setLayoutType(layoutMode);
      //'https://kubotaservice--ksdev.sandbox.my.site.com/services/data/v43.0/ui-api/record-ui/' + id.id + "?layoutTypes=" + layoutMode
      var config = {
          method: 'get',
          url: process.env.REACT_APP_API + 'salesforce/kservice/GET?param=ui-api/record-ui/' + id.id + "?layoutTypes=" + layoutMode,
          headers: { 
              'Authorization': 'Bearer ' + Cookies.get("token"),
              'X-SalesForce-Token' : Cookies.get('sf_cookie'),
              'X-Id-Token' : Cookies.get('idToken')
              }
      };
      axios(config)
      .then(function (response) {
          var JSONResponse = response.data;
          var TransformedJSON = recordLayout.getLayoutModel(id.id,JSONResponse);
          setSearchResults(TransformedJSON);
          setID(id);
      })
      .catch((error) => {
        if(error.response && error.response.status == "401")
        {
          console.log("Invalid token, need to reauth");
          // Took out logic to grab a new token.
        } else  if (error.response.status == "404" || error.response.status == "400"){
          setLoading(false);
          setCaseNotFound(true);
          setCaseErrorMessage("The Case you requested was not found. If you believe this to be an error please contact your Administrator.")
          console.log(error);
        }
      })
    }

    function fetchAttachments (){
      var images = [];
      var other = [];
      var promiseArray = [];

      // Get related Feed Attachments of Case
      let feedAttachmentsQuery = "SELECT Attachment_Name__c, Type__c, Url__c FROM Feed_Attachment__c WHERE Feed_Item__r.Case__c = \'"+id.id+"\'";
      feedAttachmentsQuery = encodeURIComponent(feedAttachmentsQuery);

      //let feedAttachmentsURL = 'https://kubotaservice--ksdev.sandbox.my.salesforce.com' + 
      //'/services/data/v56.0/query/?q='+feedAttachmentsQuery;
      let feedAttachmentsURL = process.env.REACT_APP_API + 'salesforce/kservice/get?param=query/?q=' +feedAttachmentsQuery;

      let feedAttachmentsReq = {
        method: "GET",
        url: feedAttachmentsURL,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + Cookies.get("token"),
          'X-SalesForce-Token' : Cookies.get('sf_cookie'),
          'X-Id-Token' : Cookies.get('idToken')
        }
      };

      axios(feedAttachmentsReq).then((feedAttachmentsRes) => {
        if(feedAttachmentsRes.data){
          let attachments = feedAttachmentsRes.data.records;
          for(const attachment of attachments){
            promiseArray.push(fetchAttachmentHelper(images, other, attachment));
          }
          Promise.all(promiseArray).then(() => {
            setCaseImages(images);
            setAttachments(other);
          }).catch( (error) => {
            console.log(error);
          })
        }
      });
    }
    function fetchPredictionData(){
      var req = {
        url: process.env.REACT_APP_API + `salesforce/kservice/apexrest?param=Predictions/fetchPPD?caseId=${id.id}`,
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("token"),
          'Content-Type': 'application/json'
        },
      }
      axios(req)
        .then((response) => {
          //
          if(Array.isArray(response.data)) {
            if(response.data.length > 0) {
              //Case has PPD data
              let convertedJSON = convertPPDSchemaIntoJson(response.data);
              setPredictionResults(convertedJSON);
            } else {
              //Case has no PPD data. No action
            }
          } else {
            //Return data is not an array. Error
          }
        })
        .catch((error) => {
          //No prediction data
          debugger;
        });
    }

    const handleOpen = (e) => {
      console.log(e);
      console.log(e.target.outerHTML);
      setModalContent(<div dangerouslySetInnerHTML={{__html: e.target.outerHTML}} />);
      console.log(modalContent);
      setOpen(true);
    };
    const handlePredictionClick = () => {
      //Gather machine info and naviagte to create predicton flow
      debugger;
      if(machineInformation) {
        history.push(`/support/machine/prediction/create/sn-${machineInformation.serial}/mn-${machineInformation.model}/case-${id.id}`);
      }










    }
    const handleChange = (e) => {
      let images = null;
  
      if(imageState){
        images = imageState;
      }else{
        images = [];
      }
  
      var fileName;
      if(e.target.name == "attachments"){
        for(var x = 0; x < e.target.files.length; x++){
              const blob = e.target.files[x];
              fileName = e.target.files[x].name;
              //CHECK IF HEIC
              if(blob.type.toLowerCase() === "image/heic")
              {
                heic2any({ blob: blob, toType: "image/jpeg", quality: 1 }).then(
                  (newImage) => {
                    console.log("new image:", newImage);
                    var reader = new FileReader();
                    reader.readAsDataURL(newImage);
                    reader.onloadend = function(e){
                      var isHeif = (fileName.toLowerCase().includes(".heif")) ? true : false;
                      let thisImage = {
                        imageName: (isHeif) ? fileName.replace(".heif",".jpeg") : fileName.replace(".heic",".jpeg"),
                        imageType: "image/jpg",
                        localSrc: e.currentTarget.result,
                        base64: e.currentTarget.result.replace(/^data:image\/[a-z]+;base64,/, "")
                    };
                    images.push(thisImage);
                    let tempImages = images.slice();
                    updateImageState( tempImages );
                    }
                  }
                );
              }
              else {
              let thename = e.target.files[x].name;
                var reader = new FileReader();
                 reader.readAsDataURL(blob);
                 reader.onloadend = function(e) {
                  var test = e;
                   var base64data = e.currentTarget.result;
                   let thisImage = {
                        imageName: thename,
                        imageType: "image/jpg",
                        localSrc: base64data,
                        base64: base64data.replace(/^data:image\/[a-z]+;base64,/, "")
                    };
                    images.push(thisImage);
                    let tempImages = images.slice();
                    updateImageState( tempImages );
                 }
                }
            }
      }else{
        updateFormData({
          ...formData,
          // Trimming any whitespace
          [e.target.name]: e.target.value.trim()
        });
      }
    };

    const handleSubmit = (e) => {
      e.preventDefault()
      setLoading(true);
      var comment = formData;

      // Check if file is attached
      handleThreadUpdatesAndImages(comment)
        .then((resolved) => {
          if (closeCase) {
            //'https://kubotaservice--ksdev.sandbox.my.salesforce.com/services/data/v56.0/sobjects/Case/' + id.id
            // process.env.REACT_APP_API + 'salesforce/kservice/PATCH?param=sobjects/Case/' + id.id
            var updateCaseReq = {
              method: 'put',
              url: process.env.REACT_APP_API + 'salesforce/kservice/PATCH?param=sobjects/Case/' + id.id,
              headers: {
                'Authorization': 'Bearer ' + Cookies.get("token"),
                'X-SalesForce-Token' : Cookies.get('sf_cookie'),
                'X-Id-Token' : Cookies.get('idToken')
              },
              data: {
                    "Status": "Resolved"
              }
            };
            axios(updateCaseReq)
                .then((resp) => {
                    window.location.reload();
                    console.log("Closed Case");
                  }
                )
                .catch((e) => {
                  setLoading(false);
                  console.log(e);
                });
          }
          else {
            setLoading(false);
            window.location.reload();
          }
        })
        .catch((e) => {
          console.log("resolving case failed");
          console.log(e);
        })
    };

    function  handleThreadUpdateContent(comment) {
      return new Promise((resolve,reject) => {
        if(imageState) {
          var url;
          var createPayload;
          var req;

          // Post file on Case record
          // url = 'https://kubotaservice--ksdev.sandbox.my.salesforce.com' + 
          // '/services/data/v56.0/sobjects/ContentVersion';
          url = process.env.REACT_APP_API + 'salesforce/kservice/Post?param=sobjects/ContentVersion';

          createPayload = { 
            "Title": imageState[0].imageName,
            "PathOnClient": imageState[0].imageName,
            "ContentLocation" : 'S',
            "FirstPublishLocationId": id.id,
            "versionData": imageState[0].base64,
            "NetworkId": Cookies.get('networkId')
          };

          req = {
            method : 'POST',
            url : url,
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + Cookies.get("token"),
              'X-SalesForce-Token' : Cookies.get('sf_cookie'),
              'X-Id-Token' : Cookies.get('idToken')
            },
            data : createPayload
          }
          axios(req).then((response) => {
            //Get ContentDocument Id from ContentVersion using ContentVersion Id (response.data.id)
            let qImg = "SELECT ContentDocumentId, ContentSize, FileExtension, FileType, PathOnClient, VersionDataUrl FROM ContentVersion WHERE id = \'"+response.data.id+"\'";
            qImg = encodeURIComponent(qImg);
            //let urlImg = 'https://kubotaservice--ksdev.sandbox.my.salesforce.com' + 
            //'/services/data/v56.0/query/?q='+qImg;
            let urlImg = process.env.REACT_APP_API + 'salesforce/kservice/get?param=query/?q=' + qImg;

            let reqImg = {
              method: "GET",
              url: urlImg,
              headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get("token"),
                'X-SalesForce-Token' : Cookies.get('sf_cookie'),
                'X-Id-Token' : Cookies.get('idToken')
              }
            };

            axios(reqImg).then((responseImg) => {
              let imgId = responseImg.data.records[0].ContentDocumentId;
              
              // POST Feed Item record with file
              //let feedItemURL = 'https://kubotaservice--ksdev.sandbox.my.salesforce.com' + 
              //'/services/data/v56.0/ui-api/records';
              let feedItemURL = process.env.REACT_APP_API + 'salesforce/kservice/Post?param=ui-api/records';
              let feedItemPayload = {
                "apiName": "Feed_Item__c",
                "fields": {
                  "Body__c": comment.thread,
                  "Case__c": id.id,
                  "Channel__c": "Web",
                  "Name": imgId,
                  "Parent_Id__c": id.id,
                  "Type__c": "Customer",
                  "Title__c": "Service Web"
                }
              }
              let feedItemReq = {
                method : 'POST',
                url : feedItemURL,
                headers: {
                  'Content-Type': 'application/json; charset=UTF-8',
                  'Accept': 'application/json',
                  'Authorization': 'Bearer ' + Cookies.get("token"),
                  'X-SalesForce-Token' : Cookies.get('sf_cookie'),
                  'X-Id-Token' : Cookies.get('idToken')
                },
                data : feedItemPayload
              }
              axios(feedItemReq).then((feedItemResponse) => {
                // Post Feed Attachment
                //let feedAttachmentURL = 'https://kubotaservice--ksdev.sandbox.my.salesforce.com' + 
                //'/services/data/v56.0/ui-api/records';
                let feedAttachmentURL = process.env.REACT_APP_API + 'salesforce/kservice/Post?param=ui-api/records';

                let feedAttachmentPayload = {
                  "apiName": "Feed_Attachment__c",
                  "fields": {
                    "Attachment_Name__c": responseImg.data.records[0].PathOnClient,
                    "Date__c": new Date().toISOString(),
                    "Feed_Item__c": feedItemResponse.data.id,
                    "Icon__c": getFileIconByExtn(responseImg.data.records[0].FileExtension),
                    "Size__c": responseImg.data.records[0].ContentSize,
                    "Type__c": getMimeTypeByExtn(responseImg.data.records[0].FileType),
                    "Url__c": responseImg.data.records[0].VersionDataUrl
                  }
                }
                let feedAttachmentReq = {
                  method : 'POST',
                  url : feedAttachmentURL,
                  headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + Cookies.get("token"),
                    'X-SalesForce-Token' : Cookies.get('sf_cookie'),
                    'X-Id-Token' : Cookies.get('idToken')
                  },
                  data : feedAttachmentPayload
                }

                axios(feedAttachmentReq).then((feedAttachmentResponse) => {
                  resolve(feedAttachmentResponse);
                }).catch((error) => {
                  reject(error);
                });
              }).catch((error) => {
                reject(error);
              });
            }).catch((error) => {
              reject(error);
            });
          }).catch((error) => {
            reject(error);
          });
      } else {
          // Post Feed Item record without file
        // let feedItemURL = 'https://kubotaservice--ksdev.sandbox.my.salesforce.com' + 
        // '/services/data/v56.0/ui-api/records';
        let feedItemURL = process.env.REACT_APP_API + 'salesforce/kservice/Post?param=ui-api/records';
        let feedItemPayload = {
          "apiName": "Feed_Item__c",
          "fields": {
            "Body__c": comment.thread,
            "Case__c": id.id,
            "Channel__c": "Web",
            "Name": "Text Update",
            "Parent_Id__c": id.id,
            "Type__c": "Customer",
            "Title__c": "Service Web"
          }
        }
        let feedItemReq = {
          method : 'POST',
          url : feedItemURL,
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + Cookies.get("token"),
            'X-SalesForce-Token' : Cookies.get('sf_cookie'),
            'X-Id-Token' : Cookies.get('idToken')
          },
          data : feedItemPayload
        }
        axios(feedItemReq).then((feedItemResponse) => {
          resolve(feedItemResponse);
        }).catch((error) => {
          reject(error);
        });
        }
      })  
    }

    function handleThreadUpdatesAndImages() {
      function createContentVersions() {
          //returns an array of ContentVersions created from imageState
          return new Promise((resolve, reject) => {
              if(imageState && imageState.length > 0) {
                  var contentVersionArray = [];
                  var promiseArray = [];
                  imageState.forEach((image, index) => {
                      promiseArray.push(new Promise((resolve,reject) => {
                          if(imageState) {
                              const utc = new Date().toJSON().slice(0,10).replace(/-/g,'/');
                              var url;
                              var createPayload;
                              var req;
                              // Post file on Case record
                              // '/services/data/v56.0/sobjects/ContentVersion';
                              url = process.env.REACT_APP_API + 'salesforce/kservice/Post?param=sobjects/ContentVersion';
              
                              createPayload = { 
                                  "Title":utc+": Image Attachment",
                                  "PathOnClient": image.imageName,
                                  "ContentLocation" : 'S',
                                  "FirstPublishLocationId": id.id,
                                  "versionData": image.base64,
                                  "NetworkId": Cookies.get('networkId')
                              };
              
                              req = {
                                  method : 'POST',
                                  url : url,
                                  headers: {
                                      'Content-Type': 'application/json; charset=UTF-8',
                                      'Accept': 'application/json',
                                      'Authorization': 'Bearer ' + Cookies.get("token"),
                                      'X-SalesForce-Token' : Cookies.get('sf_cookie'),
                                      'X-Id-Token' : Cookies.get('idToken')
                                  },
                                  data : createPayload
                              }
              
                              axios(req).then((response) => {
                                  //Throw content version into the array
                                  contentVersionArray.push(response);
                                  resolve();
                              }).catch((error) => {
                                  reject(error);
                              });
                          }
                    }) )
                  });
                  Promise.all(promiseArray)
                      .then(() => {
                          resolve(contentVersionArray);
                      })
                      .catch((error) => {
                          console.log(error);
                          reject(error);
                      })
              } else {
                  //Return empty array because User hasn't uploaded any images
                  resolve([]);
              }
          });
      }
      function createFeedItem(name) {
          //Creates feed item with thread update
          //Any image uploads depend on this FeedItem
          return new Promise((resolve, reject) => {
              let comment = (formData.hasOwnProperty('thread')) ? formData.thread : ""; 
              let feedItemURL = process.env.REACT_APP_API + 'salesforce/kservice/Post?param=ui-api/records';
              let feedItemPayload = {
                  "apiName": "Feed_Item__c",
                  "fields": {
                  "Body__c": comment,
                  "Case__c": id.id,
                  "Channel__c": "Web",
                  "Name": name,
                  "Parent_Id__c": id.id,
                  "Type__c": "Customer",
                  "Title__c": "Service Web"
                  }
              }
              
              let feedItemReq = {
                  method : 'POST',
                  url : feedItemURL,
                  headers: {
                  'Content-Type': 'application/json; charset=UTF-8',
                  'Accept': 'application/json',
                  'Authorization': 'Bearer ' + Cookies.get("token"),
                  'X-SalesForce-Token' : Cookies.get('sf_cookie'),
                  'X-Id-Token' : Cookies.get('idToken')
                  },
                  data : feedItemPayload
              }
              axios(feedItemReq).then((feedItemResponse) => {
                  resolve(feedItemResponse);
              }).catch((error) => {
                  reject(error);
              });
          });
      }
      function createFeedAttachments(contentVersionArray, feedItemID){
          return new Promise((resolve, reject) => {
              var promiseArray = [];
              contentVersionArray.forEach((contentVersionObj) => {
                  //For each image, query file info, then create feed attachment
                  promiseArray.push(new Promise((resolve,reject) => {
                      //Get ContentDocument Id from ContentVersion using ContentVersion Id (response.data.id)
                      let qImg = "SELECT ContentDocumentId, ContentSize, FileExtension, FileType, PathOnClient, VersionDataUrl FROM ContentVersion WHERE id = \'"+contentVersionObj.data.id+"\'";
                      qImg = encodeURIComponent(qImg);
                      //let urlImg = 'https://kubotaservice--ksdev.sandbox.my.salesforce.com' + 
                      //'/services/data/v56.0/query/?q='+qImg;
                      let urlImg = process.env.REACT_APP_API + 'salesforce/kservice/get?param=query/?q=' + qImg;
      
                      let reqImg = {
                          method: "GET",
                          url: urlImg,
                          headers: {
                              'Content-Type': 'application/json; charset=UTF-8',
                              'Accept': 'application/json',
                              'Authorization': 'Bearer ' + Cookies.get("token"),
                              'X-SalesForce-Token' : Cookies.get('sf_cookie'),
                              'X-Id-Token' : Cookies.get('idToken')
                          }
                      };
      
                      axios(reqImg).then((responseImg) => {
                          //Create FeedAttachment
                          let feedAttachmentURL = process.env.REACT_APP_API + 'salesforce/kservice/Post?param=ui-api/records';
                          let feedAttachmentPayload = {
                              "apiName": "Feed_Attachment__c",
                              "fields": {
                              "Attachment_Name__c": responseImg.data.records[0].PathOnClient,
                              "Date__c": new Date().toISOString(),
                              "Feed_Item__c": feedItemID,
                              "Icon__c": getFileIconByExtn(responseImg.data.records[0].FileExtension),
                              "Size__c": responseImg.data.records[0].ContentSize,
                              "Type__c": getMimeTypeByExtn(responseImg.data.records[0].FileType),
                              "Url__c": responseImg.data.records[0].VersionDataUrl
                              }
                          }
                          let feedAttachmentReq = {
                              method : 'POST',
                              url : feedAttachmentURL,
                              headers: {
                              'Content-Type': 'application/json; charset=UTF-8',
                              'Accept': 'application/json',
                              'Authorization': 'Bearer ' + Cookies.get("token"),
                              'X-SalesForce-Token' : Cookies.get('sf_cookie'),
                              'X-Id-Token' : Cookies.get('idToken')
                              },
                              data : feedAttachmentPayload
                          }
                          axios(feedAttachmentReq).then((feedAttachmentResponse) => {
                              resolve(feedAttachmentResponse);
                          }).catch((error) => {
                              reject(error);
                          });
                      }).catch((error) => {
                          reject(error);
                      });
                  }));
              });
              Promise.all(promiseArray)
                  .then(() => {
                      resolve();
                  })
                  .catch((error) => {
                      console.log(error);
                      reject(error)
                  });
          });
      }
      return new Promise((resolve, reject) => {
          //Main function for handling image uploads
          createContentVersions()
              .then((ContentVersionArray) => {
                  //If no images were uploaded, create a Text Update feed_item, if images present grab the 0th images DoucmentID for the name
                  var name = (ContentVersionArray.length > 0) ? ContentVersionArray[0].ContentDocumentId : "Text Update";
                  var hasImages = (ContentVersionArray.length > 0);
                  createFeedItem(name, id.id)
                      .then((feedItemObj) => {
                          if(hasImages) {
                              var feedItemID = feedItemObj.data.id;
                              createFeedAttachments(ContentVersionArray, feedItemID)
                                  .then(() => {
                                      resolve();
                                  })
                                  .catch((error) => {
                                      console.log("Error creating the feed_attachment objects");
                                      console.log(error);
                                      reject(error);
                                  });
                              console.log(feedItemObj);
                          } else {
                              //If no images, were done
                              resolve();
                          }
                      })
                      .catch((error) => {
                          console.log("Error while creating the feed_item");
                          console.log(error);
                          reject(error);
                      });
              })
              .catch((error) => {
                  console.log("Error while creating ContentVersions")
                  console.log(error);
                  reject(error);
              });

      });
  }


    function getThreads(data){
      let feedItemsQuery = "SELECT Id, Body__c, Case__c, Channel__c, CreatedDate, Title__c FROM Feed_Item__c WHERE Case__c = \'"+id.id+"\'";
      feedItemsQuery = encodeURIComponent(feedItemsQuery);

      // let feedItemsURL = 'https://kubotaservice--ksdev.sandbox.my.salesforce.com' + 
      // '/services/data/v56.0/query/?q='+feedItemsQuery;
      let feedItemsURL = process.env.REACT_APP_API + 'salesforce/kservice/get?param=query/?q=' + feedItemsQuery;

      let feedItemsReq = {
        method: "GET",
        url: feedItemsURL,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + Cookies.get("token"),
          'X-SalesForce-Token' : Cookies.get('sf_cookie'),
          'X-Id-Token' : Cookies.get('idToken')
        }
      };

      axios(feedItemsReq).then((response) => {
        if(response.data){
          const columnNamesData = ['Name','Date', 'Text', 'ThumbnailId'];
          const countData = response.data.records.length;
          const linksData = [];
          const nameData = 'getFeedItems';
          const rowsData = [];

          for (const row of response.data.records){
            let name = row.Title__c;
            let createdDate = row.CreatedDate.substring(0, 10) + ' ' + row.CreatedDate.substring(12,19);
            let text = row.Body__c;
            let thumbnailId = row.Id;
            rowsData.push([name, createdDate, text, thumbnailId]);
          }

          let newResponseData = {
            columnNames: columnNamesData,
            count: countData,
            links: linksData,
            name: nameData,
            rows: rowsData
          };
          response = newResponseData;

          let tempArray = [];
          for (var i = 0; i < response.rows.length; i++) {
            tempArray.push(
                <Accordion key={"Accordion" + i}>
                 <AccordionSummary style={{backgroundColor:"#D3D3D3"}} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography className={classes.heading}><span className={classes.bold}>{response.rows[i][0] ? (response.rows[i][0]):("Note")}</span><span className={classes.floatRight}>{response.rows[i][1]}</span></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{<p dangerouslySetInnerHTML={{__html: response.rows[i][2]}}/>}</Typography>
                  </AccordionDetails>
              </Accordion>
            );
          }
          setThreads(tempArray);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    function RenderThreads(){
      return(
        threads
      );
    }

    function RenderAttachmentImages(){
      // "blob:http://localhost:3000/e7e2948a-efd1-4a40-b532-cc8198c50e8d"
      if(caseImages && caseImages.length > 0 ){
        console.log('Case Images after Renderi: '+caseImages[0]["File Link"])
          return(
            <>
              <Typography variant="h6" component="h3" style={{paddingTop: '50px'}}>{'Image Attachments:'}</Typography>
              <ImageList rowHeight={160} className={classes.imageList} cols={3}>
                {caseImages.map((item, index) => (
                    <ImageListItem key={"attachment" + index} cols={item.cols || 1}>
                      <img style={{maxWidth:"100%"}} src={(item) ? item["File Link"] : ""}  alt={item["User File Name"]} onClick={handleOpen}/>
                    </ImageListItem>
                  
                ))}
              </ImageList>
            </>
          );
        }else{
          return null;
        }
    }

    function handleAttachmentClick(item){
      //var url = process.env.REACT_APP_API + 'osc/Download/incidents/' + item['Foreign Key'] + '?paramId=' + item['File Attachment ID'] +
      //'&contentType=application/octet-stream&filename=' + item['User File Name'] +  '&paramName=fileAttachments';
      var url = item.downloadURL;
      downloadFileAttachment(url, item['User File Name']);
    }

    function RenderAttachments(){
      if(caseAttachments && caseAttachments.length > 0){
          return(
            <React.Fragment>
              <Typography variant="h6" component="h3" style={{paddingTop: '50px'}}>{'Other Attachments:'}</Typography>
              <List
                component="nav"
                className={classes.root}
              >
                {caseAttachments.map((item, index) => (
                 <React.Fragment key = {"file-attachment" + index}> 
                  <span onClick={() => handleAttachmentClick(item)}>
                      <ListItem button>
                        <ListItemIcon>
                            <GetAppIcon />
                        </ListItemIcon>
                        <ListItemText primary={item["User File Name"]} secondary={item["Content Type"]} className={classes.downloadHeader} />
                      </ListItem>
                  </span>
                </React.Fragment>  
                ))}
              </List>
            </React.Fragment>
          );
        }else{
          return null;
        }
    }

    function removeImage(index){
      let tempImages = imageState.slice();
      tempImages.splice(index, 1);
      updateImageState( tempImages );
  }
    function CaseInformation() {
      var fields = searchResults.record.fields;
      return (
        <>
          <br/>
          <Typography variant="body1" component="h1">{"Case ID: " + fields.CaseNumber.value}</Typography>
          {fields.Contact.displayValue && <><Typography variant="body1" component="h1" style={{ wordWrap: "break-word" }}>{"Submitted By: " + fields.Contact.displayValue + ' ' + fields.ContactEmail.value}</Typography></>}
          {fields.Serial_Number__c.value && <><br/><Typography style={{display: 'inline-block'}} color="primary" variant="body1" component="h3">{"Serial Number: " + fields.Serial_Number__c.value}</Typography></>}
          {fields.Model_Number__c.value && <><Typography style={{display: 'inline-block', paddingLeft: '30px', marginBottom: '20px'}} color="primary" variant="body1" component="h3">{"Model Number: " + fields.Model_Number__c.value}</Typography><br/></>}
          {tavant &&
            <>
              {tavant['materialNumberDescription'] && <><Typography variant="body1" component="h3">{tavant['materialNumberDescription']}</Typography></>}
              {tavant['endCustomerName'] && <><Typography variant="body1" component="h3">{"Customer/Owner: " + tavant['endCustomerName']}</Typography></>}
              {tavant['endCustomerCity'] && <><Typography variant="body1" component="h3">{tavant['endCustomerCity'] + ", " + tavant['endCustomerState']}</Typography><br/></>}
              {tavant['servicingDealerName'] && <><Typography variant="body1" component="h3">{"Servicing Dealer: " + tavant['servicingDealerName']}</Typography><br/></>}
            </>
          }
          <Divider className={classes.divider} />
        </>
      );
    }
        return (
        <div>
            
            { caseNotFound &&
              <Container maxWidth="sm" className={classes.heroContent}>
                <Paper elevation={3} className={classes.heroPaper} justify="center">
                  <Grid container justifyContent="center" spacing={2}> 
                    <Grid item> 
                      <div> 
                        {caseErrorMessage}
                      </div>
                    </Grid>
                    <Grid item> 
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          history.back();
                        }}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                 

                </Paper>
              </Container>
            }
            {searchResults && !loading && layoutType != "" && !caseNotFound &&
            <Container maxWidth="lg" className={classes.heroContent}>
              <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Paper elevation={3} className={classes.heroPaper} justify="center">
                            <CaseInformation/>
                            <div className={classes.bottomMargin}>
                              <RenderThreads/>
                              <RenderAttachmentImages/>
                              <RenderAttachments/>
                          </div>
                          <Divider/>   
                          <Typography variant="h6" component="h3" className={classes.additionalInfoHeader}>{'Additional Details'} </Typography>
                          <SFContainer
                            RecordID = {ID}
                            Record = {searchResults}  
                            layoutMode = {layoutType}
                            Action = {"View"}
                            allowEdit={false}
                            objectInfo={searchResults.objectInfo}
                            editValues={searchResults.editValues}
                            defaultExpanded={false}
                          />
                          <hr></hr>
                          <Grid container>
                            <Grid item xs={10}>
                              <Typography variant="h6" component="h3" style={{marginTop: '0px'}} className={classes.additionalInfoHeader}>{'Parts Predictions'} </Typography>
                            </Grid>
                          {predictionResults  &&
                            <>
                              <Grid container style={{marginTop: '8px'}}>
                                  <Grid item xs={12} style={{marginBottom: '16px'}}>
                                      <Grid container>
                                          <Grid item xs={6}>
                                              <Typography>
                                                  <strong>Model: </strong>{predictionResults.modelNumber}
                                              </Typography>
                                          </Grid>
                                          <Grid item xs={6}>
                                              <Typography>
                                              <strong>Serial: </strong>{predictionResults.serialNumber}
                                              </Typography>
                                          </Grid>
                                          <Grid item xs={12}>
                                              <Typography>
                                                  <strong>Symptom: </strong>{predictionResults.symptom}
                                              </Typography>
                                          </Grid>
                                          
                                      </Grid>
                                  </Grid>
                                  
                                  {predictionResults.PartsCombinations.map((prediction, index) => {
                                      debugger;
                                      return(
                                          <>
                                            <Accordion key={"PPDAccordion" + index}>
                                              <AccordionSummary style={{backgroundColor:"#D3D3D3"}} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                                <strong>Prediction #{index+1} </strong>
                                              </AccordionSummary>
                                              <AccordionDetails>
                                                <Grid container key={"predictionObj" + index} style={{marginTop: '6px'}}>
                                                    <Grid item xs={12} style={{marginTop: '8px'}}>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Typography noWrap>
                                                                    <strong>Causal Part</strong>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography noWrap>
                                                                    {prediction.causalPart}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    { prediction.requiredParts && prediction.requiredParts.length > 0 &&
                                                        <>
                                                            <Grid item xs={12} style={{marginTop: '4px'}}>
                                                                <Typography>
                                                                    <strong>
                                                                        Required Parts Combination
                                                                    </strong>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid container>
                                                                {prediction.requiredParts.map((requiredPart, index) => {
                                                                    return(
                                                                        <>
                                                                            <Grid item xs={6}>
                                                                                <Typography>
                                                                                    {requiredPart.partNumber} {requiredPart.partDesc}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={6} style={{textAlign: 'center'}}>
                                                                                {requiredPart.qty}
                                                                            </Grid>
                                                                        </>
                                                                    );
                                                                })}
                                                            </Grid>
                                                        </>
                                                    }
                                                    <Grid item xs={12} style={{marginTop: '4px'}}>
                                                        <Typography noWrap>
                                                            <strong>Estimated Labor: </strong> {Math.round(prediction.recommendedJobhours)} hours
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{marginTop: '4px', textAlign: 'right', paddingRight: '48px'}}>
                                                        <Typography noWrap>
                                                            <strong>Probability Score: </strong> {prediction.probabilitySimilarPhraseMatch}%
                                                        </Typography>
                                                    </Grid>
                                                  
                                                </Grid>
                                              </AccordionDetails>
                                            </Accordion>
                                          </>
                                      );
                                  })}
                              </Grid>
                            </>
                          }
                          {!predictionResults &&
                            <Grid item xs={3}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handlePredictionClick}
                              >
                                Create Prediction
                              </Button>
                            </Grid>
                          }
                          </Grid>
                        </Paper>
                    </Grid>
                <Grid item xs={4}>
                  <Paper elevation={3} className={classes.heroPaper}>
                    {/* Update Widget  */}
                    <Typography variant="h6" component="h2">
                      Post an update
                      <form className={classes.form} noValidate onSubmit={e => { e.preventDefault(); }}>
                        <TextField variant="outlined" margin="normal" required multiline fullWidth id="thread" label="Add additional information" name="thread" onChange={handleChange}/>
                        <Grid item xs={12} classNames={classes.update}>
                          <Input name="attachments" id="contained-button-file" fullwidth inputProps={{ multiple: true }} type="file" style={{display:"none"}} onChange={handleChange} />
                          <label htmlFor="contained-button-file">
                            <Button variant="contained" color="primary" component="span" startIcon={<PhotoCameraIcon />}>
                              Upload
                            </Button>
                          </label>
                        </Grid>

                        {imageState &&
                          <Grid container spacing={2} className={classes.marginTop}>
                            {imageState.map((image, i) => {
                              return(
                                <React.Fragment key = {"ImageCard" + i}>
                                  <Grid item xs={6} md={6}>
                                      <Card elevation={3}>
                                      <CardHeader
                                          action={
                                              <IconButton onClick={(event) => removeImage(i)} aria-label="remove" color="primary">
                                                  <ClearIcon fontSize="inherit" />
                                              </IconButton>
                                          }
                                          titleTypographyProps={{variant:'body1' }}
                                      />
                                          <CardActionArea>
                                              <CardMedia
                                              className={classes.media}
                                              image={image.localSrc}
                                              title={"attachment" + i}
                                              />
                                              <CardContent>
                                                  <Typography variant="body2" color="textSecondary" component="p">
                                                        {image.imageName}
                                                  </Typography>
                                              </CardContent>
                                          </CardActionArea>
                                      </Card>
                                  </Grid>
                                </React.Fragment>
                                )
                              })
                            }
                          </Grid>
                        }
                        <Grid container className={classes.update}>
                          <Grid item xs={1} md={1}>
                            <Checkbox
                              className={classes.marginAuto}
                              name={"test"}
                              checked={closeCase}
                              key={'updateCheckbox'}
                              onChange={(event) => setCloseCase(event.target.checked)}
                              />
                          </Grid>
                          <Grid item xs={10} md={10} className={classes.closeCaseText}>
                              <Typography className={classes.checkBox}>
                                Would you like to close this case?
                              </Typography>
                          </Grid>
                        </Grid>
                        
                        <Button type="submit" fullWidth variant="contained" color="primary" size="large" className={classes.update} onClick={handleSubmit}>Save Changes</Button>
                      </form>
                    </Typography>
                  </Paper>
                </Grid>
              </Grid> 
            </Container>
            }
            {loading &&
                <Container maxWidth="lg" className={classes.heroContent}>
                  <Paper elevation={3} className={classes.heroPaper}>
                    <div className={classes.loaderCenter}>
                      <CircularProgress className={classes.loadingCenterInside} />
                    </div>
                  </Paper>
                </Container>
            }
        </div>
        );

}

async function downloadFileAttachment(url, filename, fetchProps) {
  fetchProps = {
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + Cookies.get("sf_cookie")
    }
  }
  try {
    const response = await fetch(url, fetchProps);

    if (!response.ok) {
      throw new Error(response);
    }

    const blob = await response.blob();

    // Download the file
    saveAs(blob, filename);

  } catch (error) {
    throw new Error(error);
  }
}

// Function to preview blob
function previewBlob(uri, token, version) {
  // Get current UTC Date
  let currentDate = new Date();
  const utcDate = currentDate.toUTCString();

  // Middleware endpoint to bypass CORS
  // https://stagingapi.kservice.app/rp?param=
  //let url = 'https://stagingapi.kservice.app/rp?param='+encodeURI(uri);
  let url = process.env.REACT_APP_API+'rp?param='+encodeURI(uri);

  // create headers object      
  const myHeaders = {'Authorization': 'Bearer ' + token, 'x-ms-date': utcDate, 'x-ms-version': version};
  // create request options
  const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
  };
  let mimeType='application/octet-stream'; // init mime type
  // return promise
  return new Promise((resolve, reject) => {
      // fetch request
      fetch(url, requestOptions)
      .then(response => {
        if (response.status === 200 && response.redirected === false){ // Check if URL is valid
          const contentType = response.headers.get('content-type'); // get blob mime type
          //mimeType = 'application/octet-stream'; // set to allowed type
          return response.arrayBuffer(); // returns a promise that resolves with a generic, fixed-length raw binary data buffer
        }        
        else{
          return null;
        }
      }) 
      .then(result => { // process response     
        if(result){    
          const file = new Blob([result], {type: mimeType}); 
          // create a string containing a URL
          const imageUrl = URL.createObjectURL(file);
          // return blob
          resolve(imageUrl);
        }
        // Specify a MIME Type for Blob Objects in LWS
      })
      .catch(error => { // handle error
        reject(error);
      });
  });
}

function fetchAttachmentHelper(images, other, attachment) {
  return new Promise(
    (resolve, reject) => {
      let fileName = attachment.Attachment_Name__c;
      let fileType = attachment.Type__c;
      let url = document.createElement('a');
      url.href = attachment.Url__c;
      // File storage domains
      //Substring(8) grabs the SF domain but without the https://
      var salesforceDomain = process.env.REACT_APP_SF_DOMAIN.substring(8) + '.file.force.com';
      var azureDomain = 'p214sasvcappea01.blob.core.windows.net';
      
      // If file attachment URL is SFDC
      if(url.hostname == salesforceDomain){
        // If file attachment is of Image type to be displayed
        if(fileType == "doctype:unknown") {
          let inferredFileType = attemptFileTypeInference(fileName);
          if (inferredFileType) {
            fileType = inferredFileType;
          }
        }
        if(fileType == 'image/jpeg' || fileType == 'image/png'){
          previewBlob(url.href, Cookies.get("sf_cookie"), '2021-08-06')
          .then(result => {
            let fileLink = result;
            let struct = {
              "File Link": fileLink
            }
            images.push(struct);
            resolve();
          })
          .catch(error => {
            reject(error);
          })
        }
        // Else download link available
        else{
          previewBlob(url.href, Cookies.get("sf_cookie"), '2021-08-06')
          .then(result => {
            let fileLink = result;

            let struct = {
              "downloadURL": fileLink,
              "User File Name": fileName,
              "Content Type": fileType
            }
            other.push(struct);
            resolve();
          })
          .catch(error => {
            reject(error);
          })
        }
      }

      // If file attachment URL is Azure
      else if(url.hostname == azureDomain){
        // Get azure blob storage token
        //let adTokenUrl = 'https://kubotaservice--ksdev.sandbox.my.salesforce.com' + 
        //'/services/apexrest/ADToken';
        let adTokenUrl = process.env.REACT_APP_API + 'salesforce/kservice/apexrest?param=ADToken';

        let adTokenReq = {
          method : "GET",
          url: adTokenUrl,
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Accept": "application/json",
            "Authorization": "Bearer " + Cookies.get("token"),
            "X-SalesForce-Token" : Cookies.get('sf_cookie'),
            'X-Id-Token' : Cookies.get('idToken')
          }
        }
        axios(adTokenReq).then((adTokenRes) => {
          if(adTokenRes.data){
            let adToken = adTokenRes.data;
            let x_ms_version = '2021-08-06';
        
            // If file attachment is of Image type to be displayed
            if(fileType == 'image/jpeg' || fileType == 'image/png'){
              previewBlob(url.href, adToken, x_ms_version)
              .then(result => {
                let fileLink = result;

                let struct = {
                  "File Link": fileLink
                }

                images.push(struct);
                resolve();
              })
              .catch(error => {
                reject(error);
              })
            }

            // Else download link available
            else{
              previewBlob(url.href, adToken, x_ms_version)
              .then(result => {
                let fileLink = result;

                let struct = {
                  "downloadURL": fileLink,
                  "User File Name": fileName,
                  "Content Type": fileType
                }

                other.push(struct);
                resolve();
              })
              .catch(error => {
                reject(error);
              })
            }
          }
        })
        .catch(error => {
          reject(error);
        })
      }
    }
  );
}

function attemptFileTypeInference(fileName){
  //Take a file name and attempts to recognize the image file type and return the corrected docType
  const imageFileTypeRegex = new RegExp(/^.*\.(jpg|JPG|png|PNG|jpeg|JPEG)$/);
  let matches = fileName.match(imageFileTypeRegex);
  if(matches && matches.length > 1) {
    let fileType = matches[1].toLowerCase();
    if (fileType == "jpeg" || fileType == "jpg") {
      return  "image/jpeg";
    } 
    if (fileType == "png") {
      return "image/png";
    }
  }
  return null;
}
export default CaseViewPage;