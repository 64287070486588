import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import axios from 'axios';
import { getMessaging, getToken } from "firebase/messaging";
import ApiManager from '../apiservice';
import { connect } from 'react-redux';
import { createUserType, removeUserType } from '../state/actions';
import Cookies from 'js-cookie';
import { doc, getDoc } from "firebase/firestore";
import { app } from '../firebaseConfig';
import { getFirestore } from "firebase/firestore";
import { query, where, collection, getDocs, setDoc, addDoc } from "firebase/firestore"; 
import { serverTimestamp } from "firebase/firestore";
import { b2cLogout, getNewToken} from '../pages/B2CLoginPage';




const useStyles = makeStyles((theme) => ({
    '@global': {
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
      flexWrap: 'wrap',
    },
    toolbarTitle: {
      flexGrow: 1,
      textAlign: 'center'
    },
    backbutton: {
      margin: theme.spacing(0,0,0,1)
    },
    paper: {
      position: 'absolute',
      width: '100vw',
      backgroundColor: "white",
      textAlign: "center",
      padding: theme.spacing(0, 1, 1)
    }
}));

const Timer = ({ rUserType, onUserTypeUpdate, onUserTypeLogout }) => {
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    let timeout = null;
    let sfdcTimeout = null;

    const onVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        console.log("Tab reopened, refetch the data!");
      }
    };


    useEffect(() => {
        // On Component Load
        let userType = localStorage.getItem('UT');
        console.log(userType);
        let string = atob(userType);
        console.log(string)
        onUserTypeUpdate(string);

        const messaging = getMessaging();

        getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY }).then((currentToken) => {
          if (currentToken) {
            // Send token to Firebase Function RegisterDevice.
            console.log("TOKEN:" + currentToken);
            const registerNotifRequest = {
              method: "post",
              url: process.env.REACT_APP_API + 'firebase/kservice/RegisterDevice',
              data: {
                "ContactId" : Cookies.get("c_id"),
                "Token": currentToken
              },
              headers: {
                'Authorization': 'Bearer ' + Cookies.get("token")
              }
            }
            axios(registerNotifRequest)
              .then((response) => {
                // Could extend this later, handle different responses
                console.log("Registered FCM token with Firebase");
              })
              .catch((error) => {
                console.log("Failed registering FCM token with Firebase");
              });           
          } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
        if(process.env.NODE_ENV !== "development") {
          setSessionTimer();
        }
        document.addEventListener("visibilitychange", onVisibilityChange);
        return () =>
          document.removeEventListener("visibilitychange", onVisibilityChange);
    }, []);

    function uniqid(prefix = "", random = false) {
      const sec = Date.now() * 1000 + Math.random() * 1000;
      const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
      return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}`:""}`;
    };

    function setSessionTimer(){
      console.log("session timer restarted");
      
      timeout = setTimeout(function(){
        setOpen(true);
        setTimeout(function(){
          //handleLogout();
          b2cLogout();
        }, 60000); //1 minute = 60000
      }, 840000); //14 minutes = 840000
      
      
      while (--timeout) {
          window.clearTimeout(timeout);
      }

    }

    function persistToken(){
      console.log("Token timer restarted");
      
      timeout = setTimeout(function(){
        // var axios = require('axios');

        // var config = {
        // method: 'get',
        // url: 'https://kubotaservice--ksdevsso.sandbox.my.salesforce.com/services/data/v56.0/ui-api/object-info',
        // headers: { 
        //     'Authorization': 'Bearer '+Cookies.get('sf_cookie'),
        //     //'Authorization': 'Bearer 00D790000000MUC!AQ4AQAkARB3EMgTZtGuqWeSt6Qs82XdlAhErgoKA9Nq_dDhGVPfWCw97U1M6oNunaMf79juoMobY5wMPA0qyzwYK9yps4R_s', //Expired Bearer Token
        //     'Cookie': 'BrowserId=Z6mLmUACEe2uBKUuE1-gMg; CookieConsentPolicy=0:1; LSKey-c$CookieConsentPolicy=0:1'
        // }
        // };

        // axios(config)
        // .then(function (response) {
        // console.log("SFDC Token persisted");
        // })
        // .catch(function (error) {
        // console.log(error);
        // getNewToken();
        // });
        console.log('Timer hit');
      }, 15000); //14 minutes = 840000
      
      
      while (--sfdcTimeout) {
          window.clearTimeout(sfdcTimeout);
      }

    }

    function handleLogout(){
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      // Dump Local Storage
      localStorage.clear();
      // Remove all saved data from sessionStorage
      sessionStorage.clear();
      // Dump the redux store User Type
      onUserTypeLogout();
      // window.location.href = "/login";
      history.push("/login");
    }

    function handleActive(){
      console.log("handle active hit");
      setSessionTimer();
      setOpen(false);
    }

      return(
        <Modal
          open={open}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={{top: '40%', maxWidth: '500px'}} className={classes.paper}>
            <p id="simple-modal-description">
              Your K-Service Web App Session is about to expire.  Please continue using the app to avoid being logged out.
            </p>
            <Button onClick={handleActive}
              variant="contained"
              color="primary"
              size="large"
              fullWidth>
                Continue
            </Button>
          </div>
        </Modal>
    );
}

const mapStateToProps = state => ({
  rUserType: state.userType,
})

const mapDispatchToProps = dispatch => ({
  onUserTypeUpdate: text => dispatch(createUserType(text)),
  onUserTypeLogout: text => dispatch(removeUserType(text)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
