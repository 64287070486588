import React from 'react';
import { useState, useEffect } from 'react';
import { Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory, useParams } from 'react-router';

import HistoryIcon from '@material-ui/icons/History';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles({
    centered: {
        textAlign: 'center',
    }
});

const KnowledgeRecentSearch = (...props) => {
    const classes = useStyles();
    const [history, updateHistory] = useState(JSON.parse(localStorage.getItem('RecentKnowledge')));
    const navHistory = useHistory();

    useEffect(() => {
        // On Component Load
        let searchHistory = JSON.parse(localStorage.getItem('RecentKnowledge')) || null;
        // console.log(searchHistory);
        updateHistory(searchHistory);

    }, []);

    // useEffect(() => {
    //     return navHistory.listen((location) => { 
    //         console.log(`You changed the page to: ${location.pathname}`)
    //         console.log(window.location.search);
    //      }) 
    // }, [navHistory])

    useEffect(() => {
        if (props[0].search){
            if(props[0].search.term && props[0].search.category){
                // console.log('PROPS SEARCH')
                // console.log(props[0].search.term);
                // console.log(props[0].search.category);
                setLocalSearchHistory(props[0].search.term, props[0].search.category);
                props[0].search.term = null; // Set to null to not rerun setLocalSearchHistory
            }
        }
    });

    function setLocalSearchHistory(term, category){
        let searchHistory = JSON.parse(localStorage.getItem('RecentKnowledge')) || [];
        let currentSearch = { term: term, category: category };

        if (searchHistory.length > 0) {
            searchHistory = searchHistory.filter(function (objItem, i) {
                if (objItem.term.toUpperCase() == currentSearch.term.toUpperCase() && objItem.category == currentSearch.category) {
                    console.log("remove duplicate");
                } else {
                    return objItem;
                }
            });
        }
        searchHistory.unshift(currentSearch);

        if (searchHistory.length > 10) {
            searchHistory.pop();
        }
        
        updateHistory(searchHistory);
        localStorage.setItem('RecentKnowledge', JSON.stringify(searchHistory));
    }

    

    function RecentSearch({ search }){
        const handleListItemClick = (search, event) => {
            let urlSearch = encodeURIComponent(search.term);
            let urlCategory = search.category;

            let location = '/knowledge?s='+urlSearch+'&c='+urlCategory;
            // Needs history push
            navHistory.push(location);
            // window.location.href = location;
          };
        
        return (
            <React.Fragment>
                <ListItem button onClick={(event) => handleListItemClick(search, event)}>
                    <ListItemText
                        primary={<React.Fragment>
                            <Typography 
                                style={{fontWeight: 600}}
                                variant="h6"
                            >
                                {search.term}
                            </Typography>
                        </React.Fragment>}
                        secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="textPrimary"
                                >
                                    {search.category}
                                </Typography>
                            </React.Fragment>
                        }
                    />
                    <ListItemIcon>
                        <ArrowForwardIosIcon />
                    </ListItemIcon>
                </ListItem>
                <Divider />
            </React.Fragment>
        );
    }

    if (history) {
        return (
            <List component="nav" aria-label="knowledge recent searches" className={classes.root}>
                {history.map((search, i) => (
                    <RecentSearch
                        key={i}
                        index={i}
                        search={search}
                    />
                ))}
            </List>
        );
    }
    if (!history) {
        return (
            <p className={classes.centered}>
                <HistoryIcon centered />
                <Typography centered variant="caption" display="block" gutterBottom>No Search History</Typography>
            </p>
        );
    }
}

export default KnowledgeRecentSearch;
