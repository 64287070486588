import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { useHistory } from "react-router-dom";
import { makeStyles} from '@material-ui/core/styles';
import { secondary_logout_url } from '../msalConfig';


const useStyles = makeStyles((theme) => ({
    heroContent: {
      width: "90%",
      padding: theme.spacing(8, 0, 6),
    },
    heroPaper: {
      padding: theme.spacing(1, 2, 2),
      margin: theme.spacing(0, 0, 4)
    }
  }));

const LogOutPage = () => {
    const classes = useStyles();
    const history = useHistory();


    function handleRelogPress() {
        history.push('/login');
    }
    function handleSecondaryLogout() {
        window.open(secondary_logout_url, "_self").focus();
    }
    return (
        <Container maxWidth="md" className={classes.heroContent}>
            <Paper elevation={3} className={classes.heroPaper} justify="center">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            K-Service - Logged Out
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            You are now signed out. Thanks for using K-Service.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            To sign in again, please click <Link style={{color: 'blue'}} onClick={handleRelogPress}>here</Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            Employees: If you are using a public computer, please sign out <Link style={{color: 'blue'}} onClick={handleSecondaryLogout}>here</Link> to end your K-Service session.
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default LogOutPage;
