import MakeAnalyticsRequest from "./MakeAnalyticsRequest";

export default function handleFieldUpdateAnalytics(machineDetails, dtc_code_selected,  subject, uuid ) {
//Creates a common payload w/ UUID's,Timestamps, UserAgent, etc
//then calls update functions for the following fields: 
//serial_num, model_num, dtc_code_searched, dtc_code_selected, businessUnits, subject

//Generate Common Payload from Tempalte
    var commonPayload = {
        "svc_ticket_subject": subject,
        "svc_ticket_custom" : {
            "businessUnits" : "KTC-USA",
            "dtc_code_search": "",
            "serial_num": machineDetails.serialNumber,
            "model_num": machineDetails.modelNumber,
            "dtc_code_selected": dtc_code_selected
        },
        "t": "event",
        "pid" : uuid,
        "dr": process.env.REACT_APP_DOMAIN,
        "dl": window.location.href,
        "tm": Date.now(),
        "sr": "1920x1080",
        "sd": "24-bit",
        "ul": "en-US",
        "ua": window.navigator.userAgent,
        "dt": "new-case",
        "de": "UTF-8",
        "svc_action": "ticket_field_update",
        "svc_action_data": {
            "fieldName": null
        },
        "ec": "svc",
        "ea": "click",
        "cid" : uuid
    };
    updateSerial(commonPayload);
    updateModel(commonPayload);
    updateDTCSearched(commonPayload);
    updateDTCSelected(commonPayload);
    updateBusinessUnits(commonPayload);
    updateSubject(commonPayload);
};

function updateSerial(commonPayload) {
    //Inject field name into payload
    commonPayload = {
        ...commonPayload,
        "svc_action_data": {
            "fieldName": "serial_num"
        }
    };
    MakeAnalyticsRequest(commonPayload);
}
function updateModel(commonPayload) {
    //Inject field name into payload
    commonPayload = {
        ...commonPayload,
        "svc_action_data": {
            "fieldName": "model_num"
        }
    };
    MakeAnalyticsRequest(commonPayload);
}
function updateDTCSearched(commonPayload) {
    //Inject field name into payload
    commonPayload = {
        ...commonPayload,
        "svc_action_data": {
            "fieldName": "dtc_code_searched"
        }
    };
    MakeAnalyticsRequest(commonPayload);
}
function updateDTCSelected(commonPayload) {
    //Inject field name into payload
    commonPayload = {
        ...commonPayload,
        "svc_action_data": {
            "fieldName": "dtc_code_selected"
        }
    };
    MakeAnalyticsRequest(commonPayload);
}
function updateBusinessUnits(commonPayload) {
    //Inject field name into payload
    commonPayload = {
        ...commonPayload,
        "svc_action_data": {
            "fieldName": "businessUnits"
        }
    };
    MakeAnalyticsRequest(commonPayload);
}
function updateSubject(commonPayload) {
    //Inject field name into payload
    commonPayload = {
        ...commonPayload,
        "svc_action_data": {
            "fieldName": "subject"
        }
    };
    MakeAnalyticsRequest(commonPayload);
}
