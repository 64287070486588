import React, { Component } from 'react';
import axios from 'axios';

import { FormIMEIDCUScan } from './FormIMEIDCUScan';

import { Container, Grid } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Paper } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';


export class FormIMEIParentPage extends Component {
    componentDidMount() {
        
    }
    state = {
        reference: '',
        step: 1,
        imei: '',
        dcuPartNumber: '',
        sn: '',
        mn: '',
        pin: '',
        
    }

    // Proceed next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
    }

    // Proceed next step
    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        })
    }

    setValues = (values) => {
        for (const [key, value] of Object.entries(values)) {
            if (value) {
                console.log(value);
                this.setState({ [key]: value });
            }
        }
    }

    submitState = () => {

    }

    // Handle form value changes
    handleChange = input => e => {
        this.setState({ [input]: e.target.value })
    }

    handleImages = input => e => {
        // console.log(input);
        // console.log(e);
        const temp = [];
        Array.prototype.forEach.call(e.target.files, function (file) {

            let image = {
                imageName: file.name,
                imageType: "image/jpg",
                base64: ''
            }
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                // console.log(reader.result);//base64encoded string
                image.base64 = reader.result.replace(/^data:image\/[a-z]+;base64,/, "");
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
            temp.push(image);
        });
        console.log(temp);
        this.setState({ image: temp })
    }

    render() {

        const { step } = this.state;
        const {
            imei,
            dcuPartNumber,
            sn,
            mn,
            pin
             } = this.state;
        const values = {
          imei,
          dcuPartNumber,
          sn,
          mn,
          pin
        }

        switch (step) {
            case 1:
                return (
                    <React.Fragment>
                        
                                        <FormIMEIDCUScan
                                            handleChange={this.handleChange}
                                            values={values}
                                        />
                                
                    </React.Fragment>
                )
            
        }
    }
}

const styles = {
    heroContent: {
        paddingTop: 64,
    },
    heroPaper: {
        paddingTop: 8,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16,
        marginBottom: 50
    },root: {
        display: 'flex',
        '& > * + *': {
          marginLeft: 8,
        },
    }
}

export default (FormIMEIParentPage)
