import React, { useReducer } from 'react';
import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import HistoryIcon from '@material-ui/icons/History';
import { useHistory } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import KnowledgeRecentSearch from '../components/KnowledgeRecentSearch';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Cookies from 'js-cookie';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import axios from 'axios';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CasesRecentSearch from '../components/CasesRecentSearch';
import { connect } from 'react-redux';
import { createUserType } from '../state/actions';
import ApiManager from '../apiservice';
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import { app } from '../firebaseConfig';
import { getFirestore } from "firebase/firestore";
import { getRemoteConfig, getValue } from "firebase/remote-config";
import { fetchAndActivate } from "firebase/remote-config";






const useStyles = makeStyles((theme) => ({
  centered: {
    textAlign: 'center',
  },
  heroContent: {
    width: "90%",
    padding: theme.spacing(8, 0, 6),
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  bold: {
    fontWeight: '800'
  },
  alertMSG: {
    marginBottom: theme.spacing(2)
  }
}));

const HomePage = ({ rUserType, onUserTypeUpdate }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [userLogin, setUserLogin] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userPhone, setUserPhone] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const [userDealerName, setUserDealerName] = useState(null);
  const [userDealerNo, setUserDealerNo] = useState(null);
  const [showKlinkMessage, setShowKlinkMessage] = useState(false);
  const [expireDate, setExpireDate] = useState(null);

  const [category, setCategory] = useState('Machines');

  const width = useMediaQuery(theme.breakpoints.down(490));

  const handleCategoryChange = (e, value) => {
    setCategory(value);
  }

  useEffect(() => {
    getRemoteConfigs();
    // On Component Load
    processExpireDate();
    // Grab User Info from SFDC
    if(Cookies.get('sf_cookie')){
      var axios = require('axios');

      var config = {
        method: 'get',
        url: process.env.REACT_APP_SF_DOMAIN + '.my.salesforce.com/services/oauth2/userinfo',
        headers: { 
          'Authorization': 'Bearer '+Cookies.get('sf_cookie'),
          'Cookie': 'BrowserId=Z6mLmUACEe2uBKUuE1-gMg; CookieConsentPolicy=0:0; LSKey-c$CookieConsentPolicy=0:0'
        }
      };

      axios(config)
      .then(function (response) {
        Cookies.set('user_id', response.data.user_id); //Set User Id in Cookies

        let userQuery = "SELECT AccountId, Account.AccountNumber, Account.Name, Account_Record_Type__c, ContactId, Contact.Email, Contact.Phone, FirstName, Id, LastName, Username FROM User WHERE Id = \'"+response.data['user_id']+"\'";
        userQuery = encodeURIComponent(userQuery);
        let userURL = process.env.REACT_APP_API + 'salesforce/kservice/get?param=query/?q=' +userQuery;

        let userReq = {
          method: "GET",
          url: userURL,
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + Cookies.get("token"),
            'X-SalesForce-Token' : Cookies.get('sf_cookie'),
            'X-Id-Token' : Cookies.get('idToken')
          }
        };

        axios(userReq).then((userRes) => {
          Cookies.set('a_id', userRes.data.records[0].AccountId); // Set Account Id in cookie
          Cookies.set('c_id', userRes.data.records[0].ContactId); // Set Contact Id in cookie

          setUserFirstName(userRes.data.records[0].FirstName);
          setUserLastName(userRes.data.records[0].LastName);
          setUserLogin(userRes.data.records[0].Username.substring(0, userRes.data.records[0].Username.indexOf('@')));
          
          if(userRes.data.records[0].Contact){
            setUserEmail(userRes.data.records[0].Contact.Email);
            setUserPhone(userRes.data.records[0].Contact.Phone);
          }
          if(userRes.data.records[0].Account){
            setUserDealerName(userRes.data.records[0].Account.Name);
            setUserDealerNo(userRes.data.records[0].Account.AccountNumber);
          }

          if(response.data.user_type == 'STANDARD' || (userRes.data.records[0].Account_Record_Type__c && userRes.data.records[0].Account_Record_Type__c == 'OEM Account')){ // Differentiate between internal and external users
            onUserTypeUpdate('internal');
          }
          else{
            onUserTypeUpdate('external');
          }
          // Get NetworkId and set in Cookie
          let qNetworkId = "SELECT NetworkId FROM NetworkMember WHERE MemberId = \'"+userRes.data.records[0].Id+"\'";
          qNetworkId = encodeURIComponent(qNetworkId);
          let urlNetworkId = process.env.REACT_APP_API + 'salesforce/kservice/get?param=query/?q=' + qNetworkId;

          let reqNetworkId = {
            method: "GET",
            url: urlNetworkId,
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + Cookies.get("token"),
              'X-SalesForce-Token' : Cookies.get('sf_cookie'),
              'X-Id-Token' : Cookies.get('idToken')
            }
          };

          axios(reqNetworkId).then((resNetworkId) => {
            Cookies.set('networkId', resNetworkId.data.records[0].NetworkId);
          }).catch((error) => {
            console.log(error);
          })

        }).catch((error) => {
          console.log(error);
        })
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }, []);

  const processExpireDate = () => {
    if (localStorage.getItem("PEDT")) {
      let newNum = localStorage.getItem("PEDT").toString().match(/.{1,2}/g);
      // console.log(newNum);
      let year = parseInt(newNum[0] + newNum[1]);
      let date = new Date(year, parseInt(newNum[2]) - 1, parseInt(newNum[3]), parseInt(newNum[4]), parseInt(newNum[5]), parseInt(newNum[6]));
      // console.log(date);
      let now = new Date();
      let diff = Math.abs(date.getTime() - now.getTime());
      let expireDaysUntil = diff / (1000 * 60 * 60 * 24);
      // Format "Full Month DD, YYYY"
      let month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      let eDate = month[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
      // console.log(date.getMonth());
      // console.log(date.getDate());
      // console.log(date.getFullYear());
      setExpireDate(eDate);
      // console.log(expireDaysUntil);
      // console.log(eDate);

      if (expireDaysUntil <= 10) {
        setShowKlinkMessage(true);
      } else {
        setShowKlinkMessage(false);
      }
    }
  }

  function UserInfo() {
    return (
      <React.Fragment>
        <Typography component='h2' variant='h5'>{userFirstName} {userLastName}</Typography>
        <Typography variant='subtitle1'>{userDealerName}</Typography>
        {userLogin &&
          <Typography variant='body2'>Username: {userLogin}</Typography>
        }
        {userEmail &&
          <Typography variant='body2'>Email: {userEmail}</Typography>
        }
        {userPhone &&
          <Typography variant='body2'>Phone Number: {userPhone}</Typography>
        }
      </React.Fragment>
    )
  }

  function getRemoteConfigs() {
    const remoteConfig = getRemoteConfig(app);
    remoteConfig.settings.minimumFetchIntervalMillis = 0;
    fetchAndActivate(remoteConfig)
      .then(() => {
        // ...
        const tabs = JSON.parse(getValue(remoteConfig, "tabs").asString());
        localStorage.setItem("KnowledgeTabs", JSON.stringify(tabs));
        const KnowledgeAttachmentBaseURL = getValue(remoteConfig, "KnowledgeAttachmentBaseURL").asString()
        localStorage.setItem("KnowledgeAttachmentBaseURL", KnowledgeAttachmentBaseURL);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function TabPanel(val) {

    const classes = useStyles();
    let recentMachines = JSON.parse(sessionStorage.getItem("recentMachines"));
    let machineList = [];
    if (recentMachines) {

      for (var i = 0; i < recentMachines.length; i++) {
        let serialNum = recentMachines[i].serial;
        let model = recentMachines[i].model;
        machineList.push(
          <React.Fragment>
            <ListItem button onClick={(event) => history.push("/support/machine/details/sn-" + serialNum + "/mn-" + model)}>
              <ListItemText
                primary={
                  <React.Fragment>
                    <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {/* <span className = {classes.bold}> */}
                      <Typography
                        component="span"
                        variant="h6"
                        // color="primary"
                        noWrap="true"
                        className={classes.listItem}
                        style={{ fontWeight: 600 }}
                      >
                        {"Serial: " + recentMachines[i].serial + " - Model: " + recentMachines[i].model}
                      </Typography>
                      {/* </span> */}
                    </div>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                      gutterBottom
                    >
                      {recentMachines[i].owner}
                    </Typography>
                  </React.Fragment>
                }
              />
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
            </ListItem>
            <Divider />
          </React.Fragment>
        );
      }
    } else {
      machineList.push(
        <p className={classes.centered}>
          <HistoryIcon centered />
          <Typography centered variant="caption" display="block" gutterBottom>No Machine History</Typography>
        </p>
      );
    }
    if (val.val === 'Machines') {
      return (
        <Typography>
          <List component="nav" aria-label="knowledge search results" className={classes.root}>
            {machineList}
          </List>
        </Typography>
      );
    } else if (val.val === 'Knowledge') {
      return (
        <KnowledgeRecentSearch />
      );
    } else if (val.val === 'Cases') {
      return (
        <CasesRecentSearch />
      );
    } else {
      return null;
    }
  }

  if (!width) {
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg" className={classes.heroContent}>
          {showKlinkMessage &&
            <Grid container spacing={2} className={classes.alertMSG}>
              <Grid item xs={9}>
                <Alert
                  severity="warning"
                  action={
                    <Button color="inherit" size="small" onClick={(event) => history.push("/knowledge/answer/1001568")}>
                      RESET MY PASSWORD
                    </Button>
                  }
                >
                  Your password will expire on {expireDate}. Please reset your password so you don&apos;t get locked out.
                </Alert>
              </Grid>
            </Grid>
          }
          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              <Paper elevation={3} className={classes.heroPaper}>
                <Typography variant="h5" component="h1">
                  Recent Activity
                </Typography>
                <Tabs
                  value={category}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleCategoryChange}
                  aria-label="select recent type"
                  centered
                >
                  <Tab label="Machines" value="Machines" />
                  <Tab label="Knowledge" value="Knowledge" />
                  <Tab label="Cases" value="Cases" />
                </Tabs>

                <TabPanel val={category} />

              </Paper>

            </Grid>
            {

            }
            <Grid item md={4} xs={12}>
              <Paper elevation={3} className={classes.heroPaper}>
                <UserInfo />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg" className={classes.heroContent}>
          {showKlinkMessage &&
            <Grid container spacing={2} className={classes.alertMSG}>
              <Grid item xs={12}>
                <Alert
                  severity="warning"
                  action={
                    <Button color="inherit" size="small" onClick={(event) => history.push("/knowledge/answer/1001568")}>
                      RESET MY PASSWORD
                    </Button>
                  }
                >
                  Your password will expire on {expireDate}. Please reset your password so you don&apos;t get locked out.
                </Alert>
              </Grid>
            </Grid>
          }
          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              <Paper elevation={3} className={classes.heroPaper}>
                <Typography variant="h5" component="h1">
                  Recent Activity
                </Typography>
                <Tabs
                  value={category}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleCategoryChange}
                  aria-label="select recent type"
                  variant="scrollable"
                  scrollButtons="on"
                >
                  <Tab label="Machines" value="Machines" />
                  <Tab label="Knowledge" value="Knowledge" />
                  <Tab label="Cases" value="Cases" />
                </Tabs>

                <TabPanel val={category} />

              </Paper>

            </Grid>
            {

            }
            <Grid item md={4} xs={12}>
              <Paper elevation={3} className={classes.heroPaper}>
                <UserInfo />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }

}

const mapStateToProps = state => ({
  rUserType: state.userType,
})

const mapDispatchToProps = dispatch => ({
  onUserTypeUpdate: text => dispatch(createUserType(text)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
