import MakeAnalyticsRequest from "./MakeAnalyticsRequest";
export default function handleNextStageAnalytics (stage, uuid, subject, machineDetails, DTC) {
        const stageUpdatePayload = {
            "svc_ticket_subject" : subject,
            "svc_ticket_custom" : {
                "serial_num": machineDetails.serialNumber,
                "model_num": machineDetails.modelNumber,
                "dtc_code_selected": DTC,
                "dtc_code_search": "",
                "businessUnits": "KTC-USA"
            },
            "t": "event",
            "pid" : uuid,
            "dr": process.env.REACT_APP_DOMAIN,
            "dl": window.location.href,
            "tm": Date.now(),
            "sr": "1920x1080",
            "sd": "24-bit",
            "ul": "en-US",
            "ua": window.navigator.userAgent,
            "dt": "new-case",
            "de": "UTF-8",
            "svc_action": "ticket_next_stage",
            "svc_action_data": {
                "stage": stage
            },
            "ec": "svc",
            "ea": "click",
            "cid" : uuid
        }
        MakeAnalyticsRequest(stageUpdatePayload);
}