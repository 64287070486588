/* 
 * This search helper is written to perform a model/serial search on a set of Cases
 * It is used on the CaseListPage and is setup specifically for the results from SF
 */
import Fuse from 'fuse.js'

const fuseOptions = {
	// isCaseSensitive: false,
	includeScore: true,
	// shouldSort: true,
	// includeMatches: false,
	// findAllMatches: false,
	// minMatchCharLength: 1,
	location: 0,
	threshold: 0.3,
	// distance: 100,
	// useExtendedSearch: false,
	// ignoreLocation: false,
	// ignoreFieldNorm: false,
	// fieldNormWeight: 1,
	keys: [
		
	]
};

export default function modelSerialSearch(caseList, keyword) {
    //Add facet to the fuseOption keys list
    fuseOptions.keys = ["fields.Model_Number__c.value", "fields.Serial_Number__c.value"];
    const fuse = new Fuse(caseList, fuseOptions);
    var results = fuse.search(keyword);
    return results.map(result => result.item);
}