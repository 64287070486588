import MakeAnalyticsRequest from "./MakeAnalyticsRequest";
export default function handleCreateTicketAnalytics(machineDetails, dtc_code_selected,  subject, uuid, isSolved) {
    const commonPayload = {
        "svc_ticket_subject": subject,
        "svc_ticket_custom" : {
            "businessUnits" : "KTC-USA",
            "dtc_code_search": "",
            "serial_num": machineDetails.serialNumber,
            "model_num": machineDetails.modelNumber,
            "dtc_code_selected": dtc_code_selected
        },
        "t": "event",
        "pid" : uuid,
        "dr": process.env.REACT_APP_DOMAIN,
        "dl": window.location.href,
        "tm": Date.now(),
        "sr": "1920x1080",
        "sd": "24-bit",
        "ul": "en-US",
        "ua": window.navigator.userAgent,
        "dt": "Case List",
        "de": "UTF-8",
        "svc_action": "ticket_create",
        "ec": "svc",
        "ea": "click",
        "cid" : uuid
    };
    MakeAnalyticsRequest(commonPayload);
}