export const CREATE_USER_TYPE = 'CREATE_USER_TYPE'

// Action Creator for ADD User Type
export const createUserType = text => ({
    type: CREATE_USER_TYPE,
    payload: {text}
})

export const REMOVE_USER_TYPE = 'REMOVE_USER_TYPE'

// Action Creator for REMOVE User Type
export const removeUserType = text => ({
    type: REMOVE_USER_TYPE,
    payload: {text}
})