import React from 'react'
import { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';




const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(4, 0, 6),
    width: '40%'
  },
  heroPaper: {
    padding: theme.spacing(2, 3, 3),
    margin: theme.spacing(0, 0, 4)
  },
  loadingCenterInside: {
    marginLeft: '45%',
    left: '-20px'
  },
  button : {
    marginLeft: '35%'
  }
}));

function CaseSuccess({caseNumber, caseID, caseOrReport}) {
    const classes = useStyles();
    const [ButtonText, SetButtonText] = useState('');
    const goToCase = () => {
      var location = "/support/case/" + caseID; 
      window.location.href = location;
    };

    useEffect(() => {
      if(caseOrReport === "case") {
        SetButtonText("GO TO CASE");
      } else {
        SetButtonText("GO TO REPORT");
      }
    }, []);

    return (
      <>
        {
          !caseNumber &&
          <Container maxWidth="lg" className={classes.heroContent}>
              <Paper elevation={3} className={classes.heroPaper} justify="center">
                  <CircularProgress className={classes.loadingCenterInside} />
              </Paper>
          </Container>
        }
        { caseNumber && 
          <Container maxWidth="sm" className={classes.heroContent}>
            <Paper elevation={3} className={classes.heroPaper} justify="center">
                    <Grid container spacing={2}>
                      <Typography variant="h5" gutterBottom>
                          Thank you for your submission.
                      </Typography>
                      <Typography variant="subtitle1">
                          Your case number is {caseNumber}. We will
                          send you an update after reviewing your submission details.
                      </Typography>
                      <Grid item md={12} xs={12}>
                            <Button
                                className={classes.button}
                                disabled={false}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    goToCase();
                                }}
                            >
                              {ButtonText}
                            </Button>
                        </Grid>
                    </Grid>
            </Paper>
        </Container>
        }
      </>
    
    )
}

export default CaseSuccess