import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { getMessaging, onMessage } from "firebase/messaging";
import { app } from "../firebaseConfig";

const useStyles = makeStyles((theme) => ({
  snackbarTest:{
    backgrouondColor: '#DC4405',
  },
  anchorOriginBottomLeft:{
    bottom: 65,
  },
  bottom:{
    bottom: 65
  },
  contentRoot:{
    //color: '#DC4405',
    bottom: 65,
    fontSize: 17
  }
}));

// const SnackContent = (...props) =>{
//   return(
//     <React.Fragment>
//       <Paper>
//         <Typography>
//           ajkfhkl
//         </Typography>
//       </Paper>
//     </React.Fragment>
//   )
// }

function RenderSnackBar(){
  const [messages, setMessages] = React.useState(JSON.parse(sessionStorage.getItem('RecentMessages')));
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // On Component Load
    let messageHistory = JSON.parse(sessionStorage.getItem('RecentMessages')) || null;
    // console.log(searchHistory);
    setMessages(messageHistory);

}, []);


// function prepSnack(title, description){

// };

  const messaging = getMessaging(app);

  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // prepSnack(payload.notification.title, payload.notification.body);

    let tempMessageArray = messages;

    if(!tempMessageArray){
      tempMessageArray = [];
    }
    debugger;
    if(payload.hasOwnProperty("notification")) {
      //New message format
      tempMessageArray.unshift({"title": payload.notification.title, "description": payload.notification.body});
      enqueueSnackbar(payload.notification.title + ": " + payload.notification.body, {preventDuplicate: true});
    } else {
      //Old message format
      tempMessageArray.unshift({"title": payload.notification.title, "description": payload.notification.body});
      enqueueSnackbar(payload.notification.title + ": " + payload.notification.body, {preventDuplicate: true});
    }
  
    setMessages(tempMessageArray);
    sessionStorage.setItem('RecentMessages', JSON.stringify(tempMessageArray));

    // ...
  });

  // let testData = ["Zero", "One", "Two"]

  // function openButton(num){
  //   console.log("Test Snack called" + num)

  //   enqueueSnackbar( testData[num], {
  //                   style: { backgroundColor: 'black', color: '#DC4405', bottom: 65 },
  //                   //className: {classes.anchorOriginBottomLeft},
  //                   preventDuplicate: true,
  //                   // variant: '#DC4405',
  //                   // options: {anchorOrigin:{ horizontal: 'right', vertical: 'bottom' }},
  //                   //content: (key, message) => <Paper><Typography style={{fontSize: '1.5rem'}}>{message}</Typography></Paper>
  //                   })
  // };

  // for(var i = 0; i<3; i++){
  //   openButton(i)
  // }

  //enqueueSnackbar(testData[0].title, {style: { backgroundColor: 'teal', color: 'coral' }, action: {closeButton} })

  // function theSnackbar(){
  //   return(
  //   <Snackbar
  //     anchorOrigin={{
  //       vertical: 'bottom',
  //       horizontal: 'left',
  //     }}
  //     open={open}
  //     autoHideDuration={15000000}
  //     onClose={handleClose}
  //     className={classes.anchorOriginBottomLeft}

  //     >
  //     <SnackbarContent style={{fontSize: 30}} message={testData[0]} action={closeButton}>

  //       {/* <React.Fragment> */}


  //       {/* </React.Fragment> */}
  //     </SnackbarContent>
  //   </Snackbar>
  //   )
  // }


  return( null
    // theSnackbar()
    )

}

const MessageSnackbar = () => {
  const classes = useStyles();

  //Display new message
  //If there a three new messages display them
  //If 3+, display the first two black and make the third orange and have it say "you have x new messages"
  //15 seconds to fade

  console.log("Message Snackbar Call")

const notistackRef = React.createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
}

  return(
    <SnackbarProvider
    maxSnack={3}
    //className={classes.anchorOriginBottomLeft}
    classes={{
      containerRoot: classes.contentRoot
    }}
    autoHideDuration={15000}
    ref={notistackRef}
    action={(key) => (
        <IconButton size="small" aria-label="close" style={{ color: "white" }} onClick={onClickDismiss(key)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
      >
      <RenderSnackBar />
    </SnackbarProvider>
  )
}

export default MessageSnackbar;