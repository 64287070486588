import { Grid, Container, Paper, Typography, Divider, TextField, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MachineCard from '../components/MachineCard';
import { Pagination } from '@mui/material';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import iconSearch from '../helpers/fuse/iconSearch';
import machineSearch from '../helpers/fuse/machineSearch';



const useStyles = makeStyles((theme) => ({
    heroContent: {
        padding: theme.spacing(8, 0, 6),
        width: '90%'
      },
      heroPaper: {
        padding: theme.spacing(1, 2, 2),
        margin: theme.spacing(0, 0, 4)
      },
      machineCard: {
        padding: '8px',
        paddingBottom: '0px',
        background: 'rgba(242, 240, 240, 1)',
        borderRadius: 20,
        marginBottom: '12px',
      },
      button: {
        '.MuiButton-root': {
            borderRadius: 16
          },
      }
}));


const MachineResults = () => {
    const location = useLocation();
    const classes = useStyles();
    const storage = getStorage();
    //Master is the full list of machines, used with Fuse for the Filtered list.
    const [machineMasterList, setMachineMasterList] = useState();
    //Filter list is from fuse, this is rendered on screen.
    const [fuseResults, setFuseResults] = useState();
    const [searchTerm, setSearchTerm] = useState();
    const [fuseSearchFacet, setFuseSearchFacet] = useState("");
    const [pageCount, setPageCount] = useState(1);

    useEffect(() => {
        var masterList = generateMasterList(location.state.results);
        setMachineMasterList(masterList);
        fetchIcons(masterList);
    }, []);

    useEffect(() => {
        //Wipe current icon list so we show defaults icons while new icons are being loaded
        if(machineMasterList) {
            handleFuseSearch();
        }
    }, [machineMasterList]);

    useEffect(() => {
       
    }, [pageCount]);

    useEffect(() => {
        handleFuseSearch();
    }, [searchTerm]);

    function generateMasterList(queryResults) {
        var masterList = queryResults.map((machine) => {
            return({
                "Serial": machine.WOD_2__Serial_Number__c,
                "ModelNumber": machine.Material_Name1__c,
                "CustomerName": machine.End_Customer_Name__c,
                "City": machine.Billing_Dealer_City__c,
                "ServiceDealer": machine.Servicing_Dealer_Name__c,
                "PIN": machine.Product_Identification_No__c,
                "Description": machine.Factory_Item_Description__c,
                "icon": false
            });
        });
        return masterList;
    }
    async function fetchIcons(machineMasterList) {
        //Fetch Icons for the 10 machines shown on screen
        var icons = fetchIconsHelper(machineMasterList);
        //Place new icons in each machine OBJ of our current slice
        var machinesWithNewIcons = machineMasterList.map((machine, index) => {
            return({
                ...machine,
                "icon": icons[index]
            })
        });
        setMachineMasterList(machinesWithNewIcons);
    }
    function fetchIconsHelper(machineSlice) {
        var icons = [];
        machineSlice.forEach((machine, index) => {
            var iconURL = iconSearch(location.state.iconMap, machine.ModelNumber);
            icons.push(iconURL);
        });
        return icons;
    }

    function handleFuseSearch() {
        if(!searchTerm) {
            setFuseResults(machineMasterList);
        } else {
            //Fuse wraps our machines in a parent obj w/ metrics. Use map to remove parent and just leave machine objects on top level.
            var searchResults = machineSearch(machineMasterList, fuseSearchFacet, searchTerm).map((machineResult) => {
                return machineResult.item;
            })
            setFuseResults(searchResults);
        }
    }
    
    return (
        <React.Fragment>
            <Container className={classes.heroContent}>
                <Grid container
                    alignItems="center"
                    justifyContent="center"
                > 
                    <Grid item md={6} xs={6}>
                        <Paper elevation={3} className={classes.heroPaper}>
                            <Grid container  spacing={2} direction="row" alignItems="center" justifyContent="center" >
                                <Grid item md={12} style={{marginBottom: '16px'}}>
                                    <Typography component="h1" variant="h5">
                                        Machine List
                                    </Typography>
                                </Grid>
                                <Grid item md={3} style={{marginBottom: '16px'}}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel id="FilterLabel">Filter</InputLabel>
                                        <Select
                                            labelId='FilterLabel'
                                            id="MachineResultsFilter"
                                            value={fuseSearchFacet}
                                            label="Filter"
                                            onChange={(e) => {
                                                setFuseSearchFacet(e.target.value)
                                            }}
                                        >
                                            <MenuItem value={"ModelNumber"}>Model</MenuItem>
                                            <MenuItem value={"Serial"}>Serial</MenuItem>
                                            <MenuItem value={"CustomerName"}>Customer Name</MenuItem>
                                            <MenuItem value={"City"}>City</MenuItem>
                                            <MenuItem value={"ServiceDealer"}>Servicing Dealer</MenuItem>
                                        </Select>
                                    </FormControl>
                                    
                                </Grid>
                                <Grid item md={9} style={{marginBottom: '16px'}}>
                                    { fuseSearchFacet &&
                                        <TextField fullWidth id="outlined-basic" label="Search" variant="outlined" 
                                        onChange={(e) => {
                                            setSearchTerm(e.currentTarget.value);
                                        }}
                                    />
                                    }
                                </Grid>
                                <Grid item md={12}>
                                    { fuseResults &&
                                        <React.Fragment>
                                            { fuseResults.slice(((pageCount - 1) * 10),(pageCount * 10)).map((machine, i) => {
                                                return(
                                                    <MachineCard
                                                        machine={machine}
                                                        key={'machineCard' + i}
                                                        classes={classes}
                                                        style={{marginTop: '4px'}}
                                                        index={i}
                                                    />
                                                );
                                            })
                                            }
                                        </React.Fragment>
                                    }
                                </Grid>
                                <Grid item md={12}>
                                { fuseResults &&
                                    <Pagination count={Math.ceil(fuseResults.length / 10)} variant="outlined" color="primary" onChange={(e, value) => {
                                        setPageCount(value);
                                        window.scrollTo(0,0);
                                    }} />
                                }
                                </Grid>
                            </Grid>
                            
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}

export default MachineResults;
