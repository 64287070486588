import React, { Component } from 'react'
import { makeStyles } from '@material-ui/styles';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Button } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Container, Paper } from '@material-ui/core';
import isPhone from 'validator/lib/isMobilePhone';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';
import CropFreeIcon from '@material-ui/icons/CropFreeSharp';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import Alert from '@material-ui/lab/Alert';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import { Card, CardContent } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {withRouter} from 'react-router-dom';
import { Fab } from '@material-ui/core';
import Html5QrcodeScanner from '../components/Html5QrcodeScanner';
import CloseIcon from '@material-ui/icons/Close';

import axios from 'axios';
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme) => ({
    horizFlex: {
      // display: 'flex',
      alignItems: 'center',
      // justifyContent: 'center',
    },
    flexbox: {
      display: 'flex',
      position: 'fixed',
      width: '100%',
      justifyContent: 'center',
      zIndex: '1',
      bottom: theme.spacing(10)
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {

    },
    buttonRight: {
      marginLeft: theme.spacing(2)
    },
    divider: {
      marginBottom: theme.spacing(2)
    },
    imeiForm: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0)
    },
    usageForm: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    sectionHeader: {
      marginTop: theme.spacing(2)
    },
    heroContent: {
      padding: theme.spacing(8, 0, 6),
      width: '90%'
    },
    heroPaper: {
      padding: theme.spacing(1, 2, 2),
      margin: theme.spacing(0, 0, 4)
    },
    eyebrow: {
      margin: theme.spacing(0, 0, 3)
    },
}));

export const FormIMEIDCUScan = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [imei, setImei] = useState('');
  const [usageHours, setUsageHours] = useState('');
  const [dcuPartNumber, setDcuPartNumber] = useState('');
  const [sn, setSn] = useState('');
  const [mn, setMn] = useState('');
  const [pin, setPin] = useState('');
  const [lastReported, setLastReported] = useState('');
  const [commitMsg, setCommitMsg] = useState('');
  const [alertIMEIMsg, setAlertIMEIMsg] = useState('');
  const [requiredMsg, setRequiredMsg] = useState('');
  const [scannerOpen, setScannerOpen] = useState(false);
  const [stopStream, setStopStream] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [success, setSuccess] = useState(false);
  const [commitSuccessful, setCommitSuccessful] = useState(false);
  const [successMsg, setSuccessMsg] = useState('The KubotaNOW telematics pair has been established.');
  const {startQrCode, stopQrCode, decodedQRData } = Html5QrcodeScanner({
    qrcodeMountNodeID: "qrcodemountnode"
  });

  useEffect(() => {
      // get all entities - GET
      let location = window.location.pathname.split('/');
      location.forEach(value => {
      assignParams(value);
    });
  }, []);

  useEffect(() => {
    if(scannerOpen){
      startQrCode();
    }
  }, [scannerOpen]);

  useEffect(() => {
    if(decodedQRData.data){
      stopQrCode();
      console.log('Decoded QR Data');
      console.log(decodedQRData.data);
      processQRScan(decodedQRData.data);
      setScannerOpen(false);
    }
  }, [decodedQRData]);

  const processQRScan = (s) => {
    // Explode to array
    console.log(s);
    setImei(s);
  }

  const goBack = () => {
    window.history.go(-1);
    return false;
  }

  const closeQRScanner = () => {
    stopQrCode();
    setScannerOpen(false);
    console.log('Close QR Scanner');
  }

  const openQRScanner = () => {
    setScannerOpen(true);
    console.log('Open QR Scanner');
  }

  // Process Params
  const assignParams = (value) => {
    if (value.substring(0, 2) == "sn") {
        setSn(value.substring(3));
    }

    if (value.substring(0, 2) == "mn") {
        setMn(value.substring(3));
    }

    if (value.substring(0, 3) == "pin") {
        setPin(value.substring(4));
    }
  }

  const validateIMEI = e => {
    props.handleChange('imei');
    console.log(e.type);
    if (e.type === 'change') {
      setImei(e.target.value);
      console.log(e.target.value.length);
      if(e.target.value){
        if(e.target.value.length !== 15){
          setAlertIMEIMsg('IMEI must be 15 char long.');
        }else{
          setAlertIMEIMsg('');
        }
      }
    }
    if (e.type === 'click') {
      if(e.target.value){
        if(e.target.value.length !== 15){
          console.log(e.target.value.length);
          setAlertIMEIMsg('IMEI must be 15 char long.');
          return
        }else{
          setAlertIMEIMsg('');
        }
      }

      setLoading(true);
        //NEW CODE
        var validateReq = {
          method: "get",
          url : process.env.REACT_APP_API + 'IMEI/validateDCUv2?IMEINumber=' + imei,
          headers: {
            'Authorization': 'Bearer ' + Cookies.get("token")
          }
        }
        axios(validateReq).then((response) => {
          if (response.data.type) {
            setDcuPartNumber(response.data.type);
            props.values.dcuPartNumber = response.data.type;
            setSuccess(true);
            if (typeof response.data.last_report != "undefined") {
              formatTime(response.data.last_report.server_time);
            } else {
              setLastReported('No available info.');
            }

          } else {
            setAlertIMEIMsg(response.data);
          }
            setLoading(false);
        }).catch((error) => {
          console.log(error);
          setAlertIMEIMsg('The scan results are invalid. Please refer to K-Warranty to verify and resolve this issue. '.err);
          setLoading(false);
        });
    }
  }

  const commitIMEI = (e) => {
    console.log(e.type);
    if (e.type === 'click') {
      console.log( 'UPDATE IMEI' );
        setLoading2(true);

        var data = new FormData();
        data.append('serialNumber', sn );
        data.append('modelNumber', mn );
        data.append('pin', pin );
        data.append('dcuPartNumber', dcuPartNumber );
        data.append('imeiNumber', imei );
        data.append('usageHours', usageHours );

        //NEW CODE
        var updateIMEIReq = {
          method: "post",
          url : process.env.REACT_APP_API + 'IMEI/UpdateIMEI',
          headers: {
            'Authorization': 'Bearer ' + Cookies.get("token"),
            "Content-Type" : "aplication/json"
          },
          data : {
            "serialNumber": sn,
            "modelNumber": mn,
            "pin": pin,
            "dcuPartNumber": dcuPartNumber,
            "imeiNumber": imei,
            "usageHours": usageHours[0]
          }
        };
        axios(updateIMEIReq).then((r) => {
          if(Array.isArray(r.data)){
            if(r.data[0].errorCode){
                  setCommitMsg(r.data[0].message);
              }else{
                  setCommitSuccessful(true);
              }
          }else{
              if(r.data.errorCode){
                setCommitMsg(r.data.message);
              }else{
                setCommitSuccessful(true);
              }
          }
          setLoading2(false);
        }).catch((error) => {
          console.log("SUBMIT ERROR");
          console.log(error);
          setLoading2(false);
          setCommitMsg("The application experienced an unexplained error. Please try again or submit a Case.");
        });
    }
  }

  const formatTime = (string) => {
    var date = new Date(string);
    var formatted = date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();

    setLastReported(formatted);
    props.values.lastReported = formatted;

  }
  
  const reset = e => {
    // console.log('RESET TO STEP 1');
    setCommitMsg('');
    setSuccess(false);
    setDcuPartNumber('');
    setLastReported('');
    setRequiredMsg('');
  }

  return (
      <React.Fragment>
        <CssBaseline />
        {scannerOpen &&
          <React.Fragment>
            <div className={classes.flexbox}>
              <Fab variant="extended" color="primary" aria-label="add" className={classes.fab} onClick={closeQRScanner}>
                <CloseIcon />
                Close Scanner
              </Fab>
            </div>
            <div className={classes.horizFlex}>
              <div id="qrcodemountnode"></div>
            </div>
          </React.Fragment>
        }
        {!scannerOpen &&
        <Container maxWidth="lg" className={classes.heroContent}>
        <Grid container spacing={2}>
        <Grid item md={3} xs={false}></Grid>
          <Grid item md={6} xs={12}>
            <Paper elevation={3} className={classes.heroPaper} justify="center">
              <Typography variant="h6">
                Machine Details
              </Typography>
              <Divider className={classes.divider} />
              {mn &&
                <Typography variant="body1">Model: {mn}</Typography>
              }
              {sn &&
                <Typography variant="body1">Serial: {sn}</Typography>
              }
              {pin &&
                <Typography variant="body1">PIN: {pin}</Typography>
              }
              <Typography variant="h6" className={classes.sectionHeader}>
                Step 1: Scan IMEI Barcode
              </Typography>
              <Divider className={classes.divider} />
              {loading &&
                <div className={classes.root}>
                  <CircularProgress style={{ marginLeft: '50%', position: 'relative', left: '-20px' }} />
                </div>
              }
              {!loading &&
              <Grid container spacing={3}>
                {!success &&
                <React.Fragment>

                  {!scannerOpen &&
                    <React.Fragment>
                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={openQRScanner}
                          startIcon={<CropFreeIcon />}
                        >
                          Open Scanner
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">
                          Or search IMEI by typing your IMEI value.
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          className={classes.imeiForm}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="imei"
                          label="IMEI"
                          name="imei"
                          onChange={(e) => { validateIMEI(e); }}
                          defaultValue={imei}
                          value={imei}
                          onKeyUp={(event) => {
                                let value = event.target.value.match(/\d*/);

                                if(value == null){
                                  value = "";
                                }else{
                                  value = value[0]
                                }

                                setImei(value);
                                validateIMEI(value);
                          }}
                          onBlur={(e) => { validateIMEI(e); }}
                          autoFocus
                          helperText={alertIMEIMsg}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          disabled={imei.length !== 15}
                          variant="contained"
                          color="primary"
                          size="large"
                          className={classes.button}
                          onClick={(e) => { validateIMEI(e); }}
                          startIcon={<SearchIcon />}
                        >Check DCU Status</Button>
                      </Grid>
                    </React.Fragment>
                  }
                </React.Fragment>
                }
                {success &&
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography color="textSecondary" gutterBottom>
                            KubotaNOW DCU Scanned
                          </Typography>
                          <Typography>IMEI: {imei}</Typography>
                          <Typography>DCU: {dcuPartNumber}</Typography>
                          <Typography>Last Reported: {lastReported}</Typography>
                        </CardContent>
                      </Card>
                      {requiredMsg &&
                        <Alert
                          variant="outlined"
                          severity="warning"
                          action={
                            <Button color="inherit" size="small" onClick={(e) => { reset(e); }}>
                              RESET
                            </Button>
                          }
                        >{requiredMsg}</Alert>
                      }

                    </Grid>
                  </React.Fragment>
                }
              </Grid>
              }
              {success &&
                <React.Fragment>
                  <Grid item xs={12}>
                    <Typography variant="h6" className={classes.sectionHeader}>
                      Step 2: Commit Machine/DCU Pairing
                    </Typography>
                    <Divider className={classes.divider} />
                    {loading2 &&
                      <div className={classes.root}>
                        <CircularProgress style={{ marginLeft: '50%', position: 'relative', left: '-20px' }} />
                      </div>
                    }
                    {!loading2 && !commitMsg && !commitSuccessful &&
                      <React.Fragment>
                      <TextField
                        className={classes.usageForm}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="usageHours"
                        label="Engine Hours at Time of Installation"
                        name="usageHours"
                        onChange={e => setUsageHours(e.target.value)}
                        defaultValue={usageHours}
                        value={usageHours}
                        inputProps={{ inputMode: 'decimal', maxLength: 8 }}
                        onKeyUp={(event) => {
                              let value = event.target.value.match(/\d*(?:[.]\d*)?/);

                              if(value == null){
                                value = "";
                              }

                              setUsageHours(value);
                        }}
                        autoFocus
                      />

                        <Typography gutterBottom>Do you want to commit this machine/DCU pair to the record in K-Warranty?</Typography>
                        <Button
                                disabled={!usageHours}
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={(e) => { commitIMEI(e); }}
                                startIcon={<SendIcon />}
                              >Commit</Button>
                        <Button
                                variant="contained"
                                className={classes.buttonRight}
                                onClick={(e) => { reset(e); }}
                                startIcon={<ArrowBackIosIcon />}
                              >Step 1</Button>
                      </React.Fragment>
                    }
                    {!loading2 && commitMsg && !commitSuccessful &&
                      <React.Fragment>
                        <Alert
                          variant="outlined"
                          severity="warning"
                          action={
                            <Button color="inherit" size="small" onClick={(e) => { reset(e); }}>
                              RESET
                            </Button>
                          }
                        >{commitMsg}</Alert>
                      </React.Fragment>
                    }
                    {!loading2 && !commitMsg && commitSuccessful &&
                      <React.Fragment>
                        <Alert
                          variant="outlined"
                          severity="success"
                          action={
                            <Button color="inherit" size="small" onClick={goBack}>
                              BACK
                            </Button>
                          }
                        >{successMsg}</Alert>
                      </React.Fragment>
                    }
                  </Grid>
              </React.Fragment>
              }
              </Paper>
        </Grid>
        </Grid>
      </Container>
        }
      </React.Fragment>
    )
}
