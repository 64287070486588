import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SFSection from './SFSection';
import Cookies from 'js-cookie';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


const useStyles = makeStyles((theme) => ({
  heroContent: {
    width: "90%",
    padding: theme.spacing(8, 0, 6),
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
  button: {
      marginTop: 8,
      marginLeft: 12,
  },
  buttonGroup: {
    marginTop: "16px",
    paddingRight: "10px",
    marginBottom: "16px"
  }
  
  
  
  
}));

export default function SFContainer( {RecordID, Record, layoutMode, Action, allowEdit, objectInfo, editValues, picklists, onFieldValueUpdate
  , DEPStates, ErrorStates, reqFields, setErrorStates, setStep, createCase, caseOrReport, defaultExpanded} ) {
  const classes = useStyles();
  console.log("Inside SF Contaner Component");
  const styles = {
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: 8,
        marginLeft: 12,
    },
    root: {
        display: 'flex',
        '& > * + *': {
          marginLeft: 8,
        },
      }
      
}
  const handleBack = () => {
    if(caseOrReport === "report") {
      setStep(3.5);
    } else { 
      setStep(prevState => prevState-1);
    }
  }


  return (
    <React.Fragment>
          <Grid container>
              {Record.layouts[layoutMode][Action].map((section, i) =>
                <Grid key={"sectionGrid" + i} item xs={12}>
                  <SFSection
                    key={'section' + i}
                    Section = {section}
                    index = {i}
                    allowEdit = {allowEdit}
                    objectInfo = {objectInfo}
                    editValues = {editValues}
                    picklists = {picklists}
                    onFieldValueUpdate={onFieldValueUpdate}
                    DEPStates={DEPStates}
                    ErrorStates = {ErrorStates}
                    defaultExpanded={defaultExpanded}
                    reqFields={reqFields}
                    />
                </Grid>
              )}
              {Action == "Create" &&
              <>
                <Grid item xs={12} className={classes.buttonGroup}>
                  <Grid container direction={"row-reverse"} spacing={1}>
                    <Grid item>
                      <div style={styles.buttons}>
                          <Button
                            disabled={false}
                            variant="outlined"
                            color="primary"
                            style={styles.button}
                            onClick={() => {
                              createCase(false);
                            }}
                            endIcon={<ChevronRightIcon/>}
                          >
                          Next
                          </Button>
                      </div>
                    </Grid>
                    <Grid item>
                      <div style={styles.buttons}>
                          <Button
                            disabled={false}
                            variant="outlined"
                            color="primary"
                            style={styles.button}
                            onClick={handleBack}
                            startIcon={<ChevronLeftIcon/>}
                          >
                          Back
                          </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </>
              }
          </Grid>
    </React.Fragment>
  )
}
