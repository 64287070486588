export default class SalesForceConstants {
    // static CaseRecordTypeID = '0128D000000jZPYQA2';      //KSDEV
    // static ReportRecordTypeID = '0128D000000jZYIQA2';    //KSDEV

    //static CaseRecordTypeID = '0128b000000qGfIAAU';         //KSUAT
    //static ReportRecordTypeID = '0128b000000qGfJAAU';       //KSUAT

    static CaseRecordTypeID = '0128b000000qGfIAAU';      //PROD
    static ReportRecordTypeID = '0128b000000qGfJAAU';    //PROD

    static yourekaNetworkID = '0DB8b000000kGzk'          //PROD
    // static yourekaNetworkID= '0DB8D000000CbxQ';          //KSDEV 
    // static yourekaNetworkID = '0DB8b000000kGzk';            //KSUAT 


}
