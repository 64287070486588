export function convertPPDSchemaIntoJson(SFObjects) {
    let convertedJSON = {};
    let SFParent = SFObjects[0];

    //Convert the parent into our JSON format
    convertedJSON.symptom = SFParent.Symptom__c;
    convertedJSON.serialNumber = SFParent.Serial__c;
    convertedJSON.recordID = SFParent.Id;
    convertedJSON.modelNumber = SFParent.Model__c;
    
    //Add Children
    convertedJSON.PartsCombinations = [];

    let SFChildren = SFObjects[1];
    SFChildren.forEach((Result,index) => {
        let tempResult = {
            recordID: Result.Id,
            recommendedJobhours: Result.Estimated_Labor__c,
            probabilitySimilarPhraseMatch: Result.Probability_Score__c,
            causalPartDescription: Result.Causal_Part_Description__c,
            causalPart: Result.Name,
            requiredParts: [],
            itemNo: index + 1
        };
        convertedJSON.PartsCombinations.push(tempResult); 
    });

    // Add grandchildren/required parts to their correct parent(combination)
    let requiredParts = SFObjects[2];
    convertedJSON.PartsCombinations.forEach((Combination, index) => {
        let recordId = Combination.recordID;
        //Iterate through required parts and pick out ones where our parent's recordId matches the childs stored parent recordId
        requiredParts.forEach((requiredPart) => {
            if (requiredPart.Parts_Prediction_Result__c == recordId) {
                convertedJSON.PartsCombinations[index].requiredParts.push({
                    recordID: requiredPart.Id,
                    qty: requiredPart.Quantity__c,
                    partNumber: requiredPart.Part_Number__c,
                    partDesc: requiredPart.Name,
                });
            }
        });
    });
    return convertedJSON;
  }