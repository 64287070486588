import { CREATE_USER_TYPE, REMOVE_USER_TYPE } from "./actions";

// state
// action: CREATE REMOVE
export const userType = (state = null, action) => {
    const { type, payload } = action
    
    switch (type) {
        case CREATE_USER_TYPE:
            const newUser = payload // text should be the user type
            // set localstorage
            // console.log(newUser)
            localStorage.setItem('UT', btoa(newUser.text));
            return newUser
        case REMOVE_USER_TYPE:
            localStorage.removeItem('UT');
            return null // 
        default:
            return state
    }
    return state
}