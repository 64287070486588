import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Accordion from '@material-ui/core/Accordion';

import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SFRow from './SFRow';
import Typography from '@material-ui/core/Typography';



const useStyles = makeStyles((theme) => ({
  heroContent: {
    width: "90%",
    padding: theme.spacing(8, 0, 6),
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  divider: {
    margin: theme.spacing(0,0,2)
  },
  form: {
    margin: theme.spacing(0, 0, 5)
  },
  loaderCenter: {
    marginLeft: '50%',
  },
  loadingCenterInside: {
    left: '-20px',
    position: 'relative'
  },
  listItem: {
    width: '90%',
    display: "inline-block"
  },
  sectionHeader: {
    display: "inline-block",
    padding: theme.spacing(0,0,1,0)
  },
  heading: {
    width: '100%',
    padding: theme.spacing(0, 1, 0, 0)
  },
  bold: {
    fontWeight: '800'
  },
  floatRight: {
    float: 'right'
  },
  accordionDetails : {
    marginTop: "10px"
  }
}));

export default function SFSection( {Section, index, allowEdit, objectInfo, editValues, picklists, onFieldValueUpdate, DEPStates, ErrorStates, defaultExpanded, reqFields} ) {
  const classes = useStyles();
  const columns = Section.columns;
  return (
    <Accordion key={"Accordion" + index} defaultExpanded={defaultExpanded}>
      <AccordionSummary style={{backgroundColor:"#D3D3D3"}} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
        <Typography className={classes.heading}> 
          <span className={classes.bold}>
            {Section.heading}
          </span>
        </Typography>
        {/* <Typography variant="h5" component="h1" className={classes.sectionHeader} >{Section.heading} </Typography> */}
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid container spacing={3}>
          {Section.rows.map((row, i) =>
                <SFRow
                    key={'sectionRow' + index + ',' + i}
                    row = {row}
                    sectionIndex = {index}
                    rowIndex = {i}
                    allowEdit = {allowEdit}
                    objectInfo = {objectInfo}
                    editValues = {editValues}
                    picklists = {picklists}
                    onFieldValueUpdate={onFieldValueUpdate}
                    DEPStates={DEPStates}
                    ErrorStates = {ErrorStates}
                    reqFields={reqFields}
                    columns={columns}
                />
          )}
        </Grid>
      </AccordionDetails>  
    </Accordion>  
  )
}
