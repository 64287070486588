import React from 'react'
import { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Card, CardHeader, CardActionArea, CardMedia, CardContent } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import ClearIcon from '@material-ui/icons/Clear';
import { IconButton } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import SaveIcon from '@material-ui/icons/Save';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(4, 0, 6),
    width: '70%'
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  loadingCenterInside: {
    position: 'fixed',
    top: '50%',
    marginLeft: '50%',
    left: '-20px'
  },
  submitButton: {
      width: "auto"
  },
  update: {
      marginTop: '2px'
  },
  halfWidth: {
      width: "100%"
  },
  submitCluster: {
    marginTop: '12px'
  },
  form: {
    marginBottom: '12px'
  }
}));

function FeedAttachments({setStep, editValues, objectInfo, imageState, updateImageState, formData, updateFormData, createCase, createReport, caseOrReport}) {
    const classes = useStyles();
    const [ButtonText, setButtonText] = useState('');
    const ref = React.useRef();

    useEffect(() => {
        if(caseOrReport === "case") {
            setButtonText("CREATE CASE");
        } else {
            setButtonText("CREATE REPORT");
        }
    }, []);

    const handleChange = (e) => {
        let images;
    
        if(imageState){
            images = imageState;
        }else{
            images = [];
        }
    
        var fileName;
        if(e.target.name == "attachments"){
            for(var x = 0; x < e.target.files.length; x++){
                const blob = e.target.files[x];
                fileName = e.target.files[x].name;
                //CHECK IF HEIC
                if(blob.type.toLowerCase() === "image/heic")
                {
                    heic2any({ blob: blob, toType: "image/jpeg", quality: 1 }).then(
                    (newImage) => {
                        console.log("new image:", newImage);
                        var reader = new FileReader();
                        reader.readAsDataURL(newImage);
                        reader.onloadend = function(e){
                        var isHeif = (fileName.toLowerCase().includes(".heif")) ? true : false;
                        let thisImage = {
                            imageName: (isHeif) ? fileName.replace(".heif",".jpeg") : fileName.replace(".heic",".jpeg"),
                            imageType: "image/jpg",
                            localSrc: e.currentTarget.result,
                            base64: e.currentTarget.result.replace(/^data:image\/[a-z]+;base64,/, "")
                        };
                        images.push(thisImage);
                        let tempImages = images.slice();
                        updateImageState( tempImages );
                        }
                    }
                    );
                }
                else {
                let thename = e.target.files[x].name;
                    var reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function(e) {
                    var test = e;
                    var base64data = e.currentTarget.result;
                    let thisImage = {
                            imageName: thename,
                            imageType: "image/jpg",
                            localSrc: base64data,
                            base64: base64data.replace(/^data:image\/[a-z]+;base64,/, "")
                        };
                        images.push(thisImage);
                        let tempImages = images.slice();
                        updateImageState( tempImages );
                    }
                    }
                }
        }else{
            updateFormData({
            ...formData,
            [e.target.name]: e.target.value
            });
        }
    };
    const removeImage = (e,i) => {
        debugger;
        var clone = [...imageState];
        clone.splice(i,1);
        updateImageState(clone);
        ref.current.value = null;
    }

    const handlePrevious = () => {
        if(caseOrReport == 'report') { 
            setStep(4.1);
        } else {
            setStep(prevState => prevState -1);
        }
    }

    return (
    <>
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h6" component="h2">
                    Additional Information / Images
                </Typography>
            </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={6}> 
                            <form className={classes.form} noValidate onSubmit={e => { e.preventDefault(); }}> 
                                <TextField variant="outlined" margin="normal" required multiline fullWidth={true} value={formData.thread} 
                                            id="thread" label="Add additional information" name="thread" 
                                            onChange={handleChange} className={classes.halfWidth}
                                />
                                <Input name="attachments" id="contained-button-file" fullWidth inputProps={{ multiple: true }} type="file" style={{display:"none"}} onChange={handleChange} ref={ref}/>
                                <label htmlFor="contained-button-file">
                                    <Button variant="contained" color="primary" component="span" startIcon={<PhotoCameraIcon />}>
                                    Upload
                                    </Button>
                                </label>
                            </form>
                        </Grid>
                    </Grid>    
                </Grid>
            <Grid item xs={12}>
                {imageState &&
                    <Grid container spacing={2} className={classes.marginTop}>
                        {imageState.map((image, i) => {
                        return(
                            <React.Fragment key = {"ImageCard" + i}>
                            <Grid item xs={6} md={6}>
                                <Card elevation={3}>
                                <CardHeader
                                    action={
                                        <IconButton onClick={(event) => removeImage(event,i)} aria-label="remove" color="primary">
                                            <ClearIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    titleTypographyProps={{variant:'body1' }}
                                />
                                    <CardActionArea>
                                        <CardMedia
                                        className={classes.media}
                                        image={image.localSrc}
                                        title={"attachment" + i}
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                    {image.imageName}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            </React.Fragment>
                            )
                        })
                        }
                    </Grid>
                }
            </Grid>
            
            
            <Grid container className={classes.submitCluster}  spacing={1} direction="row" justifyContent="flex-end" alignItems="flex-start" >   
                        <Grid item>
                            <Button
                                disabled={false}
                                variant="outlined"
                                color="primary"
                                onClick={handlePrevious}
                                startIcon={<ChevronLeftIcon/>}
                            >
                                Previous
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={false}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    createReport(true);
                                }}
                                startIcon={<SaveIcon/>}
                                className={classes.submitButton}
                            >
                                {ButtonText}
                            </Button>
                        </Grid>
            </Grid>
        </Grid>    
    </>
    )
}

export default FeedAttachments