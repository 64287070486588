import React, { useMemo } from 'react'
import LocalShippingIcon from '@material-ui/icons/LocalShipping';



function MachineIcon({icon}) {
    console.log('machine icon');
  return (
    <>
        { !icon &&
            <LocalShippingIcon style={{fontSize: "48px", width: "60px", stroke: "#ffffff", strokeWidth: 1}}/>
        }
        { icon &&
            <img height={'48px'} src={icon}/>
        }
    </>
  )
}

export default React.memo(MachineIcon)