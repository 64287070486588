import Fuse from 'fuse.js'

const fuseOptions = {
	// isCaseSensitive: false,
	includeScore: true,
	// shouldSort: true,
	// includeMatches: false,
	// findAllMatches: false,
	// minMatchCharLength: 1,
	location: 0,
	threshold: 0.3,
	// distance: 100,
	// useExtendedSearch: false,
	// ignoreLocation: false,
	ignoreFieldNorm: false,
	fieldNormWeight: 2,
	keys: [
        "leafNodeName"
    ]
};

export default function coveoProductFacetSearch(machineList, keyword) {
    //Add facet to the fuseOption keys list
    const fuse = new Fuse(machineList.validFacets, fuseOptions);
    var results = fuse.search(keyword);
	if (results.length > 1) {
		//Check for tie
		if(results[0].score == results[1].score) {
			//Filter down to tied results
			var tiedIndexScore = result[0].score;
			results = results.filter((result) => {
				return (result.score <= tiedIndexScore);
			});
			//Break tie by shortest length
			var shortestResult;
			results.forEach((result) => {
				if(!shortestResult) {
					shortestResult = result
				}
				else if(result.item.leafNodeName.length < shortestResult.item.leafNodeName.length) {
					shortestResult = result;
				}
			});
			results = [shortestResult];
		}
	}
    return results[0];
}