import React, { useReducer } from 'react';
import { useEffect, useState } from 'react';
import SFContainer from './SFContainer';
import axios from 'axios';
import recordLayout from '../helpers/recordLayout'
import {useParams} from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cookies from 'js-cookie';
import useWindowDimensions from '../helpers/windowDimensions';
import depGraphHelper from '../helpers/depGraphHelper';
import { forInStatement } from '@babel/types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import SFRow from './SFRow';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';


import SalesForceConstants from '../SalesForceConstants';



const useStyles = makeStyles((theme) => ({
  loaderCenter: {
    marginLeft: '50%',
  },
  loadingCenterInside: {
    left: '-20px',
    position: 'relative'
  },
  listItem: {
    width: '90%',
    display: "inline-block"
  },
  bold: {
    fontWeight: '800'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  accordionDetails: {
    marginTop: "8px"
  },
  formContainer: {
    padding: '16px'
  },
  heading: {
    marginBottom: '16px'
  },
  outlineInput: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: "black"
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black"
    }
  }
}));

export function CreateForm ({step, setStep, editValues, setEditValues, objectInfo, setObjectInfo, DTC, subject, machineDetails, createReport, ErrorStates, 
                                setErrorStates, RequiredFields, setRequiredFields, formStates, caseOrReport, fieldOverrides, preFillAccountContact, 
                                hiddenSections, hiddenFields, defaultExpanded, reportFields, setReportFields}) {
  const [fetchResults, setFetchResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const [layoutType, setLayoutType] = useState("");
  const [PickLists, setPickLists] = useState();
  // const [editValues, setEditValues] = useState([]);
  const [dependantFields, setDependantFields] = useState([]);
  const [DEPStates, setDEPStates] = useState();
  const [internalStep, setInternalStep] = useState(1);
  const STATIC_REQUIRED_REPORT_FIELDS = ["Current_Engine_Hours__c"];
  const STATIC_REQUIRED_CASE_FIELDS = ["Current_Engine_Hours__c", ];

  let serialNumber = null;
  let modelNumber = null;
  let pin = null;

  let params = useParams();
  if(params.param1){
    assignParams(params.param1);
  }

  if(params.param2){
    assignParams(params.param2);
  }

  function assignParams(value){
    if(value.substring(0,2) == "sn"){
      serialNumber = value.substring(3);
    }

    if(value.substring(0,2) == "mn"){
      modelNumber = value.substring(3);
    }

    if(value.substring(0,3) == "pin"){
      pin = value.substring(4);
    }
  }

  useEffect(() => {
    // On Component Load
    // fetchLayout();
    if(step == 4.1) {
        setInternalStep(2)
    } else {
        prefillWrapper();
    }
    setLoading(false);    
  }, []);

  function prefillWrapper(editValuesFromSF){
    var preFillFields = [];
    var recordTypeId = (caseOrReport === "report") ? SalesForceConstants.ReportRecordTypeID : SalesForceConstants.CaseRecordTypeID;
    preFillFields.push(["RecordTypeId", recordTypeId]);
    if(preFillAccountContact){
      //Reports will have AccountContact prefill from previous screen
      preFillFields.push(["AccountId", preFillAccountContact.AccountId.id, preFillAccountContact.AccountId.name]);
      preFillFields.push(["ContactId", preFillAccountContact.ContactId.id, preFillAccountContact.ContactId.name]);
      preFillFieldsFromPreviousScreens(editValuesFromSF, preFillFields);
    } else {
      //Cases wont have AccountContact prefill from previous screen
      queryUserInfoCase().then((response) => {
        var preFillFields = [];
        preFillFields.push(["AccountId", response.data.records[0].AccountId, response.data.records[0].Account.Name]);
        preFillFields.push(["ContactId", response.data.records[0].ContactId, response.data.records[0].Contact.Name]);
        preFillFieldsFromPreviousScreens(editValuesFromSF, preFillFields);
      }).catch((error) => {
        preFillFieldsFromPreviousScreens(editValuesFromSF, []);
      })
    }
    
  }

  function preFillFieldsFromPreviousScreens(editValuesFromSF, preFillFields) {
    // Job of this function is to inject pre-filled fields into current set of values.
    // Then return it so editValues can be set.
    //const preFillFields = [];
    if(DTC) {
      preFillFields.push(["DTC_Error_Codes__c", DTC]);
    }
    if(subject) {
      preFillFields.push(["Subject", subject]);
      preFillFields.push(["Description", subject]);
    }
    if(machineDetails.serialNumber) {
      preFillFields.push(["Serial_Number__c", machineDetails.serialNumber]);
    }
    if(machineDetails.modelNumber) {
      preFillFields.push(["Model_Number__c", machineDetails.modelNumber]);
    }
    if(machineDetails.pin) {
        preFillFields.push(["PIN__c", machineDetails.pin]);
    }
    if(machineDetails.imei) {
        preFillFields.push(["IMEI_Number__c", machineDetails.imei]);
    }
    if(formStates.ktcFailureDate){
        let fixedDate = formStates.ktcFailureDate + "T00:00:00Z"
        preFillFields.push(["Failure_Date__c", fixedDate]);
    }
    if(formStates.ktcProbableCauseRemarks){
        preFillFields.push(["Probable_Cause_Remarks__c", formStates.ktcProbableCauseRemarks]);
    }
    if(formStates.ktcAssembly) {
      preFillFields.push(["Assembly__c", formStates.ktcAssembly]);
    }
    if(formStates.ktcSubAssembly) {
      preFillFields.push(["Sub_Assembly__c", formStates.ktcSubAssembly]);
    }
    if(formStates.ktcKeypartName) {
      //The Casual Part from the selection is the Part# + " " + PartName ex '05411-00510 PIN, SPRING'
      //The first space seperates the Part# from the PartName
      const partNumber = formStates.ktcKeypartName.split(" ")[0];
      const indexOfFirstSpace = formStates.ktcKeypartName.indexOf(" ");
      const partName = formStates.ktcKeypartName.substring(indexOfFirstSpace+1);

      preFillFields.push(["Causal_Part_Number__c", partNumber]);
      preFillFields.push(["Causal_Part__c", partName]);
    }
    preFillFields.forEach((field) => {
        setReportFields((prevState) => {
            return {
                ...prevState,
                [field[0]]: field[1]
            }
        })
    });
}

  function queryUserInfoCase(){
    return new Promise(function(resolve, reject) {
      let qUserCase = "SELECT AccountId, Account.Name, ContactId, Contact.Name, Contact.Email, Contact.Phone, Account_Record_Type__c FROM User WHERE Id = \'"+Cookies.get('user_id')+"\'";
      qUserCase = encodeURIComponent(qUserCase);
      let urlUserCase = process.env.REACT_APP_API + 'salesforce/kservice/get?param=query/?q=' + qUserCase;

      let reqUserCase = {
        method: "GET",
        url: urlUserCase,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + Cookies.get("token"),
          'X-SalesForce-Token' : Cookies.get('sf_cookie'),
          'X-Id-Token' : Cookies.get('idToken')
        }
      };

      axios(reqUserCase).then((resUserCase) => {
        resolve(resUserCase);
      }).catch((error) => {
        reject(error);
      })
    })
  }

  const onFieldValueUpdate = (field, value, e, isDEP = false, modalFields = null ) => {
    setReportFields({
        ...reportFields,
        [field]: value
    });
  }
  const checkForRequiredFields = () => {
    var wasThereAMissingField = false;
    return new Promise((resolve, reject) => {
        STATIC_REQUIRED_REPORT_FIELDS.forEach((fieldName) => {
            if(!reportFields[fieldName]){
                wasThereAMissingField = true;
                setErrorStates({
                    ...ErrorStates,
                    [fieldName]: true,
                })
            }
        });
        if(wasThereAMissingField){
            reject();
        } else {
            resolve();
        }
    });
  };
  const handleNext = () => {
    //If we reach internalStep 2, progress to next external step(attachments page)
    if(internalStep == 1) {
        checkForRequiredFields()
            .then(() => setInternalStep(2));
    } else {
        createReport(false);
    }
  }
  const handleBack = () => {
    if(internalStep == 1) {
        if(caseOrReport == 'case') {
            setStep(3.0);
        } else {
            setStep(3.5);
        }
    } else {
        setInternalStep(1);
    }
  }
  
     return ( 
        <div>
            {loading &&
                <div className={classes.loaderCenter}>
                    <CircularProgress className={classes.loadingCenterInside} />
                </div>
            }
            { !loading && caseOrReport === 'report' &&
                <React.Fragment>
                    {internalStep == 1 && reportFields &&
                        <Grid container className={classes.formContainer}>
                            <Grid key={"sectionGrid" + '1'} item xs={12}>
                                <Typography className={classes.heading}> 
                                    <span className={classes.bold}>
                                    Issue Information
                                    </span>
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item md={12} xs={12}>
                                        <TextField 
                                            required
                                            className={classes.outlineInput}
                                            defaultValue={reportFields['Current_Engine_Hours__c']}
                                            variant="outlined"
                                            type="number"
                                            id={"Current_Engine_Hours__c"}
                                            name={"Current_Engine_Hours__c"}
                                            label={"Machine Hours/Bales"}
                                            fullWidth
                                            onChange={(event) => onFieldValueUpdate("Current_Engine_Hours__c", event.target.value, event)}
                                            key={'componentInput1'}
                                            error={(ErrorStates['Current_Engine_Hours__c']) ? true : false}
                                            helperText = {(ErrorStates['Current_Engine_Hours__c']) ? 'Machine Hours is required' : null }
                                            style={{ border: 11 }}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField 
                                            className={classes.outlineInput}
                                            variant="outlined"
                                            defaultValue={reportFields['Implement_Attachment_SN__c']}
                                            id={"Implement_Attachment_SN__c"}
                                            name={"Implement_Attachment_SN__c"}
                                            label={"Implement Attachment SN"}
                                            fullWidth
                                            onChange={(event) => onFieldValueUpdate("Implement_Attachment_SN__c", event.target.value, event)}
                                            key={'componentInput1'}
                                            error={false}
                                            helperText = ''
                                            style={{ border: 11 }}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField 
                                            className={classes.outlineInput}
                                            variant="outlined"
                                            defaultValue={reportFields['Implement_Name__c']}
                                            id={"Implement_Name__c"}
                                            name={"Implement_Name__c"}
                                            label={"Implement Name"}
                                            fullWidth
                                            onChange={(event) => onFieldValueUpdate("Implement_Name__c", event.target.value, event)}
                                            key={'componentInput1'}
                                            error={false}
                                            helperText = ''
                                            style={{ border: 11 }}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField 
                                            className={classes.outlineInput}
                                            variant="outlined"
                                            disabled
                                            defaultValue={reportFields['IMEI_Number__c']}
                                            id={"IMEI_Number__c"}
                                            name={"IMEI_Number__c"}
                                            label={"IMEI Number"}
                                            fullWidth
                                            onChange={(event) => onFieldValueUpdate("IMEI_Number__c", event.target.value, event)}
                                            key={'componentInput1'}
                                            error={false}
                                            helperText = ''
                                            style={{ border: 11 }}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField 
                                            className={classes.outlineInput}
                                            variant="outlined"
                                            defaultValue={reportFields['Symptom_Description__c']}
                                            id={"Symptom_Description__c"}
                                            name={"Symptom_Description__c"}
                                            label={"Symptom Description"}
                                            fullWidth
                                            onChange={(event) => onFieldValueUpdate("Symptom_Description__c", event.target.value, event)}
                                            key={'componentInput1'}
                                            error={false}
                                            helperText = ''
                                            style={{ border: 11 }}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField 
                                            className={classes.outlineInput}
                                            variant="outlined"
                                            defaultValue={reportFields['Remedy_Description__c']}
                                            id={"Remedy_Description__c"}
                                            name={"Remedy_Description__c"}
                                            label={"Repair Actions Taken"}
                                            fullWidth
                                            onChange={(event) => onFieldValueUpdate("Remedy_Description__c", event.target.value, event)}
                                            key={'componentInput1'}
                                            error={false}
                                            helperText = ''
                                            style={{ border: 11 }}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField 
                                            className={classes.outlineInput}
                                            variant="outlined"
                                            defaultValue={reportFields['Description']}
                                            id={"Description"}
                                            name={"Description"}
                                            label={"Description"}
                                            fullWidth
                                            onChange={(event) => onFieldValueUpdate("Description", event.target.value, event)}
                                            key={'componentInput1'}
                                            error={false}
                                            helperText = ''
                                            style={{ border: 11 }}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField 
                                            className={classes.outlineInput}
                                            variant="outlined"
                                            defaultValue={reportFields['Troubleshooting__c']}
                                            id={"Troubleshooting__c"}
                                            name={"Troubleshooting__c"}
                                            label={"Troubleshooting Repair Actions"}
                                            fullWidth
                                            onChange={(event) => onFieldValueUpdate("Troubleshooting__c", event.target.value, event)}
                                            key={'componentInput1'}
                                            error={false}
                                            helperText = ''
                                            style={{ border: 11 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.buttonGroup}>
                                        <Grid container direction={"row-reverse"} spacing={1}>
                                            <Grid className={classes.buttons} item>
                                                <Button
                                                    disabled={false}
                                                    variant="outlined"
                                                    color="primary"
                                                    style={classes.button}
                                                    onClick={handleNext}
                                                    endIcon={<ChevronRightIcon/>}
                                                >
                                                Next
                                                </Button>
                                            </Grid>
                                            <Grid className={classes.buttons} item>
                                                <Button
                                                    disabled={false}
                                                    variant="outlined"
                                                    color="primary"
                                                    style={classes.button}
                                                    onClick={handleBack}
                                                    startIcon={<ChevronLeftIcon/>}
                                                >
                                                Back
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {internalStep == 2 &&
                        <Grid container className={classes.formContainer}>
                            <Grid key={"sectionGrid" + '2'} item xs={12}>
                                <Typography className={classes.heading}> 
                                    <span className={classes.bold}>
                                        Environment Information
                                    </span>
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item md={12} xs={12}>
                                        <TextField 
                                            className={classes.outlineInput}
                                            variant="outlined"
                                            defaultValue={reportFields['Operating_Conditions__c']}
                                            id={"Operating_Conditions__c"}
                                            name={"Operating_Conditions__c"}
                                            label={"Operating Conditions"}
                                            fullWidth
                                            onChange={(event) => onFieldValueUpdate("Operating_Conditions__c", event.target.value, event)}
                                            key={'componentInput1'}
                                            error={false}
                                            helperText = ''
                                            style={{ border: 11 }}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="TerrainLabel">{"Terrain"}</InputLabel>
                                            <Select
                                                label='Terrain'
                                                labelId={"TerrainLabel"}
                                                className={classes.outlineInput}
                                                defaultValue=""
                                                id={"Terrain__c"}
                                                value={reportFields['Terrain__c']}
                                                onChange={(event) => onFieldValueUpdate("Terrain__c", event.target.value, event)}
                                            >
                                                <MenuItem value={'Flat'}>Flat</MenuItem>
                                                <MenuItem value={'Rolling Hills'}>Rolling Hills</MenuItem>
                                                <MenuItem value={'Steep Slope'}>Steep Slope</MenuItem>
                                                <MenuItem value={'Other (see Terrain Remarks)'}>Other (see Terrain Remarks)</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    { reportFields.hasOwnProperty('Terrain__c') &&
                                        <Grid item md={12} xs={12}>
                                            <TextField 
                                                className={classes.outlineInput}
                                                variant="outlined"
                                                defaultValue={reportFields['Terrain_Remarks__c']}
                                                id={"Terrain_Remarks__c"}
                                                name={"Terrain_Remarks__c"}
                                                label={"Terrain Remarks"}
                                                fullWidth
                                                onChange={(event) => onFieldValueUpdate("Terrain_Remarks__c", event.target.value, event)}
                                                key={'componentInput1'}
                                                error={false}
                                                helperText = ''
                                                style={{ border: 11 }}
                                            />
                                        </Grid>
                                    }
                                    <Grid item md={12} xs={12}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="GroundCoverLabel">{"Ground Cover"}</InputLabel>
                                            <Select
                                                label='Ground Cover'
                                                labelId={"GroundCoverLabel"}
                                                className={classes.outlineInput}
                                                defaultValue=""
                                                id={"Ground_Cover__c"}
                                                value={reportFields['Ground_Cover__c']}
                                                onChange={(event) => onFieldValueUpdate("Ground_Cover__c", event.target.value, event)}
                                            >
                                                <MenuItem value={'Asphalt'}>Asphalt</MenuItem>
                                                <MenuItem value={'Brush / Tall Weeds'}>Brush / Tall Weeds</MenuItem>
                                                <MenuItem value={'Concrete'}>Concrete</MenuItem>
                                                <MenuItem value={'Dry Soil'}>Dry Soil</MenuItem>
                                                <MenuItem value={'Grass / Short Weeds'}>Grass / Short Weeds</MenuItem>
                                                <MenuItem value={'Mud'}>Mud</MenuItem>
                                                <MenuItem value={'Rock / Gravel'}>Rock / Gravel</MenuItem>
                                                <MenuItem value={'Row Crop'}>Row Crop</MenuItem>
                                                <MenuItem value={'Sand'}>Sand</MenuItem>
                                                <MenuItem value={'Other (see Ground Remarks)'}>Other (see Ground Remarks)</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    { reportFields.hasOwnProperty('Ground_Cover__c') &&
                                        <Grid item md={12} xs={12}>
                                            <TextField 
                                                className={classes.outlineInput}
                                                variant="outlined"
                                                defaultValue={reportFields['Ground_Cover_Remarks__c']}
                                                id={"Ground_Cover_Remarks__c"}
                                                name={"Ground_Cover_Remarks__c"}
                                                label={"Ground Cover Remarks"}
                                                fullWidth
                                                onChange={(event) => onFieldValueUpdate("Ground_Cover_Remarks__c", event.target.value, event)}
                                                key={'Ground_Cover_Remarks__c'}
                                                error={false}
                                                helperText = ''
                                                style={{ border: 11 }}
                                            />
                                        </Grid>
                                    }
                                    <Grid item md={12} xs={12}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="AmbientLabel">{"Ambient Conditions"}</InputLabel>
                                                <Select
                                                    label='Ambient Conditions'
                                                    labelId={"AmbientLabel"}
                                                    defaultValue=""
                                                    className={classes.outlineInput}
                                                    id={"Ambient_Conditions__c"}
                                                    value={reportFields['Ambient_Conditions__c']}
                                                    onChange={(event) => onFieldValueUpdate("Ambient_Conditions__c", event.target.value, event)}
                                                >   
                                                    <MenuItem value={'Clear / Sunny'}>Clear / Sunny</MenuItem>
                                                    <MenuItem value={'Dry'}>Dry</MenuItem>
                                                    <MenuItem value={'Dust'}>Dust</MenuItem>
                                                    <MenuItem value={'Ice'}>Ice</MenuItem>
                                                    <MenuItem value={'Rain'}>Rain</MenuItem>
                                                    <MenuItem value={'Snow'}>Snow</MenuItem>
                                                    <MenuItem value={'Other (see Ground Remarks)'}>Other (see Ground Remarks)</MenuItem>
                                                </Select>
                                        </FormControl>
                                    </Grid>
                                    { reportFields.hasOwnProperty('Ambient_Conditions__c') &&
                                        <Grid item md={12} xs={12}>
                                            <TextField 
                                                className={classes.outlineInput}
                                                variant="outlined"
                                                defaultValue={reportFields['Ambient_Remarks__c']}
                                                id={"Ambient_Remarks__c"}
                                                name={"Ambient_Remarks__c"}
                                                label={"Ambient Remarks"}
                                                fullWidth
                                                onChange={(event) => onFieldValueUpdate("Ambient_Remarks__c", event.target.value, event)}
                                                key={'componentInput1'}
                                                error={false}
                                                helperText = ''
                                                style={{ border: 11 }}
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12} className={classes.buttonGroup}>
                                        <Grid container direction={"row-reverse"} spacing={1}>
                                            <Grid className={classes.buttons} item>
                                                <Button
                                                    disabled={false}
                                                    variant="outlined"
                                                    color="primary"
                                                    style={classes.button}
                                                    onClick={handleNext}
                                                    endIcon={<ChevronRightIcon/>}
                                                >
                                                Next
                                                </Button>
                                            </Grid>
                                            <Grid className={classes.buttons} item>
                                                <Button
                                                    disabled={false}
                                                    variant="outlined"
                                                    color="primary"
                                                    style={classes.button}
                                                    onClick={handleBack}
                                                    startIcon={<ChevronLeftIcon/>}
                                                >
                                                Back
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid> 
                            </Grid>
                        </Grid>
                    }
                </React.Fragment>
            }
            { !loading && caseOrReport === 'case' && reportFields &&
                <React.Fragment>
                    {internalStep == 1 &&
                        <Grid container className={classes.formContainer}>
                            <Grid key={"sectionGrid" + '1'} item xs={12}>
                                <Typography className={classes.heading}> 
                                    <span className={classes.bold}>
                                        Machine Information
                                    </span>
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item md={12} xs={12}>
                                        <TextField 
                                            required
                                            className={classes.outlineInput}
                                            defaultValue={reportFields['Current_Engine_Hours__c']}
                                            variant="outlined"
                                            type="number"
                                            id={"Current_Engine_Hours__c"}
                                            name={"Current_Engine_Hours__c"}
                                            label={"Machine Hours/Bales"}
                                            fullWidth
                                            onChange={(event) => onFieldValueUpdate("Current_Engine_Hours__c", event.target.value, event)}
                                            key={'componentInput1'}
                                            error={(ErrorStates['Current_Engine_Hours__c']) ? true : false}
                                            helperText = {(ErrorStates['Current_Engine_Hours__c']) ? 'Machine Hours is required' : null }
                                            style={{ border: 11 }}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField 
                                            className={classes.outlineInput}
                                            defaultValue={reportFields['Engine_Usage_Reading__c']}
                                            variant="outlined"
                                            type="number"
                                            id={"Engine_Usage_Reading__c"}
                                            name={"Engine_Usage_Reading__c"}
                                            label={"Engine Usage Reading"}
                                            fullWidth
                                            onChange={(event) => onFieldValueUpdate("Engine_Usage_Reading__c", event.target.value, event)}
                                            key={'componentInput1'}
                                            style={{ border: 11 }}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField 
                                            className={classes.outlineInput}
                                            defaultValue={reportFields['Dealer_Work_Order_Number__c']}
                                            variant="outlined"
                                            type="text"
                                            id={"Dealer_Work_Order_Number__c"}
                                            name={"Dealer_Work_Order_Number__c"}
                                            label={"Dealer Work Order Number"}
                                            fullWidth
                                            onChange={(event) => onFieldValueUpdate("Dealer_Work_Order_Number__c", event.target.value, event)}
                                            key={'componentInput1'}
                                            style={{ border: 11 }}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField 
                                            className={classes.outlineInput}
                                            defaultValue={reportFields['Implement_Name__c']}
                                            variant="outlined"
                                            type="text"
                                            id={"Implement_Name__c"}
                                            name={"Implement_Name__c"}
                                            label={"Implement Name"}
                                            fullWidth
                                            onChange={(event) => onFieldValueUpdate("Implement_Name__c", event.target.value, event)}
                                            key={'componentInput1'}
                                            style={{ border: 11 }}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField 
                                            className={classes.outlineInput}
                                            variant="outlined"
                                            defaultValue={reportFields['Implement_Attachment_SN__c']}
                                            id={"Implement_Attachment_SN__c"}
                                            name={"Implement_Attachment_SN__c"}
                                            label={"Implement Attachment SN"}
                                            fullWidth
                                            onChange={(event) => onFieldValueUpdate("Implement_Attachment_SN__c", event.target.value, event)}
                                            key={'componentInput1'}
                                            error={false}
                                            helperText = ''
                                            style={{ border: 11 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.buttonGroup}>
                                        <Grid container direction={"row-reverse"} spacing={1}>
                                            <Grid className={classes.buttons} item>
                                                <Button
                                                    disabled={false}
                                                    variant="outlined"
                                                    color="primary"
                                                    style={classes.button}
                                                    onClick={handleNext}
                                                    endIcon={<ChevronRightIcon/>}
                                                >
                                                Next
                                                </Button>
                                            </Grid>
                                            <Grid className={classes.buttons} item>
                                                <Button
                                                    disabled={false}
                                                    variant="outlined"
                                                    color="primary"
                                                    style={classes.button}
                                                    onClick={handleBack}
                                                    startIcon={<ChevronLeftIcon/>}
                                                >
                                                Back
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                   
                            </Grid>
                        </Grid>
                    }
                    {internalStep == 2 &&
                        <Grid container>
                            <Grid key={"sectionGrid" + '1'} item xs={12} className={classes.formContainer}>
                                <Typography className={classes.heading}> 
                                    <span className={classes.bold}>
                                    Issue Information
                                    </span>
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item md={12} xs={12}>
                                        <TextField 
                                            className={classes.outlineInput}
                                            variant="outlined"
                                            defaultValue={reportFields['Operating_Conditions__c']}
                                            id={"Operating_Conditions__c"}
                                            name={"Operating_Conditions__c"}
                                            label={"Operating Conditions"}
                                            fullWidth
                                            onChange={(event) => onFieldValueUpdate("Operating_Conditions__c", event.target.value, event)}
                                            key={'componentInput1'}
                                            error={false}
                                            helperText = ''
                                            style={{ border: 11 }}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="AmbientLabel">{"Ambient Conditions"}</InputLabel>
                                                <Select
                                                    label='Ambient Conditions'
                                                    labelId={"AmbientLabel"}
                                                    defaultValue=""
                                                    className={classes.outlineInput}
                                                    id={"Ambient_Conditions__c"}
                                                    value={reportFields['Ambient_Conditions__c']}
                                                    onChange={(event) => onFieldValueUpdate("Ambient_Conditions__c", event.target.value, event)}
                                                >   
                                                    <MenuItem value={'Clear / Sunny'}>Clear / Sunny</MenuItem>
                                                    <MenuItem value={'Dry'}>Dry</MenuItem>
                                                    <MenuItem value={'Dust'}>Dust</MenuItem>
                                                    <MenuItem value={'Ice'}>Ice</MenuItem>
                                                    <MenuItem value={'Rain'}>Rain</MenuItem>
                                                    <MenuItem value={'Snow'}>Snow</MenuItem>
                                                    <MenuItem value={'Other (see Ground Remarks)'}>Other (see Ground Remarks)</MenuItem>
                                                </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="TerrainLabel">{"Terrain"}</InputLabel>
                                            <Select
                                                label='Terrain'
                                                labelId={"TerrainLabel"}
                                                className={classes.outlineInput}
                                                defaultValue=""
                                                id={"Terrain__c"}
                                                value={reportFields['Terrain__c']}
                                                onChange={(event) => onFieldValueUpdate("Terrain__c", event.target.value, event)}
                                            >
                                                <MenuItem value={'Flat'}>Flat</MenuItem>
                                                <MenuItem value={'Rolling Hills'}>Rolling Hills</MenuItem>
                                                <MenuItem value={'Steep Slope'}>Steep Slope</MenuItem>
                                                <MenuItem value={'Other (see Terrain Remarks)'}>Other (see Terrain Remarks)</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField 
                                            className={classes.outlineInput}
                                            required
                                            variant="outlined"
                                            defaultValue={reportFields['Symptom_Description__c']}
                                            id={"Symptom_Description__c"}
                                            name={"Symptom_Description__c"}
                                            label={"Symptom Description"}
                                            fullWidth
                                            onChange={(event) => onFieldValueUpdate("Symptom_Description__c", event.target.value, event)}
                                            key={'componentInput1'}
                                            error={false}
                                            helperText = ''
                                            style={{ border: 11 }}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField 
                                            className={classes.outlineInput}
                                            variant="outlined"
                                            defaultValue={reportFields['Defect_Description__c']}
                                            id={"Defect_Description__c"}
                                            name={"Defect_Description__c"}
                                            label={"Defect Description"}
                                            fullWidth
                                            onChange={(event) => onFieldValueUpdate("Defect_Description__c", event.target.value, event)}
                                            key={'componentInput1'}
                                            style={{ border: 11 }}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField 
                                            className={classes.outlineInput}
                                            variant="outlined"
                                            defaultValue={reportFields['Remedy_Description__c']}
                                            id={"Remedy_Description__c"}
                                            name={"Remedy_Description__c"}
                                            label={"Repair Actions Taken"}
                                            fullWidth
                                            onChange={(event) => onFieldValueUpdate("Remedy_Description__c", event.target.value, event)}
                                            key={'componentInput1'}
                                            style={{ border: 11 }}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField 
                                            className={classes.outlineInput}
                                            required
                                            variant="outlined"
                                            defaultValue={reportFields['Description']}
                                            id={"Description"}
                                            name={"Description"}
                                            label={"Description"}
                                            fullWidth
                                            onChange={(event) => onFieldValueUpdate("Description", event.target.value, event)}
                                            key={'componentInput1'}
                                            style={{ border: 11 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.buttonGroup}>
                                        <Grid container direction={"row-reverse"} spacing={1}>
                                            <Grid className={classes.buttons} item>
                                                <Button
                                                    disabled={false}
                                                    variant="outlined"
                                                    color="primary"
                                                    style={classes.button}
                                                    onClick={handleNext}
                                                    endIcon={<ChevronRightIcon/>}
                                                >
                                                Next
                                                </Button>
                                            </Grid>
                                            <Grid className={classes.buttons} item>
                                                <Button
                                                    disabled={false}
                                                    variant="outlined"
                                                    color="primary"
                                                    style={classes.button}
                                                    onClick={handleBack}
                                                    startIcon={<ChevronLeftIcon/>}
                                                >
                                                Back
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </React.Fragment>
            }
        </div> 
  );
}