import React, {useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import Cookies from 'js-cookie';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';








const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(4, 0, 6),
    width: '40%'
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4),
    justifycontent: 'center',
    align: 'center',
    justify: 'center',
  },
  loadingCenterInside: {
    
    padding: theme.spacing(2,2),
    margin: 'auto',
    left: '-20px'
  },
  halfWidth: {
      width: "100%"
  }
}));

function CaptureDTC({setStep, DTC, setDTC, subject, setSubject, machineDetails,callNextStageAnalytics, callFieldUpdateAnalytics, caseOrReport}) {
    const classes = useStyles();
    const [codes, setCodes] = useState();
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        if(Array.isArray(codes)) {
            setLoading(false);
        }
    }, [codes]);

    useEffect(() => {
        setLoading(true);
        //On load
        var req = {
            url : process.env.REACT_APP_API + 'salesforce/kservice/apexrest?param=GetDTCValues?modelNumber='+machineDetails.modelNumber,
            //url : "https://kubotaservice--ksdev.sandbox.my.salesforce.com/services/apexrest/GetDTC?modelNumber=" + machineDetails.modelNumber,
            method: "GET",
            headers : {
                "Authorization": "Bearer " + Cookies.get('token'),
                // "X-SalesForce-Token" : Cookies.get('sf_cookie'),
                'X-Id-Token' : Cookies.get('idToken')
            }
        }
        axios(req)
            .then((response) => {
                setCodes(response.data.codes.reverse());
            })
            .catch((e) => {
                console.log(e);
            });
    },[])

    const handleSubmit = () => {
        // Check DTC and Subject aren't empty
        if(subject) {
            callNextStageAnalytics();
            callFieldUpdateAnalytics();
            if(caseOrReport == "case") {
                setStep(prevState => prevState +1);
            } else {
                setStep(prevState => prevState +2);
            }
        } else {
            // Handle
            alert("Subject cannot be empty");
        }
    }
    function extractCode(codeObject) {
        // Extracts the pcode, ecode, or SPN # and returns it
        if (codeObject.pCode) {
            return codeObject.pCode;
        } 
        else if (codeObject.errorCode) {
            return codeObject.errorCode;
        } 
        else if (codeObject.spn) {
            return codeObject.spn;
        } 
        else {
            return null;
        }
    }

    return (
        <>
        { loading &&
            <Grid container>
                <Grid item md={12} xs={12} align="center">
                    <CircularProgress className={classes.loadingCenterInside} />
                </Grid>
            </Grid>
        }
        { !loading &&
        <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
                <Typography variant="h6" gutterBottom>
                    Describe Your Problem
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <Grid container>
                    <Grid item xs={6}>
                        <FormControl className={classes.halfWidth}>
                            <InputLabel id="demo-multiple-name-label">Code</InputLabel>
                            <Select
                                disabled={(codes.length == 0) ? true : false}
                                variant={(codes.length == 0) ? "filled" : "outlined"}
                                labelId={"picklist-dtc"}
                                id={"picklist-dtc"}
                                value={DTC}
                                onChange={(e) => {
                                    setDTC(e.target.value);
                                }}
                                >
                                { codes.map((code, i) => {
                                    return(
                                    <MenuItem value = {code} key={'MenuItem' + i}>
                                        {code}
                                    </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>    
            </Grid>
            
            <Grid item md={12} xs={12}>
                <Grid container>
                    <Grid item xs={6}>
                        <FormControl className={classes.halfWidth}>
                            <TextField 
                                id="Subject" 
                                label="Subject" 
                                variant="outlined"
                                value={subject} 
                                onChange={(e) => {
                                    setSubject(e.currentTarget.value);
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
                <Grid container direction={"row-reverse"}>
                    <Grid item>
                        <Button
                            disabled={false}
                            variant="outlined"
                            color="primary"
                            onClick={handleSubmit}
                            endIcon={<ChevronRightIcon/>}
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid> 
        }
    </>
    )
}

export default CaptureDTC