import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useHistory } from "react-router-dom";
import { msalInstance } from '../msalConfig';

export const B2CLoginPage = () => {
    const history = useHistory();

    useEffect(() => {
        // On component load
        login();
    });
    
    const login = () =>{
        const loginRequest = {
            scopes: ["openid"]
        };

        msalInstance.handleRedirectPromise().then(response => {
            if (response !== null){ // B2C Login Successful
                let accountId = response.account.homeAccountId;
                localStorage.setItem('accountId', accountId); //Store SSO Account Id in Local Storage
                Cookies.set('idToken', response.idToken); //Store idToken in Cookies

                // Get tokens from middleware and redirect to /home
                var axios = require('axios');
                var data = JSON.stringify({
                "IdToken": response.idToken
                });

                var config = {
                method: 'post',
                url: process.env.REACT_APP_API + 'salesforce/kservice/AuthSSO',
                headers: { 
                    'Content-Type': 'application/json'
                },
                data : data
                };

                axios(config)
                .then(function (response2) {
                    Cookies.set('token', response2.data.token); // Store Middleware token in Cookies
                    Cookies.set('sf_cookie', response2.data.access_token); // Store SFDC Bearer token in Cookies
                    history.push("/home");
                })
                .catch(function (error) {
                    console.log(error);
                });
            } else {
                const currentAccounts = msalInstance.getAllAccounts();
                if (currentAccounts.length == 0){
                    msalInstance.loginRedirect(loginRequest);
                } else if (currentAccounts.length > 1) {
                    // Multiple users?
                    console.log("Multiple users");
                } else if (currentAccounts.length === 1) {
                    accountId = currentAccounts[0].homeAccountId;
                }
            }
        });
    }
    
    return null;
}

function refreshSFDC(encodedJWT){
    // POST request to get token
    var axios = require('axios');
    var qs = require('qs');
    var data = qs.stringify({
    'grant_type': 'urn:ietf:params:oauth:grant-type:jwt-bearer',
    'assertion': encodedJWT 
    });
    var config = {
    method: 'post',
    url: 'https://test.salesforce.com/services/oauth2/token',
    headers: { 
        'Content-Type': 'application/x-www-form-urlencoded', 
        'Cookie': 'BrowserId=Z6mLmUACEe2uBKUuE1-gMg; CookieConsentPolicy=0:0; LSKey-c$CookieConsentPolicy=0:0'
    },
    data : data
    };

    axios(config).then(function (response) {
        Cookies.set("sf_cookie", response.data.access_token);
        debugger;
    })
    .catch(function (error) {
        console.log(error);
        debugger;
    });
}

export function b2cLogout() {
    var account = msalInstance.getAccountByHomeId(localStorage.getItem('accountId'));
    debugger;

    const logoutRequest = {
        scopes: ["openid"],
        account: account
    };

    msalInstance.logoutRedirect(logoutRequest); // Call logout request and redirect

    // Clear cookies
    document.cookie.split(";").forEach((c) => {
        document.cookie = c
            .replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    // Dump Local Storage
    localStorage.clear();
    // Remove all saved data from sessionStorage
    sessionStorage.clear();
}

export async function getNewToken() {

    const redirectResponse = await msalInstance.handleRedirectPromise();

    if(redirectResponse !== null) { // Refresh SFDC Token
        let idToken = redirectResponse.idToken;
        refreshSFDC(idToken);
    }

    else { // Try and acquire token silently if not, prompt login flow
        const account = msalInstance.getAllAccounts()[0];

        const accessTokenRequest = {
            scopes: ["openid"],
            account: account
        };

        msalInstance.acquireTokenSilent(accessTokenRequest).then(function (accessTokenResponse) {
            debugger;
            let idToken = accessTokenResponse.idToken;
            refreshSFDC(idToken);
        })
        .catch(function (error) {
            debugger;
            console.log(error);
            // Try to refresh and stay on same page or push to login page
            const redirectLoginResponse = msalInstance.acquireTokenRedirect(accessTokenRequest);
            if(redirectLoginResponse !== null){
                debugger;
                let idToken = redirectResponse.idToken;
                refreshSFDC(idToken);
            }
        })
    }
}
