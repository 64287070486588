import React, { useEffect, useState } from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { TextField } from '@material-ui/core'

function SFTime({editValues, field}) {
  const [Time, SetTime] = useState();
  useEffect(() => {
    if(Time)
    {
        var newTime = Time.format('hh:mm:ss.[000Z]');
        editValues[field].current = newTime;
    }
  }, [Time]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div>
        <DesktopTimePicker
            value={editValues[field]}
            onChange={(newValue) => {
              SetTime(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
    </LocalizationProvider>
  )
}

export default SFTime