import React from 'react';
import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles, StylesProvider } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import Cookies from 'js-cookie';
import { getAnalytics, logEvent } from "firebase/analytics";


console.log("Number 2");



const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(4, 4, 6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', 
  },
  error: {
    margin: theme.spacing(0, 0, 2),

  },
  // avatar: {
  //   margin: theme.spacing(1),
  //   backgroundColor: theme.palette.secondary.main,
  // },
  // form: {
  //   width: '100%', // Fix IE 11 issue.
  //   marginTop: theme.spacing(1),
  // },
  submit: {
    margin: theme.spacing(2, 0, 1),
  },
  root: {
    background: "#efefef",
    minWidth: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column"
  },
  card: {
    maxWidth: "45%",
    minWidth: 350,
    minHeight: "20vh",
    display: "flex",
    alignItems: "center",
    padding: "25"
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4),
    //width: '90%'
  },
  heroContent: {
    width: "90%",
    padding: theme.spacing(8, 0, 6),
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const LoginPage = () => {
  const classes = useStyles();
  const [formData, updateFormData] = useState('');
  const [errorMessage, onChangeErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  

  // useEffect(() => {
  //   // On Component Load
  //   console.log("Trying to log screenview at login page render");
  //   const analytics = getAnalytics();
  //   logEvent(analytics, 'screen_view', {
  //     firebase_screen: "LoginPage", 
  //     firebase_screen_class: "LoginPage"
  //   });
  //   }, []);
    
  const processExpireDate = (date) => {
    var newNum = date.toString().match(/.{1,2}/g);
    // console.log(newNum);
    var date = new Date(newNum[0] + newNum[1], newNum[2] - 1, newNum[3], newNum[4], newNum[5], newNum[6]);
    // console.log(date);
    var now = new Date();
    var diff = date.getTime() - now.getTime();
    // console.log('Diff: ' + diff);
    var expireDaysUntil = diff / (1000 * 60 * 60 * 24);
    // Format "Full Month DD, YYYY"
    var month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    // var expireDate = month[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();

    // console.log(expireDaysUntil);
    // console.log(expireDate);
    if(expireDaysUntil < 1){
      return 0;
    }

    return (expireDaysUntil);
  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim()
    });
  };

  const deleteAllCookies = () => {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        console.log(cookie)
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    console.log('Delete Cookies')
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    // Remove any cookies
    deleteAllCookies();

    let user = {
      username: formData.username,
      password: formData.password
    }
    login(user);
  };

  const login = (user) => {
    setLoading(true);
    if (!user.username || !user.password) {
      onChangeErrorMessage('Username and password are required.');
      setLoading(false);
      return;
    }
    var url = process.env.REACT_APP_API + 'auth';
    let payload = JSON.stringify({
      'Username': user.username,
      'Password': user.password
      });
    var options = {
      headers: {
        'Content-Type': 'application/json'
      }
    };


    console.log(url);
    axios.post(url, payload, options)
      .then((response) => {
        //console.log('SSO RESPONSE');
        //console.log(JSON.stringify(response));
        Cookies.set("c_id", response.data.c_id);
        Cookies.set("org_id", response.data.org_id);
        Cookies.set("token", response.data.token);


        if (response.data.errorMessage || response.data.error) {
          throw new Error('Incorrect username or password.\n\nIf you tried to login multiple times, your\naccount may have been locked.');
        }
        if(response.data.passwordExpireTime){
          const expireDaysUntil = processExpireDate(response.data.passwordExpireTime);
          // console.log(expireDaysUntil);
          if (response.data.ptaCode && expireDaysUntil > 0) {

            localStorage.setItem("PEDT", response.data.passwordExpireTime);
            let url = process.env.REACT_APP_OSVC_DOMAIN + '/ci/pta/login/p_li/' + response.data.ptaCode + '/redirect/kservice'; //staging (tst1)
            // window.location.href = url;
            setLoading(false);
            testLoginHandler(response.data.ptaCode);
          } else {
            onChangeErrorMessage('Incorrect username or password.\n\nIf you tried to login multiple times, your\naccount may have been locked.\n\nYour password may also be expired.');
            setLoading(false);
            return;
          }
        }

      }).catch(err => {
        console.log('ERROR LOGIN SERVICE');
        setLoading(false);
        console.log(err);
        onChangeErrorMessage(err.message);
        //testLoginHandler(false);
      });
  };

  function testLoginHandler(pta){
      console.log(pta)
      let response = {}
      if(!pta){
        response.ptaCode = "1WoH7L8v~vA9tQZnw7nsjRO4HsSQpAqrRUo3FGfiEtwMl_fmlOw0mYX7iVs37FmEIqKWnnJTFs~4tNYrB43sEsxc0JokAd0wk1NubXq4bxFtPacipDnGhUrwzk2iyiYMvkDq_S5NJthykdDR8191xcWCeMS4rjQR_x3q2g7H9ZK2MGSsuM9vaWRMNLeE4EtMUAzbQT2AW4DnFz8JPMW5Pw**"
      }else{
        response.ptaCode = pta;
      }
      let url = process.env.REACT_APP_OSVC_DOMAIN + '/ci/pta/login/p_li/' + response.ptaCode + '/redirect/kservice'; //staging (tst1)
      window.location.href = url;
   }

   function ErrorMessageDisplay(){
     if(errorMessage == 'Incorrect username or password.\n\nIf you tried to login multiple times, your\naccount may have been locked.\n\nYour password may also be expired.'){
       return(
         <Alert
          severity="error"
          className={classes.error}
          action={
            <Button color="inherit" size="small" onClick={(event) => window.location.href = "https://kubota.custhelp.com/app/answers/detail_public/a_id/1001627"}>
              RESET MY PASSWORD
            </Button>
          }
          >
            {errorMessage}
          </Alert>);
     }

     return(<Alert severity="error" className={classes.error}>{errorMessage}</Alert>);
   }

  return (
    <React.Fragment>
    <CssBaseline />
      <Container maxWidth="lg" className={classes.heroContent}>
      <Grid
            container
            //className={classes.root}
            spacing={0}
            alignItems="center"
            justifyContent="center"
            item
            xs={12}
          >
          {errorMessage &&
            <ErrorMessageDisplay/>
          }
          </Grid>

        <Grid
            container
            //className={classes.root}
            spacing={0}
            alignItems="center"
            justifyContent="center"
            item
            xs={12}
          >

          <Paper elevation={3} className={classes.card}>
          <div className={classes.paper}>

            <Typography component="h1" variant="h2">
              K-Service
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Login using your Kubota Link credentials.
            </Typography>
            <form className={classes.form} noValidate onSubmit={e => { e.preventDefault(); }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                onChange={handleChange}
                disabled={loading}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
                disabled={loading}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                className={classes.submit}
                onClick={handleSubmit}
                disabled={loading}
              >
              {!loading &&
                <React.Fragment>
                  Login
                </React.Fragment>
              }
              {loading &&
                <React.Fragment>
                  <CircularProgress size={28} />
                </React.Fragment>
              }
              </Button>
            </form>
          </div>
        </Paper>
      </Grid>
    </Container>
  </React.Fragment>
  );
}
