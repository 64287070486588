import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useParams} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
}));



export const PdiPage = () => {
  const classes = useStyles();
  let serialNumber = null;
  let modelNumber = null;
  let initLoad = true;
  const history = useHistory();

  let params = useParams();

  if(params.param1){
    assignParams(params.param1);
  }

  if(params.param2){
    assignParams(params.param2);
  }

  function assignParams(value){
    if(value.substring(0,2) == "sn"){
      serialNumber = value.substring(3);
    }

    if(value.substring(0,2) == "mn"){
      modelNumber = value.substring(3);
    }
  }

  function frameLoaded(){
    if(initLoad){
      initLoad = false;
    }else{
      history.goBack()
    }
  }


  return (
    <React.Fragment>
    <CssBaseline />
      <Container maxWidth="lg" className={classes.heroContent}>
        <Grid container spacing={2}>
          <Grid item md={2} xs={false}></Grid>
          <Grid item md={8} xs={12}>
            <Paper elevation={3} className={classes.heroPaper}>
              <iframe onLoad={frameLoaded} id="myFrame" height="100vh" style={{height: "calc(100vh - 121px)"}} width="100%" src={process.env.REACT_APP_OSVC_DOMAIN + "/app/opa/pdiIframe/Model/"+ modelNumber +"/Serial/" + serialNumber}></iframe>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
