import React, { useReducer } from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { Checkbox, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import {useParams} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Alert from '@material-ui/lab/Alert';
import Chip from '@material-ui/core/Chip';
import { spacing } from '@material-ui/system';
import CircularProgress from '@material-ui/core/CircularProgress';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import axios from 'axios';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CasesRecentSearch from '../components/CasesRecentSearch';
import HistoryIcon from '@material-ui/icons/History';
import ApiManager from '../apiservice';
import Cookies from 'js-cookie';
import { Label } from '@material-ui/icons';
import modelSerialSearch from '../helpers/fuse/modelSerialSearch';


const useStyles = makeStyles((theme) => ({
  heroContent: {
    width: "90%",
    padding: theme.spacing(8, 0, 6)
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  divider: {
    margin: theme.spacing(0,0,2)
  },
  form: {
    margin: theme.spacing(0, 0, 5)
  },
  loaderCenter: {
    marginLeft: '50%',
  },
  loadingCenterInside: {
    left: '-20px',
    position: 'relative'
  },
  listItem: {
    width: '90%',
    marginLeft: '8px',
    display: "inline-block"
  },
  parentContainer: {
    '&.MuiGrid-container:hover':{
      backgroundColor:'#f5f5f5'
    },

  }
}));

export const PredictionListPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [loading, setLoading] = useReducer((loading) => !loading, false);
  const [searchResults, setSearchResults] = useState(false);
  // const [filteredResults, setFilteredResults] = useState(false);
  // const [statusFilter, setStatusFilter] = useState(["Updated", "Created", "Awaiting Feedback"]);
  // const [modelSerialSearchTerm, setmodelSerialSearchTerm] = useState();
  // const [searchHistory, setSearchHistory] = useState(JSON.parse(sessionStorage.getItem('RecentCases')));
  const samplePredictionData = {
    "count" : 13,
    "currentPageToken" : "0",
    "currentPageUrl" : "/services/data/v58.0/ui-api/list-records/Parts_Prediction__c/My_Parts_Prediction_for_Dealer?pageSize=50&pageToken=0",
    "fields" : [ ],
    "listInfoETag" : "8916f142ff88d3ad37d5c31219d19d14",
    "listReference" : {
      "id" : "00BEi0000017UaJMAU",
      "listViewApiName" : "My_Parts_Prediction_for_Dealer",
      "objectApiName" : "Parts_Prediction__c",
      "type" : "listView"
    },
    "nextPageToken" : null,
    "nextPageUrl" : null,
    "optionalFields" : [ ],
    "pageSize" : 50,
    "previousPageToken" : null,
    "previousPageUrl" : null,
    "records" : [ {
      "apiName" : "Parts_Prediction__c",
      "childRelationships" : { },
      "eTag" : "f4d48801f250eff2c15ab8fc2dbbf1b9",
      "fields" : {
        "Case__c" : {
          "displayValue" : null,
          "value" : null
        },
        "Case__r" : {
          "displayValue" : null,
          "value" : null
        },
        "CreatedDate" : {
          "displayValue" : "1/25/2024, 6:44 AM",
          "value" : "2024-01-25T14:44:15.000Z"
        },
        "Id" : {
          "displayValue" : null,
          "value" : "a1TEi000000lRRNMA2"
        },
        "LastModifiedById" : {
          "displayValue" : null,
          "value" : "0056C000004YLqhQAG"
        },
        "LastModifiedDate" : {
          "displayValue" : null,
          "value" : "2024-01-25T14:44:15.000Z"
        },
        "Model__c" : {
          "displayValue" : null,
          "value" : "SVL75W"
        },
        "Name" : {
          "displayValue" : null,
          "value" : "000151"
        },
        "Serial__c" : {
          "displayValue" : null,
          "value" : "10187"
        },
        "Symptom__c" : {
          "displayValue" : null,
          "value" : "Smoke coming from engine"
        },
        "SystemModstamp" : {
          "displayValue" : null,
          "value" : "2024-01-25T14:44:15.000Z"
        }
      },
      "id" : "a1TEi000000lRRNMA2",
      "lastModifiedById" : "0056C000004YLqhQAG",
      "lastModifiedDate" : "2024-01-25T14:44:15.000Z",
      "recordTypeId" : "012000000000000AAA",
      "recordTypeInfo" : null,
      "systemModstamp" : "2024-01-25T14:44:15.000Z",
      "weakEtag" : 1706193855000
    }, {
      "apiName" : "Parts_Prediction__c",
      "childRelationships" : { },
      "eTag" : "0c93ac583e6d5b553317b707a8d9ff6c",
      "fields" : {
        "Case__c" : {
          "displayValue" : null,
          "value" : null
        },
        "Case__r" : {
          "displayValue" : null,
          "value" : null
        },
        "CreatedDate" : {
          "displayValue" : "1/25/2024, 6:46 AM",
          "value" : "2024-01-25T14:46:18.000Z"
        },
        "Id" : {
          "displayValue" : null,
          "value" : "a1TEi000000lRZRMA2"
        },
        "LastModifiedById" : {
          "displayValue" : null,
          "value" : "0056C000004YLqhQAG"
        },
        "LastModifiedDate" : {
          "displayValue" : null,
          "value" : "2024-01-25T14:46:18.000Z"
        },
        "Model__c" : {
          "displayValue" : null,
          "value" : "SVL65-2W"
        },
        "Name" : {
          "displayValue" : null,
          "value" : "000152"
        },
        "Serial__c" : {
          "displayValue" : null,
          "value" : "12345"
        },
        "Symptom__c" : {
          "displayValue" : null,
          "value" : "Grinding noise from engine"
        },
        "SystemModstamp" : {
          "displayValue" : null,
          "value" : "2024-01-25T14:46:18.000Z"
        }
      },
      "id" : "a1TEi000000lRZRMA2",
      "lastModifiedById" : "0056C000004YLqhQAG",
      "lastModifiedDate" : "2024-01-25T14:46:18.000Z",
      "recordTypeId" : "012000000000000AAA",
      "recordTypeInfo" : null,
      "systemModstamp" : "2024-01-25T14:46:18.000Z",
      "weakEtag" : 1706193978000
    }, {
      "apiName" : "Parts_Prediction__c",
      "childRelationships" : { },
      "eTag" : "21faf57163e986a0bd2d97d260c6a099",
      "fields" : {
        "Case__c" : {
          "displayValue" : null,
          "value" : null
        },
        "Case__r" : {
          "displayValue" : null,
          "value" : null
        },
        "CreatedDate" : {
          "displayValue" : "1/25/2024, 8:21 AM",
          "value" : "2024-01-25T16:21:44.000Z"
        },
        "Id" : {
          "displayValue" : null,
          "value" : "a1TEi000000lSLpMAM"
        },
        "LastModifiedById" : {
          "displayValue" : null,
          "value" : "0056C000004YLqhQAG"
        },
        "LastModifiedDate" : {
          "displayValue" : null,
          "value" : "2024-01-25T16:21:44.000Z"
        },
        "Model__c" : {
          "displayValue" : null,
          "value" : "SVL75W"
        },
        "Name" : {
          "displayValue" : null,
          "value" : "000161"
        },
        "Serial__c" : {
          "displayValue" : null,
          "value" : "10187"
        },
        "Symptom__c" : {
          "displayValue" : null,
          "value" : "Engine not working"
        },
        "SystemModstamp" : {
          "displayValue" : null,
          "value" : "2024-01-25T16:21:44.000Z"
        }
      },
      "id" : "a1TEi000000lSLpMAM",
      "lastModifiedById" : "0056C000004YLqhQAG",
      "lastModifiedDate" : "2024-01-25T16:21:44.000Z",
      "recordTypeId" : "012000000000000AAA",
      "recordTypeInfo" : null,
      "systemModstamp" : "2024-01-25T16:21:44.000Z",
      "weakEtag" : 1706199704000
    }, {
      "apiName" : "Parts_Prediction__c",
      "childRelationships" : { },
      "eTag" : "fb06bba8a092cd9ea01f58c3d43e88e3",
      "fields" : {
        "Case__c" : {
          "displayValue" : null,
          "value" : null
        },
        "Case__r" : {
          "displayValue" : null,
          "value" : null
        },
        "CreatedDate" : {
          "displayValue" : "1/25/2024, 8:22 AM",
          "value" : "2024-01-25T16:22:29.000Z"
        },
        "Id" : {
          "displayValue" : null,
          "value" : "a1TEi000000lSNRMA2"
        },
        "LastModifiedById" : {
          "displayValue" : null,
          "value" : "0056C000004YLqhQAG"
        },
        "LastModifiedDate" : {
          "displayValue" : null,
          "value" : "2024-01-25T16:22:29.000Z"
        },
        "Model__c" : {
          "displayValue" : null,
          "value" : "SVL75W"
        },
        "Name" : {
          "displayValue" : null,
          "value" : "000162"
        },
        "Serial__c" : {
          "displayValue" : null,
          "value" : "10187"
        },
        "Symptom__c" : {
          "displayValue" : null,
          "value" : "Engine not working"
        },
        "SystemModstamp" : {
          "displayValue" : null,
          "value" : "2024-01-25T16:22:29.000Z"
        }
      },
      "id" : "a1TEi000000lSNRMA2",
      "lastModifiedById" : "0056C000004YLqhQAG",
      "lastModifiedDate" : "2024-01-25T16:22:29.000Z",
      "recordTypeId" : "012000000000000AAA",
      "recordTypeInfo" : null,
      "systemModstamp" : "2024-01-25T16:22:29.000Z",
      "weakEtag" : 1706199749000
    }, {
      "apiName" : "Parts_Prediction__c",
      "childRelationships" : { },
      "eTag" : "fd1907a10b0a2397caa9644aa6e88893",
      "fields" : {
        "Case__c" : {
          "displayValue" : null,
          "value" : null
        },
        "Case__r" : {
          "displayValue" : null,
          "value" : null
        },
        "CreatedDate" : {
          "displayValue" : "1/25/2024, 8:23 AM",
          "value" : "2024-01-25T16:23:25.000Z"
        },
        "Id" : {
          "displayValue" : null,
          "value" : "a1TEi000000lSQfMAM"
        },
        "LastModifiedById" : {
          "displayValue" : null,
          "value" : "0056C000004YLqhQAG"
        },
        "LastModifiedDate" : {
          "displayValue" : null,
          "value" : "2024-01-25T16:23:25.000Z"
        },
        "Model__c" : {
          "displayValue" : null,
          "value" : "SVL75W"
        },
        "Name" : {
          "displayValue" : null,
          "value" : "000163"
        },
        "Serial__c" : {
          "displayValue" : null,
          "value" : "10187"
        },
        "Symptom__c" : {
          "displayValue" : null,
          "value" : "Engine not working"
        },
        "SystemModstamp" : {
          "displayValue" : null,
          "value" : "2024-01-25T16:23:25.000Z"
        }
      },
      "id" : "a1TEi000000lSQfMAM",
      "lastModifiedById" : "0056C000004YLqhQAG",
      "lastModifiedDate" : "2024-01-25T16:23:25.000Z",
      "recordTypeId" : "012000000000000AAA",
      "recordTypeInfo" : null,
      "systemModstamp" : "2024-01-25T16:23:25.000Z",
      "weakEtag" : 1706199805000
    }, {
      "apiName" : "Parts_Prediction__c",
      "childRelationships" : { },
      "eTag" : "10d6d9b12c20d6ad7354ad5cb2928bde",
      "fields" : {
        "Case__c" : {
          "displayValue" : null,
          "value" : null
        },
        "Case__r" : {
          "displayValue" : null,
          "value" : null
        },
        "CreatedDate" : {
          "displayValue" : "1/25/2024, 8:29 AM",
          "value" : "2024-01-25T16:29:10.000Z"
        },
        "Id" : {
          "displayValue" : null,
          "value" : "a1TEi000000lSTtMAM"
        },
        "LastModifiedById" : {
          "displayValue" : null,
          "value" : "0056C000004YLqhQAG"
        },
        "LastModifiedDate" : {
          "displayValue" : null,
          "value" : "2024-01-25T16:29:10.000Z"
        },
        "Model__c" : {
          "displayValue" : null,
          "value" : "SVL75W"
        },
        "Name" : {
          "displayValue" : null,
          "value" : "000164"
        },
        "Serial__c" : {
          "displayValue" : null,
          "value" : "10187"
        },
        "Symptom__c" : {
          "displayValue" : null,
          "value" : "Engine not working"
        },
        "SystemModstamp" : {
          "displayValue" : null,
          "value" : "2024-01-25T16:29:10.000Z"
        }
      },
      "id" : "a1TEi000000lSTtMAM",
      "lastModifiedById" : "0056C000004YLqhQAG",
      "lastModifiedDate" : "2024-01-25T16:29:10.000Z",
      "recordTypeId" : "012000000000000AAA",
      "recordTypeInfo" : null,
      "systemModstamp" : "2024-01-25T16:29:10.000Z",
      "weakEtag" : 1706200150000
    }, {
      "apiName" : "Parts_Prediction__c",
      "childRelationships" : { },
      "eTag" : "436e8c8fdf86d06d7b88b84d8ede9b8e",
      "fields" : {
        "Case__c" : {
          "displayValue" : null,
          "value" : null
        },
        "Case__r" : {
          "displayValue" : null,
          "value" : null
        },
        "CreatedDate" : {
          "displayValue" : "1/25/2024, 8:29 AM",
          "value" : "2024-01-25T16:29:35.000Z"
        },
        "Id" : {
          "displayValue" : null,
          "value" : "a1TEi000000lSVVMA2"
        },
        "LastModifiedById" : {
          "displayValue" : null,
          "value" : "0056C000004YLqhQAG"
        },
        "LastModifiedDate" : {
          "displayValue" : null,
          "value" : "2024-01-25T16:29:35.000Z"
        },
        "Model__c" : {
          "displayValue" : null,
          "value" : "SVL75W"
        },
        "Name" : {
          "displayValue" : null,
          "value" : "000165"
        },
        "Serial__c" : {
          "displayValue" : null,
          "value" : "10187"
        },
        "Symptom__c" : {
          "displayValue" : null,
          "value" : "Engine not working"
        },
        "SystemModstamp" : {
          "displayValue" : null,
          "value" : "2024-01-25T16:29:35.000Z"
        }
      },
      "id" : "a1TEi000000lSVVMA2",
      "lastModifiedById" : "0056C000004YLqhQAG",
      "lastModifiedDate" : "2024-01-25T16:29:35.000Z",
      "recordTypeId" : "012000000000000AAA",
      "recordTypeInfo" : null,
      "systemModstamp" : "2024-01-25T16:29:35.000Z",
      "weakEtag" : 1706200175000
    }, {
      "apiName" : "Parts_Prediction__c",
      "childRelationships" : { },
      "eTag" : "152500115ed6103a13ce457bf815ce96",
      "fields" : {
        "Case__c" : {
          "displayValue" : null,
          "value" : null
        },
        "Case__r" : {
          "displayValue" : null,
          "value" : null
        },
        "CreatedDate" : {
          "displayValue" : "1/25/2024, 8:52 AM",
          "value" : "2024-01-25T16:52:08.000Z"
        },
        "Id" : {
          "displayValue" : null,
          "value" : "a1TEi000000lSldMAE"
        },
        "LastModifiedById" : {
          "displayValue" : null,
          "value" : "0056C000004YLqhQAG"
        },
        "LastModifiedDate" : {
          "displayValue" : null,
          "value" : "2024-01-25T16:52:08.000Z"
        },
        "Model__c" : {
          "displayValue" : null,
          "value" : "SVL75W"
        },
        "Name" : {
          "displayValue" : null,
          "value" : "000166"
        },
        "Serial__c" : {
          "displayValue" : null,
          "value" : "10187"
        },
        "Symptom__c" : {
          "displayValue" : null,
          "value" : "Engine not working"
        },
        "SystemModstamp" : {
          "displayValue" : null,
          "value" : "2024-01-25T16:52:08.000Z"
        }
      },
      "id" : "a1TEi000000lSldMAE",
      "lastModifiedById" : "0056C000004YLqhQAG",
      "lastModifiedDate" : "2024-01-25T16:52:08.000Z",
      "recordTypeId" : "012000000000000AAA",
      "recordTypeInfo" : null,
      "systemModstamp" : "2024-01-25T16:52:08.000Z",
      "weakEtag" : 1706201528000
    }, {
      "apiName" : "Parts_Prediction__c",
      "childRelationships" : { },
      "eTag" : "b5fe909fcc745a792603babfc16573ba",
      "fields" : {
        "Case__c" : {
          "displayValue" : null,
          "value" : null
        },
        "Case__r" : {
          "displayValue" : null,
          "value" : null
        },
        "CreatedDate" : {
          "displayValue" : "1/31/2024, 7:36 AM",
          "value" : "2024-01-31T15:36:19.000Z"
        },
        "Id" : {
          "displayValue" : null,
          "value" : "a1TEi000000mL8bMAE"
        },
        "LastModifiedById" : {
          "displayValue" : null,
          "value" : "0056C000004YLqhQAG"
        },
        "LastModifiedDate" : {
          "displayValue" : null,
          "value" : "2024-01-31T15:36:19.000Z"
        },
        "Model__c" : {
          "displayValue" : null,
          "value" : "SVL75W"
        },
        "Name" : {
          "displayValue" : null,
          "value" : "000217"
        },
        "Serial__c" : {
          "displayValue" : null,
          "value" : "10187"
        },
        "Symptom__c" : {
          "displayValue" : null,
          "value" : "Smoke coming from engine"
        },
        "SystemModstamp" : {
          "displayValue" : null,
          "value" : "2024-01-31T15:36:19.000Z"
        }
      },
      "id" : "a1TEi000000mL8bMAE",
      "lastModifiedById" : "0056C000004YLqhQAG",
      "lastModifiedDate" : "2024-01-31T15:36:19.000Z",
      "recordTypeId" : "012000000000000AAA",
      "recordTypeInfo" : null,
      "systemModstamp" : "2024-01-31T15:36:19.000Z",
      "weakEtag" : 1706715379000
    }, {
      "apiName" : "Parts_Prediction__c",
      "childRelationships" : { },
      "eTag" : "d8b46b7344e25c515602e51723301859",
      "fields" : {
        "Case__c" : {
          "displayValue" : null,
          "value" : null
        },
        "Case__r" : {
          "displayValue" : null,
          "value" : null
        },
        "CreatedDate" : {
          "displayValue" : "1/31/2024, 7:36 AM",
          "value" : "2024-01-31T15:36:55.000Z"
        },
        "Id" : {
          "displayValue" : null,
          "value" : "a1TEi000000mLADMA2"
        },
        "LastModifiedById" : {
          "displayValue" : null,
          "value" : "0056C000004YLqhQAG"
        },
        "LastModifiedDate" : {
          "displayValue" : null,
          "value" : "2024-01-31T15:36:55.000Z"
        },
        "Model__c" : {
          "displayValue" : null,
          "value" : "SVL75W"
        },
        "Name" : {
          "displayValue" : null,
          "value" : "000218"
        },
        "Serial__c" : {
          "displayValue" : null,
          "value" : "10187"
        },
        "Symptom__c" : {
          "displayValue" : null,
          "value" : "Engine not working"
        },
        "SystemModstamp" : {
          "displayValue" : null,
          "value" : "2024-01-31T15:36:55.000Z"
        }
      },
      "id" : "a1TEi000000mLADMA2",
      "lastModifiedById" : "0056C000004YLqhQAG",
      "lastModifiedDate" : "2024-01-31T15:36:55.000Z",
      "recordTypeId" : "012000000000000AAA",
      "recordTypeInfo" : null,
      "systemModstamp" : "2024-01-31T15:36:55.000Z",
      "weakEtag" : 1706715415000
    }, {
      "apiName" : "Parts_Prediction__c",
      "childRelationships" : { },
      "eTag" : "bec895d908ab1379964108bfa53d2824",
      "fields" : {
        "Case__c" : {
          "displayValue" : null,
          "value" : null
        },
        "Case__r" : {
          "displayValue" : null,
          "value" : null
        },
        "CreatedDate" : {
          "displayValue" : "1/31/2024, 10:47 AM",
          "value" : "2024-01-31T18:47:10.000Z"
        },
        "Id" : {
          "displayValue" : null,
          "value" : "a1TEi000000mMSrMAM"
        },
        "LastModifiedById" : {
          "displayValue" : null,
          "value" : "0056C000004YLqhQAG"
        },
        "LastModifiedDate" : {
          "displayValue" : null,
          "value" : "2024-01-31T18:47:10.000Z"
        },
        "Model__c" : {
          "displayValue" : null,
          "value" : "SVL90"
        },
        "Name" : {
          "displayValue" : null,
          "value" : "000232"
        },
        "Serial__c" : {
          "displayValue" : null,
          "value" : "10087"
        },
        "Symptom__c" : {
          "displayValue" : null,
          "value" : "Smoke coming from engine"
        },
        "SystemModstamp" : {
          "displayValue" : null,
          "value" : "2024-01-31T18:47:10.000Z"
        }
      },
      "id" : "a1TEi000000mMSrMAM",
      "lastModifiedById" : "0056C000004YLqhQAG",
      "lastModifiedDate" : "2024-01-31T18:47:10.000Z",
      "recordTypeId" : "012000000000000AAA",
      "recordTypeInfo" : null,
      "systemModstamp" : "2024-01-31T18:47:10.000Z",
      "weakEtag" : 1706726830000
    }, {
      "apiName" : "Parts_Prediction__c",
      "childRelationships" : { },
      "eTag" : "29220eb3f738bc29fe1fd4eb374b1843",
      "fields" : {
        "Case__c" : {
          "displayValue" : null,
          "value" : "5008b00002KkZG6AAN"
        },
        "Case__r" : {
          "displayValue" : "00637111",
          "value" : {
            "apiName" : "Case",
            "childRelationships" : { },
            "eTag" : "dc54515be096240225c539f0cc7c1064",
            "fields" : {
              "CaseNumber" : {
                "displayValue" : null,
                "value" : "00637111"
              },
              "CreatedDate" : {
                "displayValue" : null,
                "value" : "2023-05-12T18:42:36.000Z"
              },
              "Id" : {
                "displayValue" : null,
                "value" : "5008b00002KkZG6AAN"
              },
              "LastModifiedById" : {
                "displayValue" : null,
                "value" : "0058b00000HN8pAAAT"
              },
              "LastModifiedDate" : {
                "displayValue" : null,
                "value" : "2023-05-24T20:07:17.000Z"
              },
              "RecordTypeId" : {
                "displayValue" : null,
                "value" : "0128b000000qGfIAAU"
              },
              "SystemModstamp" : {
                "displayValue" : null,
                "value" : "2023-05-24T20:07:17.000Z"
              }
            },
            "id" : "5008b00002KkZG6AAN",
            "lastModifiedById" : "0058b00000HN8pAAAT",
            "lastModifiedDate" : "2023-05-24T20:07:17.000Z",
            "recordTypeId" : "0128b000000qGfIAAU",
            "recordTypeInfo" : {
              "available" : true,
              "defaultRecordTypeMapping" : true,
              "master" : false,
              "name" : "TSC Case",
              "recordTypeId" : "0128b000000qGfIAAU"
            },
            "systemModstamp" : "2023-05-24T20:07:17.000Z",
            "weakEtag" : 1684958837000
          }
        },
        "CreatedDate" : {
          "displayValue" : "1/23/2024, 12:53 PM",
          "value" : "2024-01-23T20:53:13.000Z"
        },
        "Id" : {
          "displayValue" : null,
          "value" : "a1TEi000000l4EfMAI"
        },
        "LastModifiedById" : {
          "displayValue" : null,
          "value" : "005Ei000004HF5BIAW"
        },
        "LastModifiedDate" : {
          "displayValue" : null,
          "value" : "2024-01-24T16:33:37.000Z"
        },
        "Model__c" : {
          "displayValue" : null,
          "value" : "SVL75W"
        },
        "Name" : {
          "displayValue" : null,
          "value" : "000000"
        },
        "Serial__c" : {
          "displayValue" : null,
          "value" : "10187"
        },
        "Symptom__c" : {
          "displayValue" : null,
          "value" : "Smoke coming from engine"
        },
        "SystemModstamp" : {
          "displayValue" : null,
          "value" : "2024-01-24T16:33:37.000Z"
        }
      },
      "id" : "a1TEi000000l4EfMAI",
      "lastModifiedById" : "005Ei000004HF5BIAW",
      "lastModifiedDate" : "2024-01-24T16:33:37.000Z",
      "recordTypeId" : "012000000000000AAA",
      "recordTypeInfo" : null,
      "systemModstamp" : "2024-01-24T16:33:37.000Z",
      "weakEtag" : 1706114017000
    }, {
      "apiName" : "Parts_Prediction__c",
      "childRelationships" : { },
      "eTag" : "893700f6fac432834fad9789054b44c5",
      "fields" : {
        "Case__c" : {
          "displayValue" : null,
          "value" : "5006C00000AVlxHQAT"
        },
        "Case__r" : {
          "displayValue" : "01197087",
          "value" : {
            "apiName" : "Case",
            "childRelationships" : { },
            "eTag" : "c381271495bbb1e3a1e6691cf0ebd106",
            "fields" : {
              "CaseNumber" : {
                "displayValue" : null,
                "value" : "01197087"
              },
              "CreatedDate" : {
                "displayValue" : null,
                "value" : "2023-06-30T16:04:29.000Z"
              },
              "Id" : {
                "displayValue" : null,
                "value" : "5006C00000AVlxHQAT"
              },
              "LastModifiedById" : {
                "displayValue" : null,
                "value" : "0056C000004YLqhQAG"
              },
              "LastModifiedDate" : {
                "displayValue" : null,
                "value" : "2023-06-30T16:04:30.000Z"
              },
              "RecordTypeId" : {
                "displayValue" : null,
                "value" : "0128b000000qGfIAAU"
              },
              "SystemModstamp" : {
                "displayValue" : null,
                "value" : "2023-06-30T16:04:30.000Z"
              }
            },
            "id" : "5006C00000AVlxHQAT",
            "lastModifiedById" : "0056C000004YLqhQAG",
            "lastModifiedDate" : "2023-06-30T16:04:30.000Z",
            "recordTypeId" : "0128b000000qGfIAAU",
            "recordTypeInfo" : {
              "available" : true,
              "defaultRecordTypeMapping" : true,
              "master" : false,
              "name" : "TSC Case",
              "recordTypeId" : "0128b000000qGfIAAU"
            },
            "systemModstamp" : "2023-06-30T16:04:30.000Z",
            "weakEtag" : 1688141070000
          }
        },
        "CreatedDate" : {
          "displayValue" : "2/5/2024, 12:51 PM",
          "value" : "2024-02-05T20:51:28.000Z"
        },
        "Id" : {
          "displayValue" : null,
          "value" : "a1TEi000000nD7NMAU"
        },
        "LastModifiedById" : {
          "displayValue" : null,
          "value" : "0056C000004YLqhQAG"
        },
        "LastModifiedDate" : {
          "displayValue" : null,
          "value" : "2024-02-05T20:55:23.000Z"
        },
        "Model__c" : {
          "displayValue" : null,
          "value" : "SVL65-2"
        },
        "Name" : {
          "displayValue" : null,
          "value" : "000373"
        },
        "Serial__c" : {
          "displayValue" : null,
          "value" : "10187"
        },
        "Symptom__c" : {
          "displayValue" : null,
          "value" : "Smoke coming from engine"
        },
        "SystemModstamp" : {
          "displayValue" : null,
          "value" : "2024-02-05T20:55:23.000Z"
        }
      },
      "id" : "a1TEi000000nD7NMAU",
      "lastModifiedById" : "0056C000004YLqhQAG",
      "lastModifiedDate" : "2024-02-05T20:55:23.000Z",
      "recordTypeId" : "012000000000000AAA",
      "recordTypeInfo" : null,
      "systemModstamp" : "2024-02-05T20:55:23.000Z",
      "weakEtag" : 1707166523000
    } ],
    "sortBy" : "Case__r.CaseNumber,Id"
  }


  useEffect(() => {
    // On Component Load
    fetchSearch();
  }, []);

  // useEffect(() => {
  //   //Cant filter before searchResults are fetched.
  //   if(searchResults) {
  //     setFilteredResults(handleFilterUpdates());
  //   }
  // }, [statusFilter, modelSerialSearchTerm]);

  // useEffect(() => {
  //   // searchHistory is only updated once (after cases comes back from SF)
  //   // We need to run filters on page load incase any are set by default.
  //   if (searchResults) {
  //     setFilteredResults(handleFilterUpdates());
  //   }
  // }, [searchResults]);

  function handleFilterUpdates() {
    //Perform Filtering using current filter/search values
    //Filter then search with filtered list
    //If both are null, default to unfiltered/searched searchResults
    let filteredList;
    if(!statusFilter || statusFilter === "All") {
      filteredList = searchResults;
    } else {
      filteredList = searchResults.filter(caseObj => {
        return statusFilter.includes(caseObj.fields.Status.displayValue);
      });
    }
    if(!modelSerialSearchTerm){
      return filteredList;
    } else {
      return modelSerialSearch(filteredList, modelSerialSearchTerm);
    }
  }

  const fetchSearch = (refresh = false) => {
    if(refresh == false)
      setLoading();
    var qs = require('qs');
    var config = {
        method: 'get',
        //url: process.env.REACT_APP_API + 'salesforce/kservice/Get?param=ui-api/list-records/Case/AllCases',
        url: process.env.REACT_APP_API + 'salesforce/kservice/Get?param=ui-api/list-records/Parts_Prediction__c/My_Parts_Prediction_for_Dealer',
        headers: { 
            'Authorization': 'Bearer ' + Cookies.get("token"),//Middleware token
            'X-SalesForce-Token' : Cookies.get('sf_cookie'),//SF token for Middleware
            'X-Id-Token' : Cookies.get('idToken')//Refresh ID Token
            }
        };

    axios(config)
    .then(function (response) {
        setLoading();
        console.log("Loading set to: " + loading)
        setSearchResults(response.data.records.reverse());
        //^^ Setting searchResults triggers filters to run, and filteredResults to be set.
    })
    .catch((error) => {
      debugger;
        if (error.response) {
              if(error.response.status == "401")
              {
                console.log("Token expired");
                //Replace with middleware logic sometime
              }
            }
    });
    // setSearchResults(samplePredictionData.records);
  }

  function navigateToPredictionDetail(id){
    console.log(id);
    history.push("/support/prediction/" + id);
  }



  function Results({result}) {
    let createdDate = result.fields.CreatedDate.value;
    createdDate = createdDate.substring(0,10);
    return (
    
      <Grid container id="ParentResultContainer" className={classes.parentContainer} onClick={(event) => navigateToPredictionDetail(result.id)}>
        <Grid item md={10} sm={10} xs={10}>
          <Grid container id="LeftHandInfo">
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                component="span"
                variant="h6"
                color="textPrimary"
                noWrap={true}
                className={classes.listItem}
              >
                {result.fields.Symptom__c.value}
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Grid container id="bottom half container">
                <Grid item md={6} sm={6} xs={6}>
                  <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                      gutterBottom
                      className={classes.listItem}
                    >
                      <strong>Prediction: </strong>{result.fields.Name.value}
                    </Typography>
                </Grid>
                <Grid item md={6} sm={6} xs={6} >
                  <strong>Created Date: </strong>{createdDate}
                </Grid>
                <Grid item md={12}>
                  <Grid container>
                    <Grid item md={6}>
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                        gutterBottom
                        className={classes.listItem}
                      >
                      <strong>Model: </strong>{result.fields.Model__c.value}
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                        gutterBottom
                      >
                      <strong>Serial: </strong> {result.fields.Serial__c.value}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2} sm={2} xs={2}>
          <Grid container id="ChevronContainer"
            justify="center"
            flexDirection="column"
            alignItems="center"
            style={{height: "100%"}}
          >
            <ArrowForwardIosIcon/>
          </Grid>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Divider/>
        </Grid>
     </Grid>
    )
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.heroContent}>
          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              <Paper elevation={3} className={classes.heroPaper}>
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <Typography variant="h4" component="h1">Prediction History</Typography>
                    <Divider />
                  </Grid>
                  {searchResults && !loading &&
                    <>
                      {/* <Grid item md={6} xs={6}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="statusFilterLabel">Status</InputLabel>
                          <Select
                            label='Status'
                            labelId={"statusFilterLabel"}
                            defaultValue=""
                            multiple  
                            id={"statusSelect"}
                            value={statusFilter}
                            renderValue={(selected) => selected.join(', ')}
                            onChange={(event) => {
                              if (event.target.value.includes("All")) {
                                // If ALL was NOT previously selecetd, we are now selecting it. Select everything
                                if (!statusFilter.includes("All")) {
                                  setStatusFilter(statusFilters);
                                }
                              } 
                              else if (statusFilter.includes("All") && !event.target.value.includes("All")){
                                // If ALL was previously selected but now was just deselected. Unselect everything
                                setStatusFilter([]);
                              }
                              else {
                                setStatusFilter(event.target.value)
                              }
                            }}
                          >
                            { statusFilters.map((filter) => {
                              var isAllSelected = statusFilter.includes("All");
                              return(
                                <MenuItem
                                  key={"StatusFilter-" + filter}
                                  value={filter}
                                  disabled={(filter === "All") ? false : isAllSelected}
                                >
                                   <Checkbox color="primary" checked={statusFilter.includes(filter)} />
                                  <ListItemText primary={filter } />
                                </MenuItem> 
                              )})
                            }
                          </Select>     
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={6}>
                      </Grid>
                      <Grid item md={3} xs={4}>
                        <TextField fullWidth id="outlined-basic" label="Model or Serial" variant="outlined" 
                          onChange={(e) => {
                              setmodelSerialSearchTerm(e.currentTarget.value);
                          }}
                        />
                      </Grid> */}
                      <Grid item md={12} xs={12}>
                          <List component="nav" aria-label="knowledge search results" className={classes.root}>
                            {searchResults.map((result, i) => (
                              <Results
                                key={i}
                                index={i}
                                result={result}
                              />
                            ))}
                          </List>
                      </Grid>
                    </>
                  }
                  {(!searchResults || searchResults.length <= 0) && !loading &&
                    <Grid item md={12} xs={12}>
                      <Alert severity="warning">No Prediction History to Display</Alert>
                    </Grid>
                  }
                  {loading &&
                    <Grid item md={12} xs={12}>
                      <div className={classes.loaderCenter}>
                        <CircularProgress className={classes.loadingCenterInside} />
                      </div>
                    </Grid>
                  }              
                </Grid>
              </Paper>
            </Grid>
          </Grid>
      </Container>


    </React.Fragment>
  );
}
