import React, { useReducer } from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import { app } from '../firebaseConfig';

import KnowledgeRecentSearch from '../components/KnowledgeRecentSearch';

import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import {Select, MenuItem, InputLabel, FormControl} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Alert from '@material-ui/lab/Alert';
import Pagination from '@material-ui/lab/Pagination';
import { flexbox, spacing } from '@material-ui/system';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Fab } from '@material-ui/core';

import CropFreeIcon from '@material-ui/icons/CropFreeSharp';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import Html5QrcodeScanner from '../components/Html5QrcodeScanner';
import ApiManager from '../apiservice';
import Cookies from 'js-cookie';
import DealerCard from '../components/DealerCard';
import explodeIconMap from '../helpers/explodeIconMap';

const cancelToken = axios.CancelToken;
const cancel = cancelToken.source();

const useStyles = makeStyles((theme) => ({
  fab: {

  },
  horizFlex: {
    // display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
  },
  flexbox: {
    display: 'flex',
    position: 'fixed',
    width: '100%',
    justifyContent: 'center',
    zIndex: '1',
    bottom: theme.spacing(10)
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
    width: '90%'
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  button: {
    margin: theme.spacing(0, 0, 3)
  },
  cancelButton: {
    margin: theme.spacing(1, 0, 1)
  },
  eyebrow: {
    margin: theme.spacing(0, 0, 3)
  },
  center: {
    textAlign: 'center'
  },
  loadingCenterInside: {
    position: 'relative'
  },
  

}));

export const MachineSearchPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const storage = getStorage();
  const [iconBlob, setIconBlob] = useState();
  const [serialNumber, setSerialNumber] = useState('');
  const [modelNumber, setModelNumber] = useState('');
  const [pinNumber, setPinNumber] = useState('');
  const [searchMode, setSearchMode] = useState('ModelSerial');
  const [scan, setScan] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scanMode, setScanMode] = useState(false);
  const [stopStream, setStopStream] = useState(false);
  const [imei, setIMEI] = useState(false);
  const [searchError, setSearchError] = useState(null);
  const [customerResults, setCustomerResults] = useState(false);
  const [kwarrantyToken, setKwarrantyToken] = useState();
  const [iconMap, setIconMap] = useState();
  const {startQrCode, stopQrCode, decodedQRData } = Html5QrcodeScanner({
    qrcodeMountNodeID: "qrcodemountnode"
  });
  const [customerSearchValues, setCustomerSearchValues] = useState({
    FirstName : '',
    LastName: '',
    City: '',
    State: ''
  });


  useEffect(() => {
    let path = window.location.pathname;
    if(path.includes('imei')){
      setIMEI(true);
    }
    // const gsReference = ref(storage, 'gs://k-service-staging.appspot.com/images/stockcat2.jpeg');
    // getDownloadURL(ref(storage, gsReference))
    // .then((url) => {
    //   // `url` is the download URL for 'images/stars.jpg'

    //   // This can be downloaded directly:
    //   const xhr = new XMLHttpRequest();
    //   xhr.responseType = 'blob';
    //   xhr.onload = (event) => {
    //     debugger;
    //     let imgURL = URL.createObjectURL(xhr.response);
    //     debugger;
    //     setIconBlob(imgURL);
    //   };
    //   xhr.open('GET', url);
    //   xhr.send();
    // })
    // .catch((error) => {
    //   // Handle any errors
    // });
  }, []);

  useEffect(() => {
    // console.log(serialNumber);
  }, [serialNumber]);

  useEffect(() => {
    // Process Scan Results
    if(scan){
      processQRScan(scan);
    }

  }, [scan]);

  useEffect(() => {
    if (inputValue.length >= 3 && serialNumber.length >= 5) {
      setOpen(true);
      // console.log('SEND REQUEST');
      // console.log(modelNumber);
      // console.log(inputValue);
      getTypeaheadModels();
    }

  }, [modelNumber, inputValue]);

  useEffect(() => {
    if(scanMode){
      startQrCode();
    }
  }, [scanMode]);

  useEffect(() => {
    if(decodedQRData.data){
      stopQrCode();
      console.log('Decoded QR Data');
      console.log(decodedQRData.data);
      processQRScan(decodedQRData.data);
      setScanMode(false);
    }
  }, [decodedQRData]);

  useEffect(() => {
    if(searchMode === 'Customer' && !kwarrantyToken) {
      //fetchKwarrantyToken();
      fetchIconMapFromFirebase();
    }
  }, [searchMode]);

  const openQRScanner = () => {
    setScanMode(true);
    console.log('Show QR Scanner');
  }

  const processQRScan = (s) => {
    // Explode to array
    console.log(s);
    let scanItems = s.split(/[\s]+/);

    let mn = null;
    let sn = null;
    let pin = null;

    // Loop Array
    for (const el in scanItems) {

      if(scanItems[el].startsWith('PIN')){
        pin = scanItems[el].substring(3);
      }
      // Check/assign for mn prefix
      if(scanItems[el].startsWith('WGN')){
        mn = scanItems[el].substring(3);
      }
      // Check/assign for sn prefix
      if(scanItems[el].startsWith('SN')){
        sn = scanItems[el].substring(2);
      }
    }

    // If not prefixing exists
    if(!sn && !mn && !pin && scanItems.length >= 2) {
      // assume array[0] == mn
      mn = scanItems[0];
      // assume array[1] == pin
      if(scanItems[1].length == 17){
        pin = scanItems[1];
      }else if(scanItems[1].length == 5){
        sn = scanItems[1];
      }

    }else if(!sn && !mn && !pin && scanItems.length == 1) {
      // assume array[0] == pin
      pin = scanItems[0];
    }

    // If no !sn && pin exists
      // Get sn from pin
    if( !sn && pin ){
      // console.log('Get SN from PIN: ' + this.pin)
      let temp = pin;
      sn = temp.slice(-5)
    }

    // Set machineSerial
    if(sn) setSerialNumber(sn);
    if(mn) setModelNumber(mn);
    if(pin) setPinNumber(pin);
    checkValid(sn, mn, pin);
  }

  const closeQRScanner = () => {
    stopQrCode();
    setScanMode(false);
    console.log('Close QR Scanner');
  }

  const checkValid = (sn, mn, pin) => {
    console.log('get Machine Details');
    console.log(sn + ' ' + mn + ' ' + pin);
    // let data = JSON.stringify({
    //   "data": {
    //     "materialNumber": mn,
    //     "serialNumber": sn,
    //     "productIdentificationNo": pin
    //   }
    // });
    // var url = process.env.REACT_APP_OSVC_DOMAIN + '/cc/MobileSearch/searchInventory';

    const url = process.env.REACT_APP_API + 'salesforce/kwarranty/SearchInventory';
    const req = {
      method: "post",
      url: url,
      headers: {
        'Authorization': 'Bearer ' + Cookies.get("token"),
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        "materialNumber": mn,
        "serialNumber": sn,
        "productIdentificationNo": pin
      })
    }

    // axios.post(url, data, { withCredentials: true }).then((r) => {
      axios(req).then((r) => {
        // console.log('Machine Model Search');
        console.log(r.data);
        if(r.data.serialNumber) setSerialNumber(r.data.serialNumber);
        if(r.data.materialNumber) setModelNumber(r.data.materialNumber);
        if(r.data.productIdentificationNo) setPinNumber(r.data.productIdentificationNo);

      }).catch((err) => {
        if (axios.isCancel(err)) {
          console.log('Request canceled ' + err.message);
        } else {
          console.log(err);
        }
        setSearchError(err.message);
      });

  }

  const getTypeaheadModels = () => {
    setSearchError(null);
    setLoading(true);
    console.log('get Typeahead Models');

    //Old osvc endpoint, just kept so we can search the app for them
    //var url = process.env.REACT_APP_OSVC_DOMAIN + '/cc/MobileSearch/searchMachineModel';
      const url = process.env.REACT_APP_API + 'salesforce/kwarranty/SearchModel';
      const req = {
        method: "post",
        url: url,
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("token"),
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          "materialNumber": inputValue,
          "serialNumber": serialNumber,
        })
      }
      axios(req).then((r) => {
        const modelCollection = r.data.modelList;
        const items = new Array();
        if(r.data.modelList) {
          for (let i = 0; i < modelCollection.length; i++) {
              items.push(modelCollection[i].materialNumber);
          }
          setOptions(items);
        }

        if(items.length === 1){
          setModelNumber(r.data.modelList[0].materialNumber);
          setLoading(false);
          navigateMachineDetail();
        }

        if(r.data.status === "Failure"){
          setLoading(false);
          setSearchError(r.data.message);
        }
        setLoading(false);

      }).catch((err) => {
        if (axios.isCancel(err)) {
          // console.log('Request canceled ' + err.message);
        } else {
          // console.log(err);
          setLoading(false);
          setSearchError(err);
        }

      });

  }

  // We only need if we have a submit button
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  function navigateMachineDetail(passedMN = null){
    let tempModel = modelNumber;
    if(passedMN){
        console.log("NAVIGATE MACHINE DETAIL HIT WITH: " + passedMN);
        tempModel = passedMN;
    }

    console.log('SN: ' + serialNumber + ' MN: ' + tempModel + ' PIN: ' + pinNumber);
    checkValid(serialNumber, tempModel, pinNumber);

    if(pinNumber){
      if(imei){
        // Go to imei process
        var url = "/support/machine/imei/pin-" + pinNumber;
        console.log(url);
        history.push(url);
      }else{
        // Go to machine detail
        var url = "/support/machine/details/pin-" + pinNumber;
        console.log(url);
        history.push(url);
      }
    }else if(serialNumber && tempModel){
      if(imei){
        // Go to imei process
        var url = "/support/machine/imei/sn-" + serialNumber + "/mn-" + tempModel;
        console.log(url);
        history.push(url);
      }else{
        // Go to machine detail
        var url = "/support/machine/details/sn-" + serialNumber + "/mn-" + tempModel;
        console.log(url);
        history.push(url);
      }
    }else{
      setSearchError('Machine Not Found');
    }
  }

  function handleSearchByCustomerPress() {
    if(searchMode == 'ModelSerial') {
      setSearchMode('Customer');
    } else {
      setSearchMode('ModelSerial');
    }
  }

  function attemptCustomerSearch(){
    let isThereAtleastOneFieldFilled = (customerSearchValues.FirstName || customerSearchValues.LastName || customerSearchValues.City || customerSearchValues.State);
    if(!isThereAtleastOneFieldFilled) {
      setSearchError('You must fill atleast one field');
      return;
    } else {
      //If either First or Last name are present make sure both are filled in
      if (customerSearchValues.FirstName && !(customerSearchValues.LastName || customerSearchValues.City || customerSearchValues.State)) {
        setSearchError("Searching by First Name alone is not supported. Please add another field.")
        return;
      }
      
      debugger;
      if (customerSearchValues.State && !(customerSearchValues.FirstName || customerSearchValues.LastName || customerSearchValues.City)) {
        setSearchError("Searching only by State is not supported at this time. Please add another field.");
        return;
      }
      setSearchError('');
    }
    setLoading(true);
    let queryString = "SELECT Product_Identification_No__c, End_Customer_Name__c, WOD_2__Serial_Number__c, Servicing_Dealer_Name__c, Material_Name1__c, Billing_Dealer_City__c, Billing_Dealer_State__c, Factory_Item_Description__c from WOD_2__Inventory__c WHERE Business_Units_Text__c = 'KTC-USA' AND "
    let firstLast = "";
    if(customerSearchValues.FirstName) {
      firstLast += customerSearchValues.FirstName;
    }
    if(customerSearchValues.LastName) {
      if(firstLast) {
        firstLast += " " + customerSearchValues.LastName;
      } else {
        firstLast = customerSearchValues.LastName;
      }
    }
    if(firstLast) {
      queryString += "End_Customer_Name__c like '%" + firstLast + "%' AND ";
    }

    if(customerSearchValues.City){
      queryString += "End_Customer_City__c='" + customerSearchValues.City + "' AND ";
    }
    if(customerSearchValues.State){
      queryString += "End_Customer_State__c='" + customerSearchValues.State + "' AND ";
    }
    queryString = queryString.substring(0, queryString.length -5);
    queryString += " LIMIT 500";
    queryString = encodeURIComponent(queryString);
    let baseURL = process.env.REACT_APP_API + 'salesforce/kwarranty/Get?param=query/?q=';
    // let baseURL = "https://kubota--uat.sandbox.my.salesforce.com/services/data/v56.0/query/?q=";
    let customerQueryReq = {
      url: baseURL + queryString,
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + Cookies.get("token"),
      }
    }
    axios(customerQueryReq)
      .then((response) => {
        let results = response.data.records;
        setCustomerResults(response.data.records);
        setLoading(false);
        history.push({
          pathname: "/support/machineResults",
          state: {
            results: response.data.records,
            iconMap: iconMap
          }
        })
      })
      .catch((error) => {
        setLoading(false);
        debugger;
        if(error.response.status == 400) {
          setSearchError("Search encountered an error. Please modify your search and try again.")
        } else {
          setSearchError("Search encountered an error.")
        }
        console.log(error);
      });
  }

  function fetchIconMapFromFirebase(){
    const remoteConfig = getRemoteConfig(app);
    fetchAndActivate(remoteConfig)
    .then(() => {
        var iconMap = JSON.parse(getValue(remoteConfig, "StockIconMap")._value);
        var explodedIconMap = explodeIconMap(iconMap);
        setIconMap(explodedIconMap);
    })
    .catch((err) => {
        console.log(err);
    });
  }

  function fetchKwarrantyToken() {
    var qs = require('qs');
    var tokenReq = {
      url: 'https://kubota--uat.sandbox.my.salesforce.com/services/oauth2/token',
      method: 'POST',
      data: qs.stringify({
        'grant_type': 'password',
        'client_id': '3MVG967gVD5fuTmL1omVvIWrWPkJ9ZS3C9NtBTS2DjLdJfPP79u6U9o6L0xPA0pSbmH0wgh5CX4mZ_jlYLy4E',
        'client_secret': '25904466DA5D7EA49EA0F94281E7B9E55EF9642D01CE25EBBF92D40C8C4AC473',
        'username': 'integration.user@kubota.com.integration.ktc.uat',
        'password': 'Tavant12345!' 
      }),
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded', 
      },
    }
    axios(tokenReq)
      .then((response) => {
        setKwarrantyToken(response.data.access_token);
      })
      .catch((error) => {
        console.log(error);
        setSearchError('There was a problem authenticating with the inventory system');
      })
  }

  return (
    <React.Fragment>
      <CssBaseline />
      {scanMode &&
      <React.Fragment>
      <div className={classes.flexbox}>
        <Fab variant="extended" color="primary" aria-label="add" className={classes.fab} onClick={closeQRScanner}>
          <CloseIcon />
          Close Scanner
        </Fab>
      </div>
      <div className={classes.horizFlex}>
        <div id="qrcodemountnode"></div>
      </div>
      </React.Fragment>
      }
      {!scanMode &&
      <Container className={classes.heroContent}>
      <Grid container
        alignItems="center"
        justifyContent="center">
        <Grid item md={4} xs={12}>
          <Paper elevation={3} className={classes.heroPaper} justify="center">
            <Typography component="h1" variant="h5">
              Machine Search
            </Typography>
            <Divider />
            { searchMode == 'ModelSerial' &&
              <React.Fragment>
                <form className={classes.form} noValidate onSubmit={e => { e.preventDefault(); }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="serialNumber"
                    label="Serial Number"
                    name="serialNumber"
                    value={serialNumber}
                    onChange={e => setSerialNumber(e.target.value)}
                    autoFocus
                  />
                  <Autocomplete
                    freeSolo
                    value={modelNumber}
                    onChange={(event, newValue) => {
                      setModelNumber(newValue);
                      //console.log("ON CHANGE HIT");
                      if(options.indexOf(newValue) != -1){
                        setLoading(false);
                        navigateMachineDetail(newValue)
                      }
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      // console.log('newInputValue');
                      // console.log(newInputValue);
                      setInputValue(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={options}
                    loading={loading}
                    renderInput={(params) => <TextField {...params} label="Model Number" variant="outlined" margin="normal" required fullWidth InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}/>}
                  />
                </form>
                {searchError &&
                  <Alert severity="error">{searchError}</Alert>
                }
              </React.Fragment>
            }
            { searchMode == 'Customer' &&
              <>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="FirstName"
                    label="First Name"
                    name="FirstName"
                    value={customerSearchValues.FirstName}
                    onChange={(e) => {
                      setCustomerSearchValues({
                        ...customerSearchValues,
                        [e.currentTarget.name]: e.currentTarget.value
                      })
                    }}
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="LastName"
                    label="Last Name"
                    name="LastName"
                    value={customerSearchValues.LastName}
                    onChange={(e) => {
                      setCustomerSearchValues({
                        ...customerSearchValues,
                        [e.currentTarget.name]: e.currentTarget.value
                      })
                    }}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="City"
                    label="City"
                    name="City"
                    value={customerSearchValues.City}
                    onChange={(e) => {
                      setCustomerSearchValues({
                        ...customerSearchValues,
                        [e.currentTarget.name]: e.currentTarget.value
                      })
                    }}
                  />
                  <FormControl fullWidth variant="outlined" style={{marginTop: '16px'}}>
                    <InputLabel id="stateSelectLabel">State</InputLabel>
                    <Select 
                      labelId="stateSelectLabel"
                      name={'State'} 
                      id="State" 
                      value={customerSearchValues.State} 
                      onChange={(e) => {
                        setCustomerSearchValues({
                          ...customerSearchValues,
                          [e.target.name]: e.target.value
                        });
                      }}
                    >
                      <MenuItem value="AL">Alabama</MenuItem>
                      <MenuItem value="AK">Alaska</MenuItem>
                      <MenuItem value="AZ">Arizona</MenuItem>
                      <MenuItem value="AR">Arkansas</MenuItem>
                      <MenuItem value="CA">California</MenuItem>
                      <MenuItem value="CO">Colorado</MenuItem>
                      <MenuItem value="CT">Connecticut</MenuItem>
                      <MenuItem value="DE">Delaware</MenuItem>
                      <MenuItem value="DC">District Of Columbia</MenuItem>
                      <MenuItem value="FL">Florida</MenuItem>
                      <MenuItem value="GA">Georgia</MenuItem>
                      <MenuItem value="HI">Hawaii</MenuItem>
                      <MenuItem value="ID">Idaho</MenuItem>
                      <MenuItem value="IL">Illinois</MenuItem>
                      <MenuItem value="IN">Indiana</MenuItem>
                      <MenuItem value="IA">Iowa</MenuItem>
                      <MenuItem value="KS">Kansas</MenuItem>
                      <MenuItem value="KY">Kentucky</MenuItem>
                      <MenuItem value="LA">Louisiana</MenuItem>
                      <MenuItem value="ME">Maine</MenuItem>
                      <MenuItem value="MD">Maryland</MenuItem>
                      <MenuItem value="MA">Massachusetts</MenuItem>
                      <MenuItem value="MI">Michigan</MenuItem>
                      <MenuItem value="MN">Minnesota</MenuItem>
                      <MenuItem value="MS">Mississippi</MenuItem>
                      <MenuItem value="MO">Missouri</MenuItem>
                      <MenuItem value="MT">Montana</MenuItem>
                      <MenuItem value="NE">Nebraska</MenuItem>
                      <MenuItem value="NV">Nevada</MenuItem>
                      <MenuItem value="NH">New Hampshire</MenuItem>
                      <MenuItem value="NJ">New Jersey</MenuItem>
                      <MenuItem value="NM">New Mexico</MenuItem>
                      <MenuItem value="NY">New York</MenuItem>
                      <MenuItem value="NC">North Carolina</MenuItem>
                      <MenuItem value="ND">North Dakota</MenuItem>
                      <MenuItem value="OH">Ohio</MenuItem>
                      <MenuItem value="OK">Oklahoma</MenuItem>
                      <MenuItem value="OR">Oregon</MenuItem>
                      <MenuItem value="PA">Pennsylvania</MenuItem>
                      <MenuItem value="RI">Rhode Island</MenuItem>
                      <MenuItem value="SC">South Carolina</MenuItem>
                      <MenuItem value="SD">South Dakota</MenuItem>
                      <MenuItem value="TN">Tennessee</MenuItem>
                      <MenuItem value="TX">Texas</MenuItem>
                      <MenuItem value="UT">Utah</MenuItem>
                      <MenuItem value="VT">Vermont</MenuItem>
                      <MenuItem value="VA">Virginia</MenuItem>
                      <MenuItem value="WA">Washington</MenuItem>
                      <MenuItem value="WV">West Virginia</MenuItem>
                      <MenuItem value="WI">Wisconsin</MenuItem>
                      <MenuItem value="WY">Wyoming</MenuItem>
                    </Select>
                  </FormControl>
              </>
            }
            
            {/* IF WE WANT TEXT IN DIVIDER, NEED TO MIGRATE MUI V4 -> V5 */}
            {!imei &&
              <React.Fragment>
                <Divider style={{marginTop: '16px'}}/>
                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop:'4px', marginBottom: '8px'}}>
                  OR
                </div>
                <div className={classes.center}>
                  <Button 
                    variant="outlined"
                    size="large"
                    fullWidth
                    className={classes.button}
                    startIcon={<PersonOutlineIcon color="primary"/>}
                    onClick={handleSearchByCustomerPress}
                    >
                     { (searchMode != 'Customer' ? 'Machine Search by Customer' : "Search by Model and Serial")}
                  </Button>
                  <Button
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={openQRScanner}
                    className={classes.button}
                    startIcon={<CropFreeIcon color="primary"/>}
                  >
                    Scan Machine Serial Number
                  </Button>
                  { searchMode == 'Customer' &&
                    <Button 
                      variant="contained"
                      color='primary'
                      size="large"
                      fullWidth
                      className={classes.button}
                      onClick={attemptCustomerSearch}
                    >
                     Search
                  </Button>
                  }
                </div>
                { searchError &&
                  <Alert severity="error">{searchError}</Alert>
                }
                { loading &&
                  <div className={classes.center}>
                    <CircularProgress className={classes.loadingCenterInside} />
                   </div>
                }
              </React.Fragment>
            }
          </Paper>
          {imei && 
            <Button 
              fullWidth 
              variant="contained" 
              color="primary" 
              size="large" 
              className={classes.submit} 
              onClick={() => {
                history.push('/knowledge/answer?uniqueID=42.42572$http://www.salesforce.com/org:organization/articletype:Knowledge/article:kA08b000000lIfKCAU/language:en_US')
              }}
              >
                Installation Guides
            </Button>   
          }
        </Grid>
        </Grid>
      </Container>
      }
    </React.Fragment>
  );
}
