import React from 'react';
import { BrowserRouter, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import HelpIcon from '@material-ui/icons/Help';
import EmailIcon from '@material-ui/icons/Email';
import SettingsIcon from '@material-ui/icons/Settings';
import Badge from '@material-ui/core/Badge';
import { useHistory } from "react-router-dom";
import { useState, useEffect } from 'react';

const useStyles = makeStyles({
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: 'rgba(0, 0, 0, 0.12)',
    },
    stickToBottomGap: {
        width: '100%',
        height: 56
    }
});

function BottomNav() {
    const classes = useStyles();
    const history = useHistory();
    const [value, setValue] = useState('recents');

    useEffect(() => {
        // Runs after the first render() lifecycle and set index.
        let path = window.location.pathname;
        path = path.split('/');
        setValue('/' + path[1]);
    }, []);



    const handleChange = (event, newValue) => {
        setValue(newValue);
        console.log(newValue);
        history.push(newValue);
    };

    return (
        <React.Fragment>
            <div className={classes.stickToBottomGap}></div>
            <BrowserRouter>
                <BottomNavigation
                    value={value}
                    onChange={handleChange}
                    showLabels
                    className={classes.stickToBottom}
                >
                    <BottomNavigationAction label="Home" value="/home" icon={<HomeIcon />} />
                    <BottomNavigationAction label="Knowledge" value="/knowledge" icon={<LibraryBooksIcon />} />
                    <BottomNavigationAction label="Support" value="/support" icon={<HelpIcon />} />
                    <BottomNavigationAction label="Messages" value="/messages" icon={<EmailIcon />} />
                    <BottomNavigationAction label="Settings" value="/settings" icon={<SettingsIcon />} />
                </BottomNavigation>
            </BrowserRouter>
        </React.Fragment>
    );
}

export default BottomNav;
