import Fuse from 'fuse.js'

const fuseOptions = {
	// isCaseSensitive: false,
	includeScore: true,
	// shouldSort: true,
	// includeMatches: false,
	// findAllMatches: false,
	// minMatchCharLength: 1,
	location: 0,
	threshold: 0.4,
	// distance: 100,
	// useExtendedSearch: false,
	// ignoreLocation: false,
	// ignoreFieldNorm: false,
	// fieldNormWeight: 1,
	keys: [
		"machine",
	]
};

export default function iconSearch(jsonList, keyword) {
    keyword = trimKeyword(keyword);
    // var test = searchAlg2(jsonList,keyword);
    return searchAlg1(jsonList,keyword);
}

function searchAlg1(jsonList, keyword) {
    const fuse = new Fuse(jsonList, fuseOptions);
    var results = fuse.search(keyword);
    if(results.length == 0) {
        //Is there a 1 character match? Ex: L56, could match to the L Series
        var retryResults = fuse.search(keyword[0]);
        if(retryResults.length > 0 && retryResults[0].item.machine.length == 1){
            return retryResults[0].item.icon;
        }
        return false;
    } else {
        var topResult = results[0].item.icon;
        return topResult;
    }
}
function trimKeyword(keyword) {
    //Remove any leading non A-Z chars
    let alphaNumberRegex = /[a-zA-Z0-9-_]+/;
    keyword = keyword.match(alphaNumberRegex)[0];
    return keyword;
    //
}

function searchAlg2(jsonList, keyword) {
    keyword = 'SVL';
    var results = jsonList.filter((item) => {
        return item.machine.toLowerCase().includes(keyword);
    })
    debugger;
    if(results.length < 1) {
        return 'default';
    }
    return results[0];
}
