import React from 'react';
import TextField from '@material-ui/core/TextField';

function SFTextArea({component, currentVal, onFieldValueUpdate, itemLabel, i, ErrorStates, classes, reqFields}) {

  return(
    <TextField
      className={classes.outlineInput}
      required={(reqFields.get(component.fieldInfo.apiName)) ? true : false}
       variant="outlined"
       id={component.label}
       name={component.label}
       label={component.label}
       fullWidth
       multiline
       autoComplete={component.label}
       onChange={(event) => onFieldValueUpdate(component.field, event.target.value, event)}
       defaultValue={currentVal}
       key={'componentInput' + itemLabel + ',' + i}
       data-isnull={component.isNull}
       data-datatype={component.fieldInfo.dataType}
       onBlur={(e) => {}}
      error={ErrorStates[component.field]}

   />
 );
  
  // return (
  //   <textarea 
  //   className="fieldEdit"
  //   name={component.field}
  //   value={currentVal}
  //   onChange={(event) => onFieldValueUpdate(component.field, event.target.value, event)}
  //   key={'componentInput' + itemLabel + ',' + i}
  //   data-isnull={component.isNull}
  //   data-datatype={component.fieldInfo.dataType}
  //   rows="5" 
  //   cols="33">
  //   </textarea>
  // );
}

export default SFTextArea