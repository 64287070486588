
  import { initializeApp } from "firebase/app";
  import { getAnalytics, logEvent } from "firebase/analytics";

  //Staging
  //const firebaseConfig = {
  //  apiKey: "AIzaSyDYHjkWWfQNENqnhp1WlEs_fy6qAtROSxE",
  //  authDomain: "k-service-staging.firebaseapp.com",
  //  projectId: "k-service-staging",
  //  storageBucket: "k-service-staging.appspot.com",
  //  messagingSenderId: "814001964983",
  //  appId: "1:814001964983:web:1739ef7c5110b3233b4a32",
  //  measurementId: "G-S19HXZ3X4C"
  //};
  
  //PROD 
  const firebaseConfig = {
   apiKey: "AIzaSyAAZDYg-v6rVzOve96PLPt1MZr26i1hpUY",
   authDomain: "k-service-3e1f9.firebaseapp.com",
   databaseURL: "https://k-service-3e1f9.firebaseio.com",
   projectId: "k-service-3e1f9",
   storageBucket: "k-service-3e1f9.appspot.com",
   messagingSenderId: "58641133705",
   appId: "1:58641133705:web:4d8bde28b85ac185b35658",
   measurementId: "G-R366SDKVKR"
  };

  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  export {
    app
  }
