import React, { Component, useState, useEffect } from 'react'
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ClearIcon from '@material-ui/icons/Clear';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';



const useStyles = makeStyles((theme) => ({
  halfWidth: {
      width: "100%"
  },
  buttonCluster: {
    marginTop: "15px",
  },
  error : {
    marginBottom: "10px"
  }
}));


function ContactCapturePage({setStep, setPreFillAccountContact, setErrorBanner, accountID, setAccountID, contactID, setContactID}) {
    const classes = useStyles()
    const [loading, setLoading] = useState(false);
    var ongoingRecentsSearch = null;
    var raceConditionFlag = false;

    //Value in the autocomplete field
    const [accountSearch, setAccountSearch] = useState('');
    //Options from the search, populate drop down
    const [accounts, setAccounts] = useState([]);
    //Selected Account
    // const [accountID, setAccountID] = useState();
    //Error State
    const [accountFieldError, setAccountFieldError] = useState(false);

    //Value in the autocomplete field
    const [contactSearch, setContactSearch] = useState('');
    //Options from the search, populate drop down
    const [contacts, setContacts] = useState([]);
    //Selected Contact
    // const [contactID, setContactID] = useState();
    //Error State
    const [contactFieldError, setContactFieldError] = useState(false);

    //New Contact Info
    const [showModal, setShowModal] = useState(false);
    const [newContact, setNewContact] = useState({
        "FirstName" : {
            "value" : "",
            "error" : false,
        },
        "LastName" : {
            "value" : "",
            "error" : false,
        },
        "Email" : {
            "value" : "",
            "error" : false,
        },
        "Phone" : {
            "value" : "",
            "error" : false,
        }
    });




    useEffect(() => {
        //On component load

        //Check if we already have both(navigated back from other pages)
        if(accountID && contactID) {
            setAccountSearch(accountID.name);
            //Need to delay ContactSearch set by just a bit, so the AccountSearch set doesn't wipe the Contact value.
            setTimeout(() => {
                setContactSearch(contactID.name);
            }, 50);
            
        }
        fetchRecents("AccountId");
    }, []);

    useEffect(() => {
        if(accountID) {
            fetchRecents("ContactId");
        }
    }, [accountID]);
    useEffect(() => {
        //Whenever Value inside Account lookup changes, we can refetch search
        if(accountSearch) {
            if(contactSearch) { //check for search here(set on mount), if you check for ContactID(from parent), it will be always present and you will wipe anything already set.
                setContactSearch("");
                setContactID("");
            }
            if(accountSearch.length >= 3){
                fetchValues("AccountId", accountSearch);
            }
            else{
                if(!ongoingRecentsSearch) {
                    ongoingRecentsSearch = true;
                    fetchRecents("AccountId", accountSearch);
                } else {console.log("Blocked a recent search");}
            }
        }
    }, [accountSearch]);

    useEffect(() => {
        //Whenever Value inside Account lookup changes, we can refetch search
        if(contactSearch) {
            if(contactSearch.length >= 3){
                fetchValues("ContactId", contactSearch);
            }
            else {
                if(!ongoingRecentsSearch) {
                    ongoingRecentsSearch = true;
                    fetchRecents("ContactId", contactSearch);                    
                }
            }
        }
    }, [contactSearch]);





    const fetchRecents = (apiName) => {
        ongoingRecentsSearch = true;
        console.log("Starting --- Recents");

        //Fetch values for the referenceTo object, and put into array, then set state
        var hasFilter = false;
        if(apiName == 'ContactId'){
            hasFilter = true;
        }
        var referenceTo = (apiName == "AccountId" ? "Account" : "Contact");
        var url = process.env.REACT_APP_API + 'salesforce/kservice/Get?param=ui-api/lookups/Case/' + apiName;
        
        if (hasFilter && accountID){
            url += '%3FdependentFieldBindings=AccountId='+accountID.id;
        } 
        var request = {
            url : url,
            method : 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get("token"),
                'X-SalesForce-Token' : Cookies.get('sf_cookie')
            }
        }
        axios(request).then((response) => {
            //Check if there is a pending keyword search. Fast typers will break the 3 character threshold right after starting a few recent searches sometimes causing the recent request to finish AFTER the keyword search, overwiting with an empty list.
            console.log("Done --- Recents");
                var records = response.data.lookupResults[referenceTo];
                if(records && records.count > 0)
                {
                    //Add records to select list
                    var jsonArr = [];
                    records.records.forEach((record) => {
                    //Some objects dont use normal name field. Uses Name__c field.          
                    var nameLabel;
                    if(!record.fields.hasOwnProperty("Name"))
                    {
                        if(record.fields.hasOwnProperty("Name__c")) {
                        nameLabel = record.fields.Name__c.value;
                        }
                    }
                    else {
                        nameLabel = record.fields.Name.value;
                    }
                    
                    jsonArr.push({name: nameLabel, id: record.fields.Id.value})
                    
                    });
                    if(!raceConditionFlag) { 
                        if(apiName == "AccountId")
                            setAccounts(jsonArr);
                        else
                            setContacts(jsonArr);
                    }
                }
                else
                {
                    if(!raceConditionFlag) {
                        if(apiName == "AccountId")
                            setAccounts([]);
                        else
                            setContacts([]);
                    }
                }
                //reset flags
                ongoingRecentsSearch = false;
                raceConditionFlag = false;
        }).catch((error) => {
            if(apiName == "AccountId")
                setAccounts([]);
            else
                setContacts([]);
            console.log(error);
        });
        
    };

    const fetchValues = (apiName, q = false) => {
        console.log("Starting --- Values");

        if(ongoingRecentsSearch) {
            raceConditionFlag = true;
        }
        console.log('Fetching Values for input: ' + q);
        if(!q) q = "";
        //Fetch values for the referenceTo object, and put into array, then set state
        // var url = 'https://kubotaservice--ksdev.sandbox.my.salesforce.com/services/data/v54.0/ui-api/lookups/Case/' + apiName + "?searchType=TypeAhead&q=" + q ;//+ component.fieldInfo.apiName;
        var url = process.env.REACT_APP_API + 'salesforce/kservice/Get?param=ui-api/lookups/Case/' + apiName + "?searchType=TypeAhead%26q=" + q;
        var hasFilter = false;
        var controllingField;
        var fieldId;
        var referenceTo = (apiName == "AccountId" ? "Account" : "Contact");

        if(apiName == "ContactId"){
            url += '%26dependentFieldBindings=AccountId'+'='+accountID.id;
        }

        console.log("New url is: " + url);
        var request = {
            url : url,
            method : 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get("token"),
                'X-SalesForce-Token' : Cookies.get('sf_cookie'),
                'X-Id-Token' : Cookies.get('idToken')
            }
        }
        axios(request).then((response) => {
            console.log("Done --- Values");

            var records = response.data.lookupResults[referenceTo];
            if(records && records.count > 0)
            {
                var jsonArr = []
                records.records.forEach((record) => {jsonArr.push({name: record.fields.Name.value, id: record.fields.Id.value})});
                if(apiName == "AccountId")
                    setAccounts(jsonArr);
                else
                    setContacts(jsonArr);
                console.log("Setting current values as: ");
                console.log(jsonArr);
            }
            else
            {
                if(apiName == "AccountId")
                    setAccounts([]);
                else
                    setContacts([]);
            }
            
        }).catch((error) => {
            if(apiName == "AccountId")
                setAccounts([]);
            else
                setContacts([]);
            console.log(error);
            
        });
    };

    const handleNext = () => {
        performDataValidation()
            .then( () => {
                if(showModal) {
                    submitNewContact()
                        .then((resp) => {
                            setStep(4);
                        })
                        .catch(() => {
                            setErrorBanner("We were unable to create your contact");
                            // setLoading(false);
                        })
                }
                else {
                    //Set Prefill Fields          
                    setPreFillAccountContact({
                        "AccountId" : {
                            "name" : accountID.name,
                            "id" : accountID.id,
                        },
                        "ContactId" : {
                            "name" : contactID.name,
                            "id" : contactID.id,
                        }
                    })
                    setStep(4);
                }
            })
            .catch(() => {
                //Show "Please fix fields and try again"
                // window.alert("There was a problem with")
            })
    };
    const performDataValidation = () => {
        return new Promise((resolve, reject) => {
            //Check AccountID + ConactID, if Modal is open for new contact check those 4 fields
             if(!accountID || !contactID) {
                setAccountFieldError(!accountID);
                setContactFieldError(!contactID);
                reject();
                return;
            }
            if(!showModal) {
                //Only needed to check Account + Contact, we can resolve
                resolve();
                return;
            } else {
                //Need to check all new contact fields
                let preventSubmit = false;
                let tempContactObj = {
                    ...newContact
                };
                if(!newContact.FirstName.value || !newContact.LastName.value || !newContact.Phone.value || !newContact.Email.value) {
                    if(!newContact.FirstName.value) {
                        tempContactObj = {
                            ...tempContactObj,
                            "FirstName": {
                                ...tempContactObj.FirstName,
                                "error": true
                            }
                        };
                    }
                    if(!newContact.LastName.value) {
                        tempContactObj = {
                            ...tempContactObj,
                            "LastName": {
                                ...tempContactObj.LastName,
                                "error": true
                            }
                        };
                    }
                    if(!newContact.Phone.value) {
                        tempContactObj = {
                            ...tempContactObj,
                            "Phone": {
                                ...tempContactObj.Phone,
                                "error": "This field is required!"
                            }
                        }
                    }
                    if(!newContact.Email.value) {
                        tempContactObj = {
                            ...tempContactObj,
                            "Email": {
                                ...tempContactObj.Email,
                                "error": "This field is required!"
                            }
                        }
                    }
                    preventSubmit = true;
                } 
                if(newContact.Email.value) {
                    let isGoodFormat = checkFormat("Email", newContact.Email.value);
                    tempContactObj = {
                        ...tempContactObj,
                        "Email" : {
                            ...tempContactObj.Email,
                            "error" : isGoodFormat ? "" : "Must be in a valid email format"
                        }
                    };
                    if(!isGoodFormat)
                        preventSubmit = true;
                }

                if(newContact.Phone.value) {
                    let isGoodFormat = checkFormat("Phone", newContact.Phone.value);
                    tempContactObj = {
                        ...tempContactObj,
                        "Phone" : {
                            ...tempContactObj.Phone,
                            "error" : isGoodFormat ? "" : "Must be in a valid phone format"
                        }
                    };
                    if(!isGoodFormat)
                        preventSubmit = true;
                }
                setNewContact(tempContactObj);    
                if(!preventSubmit){
                    resolve();
                    return;
                }
            }
        });
    }
    const handlePrevious = () => {
        setStep(3);
    };

    const submitNewContact = () => {
        // setLoading(true);
        
        var createPayload = {
            "url" : process.env.REACT_APP_API + "salesforce/kservice/sa/Post?param=ui-api%2Frecords",
            "method" : "POST",
            headers : { 
                'Authorization': 'Bearer ' + Cookies.get("token"),//Middleware token
                'X-SalesForce-Token' : Cookies.get('sf_cookie'),//SF token for Middleware
                'X-Id-Token' : Cookies.get('idToken')
            },
            "data" : {
                "apiName": "Contact",
                "fields": {
                    "AccountId": accountID.id,
                    "FirstName": newContact.FirstName.value,
                    "LastName": newContact.LastName.value,
                    "Email": newContact.Email.value,
                    "Phone": newContact.Phone.value,
                    "Type__c": "Dealer Contact"
                }
            }
        }
        return new Promise((resolve, reject) => { 
            axios(createPayload)
            .then((response) => {
                setPreFillAccountContact({
                    "AccountId" : {
                        "name" : accountID.name,
                        "id" : accountID.id,
                    },
                    "ContactId" : {
                        "name" : response.data.fields.FirstName.value + ' ' + response.data.fields.LastName.value,
                        "id" : response.data.id,
                    }
                })
                resolve();
            }).catch((err) => {
                console.log(err);
                reject();
            });
        })
    }

    const handleClear = () => {
        //Reset fields
        setNewContact({
            "FirstName" : {
                "value" : "",
                "error" : false,
            },
            "LastName" : {
                "value" : "",
                "error" : false,
            },
            "Email" : {
                "value" : "",
                "error" : false,
            },
            "Phone" : {
                "value" : "",
                "error" : false,
            }
        });
        //Close modal
        setShowModal(false);
        //Clear Contact
        setContactID(" ");
    }

    const checkFormat = (field, fieldValue) => {
        //Regex Formats
        const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const phoneFormat = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        if(field === "Phone") {
            return phoneFormat.test(fieldValue);
        }
        else if (field === "Email") {
            return emailFormat.test(fieldValue);
        }
        return false;
    }


    return (
        <>
            
            { loading &&
                <Grid container>
                    <Grid item md={12} xs={12} align="center">
                        <CircularProgress className={classes.loadingCenterInside} />
                    </Grid>
                </Grid>
            }
            { !loading &&
            <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Who is this Report for?
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <FormControl className={classes.halfWidth}>
                                <Autocomplete
                                        onChange={(e, v) => { 
                                            setAccountID(v);
                                            if(accountFieldError) {
                                                setAccountFieldError(false);
                                            }
                                        }}
                                        name="AccountID"
                                        id="AccountID"
                                        disabled={showModal}
                                        inputValue={accountSearch}
                                        options={accounts}
                                        getOptionLabel={option => option.name}
                                        renderInput={
                                            (params) => {
                                                if(params.inputProps.value && typeof params.inputProps.value == "object") {
                                                    params.inputProps.value = params.inputProps.value.name;
                                                }
                                                return(
                                                    <TextField {...params} 
                                                        variant="outlined" 
                                                        error={accountFieldError} 
                                                        label="Account" 
                                                        helperText={accountFieldError ? "This field is required!" : ""}
                                                    />
                                                    )
                                                }
                                        }
                                        onInputChange={(event, newInputValue) => {
                                            setAccountSearch(newInputValue);
                                        }}
                                    />
                            </FormControl>  
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <FormControl className={classes.halfWidth}>
                                <Autocomplete
                                        name="ContactID"
                                        id="ContactID"
                                        disabled={(accountID && !showModal) ? false : true}
                                        inputValue={contactSearch}
                                        options={contacts}
                                        getOptionLabel={option => {
                                            
                                            if(typeof option === "string") {
                                                return option
                                            } 
                                            else{
                                                return option.name
                                            }
                                        }}
                                        filterOptions={(options, params) => {
                                            if(options.length < 1   )
                                                return [{"name" : "Create New Contact?", "value" : null}];
                                            else
                                                return options;
                                        }}
                                        renderInput={
                                            (params) => {
                                                if(params.inputProps.value && typeof params.inputProps.value == "object") {
                                                    params.inputProps.value = params.inputProps.value.name;
                                                }
                                                return (
                                                    <TextField {...params} 
                                                        variant= {(accountID? "outlined" : "filled")} 
                                                        error={contactFieldError}
                                                        label="Contact"
                                                        helperText={contactFieldError ? "This field is required!" : ""} 
                                                    />
                                                );
                                            }
                                        }
                                        
                                        onInputChange={(event, newInputValue) => {
                                            setContactSearch(newInputValue);
                                        }}
                                        onChange={(e, v) => {
                                            if(v && v.name === "Create New Contact?") {
                                                setShowModal(true);
                                                setContactID(v);
                                            }
                                            else 
                                                setContactID(v);
                                            if(contactFieldError) {
                                                setContactFieldError(false);
                                            }
                                        }}
                                    />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    { showModal &&
                        <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                variant="outlined"
                                id={"NewContactFirstName"}
                                name={"NewContactFirstName"}
                                label={"First Name"}
                                defaultValue={newContact.FirstName.value}
                                error={newContact.FirstName.error}
                                helperText={newContact.FirstName.error ? "This field is required!" : ""}
                                fullWidth
                                onChange={(e) => {
                                    setNewContact({
                                        ...newContact,
                                        "FirstName" : { "value" : e.currentTarget.value, "error" : false}
                                    })
                                }}
                                key={'NewContactFirstName'}                    
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                variant="outlined"
                                id={"NewContactLastName"}
                                name={"NewContactLastName"}
                                label={"Last Name"}
                                defaultValue={newContact.LastName.value}
                                error={newContact.LastName.error}
                                helperText={newContact.LastName.error ? "This field is required!" : ""}
                                fullWidth
                                onChange={(e) => {
                                    setNewContact({
                                        ...newContact,
                                        "LastName" : { "value" : e.currentTarget.value, "error" : false}
                                    })
                                }}
                                key={'NewContactLastName'}
                                onBlur={(e) => {}}
                            
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                variant="outlined"
                                id={"NewContactPhone"}
                                name={"NewContactPhone"}
                                label={"Phone Number"}
                                defaultValue={newContact.Phone.value}
                                error={newContact.Phone.error}
                                helperText={newContact.Phone.error ? newContact.Phone.error : ""}
                                fullWidth
                                onChange={(e) => {
                                    setNewContact({
                                        ...newContact,
                                        "Phone" : { "value" : e.currentTarget.value, "error" : false}
                                    })
                                }}
                                key={'NewContactPhone'}
                                onBlur={(e) => {}}
                            
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                variant="outlined"
                                id={"NewContactEmail"}
                                name={"NewContactEmail"}
                                label={"Email"}
                                defaultValue={newContact.Email.value}
                                error={newContact.Email.error}
                                helperText={newContact.Email.error ? newContact.Email.error : ""}
                                fullWidth
                                onChange={(e) => {
                                    setNewContact({
                                        ...newContact,
                                        "Email" : { "value" : e.currentTarget.value, "error" : false}
                                    })
                                }}
                                key={'NewContactEmail'}
                                onBlur={(e) => {}}
                            />
                        </Grid>
                    </Grid>
                    }                    
                </Grid>

                <Grid item md={12} xs={12} className={classes.buttonCluster}>
                    <Grid container direction={"row-reverse"} spacing={2} >
                        <Grid item>
                            <Button
                                disabled={false}
                                variant="outlined"
                                color="primary"
                                onClick={handleNext}
                                endIcon={<ChevronRightIcon/>}
                            >
                                Next
                            </Button>
                        </Grid>
                        <Grid item> 
                            <Button
                                disabled={false}
                                variant="outlined"
                                color="primary"
                                onClick={handlePrevious}
                                startIcon={<ChevronLeftIcon/>}
                            >
                                Previous
                            </Button>
                        </Grid>
                        { showModal &&
                            <Grid item> 
                                <Button
                                    disabled={false}
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleClear}
                                    startIcon={<ClearIcon/>}
                                >
                                    Clear Contact
                                </Button>
                            </Grid>
                        }
                        
                    </Grid>
                </Grid>
            </Grid> 
            }
        </>
    )
}

export default ContactCapturePage