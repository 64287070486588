import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';


function SFText({component, currentVal, onFieldValueUpdate, itemLabel, i, ErrorStates, ReadOnly, ReadOnlyDataType, editValues, classes, reqFields}) {
  //Checking for a read-only label for reference data types
  if(ReadOnly && ReadOnlyDataType === "Reference") {
    if(editValues[component.field].hasOwnProperty('label')) {
      currentVal = editValues[component.field].label;
    }
  }


  return(
    <>
      <TextField
          className={!ReadOnly ? classes.outlineInput : classes.readOnly}
          required={(reqFields.get(component.fieldInfo.apiName)) ? true : false}
          disabled={ReadOnly}
          variant="outlined"
          id={component.label}
          name={component.label}
          label={component.label}
          fullWidth
          autoComplete={component.label}
          onChange={(event) => onFieldValueUpdate(component.field, event.target.value, event)}
          defaultValue={(!currentVal && ReadOnly) ? "N/A" : currentVal}
          key={'componentInput' + itemLabel + ',' + i}
          data-isnull={component.isNull}
          data-datatype={component.fieldInfo.dataType}
          onBlur={(e) => {}}
          error={ErrorStates[component.field]}
          helperText = {ErrorStates[component.field] ? 'Must be valid format' : ''}
          style={{ border: 11 }}

      />
    </>
  );
}

export default SFText