/*
Transforms the more human friendly / easier to edit list that we store in Firebase
Into a list that is more easily searchable via Fuse.js

*/
export default function ExplodeIconMap (IconMap) {
    var outputMap = [];
    IconMap.forEach((iconObj) => {
        iconObj.modelList.forEach((machine) => {
            outputMap.push({
                "machine" : machine,
                "icon": iconObj.icon
            })
        });
    });
    return outputMap;
};