import React from 'react';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LoginPage } from './pages/LoginPage';
import { B2CLoginPage } from './pages/B2CLoginPage';
import HomePage from './pages/HomePage';
import KnowledgePage from './pages/KnowledgePage';
import { KnowledgeAnswerPage } from './pages/KnowledgeAnswerPage';
import { MachineSearchPage } from './pages/MachineSearchPage'; 
import MachineDetailsPage from './pages/MachineDetailsPage';
import { DealerLookupPage } from './pages/DealerLookupPage';
import { PdiPage } from './pages/pdiPage';
import SupportPage from './pages/SupportPage';
import MessagesPage from './pages/MessagesPage';
import SettingsPage from './pages/SettingsPage';
import { FormIMEIParentPage } from './pages/FormIMEIParentPage';
import { PrivateRoute } from './PrivateRoutes';
import { KUInformation } from './pages/KubotaUniversityPage';
import { MasterCodeLookupPage } from './pages/MasterCodeLookupPage';
import CaseViewPage from './pages/CaseViewPage';
import { DealerCaseListPage } from './pages/DealerCaseListPage';
import { CaseListPage } from './pages/CaseListPage';
import { SFFormTSCPage } from './pages/SFFormTSCPage';
import CustomInspection from './pages/CustomInspection';
import CaseCreateFlowWrapper from './pages/CaseCreateFlowWrapper';
import CaseCreateFlowWrapperWithPrediction from './pages/CaseCreateFlowWrapperWithPrediction';
import LogOutPage from './pages/LogOutPage';

import Topbar from './components/Topbar';
import Timer from './components/Timer';
import NotificationCenter from './components/NotificationCenter';
import BottomNav from './components/BottomNav';
import MessageSnackbar from './components/MessageSnackbar';
import MachineResults from './pages/MachineResults';
import NotificationDashboard from './pages/NotificationDashboard';
import { PredictionListPage } from './pages/PredictionListPage';
import PredictionPage from './pages/PredictionPage';
import DealerContactsPage from './pages/DealerContactsPage';

export const Routes = () => {

    return (
        <Router>
            <Switch>
                <Route path="/login">
                    <Topbar />
                    <B2CLoginPage />
                </Route>
                <Route path="/logout">
                    <Topbar />
                    <LogOutPage />
                </Route>
                <PrivateRoute path="/home" exact>
                    <Topbar />
                    <NotificationCenter />
                    <HomePage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/knowledge" exact>
                    <Timer />
                    <Topbar />
                    <KnowledgePage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/knowledge/answer" exact>
                    <Timer />
                    <Topbar />
                    <KnowledgeAnswerPage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/machineResults" exact>
                    <Timer />
                    <Topbar />
                    <MachineResults/>
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/test/customInspection" exact>
                    <Timer />
                    <Topbar />
                    <CustomInspection/>
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/machine/search" exact>
                    <Timer />
                    <Topbar />
                    <MachineSearchPage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/machine/search/imei" exact>
                    <Timer />
                    <Topbar />
                    <MachineSearchPage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/machine/imei/:param1/:param2" exact>
                    <Timer />
                    <Topbar />
                    <FormIMEIParentPage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/machine/imei/:param1" exact>
                    <Timer />
                    <Topbar />
                    <FormIMEIParentPage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/machine/details/:param1/:param2/:param3" exact>
                    <Timer />
                    <Topbar />
                    <MachineDetailsPage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/machine/details/:param1/:param2" exact>
                    <Timer />
                    <Topbar />
                    <MachineDetailsPage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/machine/details/:param1" exact>
                    <Timer />
                    <Topbar />
                    <MachineDetailsPage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/machine/pdi/:param1/:param2" exact>
                    <Timer />
                    <Topbar />
                    <PdiPage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/sf/dealer/lookup" exact>
                    <Timer />
                    <Topbar />
                    <DealerLookupPage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/cases" exact>
                    <Timer />
                    <Topbar />
                    <CaseListPage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/predictions" exact>
                    <Timer />
                    <Topbar />
                    <PredictionListPage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/prediction/:param1" exact>
                    <Timer />
                    <Topbar />
                    <PredictionPage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/notifdashboard" exact>
                    <Timer />
                    <Topbar />
                    <NotificationDashboard />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/sf/create" exact>
                    <Timer />
                    <Topbar />
                    <SFFormTSCPage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/dealer/cases/:orgID" exact>
                    <Timer />
                    <Topbar />
                    <DealerCaseListPage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/machine/prediction/create/:param1/:param2" exact>
                    <Timer />
                    <Topbar />
                    <CaseCreateFlowWrapperWithPrediction />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/machine/prediction/create/:param1/:param2/:param3" exact>
                    <Timer />
                    <Topbar />
                    <CaseCreateFlowWrapperWithPrediction />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/machine/case/create/:param1" exact>
                    <Timer />
                    <Topbar />
                    <CaseCreateFlowWrapper />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/machine/case/create/:param1/:param2" exact>
                    <Timer />
                    <Topbar />
                    <CaseCreateFlowWrapper />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/machine/case/create/:param1/:param2/:param3" exact>
                    <Timer />
                    <Topbar />
                    <CaseCreateFlowWrapper />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/machine/report/create/:param1" exact>
                    <Timer />
                    <Topbar />
                    <CaseCreateFlowWrapper />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/machine/report/create/:param1/:param2" exact>
                    <Timer />
                    <Topbar />
                    <CaseCreateFlowWrapper />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/machine/report/create/:param1/:param2/:param3" exact>
                    <Timer />
                    <Topbar />
                    <CaseCreateFlowWrapper />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/case/:id" exact>
                    <Timer />
                    <Topbar />
                    <CaseViewPage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/ku" exact>
                    <Timer />
                    <Topbar />
                    <KUInformation />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/dcu/masterkey" exact>
                    <Timer />
                    <Topbar />
                    <MasterCodeLookupPage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support/sf/case/:id" exact>
                    <Timer />
                    <Topbar />
                    <CaseViewPage/>
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>          
                <PrivateRoute path="/support/DealerContacts" exact>
                    <Timer />
                    <Topbar />
                    <DealerContactsPage/>
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/support" exact>
                    <Timer />
                    <Topbar />
                    <SupportPage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/messages" exact>
                    <Timer />
                    <Topbar />
                    <MessagesPage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <PrivateRoute path="/settings" exact>
                    <Timer />
                    <Topbar />
                    <SettingsPage />
                    <BottomNav />
                    <MessageSnackbar />
                </PrivateRoute>
                <Route path="*">
                    <Topbar />
                    <B2CLoginPage />
                </Route>
            </Switch>
        </Router>
    );
}
