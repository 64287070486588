import React from 'react';
import { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getAnalytics, logEvent } from "firebase/analytics";
import { app } from './firebaseConfig';



export const PrivateRoute = props => {
    var user = true;
    
    var expire = null;

    function processExpireDate(date){
        var newNum = date.toString().match(/.{1,2}/g);
        // console.log(newNum);
        var date = new Date(newNum[0] + newNum[1], newNum[2], newNum[3], newNum[4], newNum[5], newNum[6]);
        // console.log(date);
        var now = new Date();
        var diff = Math.abs(date.getTime() - now.getTime());
        // console.log('Diff: ' + diff);
        var expireDaysUntil = diff / (1000 * 60 * 60 * 24);
        // Format "Full Month DD, YYYY"
        var month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        // var expireDate = month[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
    
        // console.log(expireDaysUntil);
        // console.log(expireDate);
        return (expireDaysUntil);
    }

    if(localStorage.getItem('PEDT') !== null){
        var date = localStorage.getItem('PEDT');
        console.log('Date: ' + date);
        var expireDays = processExpireDate(date);
        console.log('Expires in: ' + expireDays);
        expire = expireDays;
        console.log('Expire: ' + expire);
    }
    console.log(document.cookie.indexOf("cp_session"));
    if(document.cookie.indexOf("cp_session") > -1 && expire >= 0){
        user = true;
        console.log(user + ' ' + expire);
    }


    if(!user){
        console.log('REDIRECT');
        return <Redirect to="/login" />
    }else{
        const analytics = getAnalytics(app);

        return <Route {...props} />
    }
}