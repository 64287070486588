import React from 'react'
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

function SFCheckbox({component,  itemLabel, i, editValues, onFieldValueUpdate, ErrorStates, classes}) {
  let currentVal = editValues[component.field].current;
  return (
    <Grid Container>
      <Grid item xs={12}> 
        <Grid Container>
          <Grid item>
            <Typography>
              {component.label}
            </Typography>
          </Grid>
          <Grid item> 
            <Checkbox
              name={component.field}
              checked={currentVal}
              key={'componentInput' + itemLabel + ',' + i}
              onChange={(event) => onFieldValueUpdate(component.field, event.target.checked, event)}
              data-isnull={component.isNull}
              data-datatype={component.fieldInfo.dataType}
              error={ErrorStates[component.field]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
  
}

export default SFCheckbox