import { Routes } from './Routes';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#DC4405',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});

class App extends React.Component {
  render() {
    return (
      <div className="page-container">
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>  
      </div>
    );
  }
  
}
export default App;