import React, { useReducer } from 'react';
import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Alert from '@material-ui/lab/Alert';
import Link from '@material-ui/core/Link';

import { spacing } from '@material-ui/system';
import CircularProgress from '@material-ui/core/CircularProgress';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import axios from 'axios';
import { useHistory, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import parse from 'html-react-parser'
import { Prompt } from 'react-router'
import checkCoveoToken from '../helpers/checkCoveoToken';




const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
    width: "90%",
  },
  heroPaper: {
    padding: theme.spacing(2, 2, 2),
    margin: theme.spacing(0, 0, 4),
    // overflow: 'hidden'
    overflow: 'auto',
  },
  form: {
    margin: theme.spacing(0, 0, 5)
  },
  loaderCenter: {
    marginLeft: '50%',
  },
  loadingCenterInside: {
    left: '-20px',
    position: 'relative'
  },
  spacing:{
    margin: theme.spacing(0, 0, 2)
  }


}));

export const KnowledgeAnswerPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const [payloadData, setPayloadData] = useState(null);
  const [payloadDataAttach, setPayloadDataAttach] = useState(null);
  const [manualURL, setManualURL] = useState(null);
  const [currentDetail, setCurrentDetail] = useState(null);
  const [tweddleArray, setTweddleArray] = useState(null);
  const [previewHTML, setPreviewHTML] = useState();
  //const [answerID, setPayloadData] = useState(null);
  const [title, setTitle] = useState();
  const [attachmentTitle, setAttachmentTitle] = useState();
  const [attachmentURL, setAttachmentURL] = useState();
  const [attachmentType, setAttachmentType] = useState();

  useEffect(() => {
    // On Component Load
    var queryString = window.location.search;
    if (queryString) {
      const urlParams = new URLSearchParams(decodeURIComponent(queryString));
      if (urlParams.has('uniqueID')) {
        let uniqueID = urlParams.get('uniqueID');
        fetchArticle(uniqueID);
      }
      if(urlParams.has('title')) {
        setTitle(urlParams.get('title'));
      }
      if(urlParams.has('attachmentTitle')) {
        setAttachmentTitle(urlParams.get('attachmentTitle'));
      }
      if(urlParams.has('attachmentURL')) {
        setAttachmentURL(urlParams.get('attachmentURL'));
      }
      if(urlParams.has('attachmentType')) {
        setAttachmentType(urlParams.get('attachmentType'));
      }
    }
  }, []);
 

  function fetchArticle(uniqueID) {
    checkCoveoToken()
      .then(() => {
        var qs = require('qs');
        var req = {
          // url : "https://platform.cloud.coveo.com/rest/search/v2/html?organizationId=kubotausanonproduction1uwe4kf4h",
          url : process.env.REACT_APP_API + "Coveo/kservice/searchHtml",
          method : "POST",
          data : qs.stringify({
            enableNavigation: 'false',
            visitorId: 'ae2f6105-e893-4c03-9199-330226d512b9',
            q : '',
            uniqueId : uniqueID,
            requestedOutputSize: 0
          }),
          headers : {
            'Authorization': 'Bearer ' + Cookies.get("token"),
            'X-Coveo-Token': Cookies.get("CoveoToken")
          }
        }
        axios(req)
          .then((response) => {
            var HTMLPreview = response.data;
            setPreviewHTML(HTMLPreview);
          })
          .catch((e) => {
            console.log(e);
            debugger;
          })
      })
  }

  function fetchAttachment(e) {
    e.preventDefault();
    var fileName = attachmentTitle;
    var fileType = attachmentType;
    if(fileType === "SalesforceItem") {
      let tab = window.open();
      tab.location.href = attachmentURL;
      return;
    }

    debugger;
    let url = document.createElement('a');
    url.href = attachmentURL;
    // If file attachment URL is Azure
    let adTokenUrl = process.env.REACT_APP_API + 'salesforce/kservice/apexrest?param=ADToken';
    let adTokenReq = {
      method : "GET",
      url: adTokenUrl,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "Accept": "application/json",
        "Authorization": "Bearer " + Cookies.get("token"),
        // "X-SalesForce-Token" : Cookies.get('sf_cookie')
      }
    }
    axios(adTokenReq).then((adTokenRes) => {
      if(adTokenRes.data){
        let adToken = adTokenRes.data;
        console.log(adToken);
        let x_ms_version = '2021-08-06';
        var newURL =  localStorage.getItem("KnowledgeAttachmentBaseURL") + url.href.substring(url.href.indexOf("/Knowledge__kav"));
        previewBlob(newURL, adToken, x_ms_version)
          .then(result => {
            let fileLink = result;
            downloadFileAttachment(fileLink, fileName)
            debugger;
          })
          .catch(error => {
            debugger;
            console.log(error);
          })
      }
    })
    .catch(error => {
      console.log(error);
    }) 
  }

// Function to preview blob
  function previewBlob(uri, token, version) {
    // create headers object      
    const myHeaders = {'Authorization': 'Bearer ' + token, 'x-ms-date': getUTCDate(), 'x-ms-version': version};
    // create request options
    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    let mimeType='application/octet-stream'; // init mime type
    // return promise
    return new Promise((resolve, reject) => {
        // fetch request
        fetch(uri, requestOptions)
        .then(response => { 
            const contentType = response.headers.get('content-type'); // get blob mime type
            //mimeType = 'application/octet-stream'; // set to allowed type
            return response.arrayBuffer(); // returns a promise that resolves with a generic, fixed-length raw binary data buffer
        }) 
        .then(result => { // process response     
      // Specify a MIME Type for Blob Objects in LWS
            const file = new Blob([result], {type: mimeType}); 
            // create a string containing a URL
            const imageUrl = URL.createObjectURL(file);
            // return blob
            resolve(imageUrl);
        })
        .catch(error => { // handle error
          debugger;     
            reject(error);
        });
    });
  }
  // function to get UTC date
  function getUTCDate() {
    // get current date
    let currentDate = new Date();
    // convert to UTC
    const utcDate = currentDate.toUTCString();
    // return string
    return utcDate;
  }

  async function downloadFileAttachment(url, filename, fetchProps) {
    debugger;
    fetchProps = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + Cookies.get("sf_cookie")
      }
    }
    try {
      const response = await fetch(url, fetchProps);

      if (!response.ok) {
        throw new Error(response);
      }

      const blob = await response.blob();

      // Download the file
      saveAs(blob, filename);

    } catch (error) {
      throw new Error(error);
    }
  }

  function RenderAnswer(){
    //console.log(payloadData)

    if(payloadData){
      let visibleArray = [];
      switch(payloadData.contentType){
        case "GENERAL":
          //@Ransom some of the answers didn't have this array - IDK if it's the case but here is an example of
          //an inline if to change it out if that array doesn't exist so that .value call doesn't break it
          visibleArray["SUBJECT"] = (payloadData.content["GENERAL/SUBJECT"] ? payloadData.content["GENERAL/SUBJECT"].value : "");
          visibleArray["ISSUE"] = (payloadData.content["GENERAL/ISSUE"] ? payloadData.content["GENERAL/ISSUE"].value : "");
          visibleArray["SOLUTION"] = (payloadData.content["GENERAL/SOLUTION"] ? payloadData.content["GENERAL/SOLUTION"].value : "");
          visibleArray["MODELS"] = (payloadData.content["GENERAL/MODELS"] ? "Models: " + payloadData.content["GENERAL/MODELS"].value : "");
          visibleArray["STATUSKF"] = (payloadData.content["GENERAL/STATUSKF"] ? payloadData.content["GENERAL/STATUSKF"].value : "");
          break;
        case "MANUAL":
          visibleArray["SUBJECT"] = (payloadData.content["MANUAL/SUBJECT"] ? + payloadData.content["MANUAL/SUBJECT"].value : "");
          visibleArray["DESCRIPTION"] = (payloadData.content["MANUAL/DESCRIPTION"] ? "Description: " + payloadData.content["MANUAL/DESCRIPTION"].value : "");
          visibleArray["MODELS"] = (payloadData.content["MANUAL/MODELS"] ? "Models: " + payloadData.content["MANUAL/MODELS"].value : "");
          visibleArray["STATUSKF"] = (payloadData.content["MANUAL/STATUSKF"] ? payloadData.content["MANUAL/STATUSKF"].value : "");
          visibleArray["SOLUTION"] = (payloadData.content["MANUAL/SOLUTION"] ? payloadData.content["MANUAL/SOLUTION"].value : "");
          visibleArray["ISSUE"] = (payloadData.content["MANUAL/ISSUE"] ? payloadData.content["MANUAL/ISSUE"].value : "");
          visibleArray["EXT_LINK"] = (payloadData.content["MANUAL/EXTERNAL_LINK"] ? payloadData.content["MANUAL/EXTERNAL_LINK"].value : "");
          break;
        case "CAMPAIGNS":
          visibleArray["SUBJECT"] = (payloadData.content["CAMAPIGNS/SUBJECT"] ? payloadData.content["CAMPAIGNS/SUBJECT"].value : "");
          visibleArray["DESCRIPTION"] = (payloadData.content["CAMPAIGNS/DESCRIPTION"] ? payloadData.content["CAMPAIGNS/DESCRIPTION"].value : "");
          visibleArray["MODELS"] = (payloadData.content["CAMPAIGNS/MODELS"] ? "Models: " + payloadData.content["CAMPAIGNS/MODELS"].value : "");
          visibleArray["STATUSKF"] = (payloadData.content["CAMPAIGNS/STATUSKF"] ? payloadData.content["CAMPAIGNS/STATUSKF"].value : "");
          visibleArray["SOLUTION"] = (payloadData.content["CAMPAIGNS/SOLUTION"] ? payloadData.content["CAMPAIGNS/SOLUTION"].value : "");
          visibleArray["ISSUE"] = (payloadData.content["CAMPAIGNS/ISSUE"] ? payloadData.content["CAMPAIGNS/ISSUE"].value : "");
          break;
        case "BULLETIN":
          visibleArray["SUBJECT"] = (payloadData.content["BULLETIN/SUBJECT"] ? payloadData.content["BULLETIN/SUBJECT"].value : "");
          visibleArray["DESCRIPTION"] = (payloadData.content["BULLETIN/DESCRIPTION"] ? payloadData.content["BULLETIN/DESCRIPTION"].value : "");
          visibleArray["MODELS"] = (payloadData.content["BULLETIN/MODELS"] ? "Models: " + payloadData.content["BULLETIN/MODELS"].value : "");
          visibleArray["STATUSKF"] = (payloadData.content["BULLETIN/STATUSKF"] ? payloadData.content["BULLETIN/STATUSKF"].value : "");
          visibleArray["SOLUTION"] = (payloadData.content["BULLETIN/SOLUTION"] ? payloadData.content["BULLETIN/SOLUTION"].value : "");
          visibleArray["SERIAL"] = (payloadData.content["BULLETIN/SERIAL_NUMBERS"] ? "Serial Number: " + payloadData.content["BULLETIN/SERIAL_NUMBERS"].value : "");
          visibleArray["ISSUE"] = (payloadData.content["BULLETIN/ISSUE"] ? payloadData.content["BULLETIN/ISSUE"].value : "");
          visibleArray["CAS_PART"] = (payloadData.content["BULLETIN/CASUAL_PART_NUMBERS"] ? "Casual PartNumbers: " + payloadData.content["BULLETIN/CASUAL_PART_NUMBERS"].value : "");
          visibleArray["PARTS"] = (payloadData.content["BULLETIN/PARTS"] ? "Parts: " + payloadData.content["BULLETIN/PARTS"].value : "");
          visibleArray["WARRANTY"] = (payloadData.content["WARRANTY/CASUAL_PART_NUMBERS"] ? "Warranty: " + payloadData.content["WARRANTY/CASUAL_PART_NUMBERS"].value : "");
          break;
      }

      let attachments = [];

      // if(payloadData.attachments){
      //   for(let x = 0; x < payloadData.attachments.length; x++){
      //     if(payloadData.attachments[x]){
      //       attachments.push(<Typography key={x}><Link href="#" rel="noopener" style={{color: 'blue'}} onClick={() => window.open(payloadData.attachments[x].filePath)}>Standard attachments{payloadData.attachments[x].value + "\n"}</Link></Typography>);
      //     }
      //   }
      // }

      if(payloadData){
        let keysArray = Object.keys(payloadData.content);
        for(let x = 0; x < keysArray.length; x++){
          if(keysArray[x].indexOf("LIST_OF_FILES/ATTACHMENT") != -1){
            var attachURL = process.env.REACT_APP_OSVC_DOMAIN + '/ci/okcsFattach/get/' + payloadData.answerID + '_' + payloadData.content[keysArray[x]].position;
            attachments.push(<Typography key={x + "list_attach"}><Link href={attachURL} rel="noopener" style={{color: 'blue'}} target="_blank">{payloadData.content[keysArray[x]].value + "\n"}</Link></Typography>);
          }
        }
      }



      if(payloadData){
        let attachmentURL;
        let attachTitle;
        let keysArray = Object.keys(payloadData.content);
        for(let x = 0; x < keysArray.length; x++){
          //console.log(x)
          if(keysArray[x].indexOf("LINKLIST/EXTERNALURL") != -1){
            attachmentURL = downloadManual(payloadData.content[keysArray[x]].value, x);
            attachTitle = attachmentTitle(payloadData.content[keysArray[x]].value);
            if(tweddleArray){
              attachments.push(<Typography key={x + "list_attach"}><Link href={tweddleArray[x]} rel="noopener" style={{color: 'blue'}} target="_blank">{attachTitle}</Link></Typography>);
            }
          }
        }
      }
      console.log(tweddleArray);

      //Tweddle 1000307, 4540
      function downloadManual(detail, index){
        
        let testVar;
        //setCurrentDetail(detail);
        if(manualURL === null){// || currentDetail != detail){
          //setCurrentDetail(detail);
          console.log(index);
          console.log(detail);
          var detailSplit = detail.split(" ")[1];
          detailSplit = detailSplit.split('"')[1];
          var detailToken = detailSplit.split("?fileId=")[1];
          let url = process.env.REACT_APP_OSVC_DOMAIN + "/cc/MobileSearch/manual";
          let payload = JSON.stringify( {
            data: {
              'fileId' : detailToken
            }
          });
          console.log("Payload" + payload);

          axios.post(url, payload, {withCredentials: true})
            .then((r) => {
              console.log("Download Manual " + r.data.URL)
              // Do something with the response.
              //setPayloadDataAttach(r.data);
              //let fileNameType = detail.value;
              let tempArray;
              if(tweddleArray === null){
                tempArray = {};
              }else{
                tempArray = tweddleArray;
              }
              let fileURL = encodeURI(r.data.URL);
              for(let x = 0; x < tempArray.length; x++){
                if(tempArray.indexOf(x) !== -1){
                  console.log(x);
                  console.log("Match");
                }
              }
              
              tempArray[index] = fileURL;
              setTweddleArray(tempArray);
              setManualURL(fileURL);
            }).catch((err) => {
                console.log(err);
          });
          
        }
        console.log("Final URL " + tweddleArray);
        
        return manualURL;
      }

      function attachmentTitle(detail){
        var detailSplit = detail.split(">")[1];
        detailSplit = detailSplit.split("<")[0];
        return detailSplit;
      }

      return(
        <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg" className={classes.heroContent}>
          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              <Paper elevation={3} className={classes.heroPaper}>
  
              <Typography component="h1" variant="h5">{payloadData.title}</Typography>
              <Typography variant="subtitle1"> Published Date {payloadData.publishedDate}</Typography>
              <Divider className={classes.spacing}/>
              <Typography component="h2" variant="h6">Description</Typography>
              {<p dangerouslySetInnerHTML={{__html: visibleArray["SUBJECT"]}}/>}
              {<p dangerouslySetInnerHTML={{__html: visibleArray["DESCRIPTION"]}}/>}
              {<p dangerouslySetInnerHTML={{__html: visibleArray["ISSUE"]}}/>}
              {<p dangerouslySetInnerHTML={{__html: visibleArray["SOLUTION"]}}/>}
              {<p dangerouslySetInnerHTML={{__html: visibleArray["MODELS"]}}/>}
              {<p dangerouslySetInnerHTML={{__html: visibleArray["STATUSKF"]}}/>} 
              {<p dangerouslySetInnerHTML={{__html: visibleArray["SERIAL"]}}/>} 
              {<p dangerouslySetInnerHTML={{__html: visibleArray["CAS_PART"]}}/>} 
              {<p dangerouslySetInnerHTML={{__html: visibleArray["PARTS"]}}/>} 
              {<p dangerouslySetInnerHTML={{__html: visibleArray["WARRANTY"]}}/>} 
              {<p dangerouslySetInnerHTML={{__html: visibleArray["EXT_LINK"]}}/>} 

              </Paper>
  
            </Grid>
            <Grid item md={4} xs={12}>
              <Paper elevation={3} className={classes.heroPaper}>
                <Typography variant="h6" component="h2">
                  Attachments
                </Typography>
                <Divider />
                {attachments}
              </Paper>
            </Grid>
          </Grid>
        </Container>
  
  
      </React.Fragment>
      )
    }else{
      return null;
    }
  }

  return (
    // <RenderAnswer />
    <>
     <Container maxWidth="lg" className={classes.heroContent}>
       <Grid container spacing={2}>
         <Grid item xs={9}>
              <Prompt
                when={true}
                message= {(location, action) => {
                  if(previewHTML) {
                    setPreviewHTML(null);
                    setTimeout(() => {
                      history.push(location.pathname);
                    }, 100);
                    return false;
                  } else {
                    return true;
                  }
                }}
              /> 
              <Paper elevation={3} className={classes.heroPaper}>
                <Typography>
                  {title}
                </Typography>
                <Divider />
                <div>
                  {previewHTML &&
                    <div>
                      {parse(previewHTML)}
                    </div>
                  }
                </div>
              </Paper>
          </Grid>
          {attachmentTitle && attachmentURL &&
            <Grid item xs={3}>
              <Paper elevation={3} className={classes.heroPaper}>
                <Typography variant="h6" component="h2">
                  Attachments
                </Typography>
                <Divider />
                <Typography key={"list_attach"}>
                  <Link href={attachmentURL} rel="noopener" style={{color: 'blue'}} onClick={(e) => {fetchAttachment(e);}}>{attachmentTitle}</Link>
                </Typography>
              </Paper>
            </Grid>
          }
        </Grid>
      </Container>
    </>
  );
}