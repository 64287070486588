import React, { useReducer } from 'react';
import { useEffect, useState } from 'react';
import {
  Events,
  animateScroll,
  scrollSpy,
  scroller
} from "react-scroll";
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';

import KnowledgeRecentSearch from '../components/KnowledgeRecentSearch';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Alert from '@material-ui/lab/Alert';
import Pagination from '@material-ui/lab/Pagination';
import { spacing } from '@material-ui/system';
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from '@material-ui/lab/Skeleton';
import { app } from '../firebaseConfig';
import { fetchAndActivate } from "firebase/remote-config";
import { getValue } from "firebase/remote-config";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import axios from 'axios';

import { connect } from 'react-redux';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { alpha, borders } from '@mui/system';

import { getAnalytics, logEvent } from "firebase/analytics";
import { getRemoteConfig } from "firebase/remote-config";
import { saveAs } from 'file-saver';
import Cookies from 'js-cookie';
import { red } from '@material-ui/core/colors';
import checkCoveoToken from '../helpers/checkCoveoToken';
import html2pdf from 'html2pdf.js';


console.log("Knowledge was called");



const useStyles = makeStyles((theme) => ({
  heroContent: {
    width: "90%",
    padding: theme.spacing(8, 0, 6),
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  form: {
    margin: theme.spacing(0, 0, 5)
  },
  loaderCenter: {
    marginLeft: '50%',
  },
  loadingCenterInside: {
    left: '-20px',
    position: 'relative'
  },
  skeltonDivider: {
    margin: theme.spacing(1, 0, 1)
  },
  listIcon: {
      display: "block"
  },
  rowContainer: {
    minHeight: "40px"
  },
  Result: {
    padding: theme.spacing(0,0,0),
  },
  attachmentBox: {
    borderRadius: 4,
    border: 'solid 1px',
    maxWidth : '600px',
    margin: theme.spacing(1, 4, 1),
    paddingRight : 0,
    '&:hover': {
          backgroundColor: '#d1d1d1'
        }
  },
  attachmentExcerpt : {
    fontSize : '16px',
    paddingRight: "30px"
  },
  blue: {
    backgroundColor: 'blue'
  }

}));

const KnowledgePage = ({ rUserType }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [tabs, setTabs] = useState();
  const [searchFacets, SetSearchFacets] = useState("hahaha");
  const [formData, updateFormData] = useState({
    "search" : ""
  });
  const [loading, setLoading] = useState(true);
  const [searchResults, setSearchResults] = useState(null);
  const [displayResults, setDisplayResults] = useState(null);
  const [totalResults, setTotalResults] = useState(-1);
  const [pageCount, setPageCount] = useState(-1);
  const [loadingArray, setLoadingArray] = useState(new Array(10).fill(false));
  const [searchSession, setSearchSession] = useState(null);
  const [transactionID, setTransactionID] = useState(null);
  const [priorTransactionID, setPriorTransactionID] = useState(null);
  const [category, setCategory] = useState();
  const [searchHistory, setSearchHistory] = useState('');
  const [keyword, setKeyword] = useState(null);
  const [skeltonLoad, setSkeltonLoad] = useState(false);
  const [searchTemplate, setSearchTemplate] = useState();
  const [disabled, setDisabled] = useState(true);

  var CoveoApiKey;

  const width = useMediaQuery(theme.breakpoints.down(490));

  useEffect(() => {
  
  }, [searchFacets]);
  useEffect(() => {
    const tabs = JSON.parse(localStorage.getItem("KnowledgeTabs"));
    setCategory(tabs.tabs[0].value);
    setTabs(tabs.tabs);
    //Grabbing Coveo Search Token
    checkCoveoToken()
      .then(() => {
        setDisabled(false);
        setLoading(false);
      });
    // On Component Load
  }, []);

  useEffect(() => {
    return history.listen((location) => {
        console.log(`You changed the page to: ${location.pathname}`)
        console.log(window.location.search);
        const queryString = window.location.search;
        if (queryString) {
          const urlParams = new URLSearchParams(queryString);
          if (urlParams.has('s') && urlParams.has('c')) {
            setLoading(true);
            // ... set Search Form Value
            let urlFormData = { search: urlParams.get('s') };
            updateFormData(urlFormData);
            setCategory(urlParams.get('c'));
            // ... submit to Local Storage
            let search = { term: urlParams.get('s'), category: urlParams.get('c') };
            setSearchHistory(search);
            // ... submit to API or something
            fetchSearch(search);
          }
        }
     })
}, [history])

  useEffect(() => {
    if(category) {
      if(!loading)
        handleSubmit();
    }
   
  },[category])

  useEffect(() => {
    if(tabs) {
      fetchFacets()
        .then(() => {
          fetchTemplateSearch()
          .then(() => {
            //Check for Search terms in URL. 
            const queryString = window.location.search;
            if (queryString) {
              const urlParams = new URLSearchParams(queryString);
              if (urlParams.has('s') && urlParams.has('c')) {
                handleSearchTermsInURL(urlParams);
              }
            }
          })
        })
    }
  }, [tabs]);

  const fetchTemplateSearch = () => {
    return new Promise((resolve, reject) => {
      const remoteConfig = getRemoteConfig(app);
      fetchAndActivate(remoteConfig)
        .then(() => {
          setSearchTemplate(JSON.parse(getValue(remoteConfig, "template_search")._value));
          localStorage.setItem("template_search",getValue(remoteConfig, "template_search")._value)
          resolve();
        })
        .catch(() => {
          reject();
        });
      })
  };

  const handleSearchTermsInURL = (urlParams) => {
    setLoading(true);
        // ... set Search Form Value
        let urlFormData = { search: urlParams.get('s') };
        updateFormData(urlFormData);
        setCategory(urlParams.get('c'));
        // ... submit to Local Storage
        let search = { term: urlParams.get('s'), category: urlParams.get('c') };
        setSearchHistory(search);
        // ... submit to API or something
        fetchSearch({ term: urlParams.get('s'), category: urlParams.get('c') },0);

  }


  const fetchFacets = () => {
    return new Promise((resolve, reject) => {
      var facets = {};
      const remoteConfig = getRemoteConfig(app);
      remoteConfig.settings.minimumFetchIntervalMillis = 0;
      fetchAndActivate(remoteConfig)
        .then(() => {
          tabs.forEach((tab) => {
            var facetValue = JSON.parse(getValue(remoteConfig, tab.value)._value);
            if(facetValue) {
              facets = {
                ...facets,
                [tab.value] : facetValue
              };
            }
          });
          SetSearchFacets(facets);
          localStorage.setItem("searchFacets", JSON.stringify(facets));
          //Give setting just a small amount of time before resolving
          setTimeout(() => {
            resolve();
          }, 150);
        })
        .catch((err) => {
          reject(err);
        });
    });
    
  };

  const handleCategoryChange = (e, value) => {
    setCategory(value);
  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    if(e){
      e.preventDefault()
    }
    if (formData.search == '') {
      setSearchResults(null);
    } else {
      // ... submit to Local Storage
      let urlSearchObj = { term: formData.search, category: category };
      setSearchHistory(urlSearchObj);
      setLoading(true);
      // ... submit to API or something
      fetchSearch(urlSearchObj, 0);
    }

  };

  const paginationChange = (e, value) => {
    let urlSearchObj = { term: formData.search, category: category };
    fetchSearch(urlSearchObj, (value - 1) * 10);
  }

  function handleAttachmentClick(Attachment, URL, parentIndex) {
    fetchAttachment(Attachment, URL, parentIndex);
  }

  function fetchAttachment(Attachment, attachmentURL, parentIndex) {
    //mark result as loading
    var clone = [...loadingArray];
    clone[parentIndex] = true;
    setLoadingArray(clone);

    var fileName = Attachment.Title;
    var fileType = Attachment.raw.filetype;
    if(fileType === "SalesforceItem") {
      let tab = window.open();
      tab.location.href = attachmentURL;
      return;
    }
    let url = document.createElement('a');
    url.href = attachmentURL;
    // If file attachment URL is Azure
    let adTokenUrl = process.env.REACT_APP_API + 'salesforce/kservice/apexrest?param=ADToken';
    let adTokenReq = {
      method : "GET",
      url: adTokenUrl,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "Accept": "application/json",
        "Authorization": "Bearer " + Cookies.get("token"),
        // "X-SalesForce-Token" : Cookies.get('sf_cookie')
      }
    }
    axios(adTokenReq).then((adTokenRes) => {
      if(adTokenRes.data){
        let adToken = adTokenRes.data;
        console.log(adToken);
        let x_ms_version = '2021-08-06';
        var newURL =  localStorage.getItem("KnowledgeAttachmentBaseURL") + url.href.substring(url.href.indexOf("/Knowledge__kav"));
        previewBlob(newURL, adToken, x_ms_version)
          .then(result => {
            let fileLink = result;
            downloadFileAttachment(fileLink, fileName, parentIndex)
          })
          .catch(error => {
            console.log(error);
          })
      }
    })
    .catch(error => {
      console.log(error);
    }) 
  }


  async function downloadFileAttachment(url, filename, loadingIndex, fetchProps) {
    fetchProps = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + Cookies.get("sf_cookie")
      }
    }
    try {
      const response = await fetch(url, fetchProps);

      if (!response.ok) {
        throw new Error(response);
      }

      const blob = await response.blob();
      
      console.log("blob done");
      //Mark the result as not loading anymore
      var clone = [...loadingArray];
      clone[loadingIndex] = false;
      setLoadingArray(clone);

      // Download the file
      saveAs(blob, filename);

    } catch (error) {
      throw new Error(error);
    }
  }

  function fetchSearch (search, startingIndex) {
    var facetsFromLocalStorage = JSON.parse(localStorage.getItem("searchFacets"));
    var searchTemplateFromLocalStorage = JSON.parse(localStorage.getItem("template_search"));
    console.log("FETCHING SEARCH");
    const category = search.category;
    const facetToUse = facetsFromLocalStorage[category];
    //Build Payload, using searchTemplate(from Firebase) as our starting point
    var coveoPayload = {
      ...searchTemplateFromLocalStorage,
      "q": search.term,
      "facets": facetToUse,
      "numberOfResults": 10,
      "firstResult": startingIndex
    };
    var searchRequest =  {
      // url : "https://platform.cloud.coveo.com/rest/search/v2?organizationId=kubotausanonproduction1uwe4kf4h",
      url : process.env.REACT_APP_API + "Coveo/kservice/search",
      method: "POST",
      data : coveoPayload,
      headers : {
        'Authorization': 'Bearer ' + Cookies.get("token"),
        'X-Coveo-Token': Cookies.get("CoveoToken")
      }
    }
    axios(searchRequest)
      .then((response) => {
        // @TODO Now that Pagination is handled by subsequent Coveo calls, we can consolidate search results vs display results.
        // Previously: SearchResults stored all results, Display stored current page of results.
        // New: Just use SearchResults to store page(10 results), on pagination make new Coveo call
        setSearchResults(response.data.results);
        setDisplayResults(response.data.results);

        setTotalResults(response.data.totalCount);
        var pages = Math.ceil((response.data.totalCount) / 10);
        if(pages > 100)
          pages = 100;
        setPageCount(pages);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function ListItemLink(uniqueID, title, attachments,result, index) {
    var docType = result.raw.documenttype;
    var fileType = result.raw.filetype;

    //mark result as loading
    var clone = [...loadingArray];
    clone[index] = true;
    setLoadingArray(clone);
    
    //Attempt to download the source PDF from either Blob or Kits. If not on either, grab HTML preview.
    if(docType === "blob") {
      fetchAttachment(result, result.ClickUri);
    } else if(result.ClickUri.includes("kits.kubota")) {
      if(fileType === "pdf") {
        fetchAttachmentFromKits(result.ClickUri,title+".pdf");
      }
    } 
    else {
      //Download the HTML preview
      fetchArticle(result.UniqueId, title, index);
    }
    console.log("all done!");
    // history.push('/knowledge/answer?uniqueID=' + encodeURIComponent(uniqueID) + "&title=" + title + "&attachmentTitle=" + );
    // if(attachments) {
    //   var attachmentURL = encodeURIComponent(attachments[0].ClickUri);
    //   var attachmentTitle = encodeURIComponent(attachments[0].Title);
    //   var attachmentType = attachments[0].raw.filetype;
    //   debugger;
    //   // history.push('/knowledge/answer?uniqueID=' + encodeURIComponent(uniqueID) + "&title=" + title + "&attachmentTitle=" + attachmentTitle
    //   //               + "&attachmentURL=" + attachmentURL + "&attachmentType=" + attachmentType);
    // } else {
    //   debugger;
    //   // history.push('/knowledge/answer?uniqueID=' + encodeURIComponent(uniqueID) + "&title=" + title);
    // }
    //return <ListItem button component="a" {...props} />;
  }

  function fetchAttachmentFromKits(attachmentURL,fileName, index) {
    let x_ms_version = '2021-08-06';
    const kitToken = Cookies.get("KitToken");
    //Mark Result as Loading, these kits take a while.
    // const clone = [...resultLoadingArray];
    // clone[index] = true;
    // setResultLoadingArray(clone);
    
    previewBlob(attachmentURL, kitToken, x_ms_version)
            .then(result => {
                let fileLink = result;
                downloadFileAttachment(fileLink, fileName, index)

                // const clone = [...resultLoadingArray];
                // clone[index] = false;
                // setResultLoadingArray(clone);
            })
            .catch(error => {
                console.log(error);

                // const clone = [...resultLoadingArray];
                // clone[index] = false;
                // setResultLoadingArray(clone);
            })
}

  function fetchArticle(uniqueID, title, resultIndex) {
    checkCoveoToken()
      .then(() => {
        var qs = require('qs');
        var req = {
          // url : "https://platform.cloud.coveo.com/rest/search/v2/html?organizationId=kubotausanonproduction1uwe4kf4h",
          url : process.env.REACT_APP_API + "Coveo/kservice/searchHtml",
          method : "POST",
          data : qs.stringify({
            enableNavigation: 'false',
            visitorId: 'ae2f6105-e893-4c03-9199-330226d512b9',
            q : '',
            uniqueId : uniqueID,
            requestedOutputSize: 0
          }),
          headers : {
            'Authorization': 'Bearer ' + Cookies.get("token"),
            'X-Coveo-Token': Cookies.get("CoveoToken")
          }
        }
        axios(req)
          .then((response) => {
            var opt = {
              margin:       1,
              enableLinks: false,
              filename:     title.substring(0,20),
              html2canvas:  { scale: 1},
              jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
            };
            var worker = html2pdf().from(response.data).set(opt).save();
            //mark result as done loading
            var clone = [...loadingArray];
            clone[resultIndex] = false;
            setLoadingArray(clone);
            console.log("all done");
          })
          .catch((e) => {
            console.log(e);
            debugger;
          })
      })
  }

  // Function to preview blob
  function previewBlob(uri, token, version) {
    // create headers object      
    const myHeaders = {'Authorization': 'Bearer ' + token, 'x-ms-date': getUTCDate(), 'x-ms-version': version};
    // create request options
    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    let mimeType='application/octet-stream'; // init mime type
    let url = process.env.REACT_APP_API+'rp?param='+encodeURI(uri);
    // return promise
    return new Promise((resolve, reject) => {
        // fetch request
        fetch(url, requestOptions)
        .then(response => { 
            const contentType = response.headers.get('content-type'); // get blob mime type
            //mimeType = 'application/octet-stream'; // set to allowed type
            return response.arrayBuffer(); // returns a promise that resolves with a generic, fixed-length raw binary data buffer
        }) 
        .then(result => { // process response     
      // Specify a MIME Type for Blob Objects in LWS
            const file = new Blob([result], {type: mimeType}); 
            // create a string containing a URL
            const imageUrl = URL.createObjectURL(file);
            // return blob
            resolve(imageUrl);
        })
        .catch(error => { // handle error
            reject(error);
        });
    });
  }
  // function to get UTC date
  function getUTCDate() {
    // get current date
    let currentDate = new Date();
    // convert to UTC
    const utcDate = currentDate.toUTCString();
    // return string
    return utcDate;
  }

  function Results({ result, index }) {
    const uniqueID = result.UniqueId;
    const title = result.Title;
    if(result.parentResult) {
          var attachments = [result.parentResult];
    }
    return (
      <Container maxWidth="lg" className={classes.Result}>
        <ListItem button onClick={() => ListItemLink(uniqueID, title, attachments, result, index)}>
          <ListItemText
            primary={
              <Typography noWrap>
                {result.Title}
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {result.Excerpt}
                </Typography>
              </React.Fragment>
            }
          />
          <ListItemIcon className={classes.listIcon}>
            <ArrowForwardIosIcon />
          </ListItemIcon>
        </ListItem>
        {attachments &&
            <div>
              {
                attachments.map((attachment, attachmentIndex) => {
                  if(attachment.raw.documenttype === "blob") {
                    return (
                      <ResultAttachment
                        key={"Attachment" + index + attachmentIndex}
                        Attachment={attachment}
                        i={attachmentIndex}
                        parentIndex={index}
                      />
                    );
                  } else {
                     return (
                      <ResultAttachment
                        key={"Attachment" + index + attachmentIndex}
                        Attachment={attachment}
                        i={attachmentIndex}
                        parentIndex={index}
                      />
                    );
                  }
                })
              }
            </div>
        }
        { loadingArray[index] &&
          <div className={classes.loaderCenter}>
            <CircularProgress className={classes.loadingCenterInside} />
          </div>
        }
        <Divider />
      </Container>
    )
  }
  function ResultsNew({ result, index }) {
    const uniqueID = result.UniqueId;
    const title = result.Title;
    if(result.parentResult) {
          var attachments = [result.parentResult];
    }
    
    // facetInfo += result.raw.categorylevel2 ? `${result.raw.categorylevel2} ` : "";
    // var facetInfo = result.raw.categorylevel3 ? `${result.raw.categorylevel3} ` : "";
    var facetInfo = result.raw.categorylevel4 ? `${result.raw.categorylevel4}` : "";

    return (
      <Container maxWidth="lg" className={classes.Result}>
        <ListItem button onClick={() => ListItemLink(uniqueID, title, attachments, result, index)}>
          <Grid container>
            <Grid item md={11} sm={11} name="ResultContent">
              <Grid container 
                direction="column" 
                className={classes.bluee}
                alignItems="stretch"
              >
                <Grid item md={12} sm={12}>
                  <Grid container name="TitleAndFacetsContainer" 
                  justifyContent="space-between"
                  spacing={1}>
                    <Grid item md={8} sm={8} >
                      <Typography noWrap>
                        {result.Title}
                     </Typography>
                    </Grid>
                    <Grid item md={4} sm={4} style={{textAlign: "end"}}>
                      <Typography noWrap>
                          {facetInfo}
                      </Typography>
                    </Grid>
                  </Grid>
                

                </Grid>
                <Grid item md={12} sm={12}>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    {result.Excerpt}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={1} sm={1} name="ChevronContainer">
              <Grid container id="ChevronContainer"
                justifyContent="center"
                alignItems="center"
                style={{height: "100%"}}
              >
                <ArrowForwardIosIcon/>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
        {attachments &&
            <div>
              {
                attachments.map((attachment, attachmentIndex) => {
                  if(attachment.raw.documenttype === "blob") {
                    return (
                      <ResultAttachment
                        key={"Attachment" + index + attachmentIndex}
                        Attachment={attachment}
                        i={attachmentIndex}
                        parentIndex={index}
                      />
                    );
                  } else {
                     return (
                      <ResultAttachment
                        key={"Attachment" + index + attachmentIndex}
                        Attachment={attachment}
                        i={attachmentIndex}
                        parentIndex={index}
                      />
                    );
                  }
                })
              }
            </div>
        }
        { loadingArray[index] &&
          <div className={classes.loaderCenter}>
            <CircularProgress className={classes.loadingCenterInside} />
          </div>
        }
        <Divider />
      </Container>
    )
  }

  function ResultAttachment({Attachment, i, parentIndex}){
    const title = Attachment.Title;
    const URL = Attachment.ClickUri;
    const Excerpt = Attachment.Excerpt;
    return (
      <Container maxWidth="lg" className={classes.attachmentBox} onClick={(e) => {handleAttachmentClick(Attachment,URL, parentIndex)}}>
          <Grid container alignItems="center" className={classes.rowContainer}>
            <Grid item xs={11}>
              <Typography variant="h6" component="h6" className={classes.attachmentExcerpt} name="scroll-to-results">
                {title}
              </Typography>
              {/* <Typography variant="h6" component="h6" className={classes.attachmentExcerpt} name="scroll-to-results">
                {Excerpt}
              </Typography> */}
            </Grid>
            <Grid item xs={1} className={classes.attachmentIconGridItem}>
              
                    <ArrowForwardIosIcon className={classes.listIcon}/>
                
            </Grid>
          </Grid>
        </Container>
    );
  }

  if (!width) {
    return (
      <React.Fragment>
        <CssBaseline />
        {rUserType && tabs &&
        <Container maxWidth="lg" className={classes.heroContent}>
          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              <Paper elevation={3} className={classes.heroPaper}>
                <form className={classes.form} noValidate onSubmit={e => { e.preventDefault(); }}>
                  <Tabs
                    value={category}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleCategoryChange}
                    aria-label="select article type"
                    centered
                  >
                    {tabs.map((tab, i) => {
                        return (
                          <Tab 
                            key={"KnowledgeTab" + i}
                            label={tab.label} 
                            value={tab.value} 
                          />
                        );
                      }
                    )}
                   
                  </Tabs>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    value={formData.search}
                    required
                    fullWidth
                    id="search"
                    label="Search"
                    name="search"
                    autoFocus
                    onChange={handleChange}
                  />

                  <Button
                    type="submit"
                    disabled={disabled}
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.submit}
                    onClick={handleSubmit}
                  >
                    Search
                  </Button>
                </form>
                {totalResults > 0 && !loading &&
                  <React.Fragment>

                    <Typography variant="h4" component="h2" name="scroll-to-results">Results</Typography>
                    {displayResults && !skeltonLoad &&
                      <List component="nav" aria-label="knowledge search results" className={classes.root}>
                        {displayResults.map((result, i) => (
                          <ResultsNew
                            key={i}
                            index={i}
                            result={result}
                          />
                        ))}
                      </List>
                    }
                    {skeltonLoad &&
                      <React.Fragment>
                        <Typography component="h1" variant="h4">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Divider className={classes.skeltonDivider} />

                        <Typography component="h1" variant="h4">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Divider className={classes.skeltonDivider} />

                        <Typography component="h1" variant="h4">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Divider className={classes.skeltonDivider} />

                        <Typography component="h1" variant="h4">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Divider className={classes.skeltonDivider} />

                        <Typography component="h1" variant="h4">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Divider className={classes.skeltonDivider} />

                        <Typography component="h1" variant="h4">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Divider className={classes.skeltonDivider} />

                        <Typography component="h1" variant="h4">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Divider className={classes.skeltonDivider} />

                        <Typography component="h1" variant="h4">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Divider className={classes.skeltonDivider} />

                        <Typography component="h1" variant="h4">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Divider className={classes.skeltonDivider} />

                        <Typography component="h1" variant="h4">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Divider className={classes.skeltonDivider} />
                      </React.Fragment>
                    }
                    <Typography variant="overline" display="block" gutterBottom>Total Results Answers - {totalResults}</Typography>

                    {pageCount > 1 &&
                      <React.Fragment>

                        <Pagination count={pageCount} variant="outlined" color="primary" onChange={paginationChange} />
                      </React.Fragment>
                    }
                    {pageCount &&
                      <React.Fragment></React.Fragment>
                    }
                  </React.Fragment>
                }
                {(!searchResults || searchResults.length <= 0) && !loading &&
                  <React.Fragment>
                    <Alert severity="warning">No Search Results to Display</Alert>
                  </React.Fragment>
                }
                {loading &&
                  <div className={classes.loaderCenter}>
                    <CircularProgress className={classes.loadingCenterInside} />
                  </div>
                }

              </Paper>

            </Grid>
            <Grid item md={4} xs={12}>
              <Paper elevation={3} className={classes.heroPaper}>
                <Typography variant="h6" component="h2">
                  Recent Searches
                </Typography>
                <Divider />
                <KnowledgeRecentSearch search={searchHistory} />
              </Paper>
            </Grid>
          </Grid>
        </Container>

        }
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <CssBaseline />
        {rUserType &&
        <Container maxWidth="lg" className={classes.heroContent}>
          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              <Paper elevation={3} className={classes.heroPaper}>
                <form className={classes.form} noValidate onSubmit={e => { e.preventDefault(); }}>
                  <Tabs
                    value={category}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleCategoryChange}
                    aria-label="select article type"
                    //centered
                    variant="scrollable"
                    scrollButtons="on"
                  >
                    {tabs.map((tab, i) => {
                        return (
                          <Tab 
                            key={"KnowledgeTab" + i}
                            label={tab.label} 
                            value={tab.value} 
                          />
                        );
                      }
                    )}
                   
                  </Tabs>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    value={formData.search}
                    required
                    fullWidth
                    id="search"
                    label="Search"
                    name="search"
                    autoFocus
                    onChange={handleChange}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.submit}
                    onClick={handleSubmit}
                  >
                    Search
                  </Button>
                </form>
                {totalResults > 0 && !loading &&
                  <React.Fragment>
                    <Typography variant="h4" component="h2" name="scroll-to-results">Results</Typography>
                    { displayResults && !skeltonLoad &&
                      <List component="nav" aria-label="knowledge search results" className={classes.root}>
                        {displayResults.map((result, i) => (
                          <Results
                            key={i}
                            index={i}
                            result={result}
                          />
                        ))}
                      </List>
                    }
                    {skeltonLoad &&
                      <React.Fragment>
                        <Typography component="h1" variant="h4">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Divider className={classes.skeltonDivider} />

                        <Typography component="h1" variant="h4">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Divider className={classes.skeltonDivider} />

                        <Typography component="h1" variant="h4">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Divider className={classes.skeltonDivider} />

                        <Typography component="h1" variant="h4">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Divider className={classes.skeltonDivider} />

                        <Typography component="h1" variant="h4">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Divider className={classes.skeltonDivider} />

                        <Typography component="h1" variant="h4">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Divider className={classes.skeltonDivider} />

                        <Typography component="h1" variant="h4">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Divider className={classes.skeltonDivider} />

                        <Typography component="h1" variant="h4">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Divider className={classes.skeltonDivider} />

                        <Typography component="h1" variant="h4">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Divider className={classes.skeltonDivider} />

                        <Typography component="h1" variant="h4">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                          <Skeleton />
                        </Typography>
                        <Divider className={classes.skeltonDivider} />
                      </React.Fragment>
                    }
                    <Typography variant="overline" display="block" gutterBottom>Total Results Answers - {totalResults}</Typography>

                    {pageCount > 1 &&
                      <Pagination count={pageCount} variant="outlined" color="primary" onChange={paginationChange} />
                    }
                    {pageCount &&
                      <React.Fragment></React.Fragment>
                    }
                  </React.Fragment>
                }
                {(!searchResults || searchResults.length <= 0) && !loading &&
                  <React.Fragment>
                    <Alert severity="warning">No Search Results to Display</Alert>
                  </React.Fragment>
                }
                {loading &&
                  <div className={classes.loaderCenter}>
                    <CircularProgress className={classes.loadingCenterInside} />
                  </div>
                }
              </Paper>

            </Grid>
            <Grid item md={4} xs={12}>
              <Paper elevation={3} className={classes.heroPaper}>
                <Typography variant="h6" component="h2">
                  Recent Searches
                </Typography>
                <Divider />
                <KnowledgeRecentSearch search={searchHistory} />
              </Paper>
            </Grid>
          </Grid>
        </Container>
        }
      </React.Fragment>
    );
  }

}

const mapStateToProps = state => ({
  rUserType: state.userType,
})

export default connect(mapStateToProps)(KnowledgePage);