import React from 'react'
import TextField from '@material-ui/core/TextField';

function SFEmail({component, currentVal, onFieldValueUpdate, itemLabel, i, ErrorStates, classes}) {
 return (

    <TextField
        className={classes.outlineInput}
        variant="outlined"
        id={component.label}
        name={component.label}
        label={component.label}
        fullWidth
        autoComplete={component.label}
        onChange={(event) => onFieldValueUpdate(component.field, event.target.value, event)}
        defaultValue={currentVal}
        key={'componentInput' + itemLabel + ',' + i}
        data-isnull={component.isNull}
        data-datatype={component.fieldInfo.dataType}
        onBlur={(e) => {}}
        error={ErrorStates[component.field]}
        helperText = {ErrorStates[component.field] ? 'Must be a valid email' : ''}
    />
  )
}

export default SFEmail