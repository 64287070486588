import React, { useReducer } from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { Checkbox, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import {useParams} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Alert from '@material-ui/lab/Alert';
import Chip from '@material-ui/core/Chip';
import { spacing } from '@material-ui/system';
import CircularProgress from '@material-ui/core/CircularProgress';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import axios from 'axios';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CasesRecentSearch from '../components/CasesRecentSearch';
import HistoryIcon from '@material-ui/icons/History';
import ApiManager from '../apiservice';
import Cookies from 'js-cookie';
import { Label } from '@material-ui/icons';
import modelSerialSearch from '../helpers/fuse/modelSerialSearch';


const useStyles = makeStyles((theme) => ({
  heroContent: {
    width: "90%",
    padding: theme.spacing(8, 0, 6)
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  divider: {
    margin: theme.spacing(0,0,2)
  },
  form: {
    margin: theme.spacing(0, 0, 5)
  },
  loaderCenter: {
    marginLeft: '50%',
  },
  loadingCenterInside: {
    left: '-20px',
    position: 'relative'
  },
  listItem: {
    width: '90%',
    marginLeft: '8px',
    display: "inline-block"
  },
  parentContainer: {
    '&.MuiGrid-container:hover':{
      backgroundColor:'#f5f5f5'
    },

  }
}));

export const CaseListPage = () => {
  const statusFilters = ["All", "Created",  "Updated", "Awaiting Feedback", "Resolved", "Forced Closed"];

  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [loading, setLoading] = useReducer((loading) => !loading, false);
  const [searchResults, setSearchResults] = useState(false);
  const [filteredResults, setFilteredResults] = useState(false);
  const [statusFilter, setStatusFilter] = useState(["Updated", "Created", "Awaiting Feedback"]);
  const [modelSerialSearchTerm, setmodelSerialSearchTerm] = useState();
  const [searchHistory, setSearchHistory] = useState(JSON.parse(sessionStorage.getItem('RecentCases')));



  useEffect(() => {
    // On Component Load
    fetchSearch();
  }, []);

  useEffect(() => {
    //Cant filter before searchResults are fetched.
    if(searchResults) {
      setFilteredResults(handleFilterUpdates());
    }
  }, [statusFilter, modelSerialSearchTerm]);

  useEffect(() => {
    // searchHistory is only updated once (after cases comes back from SF)
    // We need to run filters on page load incase any are set by default.
    if (searchResults) {
      setFilteredResults(handleFilterUpdates());
    }
  }, [searchResults]);

  function handleFilterUpdates() {
    //Perform Filtering using current filter/search values
    //Filter then search with filtered list
    //If both are null, default to unfiltered/searched searchResults
    let filteredList;
    if(!statusFilter || statusFilter === "All") {
      filteredList = searchResults;
    } else {
      filteredList = searchResults.filter(caseObj => {
        return statusFilter.includes(caseObj.fields.Status.displayValue);
      });
    }
    if(!modelSerialSearchTerm){
      return filteredList;
    } else {
      return modelSerialSearch(filteredList, modelSerialSearchTerm);
    }
  }

  const fetchSearch = (refresh = false) => {
    if(refresh == false)
      setLoading();
    var qs = require('qs');
    var config = {
        method: 'get',
        //url: process.env.REACT_APP_API + 'salesforce/kservice/Get?param=ui-api/list-records/Case/AllCases',
        url: process.env.REACT_APP_API + 'salesforce/kservice/Get?param=ui-api/list-records/Case/All_TSC_Cases_for_Dealer',
        headers: { 
            'Authorization': 'Bearer ' + Cookies.get("token"),//Middleware token
            'X-SalesForce-Token' : Cookies.get('sf_cookie'),//SF token for Middleware
            'X-Id-Token' : Cookies.get('idToken')//Refresh ID Token
            }
        };

    axios(config)
    .then(function (response) {
        setLoading();
        console.log("Loading set to: " + loading)
        setSearchResults(response.data.records.reverse());
        //^^ Setting searchResults triggers filters to run, and filteredResults to be set.
    })
    .catch((error) => {
        if (error.response) {
              if(error.response.status == "401")
              {
                console.log("Token expired");
                //Replace with middleware logic sometime
              }
            }
    });
  }

  function navigateToCaseDetail(id){
    console.log(id);
    history.push("/support/case/" + id);
  }

  function Results({result}) {
    return (
      // <React.Fragment>
      //   <ListItem button onClick={(event) => navigateToCaseDetail(result.id)}>
      //     <ListItemText
      //       primary={
      //         <React.Fragment>
      //           <Typography
      //             component="span"
      //             variant="h6"
      //             color="textPrimary"
      //             noWrap="true"
      //             className={classes.listItem}
      //           >
      //             {result.fields.Subject.value}
      //           </Typography>
      //         </React.Fragment>
      //       }
      //       secondary={
      //         <React.Fragment>
      //           <Typography
      //             component="span"
      //             variant="body2"
      //             color="textPrimary"
      //             gutterBottom
      //           >
      //           {"Case: " + result.fields.CaseNumber.value}&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                  
      //           </Typography>
      //         </React.Fragment>
      //       }
      //     />
      //     <ListItemIcon>
      //       <ArrowForwardIosIcon />
      //     </ListItemIcon>
      //   </ListItem>
      //   <Divider />
      // </React.Fragment>
      <Grid container id="ParentResultContainer" style={{marginTop: "8px"}} className={classes.parentContainer} onClick={(event) => navigateToCaseDetail(result.id)}>
        <Grid item md={10} sm={10} xs={10}>
          <Grid container id="LeftHandInfo">
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                component="span"
                variant="h6"
                color="textPrimary"
                noWrap={true}
                className={classes.listItem}
              >
                {result.fields.Subject.value}
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Grid container id="bottom half container">
                <Grid item md={6} sm={6} xs={6}>
                  <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                      gutterBottom
                      className={classes.listItem}
                    >
                    {"Case: " + result.fields.CaseNumber.value}&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                      
                    </Typography>
                </Grid>
                <Grid item md={6} sm={6} xs={6} style={{textAlign: "end", paddingRight: '32px'}}>
                  {result.fields.Status.value}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2} sm={2} xs={2}>
          <Grid container id="ChevronContainer"
            justify="center"
            flexDirection="column"
            alignItems="center"
            style={{height: "100%"}}
          >
            <ArrowForwardIosIcon/>
          </Grid>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Divider/>
        </Grid>
     </Grid>
    )
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.heroContent}>
          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              <Paper elevation={3} className={classes.heroPaper}>
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <Typography variant="h4" component="h1">Case History</Typography>
                    <Divider className={classes.divider} />
                  </Grid>
                  {filteredResults && !loading &&
                    <>
                      <Grid item md={6} xs={6}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="statusFilterLabel">Status</InputLabel>
                          <Select
                            label='Status'
                            labelId={"statusFilterLabel"}
                            defaultValue=""
                            multiple  
                            id={"statusSelect"}
                            value={statusFilter}
                            renderValue={(selected) => selected.join(', ')}
                            onChange={(event) => {
                              if (event.target.value.includes("All")) {
                                // If ALL was NOT previously selecetd, we are now selecting it. Select everything
                                if (!statusFilter.includes("All")) {
                                  setStatusFilter(statusFilters);
                                }
                              } 
                              else if (statusFilter.includes("All") && !event.target.value.includes("All")){
                                // If ALL was previously selected but now was just deselected. Unselect everything
                                setStatusFilter([]);
                              }
                              else {
                                setStatusFilter(event.target.value)
                              }
                            }}
                          >
                            { statusFilters.map((filter) => {
                              var isAllSelected = statusFilter.includes("All");
                              return(
                                <MenuItem
                                  key={"StatusFilter-" + filter}
                                  value={filter}
                                  disabled={(filter === "All") ? false : isAllSelected}
                                >
                                   <Checkbox color="primary" checked={statusFilter.includes(filter)} />
                                  <ListItemText primary={filter } />
                                </MenuItem> 
                              )})
                            }
                          </Select>     
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={6}>
                      </Grid>
                      <Grid item md={3} xs={4}>
                        <TextField fullWidth id="outlined-basic" label="Model or Serial" variant="outlined" 
                          onChange={(e) => {
                              setmodelSerialSearchTerm(e.currentTarget.value);
                          }}
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                          <List component="nav" aria-label="knowledge search results" className={classes.root}>
                            {filteredResults.map((result, i) => (
                              <Results
                                key={i}
                                index={i}
                                result={result}
                              />
                            ))}
                          </List>
                      </Grid>
                    </>
                  }
                  {(!filteredResults || filteredResults.length <= 0) && !loading &&
                    <Grid item md={12} xs={12}>
                      <Alert severity="warning">No Case History to Display</Alert>
                    </Grid>
                  }
                  {loading &&
                    <Grid item md={12} xs={12}>
                      <div className={classes.loaderCenter}>
                        <CircularProgress className={classes.loadingCenterInside} />
                      </div>
                    </Grid>
                  }              
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={4} xs={12}>
              <Paper elevation={3} className={classes.heroPaper}>
                <Typography variant="h6" component="h2">
                  Recent Cases
                </Typography>
                <Divider />
                <CasesRecentSearch />

              </Paper>
            </Grid>
          </Grid>
      </Container>


    </React.Fragment>
  );
}
