import React, { useReducer } from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { TextField, MenuItem, InputLabel, Select } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Alert from '@material-ui/lab/Alert';
import Chip from '@material-ui/core/Chip';
import { spacing } from '@material-ui/system';
import CircularProgress from '@material-ui/core/CircularProgress';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import axios from 'axios';
import ApiManager from '../apiservice';
import Cookies from 'js-cookie';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    width: "90%",
    padding: theme.spacing(8, 0, 6),
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  form: {
    margin: theme.spacing(0, 0, 5)
  },
  loaderCenter: {
    marginLeft: '50%',
  },
  loadingCenterInside: {
    left: '-20px',
    position: 'relative'
  },
  formControl: {
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: 'none',
  },
  listItem: {
    width: '90%',
    display: "inline-block"
  }
}));

export const DealerLookupPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [loading, setLoading] = useReducer((loading) => !loading, false);
  const [searchResults, setSearchResults] = useState(false);
  const [state, updateState] = useState("");
  const [dealerSet, updateDealerSet] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [formData, updateFormData] = useState('');

  const handleChange = (e) => {
    if (e.target.name == "state") {
      updateState(e.target.value);
    }
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim()
    });
  };

  const handleSubmit = (e) => {
    //Prevents form from actually submitting/doing its action
    e.preventDefault();
    //Starts the loading circle thing. Call setLoading(false) when searching results come in.
    setLoading(true);

    //Leftover from OSC endpoints. Left in for ease of searching/tracking. Dont delete please ^_^
    // let url = process.env.REACT_APP_OSVC_DOMAIN + '/cc/MobileSearch/searchDealer';

    //Here are your params : )
    let city = formData.cityName;
    let state = formData.state;
    let dealer = (formData.dealerName) ? '%' + formData.dealerName + '%' : null;
    let dealerNumber = formData.dealerNumber;

    if(!(city || state || dealer || dealerNumber)) {
      setErrorMessage("Atleast one field is required");
      setLoading(false);
      return;
    }
    //Clear any error messages on a sucessfull search
    if(errorMessage) {
      setErrorMessage('');
    }
   
    //Construct SOQL Query
    var q = 'SELECT Id, Name, BillingCity, BillingState FROM Account WHERE ';
    if(dealer){
      q +=  'Name LIKE \''+dealer+'\' AND ';
    }
    if(city){
      q +=  'BillingCity LIKE \''+city+'\' AND ';
    }
    if(state){
      q +=  'BillingState LIKE \''+state+'\' AND ';
    }
    if(dealerNumber) {
      q +=  'AccountNumber LIKE \''+ dealerNumber+'\' AND ';
    }

    q = q.substring(0, q.length - 5);
    console.log('Query before encoding: '+q);
    q = encodeURIComponent(q);
    console.log('Query after encoding: '+q);

    var url = process.env.REACT_APP_API + 'salesforce/kservice/Get?param=query/?q='+q;

    const req = {
      method: "GET",
      url: url,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + Cookies.get("token"),
        'X-SalesForce-Token' : Cookies.get('sf_cookie'),
        'X-Id-Token' : Cookies.get('idToken')
      }
    }

    axios(req).then((response) => {
      setLoading(false);
      if (response.data) {
        console.log("Dealer lookup response");
        //columnNames: [CaseNumber, RecordTypeId, Subject, Model_Number__c, CreatedDate, status, AccountId]
        const columnNamesData = ['org_id', 'Name', 'City', 'State/Province'];
        //count: data.length
        const countData = response.data.length;
        //links:
        const linksData = [];
        //name:
        const nameData = 'getDealer';
        //rows: for [[response.data.Id, response.data.Name, response.data.BillingCity, response.data.State]]
        const rowsData = [];
        for (const row of response.data.records){
          //Case number, record type, subject, model number, date opened, status, owner
          rowsData.push([row.Id, row.Name, row.BillingCity, row.BillingState]);
        }
        var newResponseData = {
          columnNames: columnNamesData,
          count: countData,
          links: linksData,
          name: nameData,
          rows: rowsData
        };
        response = ApiManager.csvToJSON(newResponseData).reverse();
        setSearchResults(response);
      } else {
        setSearchResults(false);
      }
    }).catch(function (error) {
      console.log(error);
    });

  };


  //Shouldnt need to touch anything below here.

  function dealerClicked(org_id) {
    console.log("dealer clicked called");
    console.log(org_id);
    history.push("/support/dealer/cases/" + org_id);
  }

  function ErrorMessageDisplay(){
    return(<Alert severity="error" className={classes.error}>{errorMessage}</Alert>);
  }

  function Results({ result }) {
    return (
      <React.Fragment>
        <ListItem button onClick={(event) => dealerClicked(result.org_id)}>
          <ListItemText
            primary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="h6"
                  color="primary"
                  noWrap="true"
                  className={classes.listItem}
                >
                  {result.Name}
                </Typography>
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                  gutterBottom
                >
                  {result.City + "," + result["State/Province"]}
                </Typography>
              </React.Fragment>
            }
          />
          <ListItemIcon>
            <ArrowForwardIosIcon />
          </ListItemIcon>
        </ListItem>
        <Divider />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.heroContent}>
        <Grid container spacing={2}>
          <Grid item md={2} xs={12}>
          </Grid>
          <Grid item md={8} xs={12}>
            <Paper elevation={3} className={classes.heroPaper}>
              <Typography variant="h4" component="h1">Dealer Search</Typography>
              {errorMessage && (
                <ErrorMessageDisplay/>
            )}

              <form className={classes.form} noValidate onSubmit={e => { e.preventDefault(); }}>
                <TextField variant="outlined" margin="normal" fullWidth id="cityName" label="City" name="cityName" autoFocus onChange={handleChange} />

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="State">State</InputLabel>
                  <Select labelId="State" id="select" value={state} name="state" onChange={handleChange}>
                    <MenuItem value="AL">Alabama</MenuItem>
                    <MenuItem value="AK">Alaska</MenuItem>
                    <MenuItem value="AZ">Arizona</MenuItem>
                    <MenuItem value="AR">Arkansas</MenuItem>
                    <MenuItem value="CA">California</MenuItem>
                    <MenuItem value="CO">Colorado</MenuItem>
                    <MenuItem value="CT">Connecticut</MenuItem>
                    <MenuItem value="DE">Delaware</MenuItem>
                    <MenuItem value="DC">District Of Columbia</MenuItem>
                    <MenuItem value="FL">Florida</MenuItem>
                    <MenuItem value="GA">Georgia</MenuItem>
                    <MenuItem value="HI">Hawaii</MenuItem>
                    <MenuItem value="ID">Idaho</MenuItem>
                    <MenuItem value="IL">Illinois</MenuItem>
                    <MenuItem value="IN">Indiana</MenuItem>
                    <MenuItem value="IA">Iowa</MenuItem>
                    <MenuItem value="KS">Kansas</MenuItem>
                    <MenuItem value="KY">Kentucky</MenuItem>
                    <MenuItem value="LA">Louisiana</MenuItem>
                    <MenuItem value="ME">Maine</MenuItem>
                    <MenuItem value="MD">Maryland</MenuItem>
                    <MenuItem value="MA">Massachusetts</MenuItem>
                    <MenuItem value="MI">Michigan</MenuItem>
                    <MenuItem value="MN">Minnesota</MenuItem>
                    <MenuItem value="MS">Mississippi</MenuItem>
                    <MenuItem value="MO">Missouri</MenuItem>
                    <MenuItem value="MT">Montana</MenuItem>
                    <MenuItem value="NE">Nebraska</MenuItem>
                    <MenuItem value="NV">Nevada</MenuItem>
                    <MenuItem value="NH">New Hampshire</MenuItem>
                    <MenuItem value="NJ">New Jersey</MenuItem>
                    <MenuItem value="NM">New Mexico</MenuItem>
                    <MenuItem value="NY">New York</MenuItem>
                    <MenuItem value="NC">North Carolina</MenuItem>
                    <MenuItem value="ND">North Dakota</MenuItem>
                    <MenuItem value="OH">Ohio</MenuItem>
                    <MenuItem value="OK">Oklahoma</MenuItem>
                    <MenuItem value="OR">Oregon</MenuItem>
                    <MenuItem value="PA">Pennsylvania</MenuItem>
                    <MenuItem value="RI">Rhode Island</MenuItem>
                    <MenuItem value="SC">South Carolina</MenuItem>
                    <MenuItem value="SD">South Dakota</MenuItem>
                    <MenuItem value="TN">Tennessee</MenuItem>
                    <MenuItem value="TX">Texas</MenuItem>
                    <MenuItem value="UT">Utah</MenuItem>
                    <MenuItem value="VT">Vermont</MenuItem>
                    <MenuItem value="VA">Virginia</MenuItem>
                    <MenuItem value="WA">Washington</MenuItem>
                    <MenuItem value="WV">West Virginia</MenuItem>
                    <MenuItem value="WI">Wisconsin</MenuItem>
                    <MenuItem value="WY">Wyoming</MenuItem>
                  </Select>
                </FormControl>

                <TextField variant="outlined" margin="normal" fullWidth name="dealerName" label="Dealer Name" id="dealerName" onChange={handleChange} />
                <TextField variant="outlined" margin="normal" fullWidth name="dealerNumber" label="Dealer Number" id="dealerNumber" onChange={handleChange} />
                <Button type="submit" fullWidth variant="contained" color="primary" size="large" className={classes.submit} onClick={handleSubmit} >Find Dealer</Button>
              </form>
              {searchResults && searchResults.length < 1 && !loading &&
                <Alert severity="warning">No Search Results to Display</Alert>
              }
              {searchResults && !loading &&
                <React.Fragment>
                  <List component="nav" aria-label="knowledge search results" className={classes.root}>
                    {searchResults.map((result, i) => (
                      <Results
                        key={i}
                        index={i}
                        result={result}
                      />
                    ))}
                  </List>
                </React.Fragment>
              }
              {loading &&
                <div className={classes.loaderCenter}>
                  <CircularProgress className={classes.loadingCenterInside} />
                </div>
              }
            </Paper>

          </Grid>
          <Grid item md={2} xs={12}>
          </Grid>
        </Grid>
      </Container>


    </React.Fragment>
  );
}
