import { CircularProgress, Container, CssBaseline, Divider, Grid, Paper, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import { app } from '../firebaseConfig';
import { Alert } from '@material-ui/lab';
import Cookies from 'js-cookie';
import axios from 'axios';




const useStyles = makeStyles((theme) => ({
    heroContent: {
        padding: theme.spacing(8, 0, 6),
        width: '90%'
      },
      heroPaper: {
        padding: theme.spacing(1, 2, 2),
        margin: theme.spacing(0, 0, 4)
      },
      heading: {
        padding: theme.spacing(1, 0, 0)
      },
      loadingCenterInside: {
        marginTop: '2%',
        marginLeft: '48%',
      }
  }));

function DealerContactsPage() {
    const classes = useStyles();
    const [sectionsFromFirebase, setSectionsFromFirebase] = useState();
    const [dealerContactsFromSalesforce, setDealerContactsFromSalesforce] = useState();
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();
    useEffect(() => {
        let promises = [];
        promises.push(fetchDealerContactSections());
        promises.push(fetchContactsFromSalesforce());
        Promise.all(promises)
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                debugger;
                console.log('Error fetching dealer contacts: ', error);
                setErrorMessage('There was an error fetching dealer contacts, please contact your Administrator');
                setLoading(false);
            });
    }, []);

    const fetchDealerContactSections = () => {
        return new Promise((resolve,reject) => {
            setLoading(true);
            const remoteConfig = getRemoteConfig(app);
            fetchAndActivate(remoteConfig)
            .then(() => {
                console.log(getValue(remoteConfig, "DealerContactSections"));
                var sections = JSON.parse(getValue(remoteConfig, "DealerContactSections")._value);
                setSectionsFromFirebase(sections);
                resolve();
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
        })
        
    }
    const fetchContactsFromSalesforce = () => {
        return new Promise((resolve, reject) => {
            let accountRecordId = Cookies.get("a_id");
            let ContactsRequest = {
                url : process.env.REACT_APP_API + `salesforce/kservice/apexrest?param=DealerContacts?recordId=${accountRecordId}`,
                method: "GET",
                headers : {
                    "Authorization": "Bearer " + Cookies.get('token'),
                }
            }
            axios(ContactsRequest)
                .then((response) => {
                    setDealerContactsFromSalesforce(response.data);
                    resolve();
                })
                .catch((e) => {
                    reject(e);
                });
        }); 
    }

    function RenderContacts({sectionsFromFirebase}){
        var test = sectionsFromFirebase;
        debugger;
        return(
            <>
                { Object.entries(sectionsFromFirebase).map((section, index) => {
                        return (
                            <Grid item md={12} key={"dealerSection"+index}>
                               <DealerContactSection
                                section={section}
                                rowIndex={index}
                               />
                            </Grid>
                        );
                    })
                }
            </>
        );
    }
    function DealerContactSection({section, rowIndex}){
        if(section[1]) {
            return (
                <Grid container style={{marginTop: '8px'}}>
                    <Grid item md={12}>
                        <Typography>
                            <strong>{section[0]}</strong>
                        </Typography>
                    </Grid>
                    {section[1].map((field, index) => {
                        var fieldNameAsLower = field.toLowerCase();
                        var fieldName = field;
                        if(fieldNameAsLower.includes('name')){
                            fieldName = 'Name';
                        } else if(fieldNameAsLower.includes('email')){
                            fieldName = 'Email'
                        } else if (fieldNameAsLower.includes('number')) {
                            fieldName = 'Number'
                        }
                        var test = dealerContactsFromSalesforce;
                        debugger;
                        return (
                            <Grid item md={6} key={'dealerContactField' + rowIndex + index}>
                                <Typography variant="body1" component="h1">{fieldName +': ' + dealerContactsFromSalesforce[field]}</Typography>
                            </Grid>
                        );
                    })
    
                    }
                </Grid>
            );
        } 
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="lg" component="main" className={classes.heroContent}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item md={8} xs={12}>
                    
                        <Paper elevation={3} className={classes.heroPaper}>
                        
                            <Grid container>
                                <Grid item md={12} xs={12}>
                                {loading &&
                                    <CircularProgress className={classes.loadingCenterInside} />
                                }
                                {!loading && 
                                    <>
                                        <Typography component="h1" variant="h4" className={classes.heading}>Dealer Contacts</Typography>
                                        <Divider />
                                        { errorMessage &&
                                            <Alert severity="error" style={{marginTop: '8px'}}>{errorMessage}</Alert>
                                        }
                                        { !errorMessage &&
                                            <>
                                                <Grid container>
                                                    <RenderContacts
                                                        sectionsFromFirebase={sectionsFromFirebase}
                                                    />
                                                </Grid>
                                            </>
                                        }
                                    </>
                                }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default DealerContactsPage