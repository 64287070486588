import React, { useReducer } from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Cookies from 'js-cookie';
import { getMessaging, onMessage } from "firebase/messaging";
import { connect } from 'react-redux';
import ApiManager from '../apiservice';
import { getAnalytics, logEvent } from "firebase/analytics";


const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
    width: '90%'
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  heading: {
    padding: theme.spacing(1, 0, 0)
  },
  loaderCenter: {
    marginTop: theme.spacing(2),
    marginLeft: '50%',
    left: '-20px',
    position: 'relative'
  },

}));

const MessagesPage = ( { rUserType } ) => {
  const classes = useStyles();
  const [messages, setMessages] = useState([]);
  const [messageTitle, setMessageTitle] = useState(null);
  const [messageDescription, setMessageDecription] = useState(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  function navigateToMessage(item){
    console.log("Navigating to Case: " + item.id);
    var url;
    if (item.type == "Machine") {
      url = `/support/machine/details/sn-${item.modelNumber}/mn-${item.serialNumber}`;
    } else if(item.type = "Knowledge") {
      url = `/knowledge?s=${item.searchTerm}&c=${item.knowledgeCategory}`;
    } else if(item.type == "Case" || item.type == "Report") {
      url = `/support/case/${item.id}`;
    }
    history.push(url); 
  }

  useEffect(() => {
    const messaging = getMessaging();
    fetchMessages();
  }, []);

  function fetchMessages(){
    console.log("FETCH MESSAGE HIT");
    setLoading(true);
    const url = process.env.REACT_APP_API + 'firebase/kservice/FetchNotifications';
    const req = {
      method: "post",
      url: url,
      headers: {  
        "Content-Type": "application/json; charset=UTF-8",
        "Accept": "application/json",
        "Authorization": "Bearer " + Cookies.get("token"),
      },
      data: {
        "ContactId" : Cookies.get("c_id")
      }
    }
    axios(req)
      .then((response) => {
        console.log("fetch message response..");
        response = response.data.notifications;
        let tempArray = [];
        for (var i = 0; i < response.length; i++) {
          console.log("History Message")
          let tempNotif = {"type": response[i].type, "title" : response[i].title, "description" : response[i].body, "id" : response[i].Id, "received" : response[i].delivered, "firebaseID" : response[i].firebaseID}; 
          if(tempNotif.type == "Knowledge") {
            tempNotif = {
              ...tempNotif,
              'knowledgeCategory': response[i].knowledgeCategory,
              'searchTerm': response[i].searchTerm,
            }
          } else if (tempNotif.type == "Machine") {
            tempNotif = {
              ...tempNotif,
              'modelNumber': response[i].modelNumber,
              'serialNumber': response[i].serialNumber
            }
          }
          tempArray.push(tempNotif);
        }
        setMessages(tempArray);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Not sending" + error);
        setLoading(false);
    });
  }
  function messageDelete(result){
    var req = {
      method: "POST",
      url: process.env.REACT_APP_API + 'firebase/kservice/DeleteNotification',
      data: {
        "FirebaseID": result.firebaseID
      },
       headers: {  
        "Content-Type": "application/json; charset=UTF-8",
        "Accept": "application/json",
        "Authorization": "Bearer " + Cookies.get("token"),
      }
    };
    axios(req)
      .then(() => {
        console.log("Deleted message");
        setMessages( messages.filter( function (array) {
          return array !== result;
        }));
      })
      .catch((e) => {
        console.log(e);
      });
  }


  function MessageRender({result}){
    //console.log("Message" + result);
    return(
      <React.Fragment>
        <ListItem button onClick={(event) => navigateToMessage(result)}>
          <ListItemText
          primary={
            <Typography
            variant="h5"

            >
              {result.title}
            </Typography>
          }
          secondary={
            <Typography variant="subtitle1">
              {result.description}
            </Typography>
          }
          />
          {/* <ListItemIcon>
            <ArrowForwardIosIcon />
          </ListItemIcon> */}
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={() => messageDelete(result)}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>

        </ListItem>
        <Divider />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <CssBaseline />
      {rUserType &&
        <Container maxWidth="lg" component="main" className={classes.heroContent}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item md={8} xs={12}>
            <Paper elevation={3} className={classes.heroPaper}>
              <Typography component="h1" variant="h4" className={classes.heading}>Messages</Typography>
              <Divider />
              {loading &&
                <div className={classes.loaderCenter}>
                  <CircularProgress className={classes.loadingCenterInside} />
                </div>
              }
              {messages && !loading &&
                <React.Fragment>
                  <List component="nav" aria-label="knowledge search results" className={classes.root}>
                    {messages.map((result, i) => (
                      console.log(result),
                      <MessageRender
                        key={i}
                        index={i}
                        result={result}
                      />
                    ))}
                  </List>
                </React.Fragment>
              }
              {(!loading && (!messages || messages.length <= 0)) &&
                <React.Fragment>
                  <Alert severity="warning">No Messages to Display</Alert>
                </React.Fragment>
              }
            </Paper>
          </Grid>
        </Grid>
      </Container>
      }
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  rUserType: state.userType,
})

export default connect(mapStateToProps)(MessagesPage);
