import React from 'react';
import { useState, useEffect } from 'react';
import { Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from "react-router-dom";
import HistoryIcon from '@material-ui/icons/History';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles({
    centered: {
        textAlign: 'center',
    }
});

const CasesRecentSearch = () => {
    const classes = useStyles();
    const history = useHistory();
    const [sHistory, updateHistory] = useState(JSON.parse(sessionStorage.getItem('RecentCases')));

    useEffect(() => {
        // On Component Load
        let searchHistory = JSON.parse(sessionStorage.getItem('RecentCases')) || null;
        // console.log(searchHistory);
        updateHistory(searchHistory);

    }, []);



    function RecentSearch({ search }){
        const handleListItemClick = (search, event) => {
            //let urlSearch = encodeURIComponent(search.term);
            let urlId = search.id;
            let location = '/support/case/'+urlId;
            console.log(location);
            // window.location.href = location;
            history.push(location)
          };


        return (
            <React.Fragment>
                <ListItem button onClick={(event) => handleListItemClick(search, event)}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="h6"
                                  //color="textPrimary"
                                  style={{fontWeight: 600}}
                                >
                                  {search.subject}
                                </Typography>
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                              <div style={{overflow: "hidden", textOverflow: "ellipsis"}}>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="textPrimary"
                                    noWrap
                                    // minWidth={250}
                                >
                                    ID: {search.referenceNumber}&emsp;&emsp;&emsp;
                                    Status: {search.status}

                                </Typography>
                              </div>
                            </React.Fragment>
                        }
                    />
                    <ListItemIcon>
                        <ArrowForwardIosIcon />
                    </ListItemIcon>
                </ListItem>
                <Divider />
            </React.Fragment>
        );
    }

    if (sHistory) {
        return (
            <List component="nav" aria-label="cases recent searches" className={classes.root}>
                {sHistory.map((search, i) => (
                    <RecentSearch
                        key={i}
                        index={i}
                        search={search}
                    />
                ))}
            </List>
        );
    }
    if (!sHistory) {
        return (
            <p className={classes.centered}>
                <HistoryIcon centered />
                <Typography centered variant="caption" display="block" gutterBottom>No Search History</Typography>
            </p>
        );
    }
}

export default CasesRecentSearch;
