import axios from "axios";
import Cookies from "js-cookie";

export default function MakeAnalyticsRequest(payload) {
    var analyticsRequest = {
            // "url" : "https://analytics.cloud.coveo.com/rest/ua/v15/analytics/collect",
            "url" : process.env.REACT_APP_API + "Coveo/kservice/analytics",
            "method" : "post",
            headers: {
                'Authorization': 'Bearer ' + Cookies.get("token"),
                'X-Coveo-Token': Cookies.get("CoveoToken"),
                'Content-Type': 'application/json'
            },
            data: payload
        };
    axios(analyticsRequest)
        .catch((error) => {
            console.log(error);
        });
}