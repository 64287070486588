import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import { convertTimeToRelative } from '../helpers/convertTimestampToRelative';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

function AdminNotificationHistoryTable({notifications}) {
  return (
    <>
        { notifications &&
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{backgroundColor: "#9ca3af"}}>
                    <TableRow>
                    <TableCell >Title</TableCell>
                    <TableCell >Subject</TableCell>
                    <TableCell >Recipients</TableCell>
                    <TableCell >Timestamp</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {notifications.map((row, index) => (
                    <TableRow
                        key={"Notif" + index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                        {row.title}
                        </TableCell>
                        <TableCell >{row.body}</TableCell>
                        <TableCell >{row.count}</TableCell>
                        <TableCell >{convertTimeToRelative(row.timestamp)}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
        }
    </>
    
  )
}

export default AdminNotificationHistoryTable