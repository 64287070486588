import React, { useReducer } from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import {useParams} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Alert from '@material-ui/lab/Alert';
import Chip from '@material-ui/core/Chip';
import { spacing } from '@material-ui/system';
import CircularProgress from '@material-ui/core/CircularProgress';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import axios from 'axios';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CasesRecentSearch from '../components/CasesRecentSearch';
import HistoryIcon from '@material-ui/icons/History';
import ApiManager from '../apiservice';
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    width: "90%",
    padding: theme.spacing(8, 0, 6),
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  divider: {
    margin: theme.spacing(0,0,2)
  },
  form: {
    margin: theme.spacing(0, 0, 5)
  },
  loaderCenter: {
    marginLeft: '50%',
  },
  loadingCenterInside: {
    left: '-20px',
    position: 'relative'
  },
  listItem: {
    width: '90%',
    display: "inline-block"
  }
}));

export const DealerCaseListPage = () => {
  const id = useParams();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [loading, setLoading] = useReducer((loading) => !loading, false);
  const [searchResults, setSearchResults] = useState(false);
  const [searchHistory, setSearchHistory] = useState(JSON.parse(sessionStorage.getItem('RecentCases')));



  let params = useParams();

  useEffect(() => {
    // On Component Load
    fetchSearch();

  }, []);

  const fetchSearch = () => {
    setLoading();
      var url = process.env.REACT_APP_API + 'salesforce/kservice/Get?param=ui-api/records/'+id.orgID+'/child-relationships/'
              + 'Cases?fields=Case.Id,Case.CaseNumber,Case.Subject,Case.Status%26pageSize=100'
      console.log('URL: '+url);
      var req = {
        method : 'GET',
        url: url,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + Cookies.get("token"),
          'X-SalesForce-Token' : Cookies.get('sf_cookie')
        }
      }
      axios(req).then((response) => {
        if(response.data){
          const columnNamesData = ['CaseNumber', 'Subject', 'Status', 'Case ID'];
          const countData = response.data.count;
          const linksData = [];
          const nameData = 'getRelatedCases';
          const rowsData = [];
          for (const row of response.data.records){
            let fields = row.fields;
            rowsData.push([fields.CaseNumber.value, fields.Subject.value, fields.Status.value, fields.Id.value]);
          }
          var newResponseData = {
            columnNames: columnNamesData,
            count: countData,
            links: linksData,
            name: nameData,
            rows: rowsData
          };
          response = ApiManager.csvToJSON(newResponseData);
          setSearchResults(response.reverse());
          console.log(searchResults);
        }
        console.log("case list response testing "+response);
        setLoading();
      }).catch((error) => {
        console.log(error);
      })
  }

  function navigateToCaseDetail(id){
    console.log(id);
    history.push("/support/case/" + id);
  }

  function Results({result}) {
    return (
      <React.Fragment>
        <ListItem button onClick={(event) => navigateToCaseDetail(result['Case ID'])}>
          <ListItemText
            primary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="h6"
                  color="textPrimary"
                  noWrap="true"
                  className={classes.listItem}
                >
                  {result.Subject}
                </Typography>
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                  gutterBottom
                >
                {"Case: " + result.CaseNumber}&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                  <Chip
                    label={"Status: " + result.Status}
                    clickable
                    color="primary"
                    variant="outlined"
                  />
                </Typography>
              </React.Fragment>
            }
          />
          <ListItemIcon>
            <ArrowForwardIosIcon />
          </ListItemIcon>
        </ListItem>
        <Divider />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.heroContent}>
        <Grid container spacing={2}>
          <Grid item md={8} xs={12}>
            <Paper elevation={3} className={classes.heroPaper}>
            <Typography variant="h4" component="h1">Case History</Typography>
            <Divider className={classes.divider} />
              {searchResults && !loading &&
                <React.Fragment>
                  <List component="nav" aria-label="knowledge search results" className={classes.root}>
                    {searchResults.map((result, i) => (
                      <Results
                        key={i}
                        index={i}
                        result={result}
                      />
                    ))}
                  </List>
                </React.Fragment>
              }
              {(!searchResults || searchResults.length <= 0) && !loading &&
                <React.Fragment>
                  <Alert severity="warning">No Case History to Display</Alert>
                </React.Fragment>
              }
              {loading &&
                <div className={classes.loaderCenter}>
                  <CircularProgress className={classes.loadingCenterInside} />
                </div>
              }
            </Paper>

          </Grid>
          <Grid item md={4} xs={12}>
            <Paper elevation={3} className={classes.heroPaper}>
              <Typography variant="h6" component="h2">
                Recent Cases
              </Typography>
              <Divider />
              <CasesRecentSearch />

            </Paper>
          </Grid>
        </Grid>
      </Container>


    </React.Fragment>
  );
}
