import Fuse from 'fuse.js'

const fuseOptions = {
	// isCaseSensitive: false,
	includeScore: true,
	// shouldSort: true,
	// includeMatches: false,
	// findAllMatches: false,
	// minMatchCharLength: 1,
	location: 0,
	threshold: 0.3,
	// distance: 100,
	// useExtendedSearch: false,
	// ignoreLocation: false,
	// ignoreFieldNorm: false,
	// fieldNormWeight: 1,
	keys: [
		
	]
};

export default function machineSearch(machineList, facet, keyword) {
    //Add facet to the fuseOption keys list
    fuseOptions.keys = [facet];
    const fuse = new Fuse(machineList, fuseOptions);
    var results = fuse.search(keyword);
    return results;
}