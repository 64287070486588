import { Button, Divider, Grid, Paper, Typography } from '@material-ui/core';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useHistory } from "react-router-dom";
import SvgIcon from '@material-ui/core/SvgIcon';
import Icon from '@material-ui/core/Icon';

import React, { useState } from 'react';
import { useEffect } from 'react';

const MachineCard = ({machine, classes, index, iconList}) => {
    const storage = getStorage();
    const history = useHistory();
    const [icon, setIcon] = useState();

    function handleMachineClick(machine){
        let trimmedSerialNumber = machine.Serial.substring(machine.Serial.length - 5);
        let URLString = `/support/machine/details/sn-${trimmedSerialNumber}/mn-${machine.ModelNumber}`;
        if(machine.Product_Identification_No__c) {
            URLString += `/pin-${machine.PIN}`;
        }
        history.push(URLString);
    }
    useEffect(() => {
        if(machine.icon) {
            fetchIconBlob(machine.icon)
        } else {
            setIcon(false);
        }
    }, [machine.icon]);

    function fetchIconBlob(imgSrc){
        return new Promise((resolve, reject) => {
            const gsReference = ref(storage, imgSrc);
            getDownloadURL(ref(storage, gsReference))
            .then((url) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    let imgURL = URL.createObjectURL(xhr.response);
                    setIcon(imgURL);
                };
                xhr.open('GET', url);
                xhr.send();
            })
            .catch((error) => {
                // Handle any errors
                console.log(error);
                reject(false);
            });
        });   
    }

    return (
        <React.Fragment>
            <Grid container className={classes.machineCard}>
                <Grid item md={12} style={{borderBottomWidth: '0.5px', borderBottomStyle:'dashed' }}>
                    <Grid container>
                        <Grid item justifyContent='center' alignContent='center' alignItems='center' style={{marginBottom: '4px', minWidth: '78px'}}>
                            <Paper elevation={0} style={{border: 'solid 1px orange', borderRadius: 15, padding: '4px', display: "flex", justifyContent: "center"}}>
                                { !icon &&
                                    <LocalShippingIcon style={{fontSize: "48px", stroke: "#ffffff", strokeWidth: 1}}/>
                                }
                                { icon &&
                                    <img height={'48px'} src={icon}/>
                                }
                            </Paper>
                        </Grid>
                        <Grid item md={10}>
                            <Grid container style={{paddingLeft: "8px"}}>
                                <Grid md={6}>
                                    <Typography color='primary' variant='subtitle2'>
                                        {machine.ModelNumber}
                                    </Typography>
                                </Grid>
                                <Grid md={6} style={{textAlign: 'right'}}>
                                    <Typography color='primary' variant='subtitle2' >
                                        {machine.Serial}
                                    </Typography>
                                </Grid>
                                <Grid md={12} >
                                    <Typography variant='subtitle2' style={{fontWeight: 'bold'}}> 
                                        {machine.Description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} style={{borderBottomWidth: '0.5px', borderBottomStyle:'dashed', padding: '4px', paddingTop: '8px', paddingBottom: '8px'}}>
                    <Grid container>
                        <Grid md={8}>
                            <Typography variant='subtitle2'>
                                Customer Name
                            </Typography>
                        </Grid>
                        <Grid md={4} style={{textAlign: 'right'}}>
                            <Typography variant='subtitle2'>
                             Location
                            </Typography>
                        </Grid>
                        <Grid md={8}>
                            <Typography variant='subtitle2' style={{fontWeight: 'bold'}}>
                                {machine.CustomerName}
                            </Typography>
                        </Grid>
                        <Grid md={4} style={{textAlign: 'right' }}>
                            <Typography variant='subtitle2' noWrap style={{fontWeight: 'bold'}}>
                                {machine.City}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} style={{padding: '4px', paddingTop: '8px', paddingBottom: '16px'}}>
                    <Grid container>
                        <Grid item md={9}>
                            <Grid container>
                                <Grid item md={12}>
                                    <Typography variant='subtitle2'>
                                     Service Dealer
                                    </Typography>
                                </Grid>
                                <Grid item md={12} >
                                    <Typography variant='subtitle2' style={{fontWeight: 'bold'}}>
                                        {machine.ServiceDealer}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={3}>
                            <Grid container direction={'row-reverse'} textAlign='center' alignItems='veritcal'>
                                <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        onClick={() => {handleMachineClick(machine)}}
                                        style={{borderRadius : 50, marginTop: '8px'}}
                                    >
                                        View
                                    </Button>
                                </Grid>
                            </Grid>
                       </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default MachineCard;
