import React from 'react'
import { makeStyles, createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@mui/material/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SFDateTime from '../components/input/SFDateTime';
import SFTime from '../components/input/SFTime';
import SFLookupRelationship from '../components/input/SFLookupRelationship';
import SFPicklist from "../components/input/SFPicklist";
import SFPicklistTest from "../components/input/SFPicklistTest";
import SFText from '../components/input/SFText';
import SFTextArea from '../components/input/SFTextArea';
import SFNumber from '../components/input/SFNumber';
import SFPercent from '../components/input/SFPercent';
import SFCurrency from '../components/input/SFCurrency';
import SFEmail from '../components/input/SFEmail';
import SFCheckbox from '../components/input/SFCheckbox';
import TextField from '@material-ui/core/TextField';
import FormControl from '@mui/material/FormControl';
import Container from '@material-ui/core/Container';





const useStyles = makeStyles((theme) => ({
  heroContent: {
    width: "90%",
    padding: theme.spacing(8, 0, 6),
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  divider: {
    margin: theme.spacing(0,0,2)
  },
  form: {
    margin: theme.spacing(0, 0, 5)
  },
  loaderCenter: {
    marginLeft: '50%',
  },
  loadingCenterInside: {
    left: '-20px',
    position: 'relative'
  },
  listItem: {
    width: '90%',
    display: "inline-block"
  },
  textField: {
    width: '100%'
  },
  halfWidth: {
      width: "100%",
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: "black"
        }
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "black"
      }
  },
  checkboxFormLabel: {
    margin: theme.spacing(0)  
  },
  checkbox: {
    display: "flex",
    alignItems: "start",
    justifyContent: "start"
  },
  readOnlyBox: {
        backgroundColor: "green"
    },
  outlineInput: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: "black"
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black"
    }
  }
}));

const myTheme = createTheme();




const getViewItemCells = (item, itemLabel, nonEmptyItem, columnSize=6, i) => {
  if (item.customLinkUrl) { // TODO: concat instanceUrl to the start of these custom link urls
    return (
      <td className="slds-cell-wrap" key={'customLink' + itemLabel}><a href={item.customLinkUrl}>{item.linkText}</a></td>
    );
  } else if (item.customText) {
    return [
      <td className="slds-cell-wrap" key={'label' + itemLabel}>{item.label}</td>,
      <td className="slds-cell-wrap" key={'value' + itemLabel} style={{"maxWidth":"310px"}}>{item.customText}</td>
    ];
  } else {
    return [
      <React.Fragment key = {"ViewItemCell" + i}>
        <Grid item md={columnSize} xs={columnSize}>
          <Typography variant="body1" component="h1">{item.label}      
            { item.linkId &&
              <React.Fragment>
                { item.values.map((component, i) => {
                  return " : " + item.linkText
                }
                )}
              </React.Fragment>
            }
            { !item.linkId && !item.customLinkUrl && !nonEmptyItem &&
              <label key={'componentEmpty' + itemLabel}>-</label>
            }
            { !item.linkId && !item.customLinkUrl && nonEmptyItem &&
              <React.Fragment>
                { item.values.map((component, i) => {
                  if (component.displayValue && component.displayValue.length > 0) {
                    return " : " + component.displayValue
                  } else {
                    return null
                  }
                }
                )}
              </React.Fragment>
            }
          </Typography>  
          </Grid>
      </React.Fragment>
    ];
  }
}

const getEditItemCells = (item, itemLabel, nonEmptyItem, columnSize=6, i, objectInfo, editValues, picklists, onFieldValueUpdate, DEPStates, classes, ErrorStates, reqFields) => {
  /*
  1. Required? Yes: add required span
  2. Editable? Yes: getEditComponent
     No: Display label of value
  
  */

  //Might need to expand the SFRow constructor to .map the values and call getEditItemCells for each value
  // Currently all fields only have 1 value, but perhaps its possible to display multiple values under 1 row? IDK this suffices for now
  if(item.values.length == 0)
  {
    return (
      <>
      </>
    );
  }
  var component = item.values[0];

  var isReference = false;
  if(!component.editableForUpdate) {
    var viewOnlyLabel = component.displayValue;
    if(component.fieldInfo.dataType === 'Reference'){
      viewOnlyLabel = item.linkText;
    }
  }
  

  myTheme.typography.body2 = {
    fontSize: '10rem',
    color: 'red'
  };
  return [
    <React.Fragment key = {"EditItemCell" + i}>
      <Grid item md={columnSize} xs={columnSize}>
        {/* <Typography variant="body1" component="h1">{item.label}       */}
          {/* { component.required &&
            <span className="slds-required">*</span>
          } */}
          { component.editableForUpdate &&
            getEditComponent(component, itemLabel, i, editValues, columnSize, picklists, objectInfo,onFieldValueUpdate, DEPStates, classes, ErrorStates, reqFields)
          }
          { !component.editableForUpdate &&
            getEditComponent(component, itemLabel, i, editValues, columnSize, picklists, objectInfo,onFieldValueUpdate, DEPStates, classes, ErrorStates, reqFields, true)
          }
        {/* </Typography>   */}
      </Grid>
    </React.Fragment>
    ];
}
const getFlattenedTree = (objectInfo, fieldTree, rootField) => {
  const flattenedTree = []
  flattenedTree.push(objectInfo.fields[rootField])
  doFlattenTree(objectInfo, fieldTree, flattenedTree)
  return flattenedTree
}

const doFlattenTree = (objectInfo, tree, flattenedTree) => {
  if (tree) {
    let treeKeys = Object.keys(tree)
    for (var treeKey in treeKeys){
      flattenedTree.push(objectInfo.fields[treeKeys[treeKey]])
      doFlattenTree(objectInfo, tree[treeKeys[treeKey]], flattenedTree)
    }
  }
}

const getEditComponent = (component,  itemLabel, i, editValues, columnSize, picklists, objectInfo,onFieldValueUpdate, DEPStates, classes, ErrorStates, reqFields, ReadOnly=false) => {
  var dataType;
  if(component.fieldInfo && component.fieldInfo.dataType)
    dataType = component.fieldInfo.dataType;
  let currentVal = editValues[component.field].current;
  let currentValStr = "";
  if (currentVal != null) {
      currentValStr = currentVal.toString();
  }  
  //console.log(dataType);
  //Picklist and Picklist Multi
  if(ReadOnly) {
    return (
      <SFText 
          component = {component}
          currentVal = {currentVal} 
          onFieldValueUpdate = {onFieldValueUpdate} 
          itemLabel = {itemLabel}
          i = {i}
          ErrorStates = {ErrorStates}
          className={classes.halfWidth}
          ReadOnly={true}
          ReadOnlyDataType={dataType}
          editValues={editValues}
          classes={classes}
          reqFields={reqFields}
      />
    );
  }
  if (dataType === 'Picklist') {
    return (
      <SFPicklistTest
      component = {component}
      className={classes.halfWidth}  
      itemLabel = {itemLabel} 
      i = {i} 
      editValues = {editValues} 
      columnSize = {columnSize} 
      picklists = {picklists} 
      objectInfo = {objectInfo}
      onFieldValueUpdate = {onFieldValueUpdate} 
      DEPStates = {DEPStates}
      classes = {classes}
      ErrorStates = {ErrorStates}
      />
    )
  }
  //DateTime
  else if(dataType === 'DateTime'){
    return (
        <SFDateTime
        component = {component}
        editValues = {editValues}
        field = {component.field}
        ErrorStates = {ErrorStates}
        classes = {classes}
        />
    );
  }
  else if(dataType === 'Time'){
    return (
        <SFTime
        editValues = {editValues}
        field = {component.field}
        ErrorStates = {ErrorStates}
        />
    );
  }
  //ExternalLookup / Reference
  else if(dataType === 'Reference'){
    return (
          <SFLookupRelationship
            field = {component.field} 
            className={classes.halfWidth}
            itemLabel = {itemLabel} 
            i = {i}
            component = {component}
            currentVal = {currentVal} 
            onFieldValueUpdate = {onFieldValueUpdate}
            referenceTo = {component.fieldInfo.referenceToInfos[0].apiName}
            ErrorStates = {ErrorStates}
            editValues={editValues}
            classes={classes}
          /> 
    );
  }
  //Text(should be our default) ; Phone is exact same functionality as text(no data validation)
  else if(dataType === 'String' || dataType === 'Phone' || dataType === 'URL')
  {
    return (
       <SFText 
          component = {component}
          currentVal = {currentVal} 
          onFieldValueUpdate = {onFieldValueUpdate} 
          itemLabel = {itemLabel}
          i = {i}
          ErrorStates = {ErrorStates}
          className={classes.halfWidth}
          classes={classes}
          reqFields={reqFields}
        />
      );
  }
  //Text Area
  else if(dataType === 'TextArea'){
    return (
        <SFTextArea
            component = {component}
            currentVal = {currentVal} 
            onFieldValueUpdate = {onFieldValueUpdate} 
            itemLabel = {itemLabel}
            i = {i}
            ErrorStates = {ErrorStates}
            classes={classes}
            reqFields={reqFields}
          />
    );
  }
  else if(dataType === 'Double'){
    return (
        <SFNumber
          component = {component}
          currentVal = {currentVal} 
          onFieldValueUpdate = {onFieldValueUpdate} 
          itemLabel = {itemLabel}
          ErrorStates = {ErrorStates}
          classes={classes}
          reqFields={reqFields}
        />
    );
  }
  else if(dataType === 'Percent')
  {
    return (
       <Grid item md={columnSize} xs={columnSize} >
        <SFPercent
          component = {component}
          currentVal = {currentVal} 
          onFieldValueUpdate = {onFieldValueUpdate} 
          itemLabel = {itemLabel}
          i = {i}
          ErrorStates = {ErrorStates}
          classes={classes}
        />
       </Grid>
    );
  }
  else if (dataType === 'Currency'){
    return(
        <SFCurrency
          component = {component}
          currentVal = {currentVal} 
          onFieldValueUpdate = {onFieldValueUpdate} 
          itemLabel = {itemLabel}
          i = {i}
          ErrorStates = {ErrorStates}
          classes={classes}
        />
    );
  }
  else if (dataType === 'Email')
  {
    return (
         <SFEmail
          component = {component}
          currentVal = {currentVal} 
          onFieldValueUpdate = {onFieldValueUpdate} 
          itemLabel = {itemLabel}
          i = {i}
          ErrorStates = {ErrorStates}
          classes={classes}
        />
    );
  }
  // Checkbox / Boolean Else if
  else if(dataType === 'Boolean'){
    return (
      <SFCheckbox
        field = {component.field}
        itemLabel = {itemLabel}
        component = {component}
        i = {i}
        editValues = {editValues}
        onFieldValueUpdate = {onFieldValueUpdate}
        ErrorStates = {ErrorStates}
        classes={classes}
      />
    );
  }
  //Default / BaseCase, shouldn't be used much once everything is implemented
  else{
    let currentVal = editValues[component.field].current;
    let currentValStr = "";
    if (currentVal != null) {
      currentValStr = currentVal.toString();
    }
    let componentType = 'text'
    if(component && component.fieldInfo.dataType == "Boolean") {
      componentType = 'checkbox'
      currentValStr = currentVal;
    }
    return (
      <Grid item md={columnSize} xs={columnSize} >
        {
          <TextField
            required
            className = {classes.textField}
            variant="outlined"
            id={component.label}
            name={component.label}
            label={component.label}
            fullWidth
            autoComplete={component.label}
            onChange={(event) => onFieldValueUpdate(component.field, event.target.value, event)}
            defaultValue={currentVal}
            key={'componentInput' + itemLabel + ',' + i}
            data-isnull={component.isNull}
            data-datatype={component.fieldInfo.dataType}
            onBlur={(e) => {}}
            error={1 === 2}
          />
        
        
        /* <input
          type={componentType}
          className="fieldEdit"
          name={component.field}
          value={currentVal}
          onChange={(event) => onFieldValueUpdate(component.field, event.target.value, event)}
          key={'componentInput' + itemLabel + ',' + i}
          data-isnull={component.isNull}
          data-datatype={component.fieldInfo.dataType}/> */}
      </Grid>
      );
  }
}


export default function SFRow( {row , sectionIndex, rowIndex, allowEdit, objectInfo, editValues, picklists, onFieldValueUpdate, DEPStates, ErrorStates, columns, reqFields} ) {
  const classes = useStyles();
  let rowLabel = sectionIndex + ',' + rowIndex;
  let columnSize = 12 / columns;

  return (
    <React.Fragment>
          {row.items.map((item, i) => {
            let itemLabel = rowLabel + ',' + i
            let nonEmptyItem = item.values.find((component) => component.displayValue && (component.displayValue.length > 0))
            // let editOnNew = item.values[0].editableForNew;
            if (!allowEdit) {
              return getViewItemCells(item, itemLabel, nonEmptyItem,columnSize, i);
            } 
            else{
              return getEditItemCells(item, itemLabel, nonEmptyItem,columnSize, i, objectInfo, editValues, picklists, onFieldValueUpdate, DEPStates, classes, ErrorStates, reqFields);
            }
        })}
    </React.Fragment>
  )
}
