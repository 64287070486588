import Cookies from 'js-cookie';
import axios from 'axios';

export default function checkCoveoToken() {
//Checks validity of current Coveo token, if expired, fetches new token then resolves
    return new Promise((resolve, reject) => {
        const currentCoveoToken = Cookies.get("CoveoToken");
        var expiryTimestamp;
        if(currentCoveoToken) {
            const parsedJWT = parseJwt(currentCoveoToken);
            expiryTimestamp = parsedJWT.exp;
        }
        if(checkIfTimestampIsValid(expiryTimestamp)){
            resolve();
        } else {
            getNewToken()
                .then(() => {
                    console.log("getNewToken Resolved!");
                    resolve();
                })
                .catch(() => {
                    console.log("getNewToken rejected");
                    reject("CoveoToken was invalid. Could not retrieve a new token");
                })
        }
    });
}

function getNewTokenDirectly(){
//Gets new token
    var request = {
      method: 'post',
      url: process.env.REACT_APP_API + "Coveo/kservice/requestSearchToken",
      headers: {
      'Authorization': 'Bearer ' + Cookies.get("token"),
      'Content-Type': 'application/json'
      },
      data: {
        "userIds": [
          {
            "name": "cory.creighton@speridian.com",
            "provider": "Email Security Provider"
          }
        ]
      }
    }
    return axios(request)
            .then((response) => {
                const CoveoApiKey = response.data.token;
                Cookies.set("CoveoToken", CoveoApiKey);
            })
            .catch((e) => {
                console.log(e);
            });
}

function getNewToken(){
    return new Promise((resolve, reject) => {
         //Gets new token
        var request = {
            method: 'get',
            url: process.env.REACT_APP_API + 'salesforce/kservice/apexrest?param=CoveoTokens',
            headers: {
            'Authorization': 'Bearer ' + Cookies.get("token"),
            'Content-Type': 'application/json',
            // "X-SalesForce-Token" : Cookies.get('sf_cookie'),
            'X-Id-Token' : Cookies.get('idToken')
            }
        }
        return axios(request)
                .then((response) => {
                    const CoveoApiKey = JSON.parse(response.data.coveoSearchAccess).accessToken;
                    const kitToken = response.data.kitToken;
                    const CoveoCaseAssit = JSON.parse(response.data.coveoCaseAssist).accessToken;
                    Cookies.set("KitToken", kitToken);
                    Cookies.set("CoveoToken", CoveoApiKey);
                    Cookies.set("CoveoCaseAssistToken", CoveoCaseAssit);
                    resolve();
                })
                .catch((e) => {
                    console.log("bad request!");
                    console.log(e);
                    reject();
                });
        });
}

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

function checkIfTimestampIsValid(timestamp) {
    if(timestamp) {
        const currentTimestamp = Math.round(Date.now() / 1000);
        return (timestamp > currentTimestamp);
    }
    return false;
}