import React, { useEffect, useState } from 'react'
import { Button, Checkbox, CircularProgress, FormControl,  Grid,  InputLabel,  ListItemText,  MenuItem,  OutlinedInput,  Select, TextField, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import SendIcon from '@material-ui/icons/Send';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Cookies from 'js-cookie';



import { number } from 'prop-types';
import axios from 'axios';


const useStyles = makeStyles((theme) => ({
    form: {
        margin: "32px",
        marginTop: "16px",
        marginBottom: "16px"
    },
    input: {
        marginBottom: "10px"
    },
    fieldsContainer: {
        marginTop: "48px"
    },
    formControl: {
        m: 1,
        width: 300,
    },
    submit: {
        marginTop: "32px"
    },
    filterChildren: {
        marginRight: "8px"
    }
  }));

const dropDownOptions = {
    "" : [],
    "Platform": ["PWA", "IOS", "ANDROID"],
}



var filterOptions = {
    "": {
        "type": "",
        "operators": [],
        "values": []
    },
    "Platform": {
        "type": "select",
        "operators": ["Equals"],
        "values": ["PWA", "IOS", "ANDROID"],
        "selected": false
    },
    "Division__c": {
        "type": "textfield",
        "operators": ["Equals"],
        "selected": false
    },
    "AccountNumber": {
        "type": "textfield",
        "operators": ["Equals","Is any"],
        "selected": false
    },
    "Contract_Series__c": {
        "type": "textfield",
        "operators": ["Includes"],
        "selected": false
    }
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

function AdminNotificationForm({refetchFromFirebase}) {
    const [loading, setLoading] = useState(false);
    const [notificationType, setNotificationType] = useState('');
    const [notificationTitle, setNotificationTitle] = useState('');
    const [notificationBody, setNotificationBody] = useState('');
    //KnowledgeNotifs
    const [knowledgeFacet, setKnowledgeFacet] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    //MachineNotifs
    const [modelNumber, setModelNumber] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    //Filters/scope
    const [filters, setFilters] = useState([]);
    const classes = useStyles();
    //Submission
    const [buttonText, setButtonText] = useState("Validate");
    const [successText, setSuccessText] = useState();
    //Validation
    const [notificationError, setNotificationError] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [errorState, setErrorState] = useState({
        "notificationType" : false,
        "notificationTitle": false,
        "notificationBody": false,
        "modelNumber": false,
        "serialNumber": false,
        "knowledgeFacet": false,
        "searchTerm": false,
    });
    const [queryCount, setQueryCount] = useState(false);
    

    useEffect(() => {
        //Reset payload fields when notificationType changes
        if(notificationType) {
            setKnowledgeFacet('');
            setSearchTerm('');
            setModelNumber('');
            setSerialNumber('');
        }
    }, [notificationType]);

    useEffect(() => {
        if (buttonText == "Send Notification") {
            setQueryCount(false);
            setButtonText("Validate");
        }
    }, [notificationType, notificationBody, notificationTitle, knowledgeFacet, searchTerm, modelNumber, serialNumber, filters]);

    function addFilter() {
        setFilters([...filters, {
            "field": "",
            "operator": "",
            "value": []
        }]);
    }

    function removeFilter(i) {
        let field = filters[i].field;
        filterOptions[field].selected = false;
        setFilters(
            [
                ...filters.slice(0,i),
                ...filters.slice(i+1)
            ]);
    }
    function resetAllFields(){
        //This changes Model/Serial/Facet/SearchTerm (useEffect on NotifType)
        setNotificationType('');
        setNotificationTitle('');
        setNotificationBody('');
        setFilters([]);
    }

    function validateForm() {
        
        var errors = false;
        function setErrorStateHelper(field){
            setErrorState( prevState => {
                return {
                    ...prevState,
                    [field]: true
                };
            })
            errors = true;
        }
        function clearErrorStateHelper(field){
            setErrorState( prevState => {
                return {
                    ...prevState,
                    [field]: false
                };
            })
        }
        if (!notificationType) {
            setErrorStateHelper("notificationType");
        } else if (errorState["notificationType"]) {clearErrorStateHelper("notificationType")}

        if (!notificationTitle) {
            setErrorStateHelper("notificationTitle");
        } else if (errorState["notificationTitle"]) {clearErrorStateHelper("notificationTitle")}

        if (!notificationBody) {
            setErrorStateHelper("notificationBody");
        } else if (errorState["notificationBody"]) {clearErrorStateHelper("notificationBody")}

        if (notificationType == 'Knowledge') {
            if (!knowledgeFacet) {
                setErrorStateHelper("knowledgeFacet");
            } else if (errorState["knowledgeFacet"]) {clearErrorStateHelper("knowledgeFacet")}
            if (!searchTerm) {
                setErrorStateHelper("searchTerm");
            } else if (errorState["searchTerm"]) {clearErrorStateHelper("searchTerm")}
        }
        if (notificationType == "Machine Detail") {
            if (!modelNumber) {
                setErrorStateHelper("modelNumber");
            } else if (errorState["modelNumber"]) {clearErrorStateHelper("modelNumber")}
            if (!serialNumber) {
                setErrorStateHelper("serialNumber");
            } else if (errorState["serialNumber"]) {clearErrorStateHelper("serialNumber")}
        }
        if (errors) {
            debugger;
            if(notificationError) {
                setNotificationError(false);
            }
            setErrorText("You have missing required fields. Please fill them in and try again.");
            return;
        }
        // Check filters for completenes
        filters.forEach((filter) => {
            if (!filter.field || !filter.value) {
                errors = true;
            } else if (filter.operator == "Is any" || filter.operator == "Includes") {
                debugger;
                filter.value = filter.value.replace(/\s+/g,'');
                filter.value = filter.value.toUpperCase();
                let values = filter.value.split(",");
                if (values.length < 1) {
                    errors = true;
                } else {
                    // Replace user entered string with what the REGEX matched
                    let matchedString = "";
                    values.forEach((indexValue) => {
                        matchedString += indexValue + ","
                    });
                    // If user supplied more than 1 term (X,Y,) remove trailing , from loop
                    if (matchedString.lastIndexOf(",") > 0) {
                        matchedString.slice(0,matchedString.lastIndexOf(","));
                    } 
                }
            }
        })

        if (errors) {
            if(notificationError) {
                setNotificationError(false);
            }
            setErrorText("You have incomplete filters. Please remove incomplete filters and try again.");
            return;
        }

        if (!errors) {
            setErrorText('');
            //Create payload
            var payload = {
                "execute": null,
                "conditionals": [
                ],
                "message": {
                    "type": "machine",
                    "title": notificationTitle,
                    "body": notificationBody
                }
            }
            if (notificationType == "Knowledge") {
                payload.message = {
                    ...payload.message,
                    "type": "Knowledge",
                    "knowledgeCategory": knowledgeFacet,
                    "searchTerm": searchTerm
                }
            }
            if (notificationType == "Machine Detail") {
                payload.message = {
                    ...payload.message,
                    "type": "Machine",
                    "modelNumber": modelNumber,
                    "serialNumber": serialNumber
                }
            }

            //Add filters, but use slice so we only get a shallow copy
            //If no shallow copy we will be modifying the front end value(String -> Array will cause issues)
            debugger;
            payload.conditionals = JSON.parse(JSON.stringify(filters));
            //Convert any Is Any values into arrays
            payload.conditionals.forEach((conditional) => {
                if (conditional.operator == "Is any" || conditional.operator == "Includes") {
                    conditional.value = conditional.value.split(',');
                }
            });
            debugger;

            
            // Submit or Validate
            let validateRequest = {
                method: "POST",
                url: process.env.REACT_APP_API + "firebase/kservice/DeeplinkPushNotifications",
                headers: {
                    'Authorization': 'Bearer ' + Cookies.get("token")
                },
                data: payload
            }

            if (buttonText == "Validate") {
                validateRequest.data.execute = false;
                setLoading(true);
                debugger;
                axios(validateRequest)
                    .then((response) => {
                        if(response.data.valid) {
                            setLoading(false);
                            setQueryCount(response.data.count);
                            setButtonText("Send Notification");
                        }
                    })
                    .catch((error) => {
                        setErrorText(error);
                        setLoading(false);
                    });
            } 
            else if (buttonText == "Send Notification") {
                
                validateRequest.data.execute = true;
                setLoading(true);
                axios(validateRequest)
                .then((response) => {
                    if(response.data.success) {
                        debugger;
                        setLoading(false);
                        setSuccessText(`Notification sent to ${queryCount} people!`);
                        resetAllFields();
                        refetchFromFirebase();
                    } else {
                        setLoading(false);
                        setErrorText(response.data.error);
                        setNotificationError(true);
                    }
                })
                .catch();
            }
        }
    }


    return (
        <>
            <Grid container>
                { errorText &&
                    <Grid item md={12} sm={12} className={classes.input}>
                        <Alert severity={notificationError?"error":"warning"}>
                            {errorText}
                        </Alert>
                    </Grid>
                }
                { successText &&
                     <Grid item md={12} sm={12} className={classes.input}>
                        <Alert severity="success">
                            {successText}
                        </Alert>
                    </Grid>
                }
                <Grid item md={8} sm={10} xsm={12} className={classes.form}>
                    <Grid container>
                        
                        <Grid item md={12} sm={12} xs={12} className={classes.input}>
                            <Typography>
                                Chose your notification type
                            </Typography>
                            <Select value={notificationType} variant="outlined" onChange={(e) => {setNotificationType(e.target.value)}}
                                displayEmpty
                                error={(errorState["notificationType"]) ? true : false}
                                renderValue={(v) => {
                                    if(!v){
                                        return "Type";
                                    }
                                    return v;
                                }}
                            >
                                <MenuItem value="Knowledge">
                                    Knowledge
                                </MenuItem>
                                <MenuItem value="Machine Detail">
                                    Machine Detail
                                </MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} className={classes.input}>
                            <Grid container className={classes.fieldsContainer}>
                                <Grid item md={8} className={classes.input}>
                                    <Typography>
                                        Notification Title*
                                    </Typography>
                                    <TextField fullWidth value={notificationTitle} id="title" variant="outlined" placeholder="Title" error={(errorState["notificationTitle"])?true:false} onChange={(e) => {setNotificationTitle(e.target.value)}}/>
                                </Grid>
                                <Grid item md={8} className={classes.input}>
                                    <Typography>
                                        Notification Body*
                                    </Typography>
                                    <TextField fullWidth value={notificationBody} multiline id="body" variant="outlined" placeholder="Body" error={(errorState["notificationBody"])?true:false} onChange={(e) => {setNotificationBody(e.target.value)}}/>
                                </Grid>
                                {notificationType == "Knowledge" && 
                                <>
                                    <Grid item md={12} className={classes.input}>
                                        <Typography>
                                            Knowledge Facet
                                        </Typography>
                                        <Select fullWidth value={knowledgeFacet} error={(errorState["knowledgeFacet"])?true:false} variant="outlined" onChange={(e) => {setKnowledgeFacet(e.target.value)}}
                                            displayEmpty
                                            renderValue={(v) => {
                                                if(!v){
                                                    return "Facet";
                                                }
                                                return v;
                                            }}
                                        >
                                            <MenuItem value="General">
                                                General
                                            </MenuItem>
                                            <MenuItem value="Manuals">
                                                Manuals
                                            </MenuItem>
                                            <MenuItem value="Campaigns">
                                                Campaigns
                                            </MenuItem>
                                            <MenuItem value="Bulletins">
                                                Bulletins
                                            </MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item md={12} className={classes.input}>
                                        <Typography>
                                            Search Text
                                        </Typography>
                                        <TextField fullWidth multiline id="body" variant="outlined" error={(errorState["searchTerm"])?true:false} placeholder="Search Text" onChange={(e) => {setSearchTerm(e.target.value)}}/>
                                    </Grid>
                                </>
                                }
                                {notificationType == "Machine Detail" &&
                                <>
                                    <Grid item md={12} className={classes.input}>
                                        <Typography>
                                            Model Number
                                        </Typography>
                                        <TextField fullWidth multiline id="model" variant="outlined" placeholder="Model Number" error={(errorState["modelNumber"])?true:false} onChange={(e) => {setModelNumber(e.target.value)}}/>
                                    </Grid>
                                    <Grid item md={12} className={classes.input}>
                                        <Typography>
                                            Serial Number
                                        </Typography>
                                        <TextField fullWidth multiline id="body" variant="outlined" placeholder="Serial Number" error={(errorState["serialNumber"])?true:false} onChange={(e) => {setSerialNumber(e.target.value)}}/>
                                    </Grid>
                                </>
                                }
                            </Grid>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} className={classes.input}>
                            <Grid container className={classes.fieldsContainer}>
                                <Grid item md={12} sm={12}>
                                    <Typography>Target</Typography>
                                </Grid>
                                {filters.length > 0 &&
                                    <Grid item md={12} sm={12} xs={12}>
                                        <FilterRows
                                            filters={filters}
                                            setFilters={setFilters}
                                            filterOptions={filterOptions}
                                            classes={classes}
                                            addFilter={addFilter}
                                            removeFilter={removeFilter}
                                        />
                                    </Grid>
                                }
                                {filters.length == 0 &&
                                    <Grid item md={12} sm={12} xs={12}>
                                        <Button 
                                        startIcon={<AddIcon/>}
                                        color="primary"
                                        onClick={addFilter}
                                        variant="outlined">
                                            Add filter
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        
                        <Grid item md={12} sm={12} xs={12} className={classes.submit}>
                            <Grid container>
                                <Grid item md={12} sm={12}>
                                    { queryCount !== false &&
                                        <Typography style={{marginBottom: "4px"}}>
                                            This notification will target <strong>{queryCount}</strong> people. {(queryCount > 0 ? "Are you sure?" : "Please revise your filters to include someone.")}
                                        </Typography>
                                    }
                                </Grid>
                                    {buttonText == "Send Notification" &&
                                        <>
                                            <Grid item>
                                                <Button 
                                                    color="primary"
                                                    onClick={() => {
                                                        validateForm();
                                                    }}
                                                    endIcon={<SendIcon/>}
                                                    variant="contained"
                                                    disabled={(queryCount === 0 || loading)?true:false}
                                                    >
                                                        {buttonText}
                                                </Button>
                                            </Grid>
                                            <Grid item style={{marginLeft: "8px"}}>
                                                {loading &&
                                                        <CircularProgress/>
                                                }
                                            </Grid>
                                        </>
                                    }
                                    {buttonText == "Validate" &&
                                        <>
                                            <Grid item>
                                                <Button 
                                                    color="primary"
                                                    onClick={() => {
                                                        validateForm();
                                                    }}
                                                    variant="contained"
                                                    disabled={loading}
                                                >
                                                        {buttonText}
                                                </Button>
                                            </Grid>
                                            <Grid item style={{marginLeft: "8px"}}>
                                                {loading &&
                                                        <CircularProgress/>
                                                }
                                            </Grid>
                                        </>
                                         
                                    }
                            </Grid>
                            
                            
                        </Grid>
                    </Grid>
                </Grid>
                
            </Grid>
        </>
    )
}

function FilterRows({filters, setFilters, filterOptions, classes, addFilter, removeFilter}){
    let filtersArray = [];
    const width = useMediaQuery('(min-width:620px)');
    for(let i = 0; i < filters.length; i++){
        let test = window.innerWidth;
        filtersArray.push(
            <Grid key={"filterRow"+i} item md={12} sm={12} className={classes.input}>
                <Grid container wrap="nowrap">
                    <Grid item md={9}>
                        <Select value={filters[i].field} variant="outlined" className={classes.filterChildren} onChange={(e) => {
                            // Updates filter at i index

                            //Grab previous value
                            let previousSelectedField = filters[i].field;
                            if(previousSelectedField) {
                                //Reset previous field so it goes back in list of selectable values
                                filterOptions[previousSelectedField].selected = false;
                            }
                            //Mark current selection as selected
                            filterOptions[e.target.value].selected = true;

                            setFilters(
                                [
                                    ...filters.slice(0,i), 
                                    {
                                        "field": e.target.value,
                                        "operator": "",
                                        "value": []
                                    },
                                    ...filters.slice(i+1)
                            ]);
                        }}
                            displayEmpty
                            renderValue={(v) => {
                                if(!v){
                                    return "Field";
                                }
                                return v;
                            }}
                        >
                            {Object.keys(filterOptions).map((filterName, filterIndex) => {
                                return (
                                    <MenuItem disabled={filterOptions[filterName].selected == true} key={`Filter${i}-${filterIndex}`} value={filterName}>
                                        {filterName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <Select value={filters[i].operator} disabled={filters[i].field ? false : true} variant="outlined" className={classes.filterChildren} onChange={(e) => {
                            // Updates filter at i index
                            setFilters(
                                [
                                    ...filters.slice(0,i), 
                                    {
                                        ...filters[i],
                                        "operator": e.target.value
                                    },
                                    ...filters.slice(i+1)
                            ])
                        }}
                            displayEmpty
                            renderValue={(v) => {
                                if(!v){
                                    return "Operator";
                                }
                                return v;
                            }}
                        >
                            {filterOptions[filters[i].field].operators.map((operator, operatorIndex) => {
                                return (
                                    <MenuItem key={`Operator${i}-${operatorIndex}`} value={operator}>
                                        {operator}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                        <FormControl style={{m: 1}} className={classes.filterChildren}>
                            { (filterOptions[filters[i].field].type == "select" || filterOptions[filters[i].field].type == "") &&
                                <Select
                                    id={"filterValueSelect"+i}
                                    multiple
                                    shrink={true}
                                    labelWidth={0}
                                    disabled={filters[i].field ? false : true}
                                    value={filters[i].value}
                                    displayEmpty
                                    style={{minWidth: "135px", maxWidth: "135px"}}
                                    onChange={(e) => {
                                        setFilters(
                                            [
                                                ...filters.slice(0,i), 
                                                {
                                                    ...filters[i],
                                                    "value": e.target.value
                                                },
                                                ...filters.slice(i+1,filters.length+1)
                                        ])
                                    }}
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => {
                                        if (selected.length == 0) {
                                            return "Value";
                                        }
                                        return selected.join(', ')
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {filterOptions[filters[i].field].values.map((name) => {
                                        return (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={
                                                (filters[i].value.indexOf(name) > -1)
                                            } />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                        );
                                        })}
                                </Select>
                            }
                            { filterOptions[filters[i].field].type == "textfield"  &&
                                <TextField value={filters[i].value} id="title" variant="outlined" placeholder="Value" style={{maxWidth: "135px"}}
                                    helperText={(filters[i].operator == "Includes" || filters[i].operator == "Is any") ?  "Seperate by comma" : ""}
                                    onChange={(e) => {
                                        setFilters(
                                            [
                                                ...filters.slice(0,i), 
                                                {
                                                    ...filters[i],
                                                    "value": e.target.value
                                                },
                                                ...filters.slice(i+1,filters.length+1)
                                        ])
                                    }}
                                />
                            }
                        </FormControl>
                    </Grid>
                    

                    <Grid item md={3}>
                        <Grid container direction="row-reverse" >
                            <Grid item>
                            {width &&
                                 <Button 
                                    startIcon={<RemoveIcon/>}
                                    color="primary"
                                    style={{minHeight: "56px"}}
                                    onClick={() => {
                                        removeFilter(i)
                                    }}
                                    variant="outlined"
                                >
                                        Remove
                                </Button>
                            }
                            {!width &&
                                 <Button 
                                    startIcon={<RemoveIcon/>}
                                    color="primary"
                                    style={{minHeight: "56px"}}
                                    onClick={() => {
                                        removeFilter(i)
                                    }}
                                    variant="outlined"
                                >
                                        
                                </Button>
                            }
                               
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </Grid> 
            </Grid>
        )
    }
    filtersArray.push(<Button 
        startIcon={<AddIcon/>}
        color="primary"
        onClick={addFilter}
        variant="outlined">
            Add filter
        </Button>);
    return filtersArray;
}



export default AdminNotificationForm