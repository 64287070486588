import React, { useReducer } from 'react';
import {  Accordion, AccordionDetails, AccordionSummary, Checkbox, Link, MenuItem, Select, Snackbar, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import moment from 'moment';
import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useHistory } from "react-router-dom";
import KnowledgeRecentSearch from '../components/KnowledgeRecentSearch';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Pagination from '@material-ui/lab/Pagination';
import { spacing } from '@material-ui/system';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useParams} from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import Cookies from 'js-cookie';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import axios from 'axios';
import { connect } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ApiManager from '../apiservice';
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { app } from '../firebaseConfig';
import checkCoveoToken from '../helpers/checkCoveoToken';
import SalesForceConstants from '../SalesForceConstants';
import coveoProductFacetSearch from '../helpers/fuse/coveoProductFacetSearch';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ExplodeIconMap from '../helpers/explodeIconMap';
import iconSearch from '../helpers/fuse/iconSearch';
// import GoogleMap from '../helpers/maps/GoogleMap';
import MachineIcon from '../components/MachineIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';






const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 500,
    backgroundColor: "white",
    padding: theme.spacing(0, 1, 1)
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
    width: "90%",
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  form: {
    margin: theme.spacing(0, 0, 5)
  },
  loaderCenter: {
    marginLeft: '50%',
  },
  loadingCenterInside: {
    position: 'fixed',
    top: '50%',
    marginLeft: '50%',
    left: '-20px'
  },
  sidebutton: {
    margin: theme.spacing(1, 0, 1)
  },
  modal: {
    position: 'absolute',
    width: '30%',
    backgroundColor: 'white',
  },
  majorComponentModal: {
    position: 'absolute',
    width: '45%',
    backgroundColor: 'white',
  },
  topDivider: {
    margin: theme.spacing(0, 0, 1)
  },
  midDivider: {
    margin: theme.spacing(1, 0, 1)
  },
  chip: {
    display: 'inline-block', 
    backgroundColor: '#FFCC00',
    fontSize: '14px',
    color: '#000', 
    padding: '0px 10px', 
    borderRadius: '10px',
    margin: '5px'
  },
  greenChip: {
    display: 'inline-block', 
    backgroundColor: 'green',
    color: 'white',
    fontSize: '14px',
    padding: '0px 10px', 
    borderRadius: '10px',
    margin: '5px'
  },
  tabItem: {
    '&:hover': {
          backgroundColor: '#d1d1d1'
        }
  },
  listIcon: {
    
  },
  attachmentBox: {
    borderRadius: 4,
    border: 'solid 1px',
    minHeight: "40px",
    maxWidth : '600px',
    margin: theme.spacing(1, 4, 1),
    paddingRight : 0,
    '&:hover': {
          backgroundColor: '#d1d1d1'
        }
  },
  attachmentExcerpt : {
    fontSize : '16px',
    paddingRight: "30px"
  },
  Result: {
    padding: theme.spacing(0,0,0,0)
  },
  ListItem: {
    padding: theme.spacing(1,0,0,0)
  },
  TelematicsMetric: {
    color: "#e05a21",
    fontWeight: "bold"
  },
  majorComponent: {
    '&:hover': {
      backgroundColor: '#d1d1d1'
    }
  },
  disabledSwitch: {
      cursor: 'not-allowed',
  },
  whiteText: {
    color: 'white'
  }
}));

const MachineDetailsPage = ({ rUserType }) => {
  const storage = getStorage();
  const classes = useStyles();
  const theme = useTheme();
  const params = useParams();
  const [loading, setLoading] = useReducer((loading) => !loading, false);
  const [failedLookup, setFailedLookup] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [totalResults, setTotalResults] = useState(-1);
  const [pageCount, setPageCount] = useState(-1);
  const [category, setCategory] = useState('Campaigns');
  const [machineData, setMachineData] = useState(null);
  const [manuals, setManuals] = useState();
  const [campaigns, setCampaigns] = useState();
  const [caseData, setCaseData] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState();
  const history = useHistory();
  const [stolen, setStolen] = useState(false);
  const [scrapped, setScrapped] = useState(false);
  const [preOwned, setPreOwned] = useState(false);
  const [auctioned, setAuctioned] = useState(false);
  const [donated, setDonated] = useState(false);
  const [modified, setModified] = useState(false);
  const [inventoryId, setInventoryId] = useState();
  const [hasKTAC, setHasKTAC] = useState(false);
  const [imei, setImei] = useState();
  const [campaignKnowledgeResultsVisibility, setCampaignKnowledgeResultsVisibility] = useState([false,false,false,false,false,false,false,false,false,false]);
  const [campaignCoveoResults, setCampaignCoveoResults] = useState([[],[],[],[],[],[],[],[],[],[]]);
  const [icon, setIcon] = useState();
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('');
  const [accordionSections, setAccordionSections] = useState();
  
  // Telematics
  const [hasTelematics, setHasTelematics] = useState();
  const [telematicsHelperText, setTelematicsHelperText] = useState(0);
  const [telematicsData, setTelematicsData] = useState(1);
  const [telematicsModalOpen, setTelematicsModalOpen] = useState(0);
  const [faultCodes, setFaultCodes] = useState([]);
  const [mapsModalOpen, setMapsModalOpen] = useState(0);
  const sampleFaultCodeHistory = {
    "status": "success",
    "data": [
        {
            "startTime": "2024-01-10T22:25:02.0000000Z",
            "hrReading": 2476,
            "endTime": "2024-01-10T22:25:41.0000000Z",
            "codeType": "DTC",
            "spn": 0,
            "fmi": 0,
            "sa": 0,
            "oc": 0,
            "epCode": "9000",
            "description": "DPF Regeneration Required (Level 2)",
            "severity": "Low",
            "severity_order": 1,
            "noMoreRecords": "false",
            "pageNumber": 1,
            "recordId": 1,
            "totalRecords": 6
        },
        {
            "startTime": "2024-01-10T22:35:10.0000000Z",
            "hrReading": 2476,
            "endTime": "2024-01-10T22:42:52.0000000Z",
            "codeType": "DTC",
            "spn": 0,
            "fmi": 0,
            "sa": 0,
            "oc": 0,
            "epCode": "9000",
            "description": "DPF Regeneration Required (Level 2)",
            "severity": "Low",
            "severity_order": 1,
            "noMoreRecords": "false",
            "pageNumber": 1,
            "recordId": 2,
            "totalRecords": 6
        },
        {
            "startTime": "2024-01-10T22:45:57.0000000Z",
            "hrReading": 2477,
            "endTime": "2024-01-10T22:48:52.0000000Z",
            "codeType": "DTC",
            "spn": 0,
            "fmi": 0,
            "sa": 0,
            "oc": 0,
            "epCode": "9000",
            "description": "DPF Regeneration Required (Level 2)",
            "severity": "Low",
            "severity_order": 1,
            "noMoreRecords": "false",
            "pageNumber": 1,
            "recordId": 3,
            "totalRecords": 6
        },
        {
            "startTime": "2024-01-10T22:08:30.0000000Z",
            "hrReading": 2476,
            "endTime": "2024-01-10T22:25:11.0000000Z",
            "codeType": "DTC",
            "spn": 0,
            "fmi": 0,
            "sa": 0,
            "oc": 0,
            "epCode": "9006",
            "description": "DPF Regeneration inhibit warning (Level 2)",
            "severity": "Low",
            "severity_order": 1,
            "noMoreRecords": "false",
            "pageNumber": 1,
            "recordId": 4,
            "totalRecords": 6
        },
        {
            "startTime": "2024-01-10T22:35:28.0000000Z",
            "hrReading": 2476,
            "endTime": "2024-01-10T22:35:41.0000000Z",
            "codeType": "DTC",
            "spn": 0,
            "fmi": 0,
            "sa": 0,
            "oc": 0,
            "epCode": "9006",
            "description": "DPF Regeneration inhibit warning (Level 2)",
            "severity": "Low",
            "severity_order": 1,
            "noMoreRecords": "false",
            "pageNumber": 1,
            "recordId": 5,
            "totalRecords": 6
        },
        {
            "startTime": "2024-01-10T22:25:48.0000000Z",
            "hrReading": 2476,
            "endTime": "2024-01-10T22:35:21.0000000Z",
            "codeType": "DTC",
            "spn": 0,
            "fmi": 0,
            "sa": 0,
            "oc": 0,
            "epCode": "9006",
            "description": "DPF Regeneration inhibit warning (Level 2)",
            "severity": "Low",
            "severity_order": 1,
            "noMoreRecords": "false",
            "pageNumber": 1,
            "recordId": 6,
            "totalRecords": 6
        }
    ],
    "msg": "The Last Telemetry is as below"
  };



  //Major Components
  const [majorComponents, setMajorComponents] = useState(false);
  const [openedMajorComponent, setOpenedMajorComponent] = useState(0);


  useEffect(() => {
  // On Component Load we need to fetch Machine Data
    let serialNumber = null;
    let modelNumber = null;
    let pin = null;
    var test = params;

    if(params.param1){
      assignParams(params.param1);
    }

    if(params.param2){
      assignParams(params.param2);
    }

    if(params.param3){
      assignParams(params.param3);
    }

    function assignParams(value){
      if(value.substring(0,2) == "sn"){
        serialNumber = value.substring(3);
      }

      if(value.substring(0,2) == "mn"){
        modelNumber = value.substring(3);
      }

      if(value.substring(0,3) == "pin"){
        pin = value.substring(4);
      }
    }

    const url = process.env.REACT_APP_API + 'salesforce/kwarranty/SearchInventory';
    const req = {
      method: "post",
      url: url,
      headers: {
        'Authorization': 'Bearer ' + Cookies.get("token"),
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        "materialNumber": modelNumber,
        "serialNumber": serialNumber,
        "productIdentificationNo": pin
      })
    }
    if(!machineData){
      
      console.log("Making a request from component load!")
      axios(req).then((response) => {
        startIconSearch(response.data.materialNumber);
        console.log(response.data);
        let recentItem = {
          serial: response.data.serialNumber,
          model: response.data.materialNumber,
          owner: response.data.endCustomerName,
          isStolen: response.data.isStolenInventory
        }
        let recentMachines = JSON.parse(sessionStorage.getItem("recentMachines"));
        console.log()
        if(!recentMachines){
          recentMachines = [];
        }
        if(recentItem.isStolen === true){
          setStolen(true)
        }
        if(response.data.isPreOwned === true){
          setPreOwned(true);
        }
        if(response.data.isScrapped === true){
          setScrapped(true);
        }
        if(response.data.isAuction === true){
          setAuctioned(true);
        }
        if(response.data.isDonated === true){
          setDonated(true);
        }
        if(response.data.isModified === true){
          setModified(true);
        }
     

        for(let i = recentMachines.length-1; i > -1; i--){
          if(recentMachines[i].serial == recentItem.serial && recentMachines[i].model == recentItem.model && recentMachines[i].owner == recentItem.owner){
              recentMachines.splice(i, 1);
          }
        }

        recentMachines.push(recentItem);
        sessionStorage.setItem("recentMachines", JSON.stringify(recentMachines));
        setInventoryId(response.data.inventoryId);
        setImei(response.data.imei);
        setMachineData(response.data);
        buildCampaignVisibilityArray(response.data);
      })
      .catch(function (error) {
        setFailedLookup(true);
        console.log(error);
      });
    }
    // checkForKTAC(modelNumber, serialNumber);
    setFaultCodes([
      {
        "HR Reading": 1469,
        "SPN": 529,
        "FMI": 13,
        "SA": "-",
        "E/P Code": "P2088",
        "Description": "DEP Pump Failed!!",
        "Severity": "High"
      },
      {
        "HR Reading": 1469,
        "SPN": 3702,
        "FMI": 1,
        "SA": "61",
        "E/P Code": "P1A14",
        "Description": "Regeneration inhibit request reception",
        "Severity": "Medium"
      },
      {
        "HR Reading": 1469,
        "SPN": "-",
        "FMI": "-",
        "SA": "-",
        "E/P Code": "9517",
        "Description": "Diesel Exhaust Fluid Piping Pump Error",
        "Severity": "Low"
      }
    ])
  }, []);

  useEffect(() => {
  // On MachineData update
    if(machineData && !caseData){
      console.log("Making request from MachineDataHook");
      var test = machineData;
      var q = "SELECT Id, CaseNumber,Status,Subject FROM CASE WHERE RecordTypeId = '" + SalesForceConstants.CaseRecordTypeID + "' "
            + 'AND Model_Number__c LIKE \''+machineData.materialNumber+'\'  '
            + 'AND Serial_Number__c LIKE \''+machineData.serialNumber+'\'  ';
      q = encodeURIComponent(q);
      // const fetchCasesUrl = 'https://kubotaservice--ksdev.sandbox.my.salesforce.com' +'/services/data/v56.0/query/?q='+q;
      const fetchCasesUrl = process.env.REACT_APP_API + 'salesforce/kservice/get?param=query/?q=' +q;
      const fetchCasesReq = {
        method: "GET",
        url: fetchCasesUrl,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + Cookies.get("token"),
          'X-SalesForce-Token' : Cookies.get('sf_cookie')
        }
      }
      axios(fetchCasesReq)
        .then((response) => {
          setCaseData(response.data.records.reverse());
        })
        .catch(function (error) {
          // Could extend later on failure to display failure under Case Tab
          console.log(error);
        });

      var q = "SELECT Id, CaseNumber,Status,Subject FROM CASE WHERE RecordTypeId = '" + SalesForceConstants.ReportRecordTypeID + "'"
            + 'AND Model_Number__c LIKE \''+machineData.materialNumber+'\'  '
            + 'AND Serial_Number__c LIKE \''+machineData.serialNumber+'\'  ';
            
      q = encodeURIComponent(q);
      const fetchReportsReq = {
        method: 'get',
        url : process.env.REACT_APP_API + 'salesforce/kservice/get?param=query/?q='+q,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + Cookies.get("token"),
          'X-SalesForce-Token' : Cookies.get('sf_cookie')
        }
      }
      axios(fetchReportsReq)
        .then((response) => {
          setReportData(response.data.records.reverse());
        })
        .catch((error) => {
          console.log(error);
        });
      checkCoveoToken()
        .then(() => {
          fetchManuals();
        })
        .catch((error) => {
          console.log("Error fetching Coveo token", error);
        });
    }
    // if (machineData && machineData.imeiNumber) {
    //   setHasTelematics(true)
    //   fetchTelematicsData(machineData.imeiNumber);
    // }
    // if (machineData && machineData.inventoryId) {
    //   fetchMajorComponents(machineData.inventoryId);
    // }
  }, [machineData]);

  function fetchMajorComponents(inventoryId) {
    const majorComponentsQuery = `SELECT Name, Type__c, WOD_2__Serial_number__c FROM WOD_2__Major_Component__c WHERE WOD_2__Inventory__c = '${inventoryId}' LIMIT 5`;
    const request = {
      url: process.env.REACT_APP_API + `salesforce/kwarranty/Get?param=query/?q=${majorComponentsQuery}`,
      method: "GET",
      headers: {
        'Authorization': "Bearer " + Cookies.get("token")
      }
    }
    axios(request)
      .then((resp) => {
        setMajorComponents(resp.data.records);
      })
      .catch((error) => {
        console.log("Error fetching major component data", error);
      })
  }
  function fetchTelematicsData(imei) {
    let url = process.env.REACT_APP_API + 'IMEI/validateDCUv2?IMEINumber=' + imei;
    let config = {
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`
      }
    }
    axios.get(url, config)
      .then((resp) => {
        if(resp.data.hasOwnProperty("last_report")){
          setTelematicsData(resp.data["last_report"]);
          fetchFaultCodes();
        }
      })
      .catch((error) => {
        console.log("Error fetching telematics data", error);
      })
  }
  function fetchFaultCodes(){
    debugger;
    let faultCodesReq = {
      url: process.env.REACT_APP_API + 'api/v1/telematics/faultcodes',
      method: "POST",
      data: {
        "pin" : "",
        "serialNumber": "45326",
        "model": "SVL95-2",
        "languageLocaleKey": "en-US",
        "nextPageNumber":1,
        "dateLiteral":"7Day",
        "sortBy":"severity",
        "sortOrder":"asc",
        "isExternalUser":"False",
        "filterCriteria":{
           "faultSeverity":{
               "severity":[]
               }
           }
      },
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`
      }
    }
    axios(faultCodesReq)
        .then((response) => {
          debugger;
          setFaultCodes(response.data.data);
        })
        .catch((error) => {
          debugger;
          console.log('Error fetching fault codes', error);
        })
  }
  async function startIconSearch(modelNumber){
    let iconMap = await fetchFirebaseIconMap();
    if(!iconMap) {
      return; //Icon will stay as stock icon if we experience a failure
    }
    let icon = iconSearch(iconMap, modelNumber);
    if(icon) {
      let imgBlob = await fetchIconBlob(icon);
      if(!imgBlob) {return;}
      setIcon(imgBlob);
      console.log('setting icon');
    }
  }
  function fetchIconBlob(imgSrc){
    return new Promise((resolve, reject) => {
        const gsReference = ref(storage, imgSrc);
        getDownloadURL(ref(storage, gsReference))
        .then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                let imgURL = URL.createObjectURL(xhr.response);
                resolve(imgURL);
            };
            xhr.open('GET', url);
            xhr.send();
        })
        .catch((error) => {
            // Handle any errors
            console.log(error);
            reject(false);
        });
    });   
}
  function fetchFirebaseIconMap() {
    return new Promise((resolve, reject) => {
      const remoteConfig = getRemoteConfig(app);
      fetchAndActivate(remoteConfig)
      .then(() => {
          var iconMap = JSON.parse(getValue(remoteConfig, "StockIconMap")._value);
          var explodedIconMap = ExplodeIconMap(iconMap);
          return resolve(explodedIconMap);
      })
      .catch((err) => {
          console.log(err);
          reject();
      });
    });
    
  }

  async function fetchManuals() {
    //Grab our list of valid product facets from firebase
    let searchableFacetArray = await fetchSearchableFacetArray();
    if(!searchableFacetArray) {
      //Set some error
      return;
    }
    //Match the model number against our list of known valid product facets    
    let validProductFacet = coveoProductFacetSearch(searchableFacetArray, machineData.materialNumber);
    if(!validProductFacet || validProductFacet.length == 0) {
      //Set some error
      return;
    }
    // Run a coveo search for Manuals with our selected product facet
    let coveoManualResults = await makeCoveoManualsSearch(validProductFacet.item.facetPath);
    if (coveoManualResults) {
      setManuals(organizeMachineManuals(coveoManualResults)); 
    }
  }

  function organizeMachineManuals(manuals) {
    organizeMachineManualsFork(manuals);
    // We prefer to move workshop manuals to the top of the list so people can find them easier
    // So we capture all the WSM's and put them first in the list
      let workshopManuals = [];
      let assemblyInstructions = [];
      let otherManuals = [];
      manuals.forEach((manual) => {
        if (manual.raw.categorylevel4 == "Workshop Manuals" || manual.raw.categorylevel4 == "Operator's / Owner's Manual"){
          workshopManuals.push(manual);
        }
        else if (manual.raw.categorylevel4 == "Assembly Instructions") {
          assemblyInstructions.push(manual);
        }
        else {
          otherManuals.push(manual);
        }
      });
      return workshopManuals.concat(assemblyInstructions.concat(otherManuals));
  }

  function organizeMachineManualsFork(manuals) {
    let template = {
      'totalCount': manuals.length,
      'sections': {
        'Workshop Manuals': {
          count: 0,
          expanded: true,
          manuals: [],
        },
        "Operator's / Owner's Manual": {
          count: 0,
          expanded: false,
          manuals: [],
        },
        "Assembly Instructions": {
          count: 0,
          expanded: false,
          manuals: [],
        },
        "Other": {
          count: 0,
          expanded: false,
          manuals: [],
        },
      }
    }
    for(let manual of manuals){
      let category = manual.raw.categorylevel4;
      console.log(category);
      if(template.sections.hasOwnProperty(category)){
        template.sections[category].manuals.push(manual);
        template.sections[category].count++;
      } else {
        template.sections.Other.manuals.push(manual);
        template.sections.Other.count++;
      }
    }

    setAccordionSections(template);   
  }

  function fetchSearchableFacetArray(){
    return new Promise((resolve, reject) => {
      const remoteConfig = getRemoteConfig(app);
      fetchAndActivate(remoteConfig)
        .then(() => {
          var template = JSON.parse(getValue(remoteConfig, "CoveoProductFacetsTranslatedArray")._value);
          resolve(template);
        })
        .catch((e) => {
          console.log(e)
          reject();
        });
    });
  }
  function fetchCoveoManualsSearchPayload(){
    return new Promise((resolve, reject) => {
      const remoteConfig = getRemoteConfig(app);
        fetchAndActivate(remoteConfig)
          .then(() => {
            var template = JSON.parse(getValue(remoteConfig, "machine_manuals_template")._value);
            resolve(template);
          })
          .catch((e) => {
            console.log(e)
            reject();
          });
      });
  }
  function adjustForStagingCoveo(productFacet){
    productFacet.currentValues[0].children[0].children[0].children = [];
    productFacet.currentValues[0].children[0].children[0].state = "selected";
    return productFacet;
  }
  async function makeCoveoManualsSearch(facetPath){
    var coveoPayloadTemplate = await fetchCoveoManualsSearchPayload();
    return new Promise((resolve, reject) => {
      if(!coveoPayloadTemplate) {
        return reject();
      }
      let productFacetTemplate = {
        "delimitingCharacter": "|",
        "filterFacetCount": true,
        "injectionDepth": 1000,
        "numberOfValues": 1,
        "sortCriteria": "occurrences",
        "basePath": [],
        "filterByBasePath": true,
        "currentValues": [
          {
            "value": null,
            "state": "idle",
            "children": [
              {
                "value": null,
                "state": "idle",
                "children": [
                  {
                    "value": null,
                    "state": "idle",
                    "children": [
                      {
                        "value": null,
                        "state": "selected",
                        "children": [],
                        "retrieveChildren": false,
                        "retrieveCount": 8
                      }
                    ],
                    "retrieveChildren": false,
                    "retrieveCount": 8
                  }
                ],
                "retrieveChildren": false,
                "retrieveCount": 8
              }
            ],
            "retrieveChildren": false,
            "retrieveCount": 8
          }
        ],
        "preventAutoSelect": false,
        "type": "hierarchical",
        "field": "commonproduct",
        "facetId": "commonproduct"
      };
      let facetLevels = facetPath.split("|");
      if (facetLevels.length != 4) {
        return reject();
      }
      //Insert 3 levels into the template
      productFacetTemplate.currentValues[0].value = facetLevels[0];
      productFacetTemplate.currentValues[0].children[0].value = facetLevels[1];
      productFacetTemplate.currentValues[0].children[0].children[0].value = facetLevels[2];
      productFacetTemplate.currentValues[0].children[0].children[0].children[0].value = facetLevels[3];

      // TEMP FIX FOR COVEOS ENVIRONMENTAL DIFFERENCES
      // TL;DR - Coveo's Staging environemnt has manuals living at the 3rd facet level, while Production is mostly 4th level
      // If in Stage, remove 4th level so we get results
      productFacetTemplate = adjustForStagingCoveo(productFacetTemplate);
      
      // TEMP FIX FOR COVEOS ENVIRONMENTAL DIFFERENCES

      //Inject commonProduct facet into facet list of the manuals template
      coveoPayloadTemplate.facets.push(productFacetTemplate);
      var CoveoReq = {
        url : process.env.REACT_APP_API + "Coveo/kservice/search",
        method: "POST",
        headers : {
          'Authorization': 'Bearer ' + Cookies.get("token"),
          'X-Coveo-Token': Cookies.get("CoveoToken")
        },
        data : coveoPayloadTemplate
      }
      axios(CoveoReq)
        .then((response) => {
          var result = response.data.results;
          resolve(result);
        })
        .catch((e) => {
          console.log(e);
          resolve(null);
        });
    })
  }
  function buildCampaignVisibilityArray(preStateMachineData) {
    //Iterate Through campaigns and build bool array to control coveo results hide/show on click
    //Also Add a blank array to the CoveoKnowledgeArray
    var boolArray = [];
    var knowledgeArray =[];
    preStateMachineData.campaigns.forEach(() => {
      boolArray.push(false);
      knowledgeArray.push([]);
    });
    setCampaignKnowledgeResultsVisibility(boolArray);
    setCampaignCoveoResults(knowledgeArray);
  }

  function checkForKTAC(modelNumber, serialNumber){

    var ktacRequest = {
      "method": "get",
      "url": process.env.REACT_APP_API + `salesforce/kwarranty/checkInsurance?serial=${serialNumber}&model=${modelNumber}`,
      "headers" : {
        'Authorization': 'Bearer ' + Cookies.get("token")
      }
    }
    axios(ktacRequest)
      .then((response) => {
        response = response.data;
        if(response.hasOwnProperty("result")) {
          setHasKTAC(response.result);
        }
      })
      .catch((err) => {
        console.log(err)
      });
  }
  function openWarranty(item){
    let startAndEndDates = item.warrantyStartDate;
    let startDate;
    let endDate;

    let content = [
      <React.Fragment key = {"ContentFragment"}>
        <Typography variant="h5" component="h3">{item.policyName}</Typography>
        <Divider className={classes.topDivider}/>
        { item.warrantyStartDate && 
          <Typography variant="body1" component="h3">{"Start Date: " + item.warrantyStartDate}</Typography>
        }
        { item.warrantyStartDate && 
          <Typography variant="body1" component="h3">{"End Date: " + item.warrantyEndDate}</Typography>
        }
        <Divider className={classes.midDivider}/>
        {item.policyType && 
          <Typography variant="body1" component="h3">{"Policy Type: " + item.policyType}</Typography>
        }
        { item.policyCode &&
          <Typography variant="body1" component="h3">{"Policy Code: " + item.policyCode}</Typography>
        }
      </React.Fragment>
    ];

    if(item.pdInventoryUsages && item.pdInventoryUsages.length > 0){
      //content.push(<Typography variant="subtitle2" component="h3">{"Warranty Usage "}</Typography>);
      content.push(<Divider className={classes.midDivider}/>);
      for (var i = 0; i < item.pdInventoryUsages.length; i++) {
        content.push(<Typography variant="body1" component="h3">{'Usage Type: ' + item.pdInventoryUsages[i].usageReadingType + ' @ ' + item.pdInventoryUsages[i].maximumUnit + ' ' + item.pdInventoryUsages[i].unitsOfMeasure}</Typography>);
        content.push(<Divider className={classes.midDivider}/>);
      }
    }
    setModalContent(content);
    setOpen(true);
  }


  function openClaim(item){
    let content = [
      <React.Fragment key = {"OpenClaimFragment"}>
      <Typography variant="h5" component="h3">{'Claim #' + item.claimNumber + '     Claim Type: ' + item.claimType}</Typography>
      <Divider className={classes.topDivider}/>
      { item.inventorySerialNumber && 
        <Typography variant="body1" component="h3">{'Serial: ' + item.inventorySerialNumber + 'Model: ' + item.modelNumber}</Typography>
      }
      { item.dealer && 
        <Typography variant="body1" component="h3">{'Dealer: ' + item.dealer}</Typography>
      }
      { item.dateOfRepair && 
        <Typography variant="body1" component="h3">{'Date of Repair: ' + item.dateOfRepair}</Typography>
      }
      { item.claimType && 
        <Typography variant="body1" component="h3">{'Claim Type: ' + item.claimType}</Typography>
      }
      <Divider className={classes.midDivider}/>
      { item.symptomDescription && 
        <Typography variant="body1" component="h3">{"Symptom Description:  " + item.symptomDescription}</Typography>
      }
      { item.defectDescription && 
        <Typography variant="body1" component="h3">{"Defect Description: " + item.defectDescription}</Typography>
      }
      <Divider className={classes.midDivider}/>
      { item.causalPartDescription && 
        <Typography variant="body1" component="h3">{"Causal Part Description: " + item.causalPartDescription}</Typography>
      }
      { item.causalPartNumber && 
        <Typography variant="body1" component="h3">{"Causal Part Number: " + item.causalPartNumber}</Typography>
      }
      { item.machineUsageReading && 
        <Typography variant="body1" component="h3">{"Machine Usage Reading:  " + item.machineUsageReading}</Typography>
      }
      { item.serviceCampaign && 
        <Typography variant="body1" component="h3">{"Service Campaign: " + item.serviceCampaign}</Typography>
      }
    </React.Fragment>
    ];

    setModalContent(content);
    setOpen(true);
  }

  function ListItemLink(uniqueID, title, result) {
    
    // var attachments = result.parentResult;
    var attachments = result;

    // let URL = attachments.ClickUri;
    let URL = result.ClickUri;

    // fetchAttachment(attachments, URL);
    var docType = result.raw.documenttype;
    var fileType = result.raw.filetype;

    if(docType === "blob") {
      fetchAttachment(result, result.ClickUri);
    } else if(result.ClickUri.includes("kits.kubota")) {
      if(fileType === "pdf") {
        fetchAttachmentFromKits(result.ClickUri,title+".pdf");
      }
    } 
    else {
      debugger;
      // history.push('/knowledge/answer?uniqueID=' + encodeURIComponent(uniqueID) + "&title=" + title);
    }
  }

  function handleAttachmentClick(Attachment, URL) {
    fetchAttachment(Attachment, URL);
  }

  function fetchAttachment(Attachment,attachmentURL) {
    var fileName = Attachment.Title;
    var fileType = Attachment.raw.filetype;
    if(fileType === "SalesforceItem") {
      let tab = window.open();
      tab.location.href = attachmentURL;
      return;
    }
    let url = document.createElement('a');
    url.href = attachmentURL;
    // If file attachment URL is Azure
    let adTokenUrl = process.env.REACT_APP_API + 'salesforce/kservice/apexrest?param=ADToken';
    let adTokenReq = {
      method : "GET",
      url: adTokenUrl,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "Accept": "application/json",
        "Authorization": "Bearer " + Cookies.get("token"),
        // "X-SalesForce-Token" : Cookies.get('sf_cookie')
      }
    }
    axios(adTokenReq).then((adTokenRes) => {
      if(adTokenRes.data){
        let adToken = adTokenRes.data;
        console.log(adToken);
        let x_ms_version = '2021-08-06';
        //Move this into Firebase remote config
        var newURL =  localStorage.getItem("KnowledgeAttachmentBaseURL") + url.href.substring(url.href.indexOf("/Knowledge__kav"));
        previewBlob(newURL, adToken, x_ms_version)
        .then(result => {
          let fileLink = result;
          downloadFileAttachment(fileLink, fileName)
        })
        .catch(error => {
          console.log(error);
        })
      }
    })
    .catch(error => {
      console.log(error);
    }) 
  }
  function fetchAttachmentFromKits(attachmentURL,fileName, index) {
    let x_ms_version = '2021-08-06';
    const kitToken = Cookies.get("KitToken");
    //Mark Result as Loading, these kits take a while.
    // const clone = [...resultLoadingArray];
    // clone[index] = true;
    // setResultLoadingArray(clone);
    
    previewBlob(attachmentURL, kitToken, x_ms_version)
            .then(result => {
                let fileLink = result;
                downloadFileAttachment(fileLink, fileName, index)

                // const clone = [...resultLoadingArray];
                // clone[index] = false;
                // setResultLoadingArray(clone);
            })
            .catch(error => {
                console.log(error);

                // const clone = [...resultLoadingArray];
                // clone[index] = false;
                // setResultLoadingArray(clone);
            })
}

  async function downloadFileAttachment(url, filename, fetchProps) {
    fetchProps = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + Cookies.get("sf_cookie")
      }
    }
    try {
      const response = await fetch(url, fetchProps);
      if (!response.ok) {
        throw new Error(response);
      }
      const blob = await response.blob();
      // Download the file
      saveAs(blob, filename);

    } catch (error) {
      throw new Error(error);
    }
  }


  // Function to preview blob
  function previewBlob(uri, token, version) {
    // create headers object      
    const myHeaders = {'Authorization': 'Bearer ' + token, 'x-ms-date': getUTCDate(), 'x-ms-version': version};
    // create request options
    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    let mimeType='application/octet-stream'; // init mime type
    let url = process.env.REACT_APP_API+'rp?param='+encodeURI(uri);

    // return promise
    return new Promise((resolve, reject) => {
        // fetch request
        fetch(url, requestOptions)
        .then(response => { 
            const contentType = response.headers.get('content-type'); // get blob mime type
            //mimeType = 'application/octet-stream'; // set to allowed type
            return response.arrayBuffer(); // returns a promise that resolves with a generic, fixed-length raw binary data buffer
        }) 
        .then(result => { // process response     
      // Specify a MIME Type for Blob Objects in LWS
            const file = new Blob([result], {type: mimeType}); 
            // create a string containing a URL
            const imageUrl = URL.createObjectURL(file);
            // return blob
            resolve(imageUrl);
        })
        .catch(error => { // handle error
          reject(error);
        });
    });
  }

  // function to get UTC date
  function getUTCDate() {
    // get current date
    let currentDate = new Date();
    // convert to UTC
    const utcDate = currentDate.toUTCString();
    // return string
    return utcDate;
  }

  const campaignTap = (item, i) => {
    //Campaign onClick, if search hasn't been made yet perform it, then unhide the results    
    //If results haven't been fetched yet, do so
    if(campaignCoveoResults[i].length == 0) {
      fetchCoveoCampaignSearch(i, item.campaignName)
        .then(() => {
          toggleVisibility(i);
        })
    } else {
      toggleVisibility(i);
    }
  };


  const toggleVisibility = (i) => {
    //Cycle visibility, after Results are fetched
    var clone = [...campaignKnowledgeResultsVisibility];
    clone[i] = !clone[i];
    setCampaignKnowledgeResultsVisibility(clone);
  }

  const fetchCoveoCampaignSearch = (i,campaignTitle,template) => {
    var template;
    return new Promise((resolve, reject) => {
      //Steps:
      //1. Get Template
      fetchMachineCampaignTemplate()
          .then((CoveoTemplate) => {
            template = CoveoTemplate;
            //2. Check Token
            checkCoveoToken()
              .then(() => {
                makeCoveoCampaignSearch(campaignTitle)//3. Make Request
                  .then(() => {
                    resolve();
                  })
              })
              .catch((e) => console.log(e));
          });
    })

    function makeCoveoCampaignSearch(campaignTitle) {
      return new Promise((resolve, reject) => {
        var injectedTemplate = {
          ...template,
          "q" : campaignTitle
        };
        var CoveoReq = {
            url : process.env.REACT_APP_API + "Coveo/kservice/search",
            method: "POST",
            headers : {
              'Authorization': 'Bearer ' + Cookies.get("token"),
              'X-Coveo-Token': Cookies.get("CoveoToken")
            },
            data : injectedTemplate
          }
          axios(CoveoReq)
            .then((response) => {
              var result = response.data.results;
              var clone = [...campaignCoveoResults];
              clone.splice(i,1,result);
              setCampaignCoveoResults(clone);
              resolve();
            })
            .catch((e) => {
              console.log(e);
              reject();
            });
      })
    }
    function fetchMachineCampaignTemplate() { 
      return new Promise((resolve,reject) => {
        const remoteConfig = getRemoteConfig(app);
        fetchAndActivate(remoteConfig)
          .then(() => {
            var template = JSON.parse(getValue(remoteConfig, "machine_campaigns_template")._value);
            resolve(template);
          })
          .catch((e) => {
            console.log(e)
            reject();
          });
      })
    } 
  }

  const openCampaign = (string) => {
    console.log(string);
    let data = JSON.stringify({
      "data": {
        "kw": string,
        "page": 1,
        "answerType": "OKKB-CAMPAIGNS"
      }
    });

    const url = process.env.REACT_APP_OSVC_DOMAIN + '/cc/MobileSearch/search';
    setLoading(true);
    axios.post(url, data, {withCredentials: true})
      .then((response) => {
        console.log(response.data);
        if(response.data.results.length > 0){
          findCampaignNeedle(response.data.results, string);
          //history.push("/knowledge?s="+item.campaignName+"&c="+"OKKB-CAMPAIGNS")
        }else{
          getBulletin(string);
          //history.push("/knowledge/answer/" + response.data[0].answerID);
        }
      }).catch((error) => {
        console.log(error);
      })
  };

  const findCampaignNeedle = (results, needle) => {
    // console.log('FIND CAMPAIGN NEEDLE');
    console.log(needle);
    let answer = '';
    new Promise((resolve,reject) => {
      for(var campaign of results){
        if(campaign.answerDetails.title.startsWith(needle)){
          answer = campaign;
          break;
        }
      }
      if(answer != ''){
        resolve(answer);
      }else{
        reject('No Campaign');
      }
    }).then((answer) =>  {
      console.log('Nav To ANSWER DETAIL KA');
      history.push("/knowledge/answer/" + answer.answerID);
    }).catch((reason) => {
        console.log(reason);
        // No Campaign try Bulletin
        getBulletin(needle);
    })
  };

  const findBulletinNeedle = (results, needle) => {
    // console.log('FIND BULLETING NEEDLE');
    console.log(needle);
    let answer = '';
    new Promise((resolve,reject) => {

        for(var campaign of results){
          if(campaign.answerDetails.title.startsWith(needle)){
            answer = campaign;
            break;
          }
        }
      if(answer != ''){
        resolve(answer);
      }else{
        reject('NO Bulletin');
      }
    }).then((answer) => {
      history.push("/knowledge/answer/" + answer.answerID);
    }).catch((reason) => {
      setLoading(false);
      setModalContent('No campaign or bulletin details found for ' + needle + '. Contact TSC for specific bulletin information.');
      setOpen(true);
    })
  };

  const getBulletin = (string) => {
    console.log(string);

    var data = JSON.stringify({
      "data": {
        "kw": string,
        "page": 1,
        "answerType": "OKKB-BULLETIN"
      }
    });

    var url = process.env.REACT_APP_OSVC_DOMAIN + '/cc/MobileSearch/search';

    axios.post(url, data,{withCredentials: true})
      .then((response) => {
        // console.log(response.data.results);
        if (response.data.results.length > 0) {
          findBulletinNeedle(response.data.results, string);
        }
        else{
          setLoading(false);
          setModalContent('No campaign or bulletin details found for ' + string + '. Contact TSC for specific bulletin information.');
          setOpen(true);
        }

      }).catch(err => {
        console.log(err);
        setLoading(false);
        setModalContent('No campaign or bulletin details found for ' + string + '. Contact TSC for specific bulletin information.');
        setOpen(true);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const width = useMediaQuery(theme.breakpoints.down(1322));

  

  const handleCategoryChange = (e, value) => {
    setCategory(value);
  }
  function ManualAccordions(){
    if(accordionSections.totalCount == 0){
      return (<div>
        No manuals found
      </div>);
    }

    return (
      <Grid item md={12}>
          {Object.entries(accordionSections.sections).map((manual, index) => {
            var sectionCount = manual[1].count;
            let section = manual[1];
            if(sectionCount > 0) {
              return(
                <Accordion 
                  key={'manualAccordion'+ index} 
                  expanded={accordionSections.sections[manual[0]].expanded}
                  onChange={() => {
                    debugger;
                    setAccordionSections({
                      ...accordionSections,
                      'sections': {
                        ...accordionSections.sections,
                        [manual[0]]: {
                          ...accordionSections.sections[manual[0]],
                          'expanded': !accordionSections.sections[manual[0]].expanded
                        }
                      }
                    })
                  }}
                >
                  <AccordionSummary style={{backgroundColor:"#f2f0f0"}} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography className={classes.heading}>{manual[0]}  {manual[1].count}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      { manual[1].manuals.map((individualManual, i) => {
                        return(
                          <Grid item md={12} key={i}>
                             <Results
                              i={i}
                              result={individualManual}
                              showAttachments={false}
                              sectionCount={sectionCount}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            }
          })}
      </Grid>
    );
  }
   function Results({ result, i, showAttachments, sectionCount}) {
    const uniqueID = result.UniqueId;
    const title = result.Title;
    if(result.parentResult) {
          var attachments = [result.parentResult];
    }
    // var facetInfo = result.raw.categorylevel3 ? `${result.raw.categorylevel3} ` : "";
    var facetInfo = result.raw.categorylevel4 ? ` ${result.raw.categorylevel4} ` : "";
    return (
      <Container maxWidth="lg" className={classes.Result}>
        <ListItem button onClick={() => ListItemLink(uniqueID, title, result)} className={classes.ListItem}>
          <Grid container>
              <Grid item md={11} sm={11} name="ResultContent">
                <Grid container 
                  direction="column" 
                  className={classes.bluee}
                  alignItems="stretch"
                >
                  <Grid item md={12} sm={12}>
                    <Grid container name="TitleAndFacetsContainer" 
                    justifyContent="space-between"
                    spacing={1}>
                      <Grid item md={8} sm={8} >
                        <Typography noWrap>
                          {result.Title}
                      </Typography>
                      </Grid>
                      <Grid item md={4} sm={4} style={{textAlign: "end"}}>
                        <Typography noWrap>
                            {facetInfo}
                        </Typography>
                      </Grid>
                    </Grid>
                  

                  </Grid>
                  <Grid item md={12} sm={12}>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {result.Excerpt}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={1} sm={1} name="ChevronContainer">
                <Grid container id="ChevronContainer"
                  justifyContent="center"
                  alignItems="center"
                  style={{height: "100%"}}
                >
                  <ArrowForwardIosIcon/>
                </Grid>
              </Grid>
          </Grid>
        </ListItem>
        {attachments && showAttachments &&
            <div>
              {
                attachments.map((attachment, attachmentIndex) => {
                    return (
                      <ResultAttachment
                        key={"Attachment" + i + attachmentIndex}
                        Attachment={attachment}
                        i={attachmentIndex}
                      />
                    );
                })
              }
            </div>
        }
        { i != sectionCount -1 &&
          <Divider />
        }
      </Container>
    )
  }

function ResultAttachment({Attachment, i}){
    const title = Attachment.Title;
    const URL = Attachment.ClickUri;
    const Excerpt = Attachment.Excerpt;
    return (
      <Container maxWidth="lg" className={classes.attachmentBox} onClick={(e) => {handleAttachmentClick(Attachment,URL)}}>
        <Grid container alignItems="center">
          <Grid item xs={11}>
            <Typography variant="h6" component="h6" className={classes.attachmentExcerpt} name="scroll-to-results">
              {title}
            </Typography>
            
          </Grid>
          <Grid item xs={1}>
            <Grid container direction="row-reverse"> 
              <Grid item>
                <ListItemIcon className={classes.listIcon}  >
                  <ArrowForwardIosIcon />
                </ListItemIcon>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
  function calculateIfDateIsInRange(warrantyEndDate){
    // Takes in a Warranty item and calculates if it is out of date
    // Returns boolean
    let warrantyEnd = moment(warrantyEndDate).valueOf();
    let currentTime = moment().valueOf();
    return warrantyEnd > currentTime;
  }
  function RenderData(){
    const classes = useStyles();
    const history = useHistory();
    if(machineData){

      let incidentReports = [];
      let incidentCases = [];

      if(caseData){
        caseData.forEach((Case, i) => {
          var caseID = Case.Id.valueOf();
              incidentCases.push(
                <Container onClick={(event) => caseView(caseID)} className={classes.tabItem}>
                  <Grid container alignItems="center">
                    <Grid item xs={11}>
                      <Typography key={"reportname-" + i} style={{paddingTop:'10px'}} variant="body1" component="h3">{Case.CaseNumber}</Typography>
                      <Typography key={"reporttype-" + i} style={{display: 'inline-block', backgroundColor: 'green', color: 'white', marginBottom: '10px', padding: '0px 10px', borderRadius: '10px'}} variant="body1" component="h3">{"Status: " + Case.Status}</Typography>
                      <Typography key={"reportdate-" + i} style={{display: 'inline-block', paddingLeft:'20px', paddingBottom:'10px'}} variant="body1" component="h3">{Case.Subject}</Typography>
                    </Grid>
                    <Grid item xs={1} >
                      <ArrowForwardIosIcon className={classes.listIcon} />
                    </Grid> 
                  </Grid>
                  <Divider key={"reportdivider-" + i} />
                </Container>
              );
        });
        
       }
      if(reportData){
        reportData.forEach((report, i) => {
          var caseID = report.Id.valueOf();
          incidentReports.push(
            <Container onClick={(event) => caseView(caseID)} className={classes.tabItem}>
              <Grid container alignItems="center">
                <Grid item xs={11}>
                  <Typography key={"reportname-" + i} style={{paddingTop:'10px'}} variant="body1" component="h3">{report.CaseNumber}</Typography>
                  <Typography key={"reporttype-" + i} style={{display: 'inline-block', backgroundColor: 'green', color: 'white', marginBottom: '10px', padding: '0px 10px', borderRadius: '10px'}} variant="body1" component="h3">{"Status: " + report.Status}</Typography>
                  <Typography key={"reportdate-" + i} style={{display: 'inline-block', paddingLeft:'20px', paddingBottom:'10px'}} variant="body1" component="h3">{report.Subject}</Typography>
                </Grid>
                <Grid item xs={1} >
                  <ArrowForwardIosIcon className={classes.listIcon} />
                </Grid> 
              </Grid>
              <Divider key={"reportdivider-" + i} />
            </Container>
          );
        })
      }
      let renderCampaigns = []; //Background color change task
      machineData.campaigns.forEach((item, i) => {
        let color = "";
        //could clean up with inline if maybe in the react component
        if(item.isMandatory === true){
          color="orange"
        }else{
          color="green"
        }
        //Needed this workaround to prevent i from changing in the campaignTap call
        renderCampaigns.push(
          <> 
            <Container onClick={(event) => campaignTap(item, i)} className={classes.tabItem}>
              <Grid container alignItems="center">
                <Grid item xs={11}>
                  <Typography key={"campaignname-" + i} style={{paddingTop:'10px'}} variant="body1" component="h3">{machineData.campaigns[i].campaignName}</Typography>
                  <Typography key={"campaigntype-" + i} style={{display: 'inline-block', backgroundColor: color, color: 'white', padding: '0px 10px', borderRadius: '10px'}} variant="body1" component="h3">{machineData.campaigns[i].campaignType}</Typography>
                  <Typography key={"campaigndate-" + i} style={{display: 'inline-block', paddingLeft:'20px', paddingBottom:'10px'}} variant="body1" component="h3">{"End Date: " + machineData.campaigns[i].campaignEndDate}</Typography>
                </Grid>
                <Grid item xs={1} >
                  {!campaignKnowledgeResultsVisibility[i] &&
                    <AddIcon className={classes.listIcon} />
                  }
                  {campaignKnowledgeResultsVisibility[i] &&
                    <RemoveIcon className={classes.listIcon} />
                  }
                </Grid> 
              </Grid>
            </Container>
            <Container>
              <Grid container alignItems="center">
              { campaignKnowledgeResultsVisibility[i] && campaignCoveoResults[i].length > 0 && campaignCoveoResults[i].map((result, j) => {
                      return (
                        <Grid item xs={12} key={"campaignCoveoResult"+i+j}>
                            <Results
                              key={i}
                              i={j}
                              result={result}
                              showAttachments={true}
                            />
                        </Grid> 
                      );
                    })
                  }
              </Grid> 
            </Container>
            <Divider key={"campaigndivider-" + i} /> 
          </>
        );
      });


      let renderWarranty = [];
      for (var i = 0; i < machineData.policies.length; i++) {
        let item = machineData.policies[i];
        let isWarrantyStillValid = calculateIfDateIsInRange(machineData.policies[i].warrantyEndDate);
        renderWarranty.push
        (
            <Container onClick={(event) => openWarranty(item)} className={classes.tabItem}>
              <Grid container alignItems="center">
                <Grid item xs={11}>
                  <Typography key={"policiesname-" + i} style={{paddingTop:'10px'}} variant="body1" component="h3">{machineData.policies[i].policyName}</Typography>
                  <Typography key={"policiestype-" + i} style={{display: 'inline-block', backgroundColor: (isWarrantyStillValid ? '#DC4405' : 'gray') , color: 'white', padding: '0px 10px', borderRadius: '10px'}} variant="body1" component="h3">{machineData.policies[i].warrantyStartDate + " - " + machineData.policies[i].warrantyEndDate}</Typography>
                  <Typography key={"policiesdate-" + i} style={{display: 'inline-block', paddingLeft:'20px', paddingBottom:'10px'}} variant="body1" component="h3">{"Policy Type: " + machineData.policies[i].policyType}</Typography>
                </Grid>
                <Grid item xs={1} >
                  <ArrowForwardIosIcon className={classes.listIcon} />
                </Grid> 
              </Grid>
              <Divider key={"policiesdivider-" + i} />
            </Container>
      );
      }

      let renderClaims = [];
      for (var i = 0; i < machineData.claims.length; i++) {
        let item = machineData.claims[i];
        renderClaims.push(
          <Container onClick={(event) => openClaim(item)} className={classes.tabItem}>
            <Grid container alignItems="center">
                <Grid item xs={11}>
                  <Typography key={"claimsname-" + i} style={{paddingTop:'10px'}} variant="body1" component="h3">{machineData.claims[i].symptomDescription}</Typography>
                  <Typography key={"claimstype-" + i} style={{display: 'inline-block', backgroundColor: 'green', color: 'white', padding: '0px 10px', borderRadius: '10px'}} variant="body1" component="h3">{machineData.claims[i].dateOfRepair}</Typography>
                  <Typography key={"claimsdate-" + i} style={{display: 'inline-block', paddingLeft:'20px', paddingBottom:'10px'}} variant="body1" component="h3">{"Servicing Dealer: " + machineData.claims[i].dealer}</Typography>
                </Grid>
                <Grid item xs={1} >
                  <ArrowForwardIosIcon className={classes.listIcon} />
                </Grid> 
              </Grid>
              <Divider key={"policiesdivider-" + i} />
            </Container>
      );
      }

      function pdiPress(){
        history.push('/support/machine/pdi/sn-' + machineData.serialNumber + '/mn-' + machineData.materialNumber);
      }
      function caseView(i_id){
        history.push('/support/case/' + i_id);
      }
      function casePress(){

        let path = '';
        let pathSN = (machineData.serialNumber ? 'sn-'+ machineData.serialNumber + '/' : '');
        let pathMN = (machineData.materialNumber ? 'mn-'+ machineData.materialNumber + '/' : '');
        let pathPIN = (machineData.productIdentificationNo ? 'pin-'+ machineData.productIdentificationNo + '/' : '');
        path = path.concat(pathSN,pathMN,pathPIN);
        console.log(path);
        history.push({
          pathname: '/support/machine/case/create/' + path,
          state: {
            inventoryId: inventoryId,
            imei: imei
          }
          });
      }
      function predictionPress(){
        let path = '';
        let pathSN = (machineData.serialNumber ? 'sn-'+ machineData.serialNumber + '/' : '');
        let pathMN = (machineData.materialNumber ? 'mn-'+ machineData.materialNumber + '/' : '');
        let pathPIN = (machineData.productIdentificationNo ? 'pin-'+ machineData.productIdentificationNo + '/' : '');
        path = path.concat(pathSN,pathMN,pathPIN);
        console.log(path);
        history.push({
          pathname: '/support/machine/prediction/create/' + path,
          state: {
            inventoryId: inventoryId,
            imei: imei
          }
          });
      }
      async function qualityReportPress(){
        function fetchExternalRecordId() {
          return new Promise((resolve, reject) => {
            let machineRecordId = machineData.inventoryId;
            let queryUrl = process.env.REACT_APP_API + 'salesforce/kservice/get?param=query/?q=' + `Select Id from WOD_2_Inventory_c__x Where Id = '${machineRecordId}'`;
            let queryReq = {
              url: queryUrl,
              method: 'GET',
              headers: {
                'Authorization': 'Bearer ' + Cookies.get("token"),
                'X-SalesForce-Token' : Cookies.get('sf_cookie')
              }
            }
            axios(queryReq)
              .then((response) => {

                if (response.data.records && response.data.records[0].Id) {
                  resolve(response.data.records[0].Id);
                } else {
                  reject('Unable to retreive external ID');
                }
              })
              .catch((error) => {
                console.log('encountered error querrying for external ID', error);
                reject(error);
              });
          });
          
        }
        if(Cookies.get('sf_cookie')){
          var networkId = SalesForceConstants.yourekaNetworkID;
          try {
            let externalRecordId = await fetchExternalRecordId();      
            var url = process.env.REACT_APP_SF_DOMAIN + '.my.salesforce.com/secur/frontdoor.jsp?sid='+Cookies.get('sf_cookie')+'&retURL=%2Fservlet%2Fnetworks%2Fswitch%3FnetworkId%3D'+networkId+`%26startURL%3D%2Fs%2Fdetail%2F${externalRecordId}`;
            window.open(url, "_blank").focus();
          } catch (error) {
            setSnackbarErrorMessage('Error directing to Quality Report');
            console.log('Error redirecting to Salesforce: ', error);
          }
      }
      }
      function sfcasePress(){

        let path = '';
        let pathSN = (machineData.serialNumber ? 'sn-'+ machineData.serialNumber + '/' : '');
        let pathMN = (machineData.materialNumber ? 'mn-'+ machineData.materialNumber + '/' : '');
        let pathPIN = (machineData.productIdentificationNo ? 'pin-'+ machineData.productIdentificationNo + '/' : '');
        path = path.concat(pathSN,pathMN,pathPIN);
        console.log(path);
        history.push({
          pathname: '/support/sf/create',
          });
      }
      function reportPress(){
        let path = '';
        let pathSN = (machineData.serialNumber ? 'sn-'+ machineData.serialNumber + '/' : '');
        let pathMN = (machineData.materialNumber ? 'mn-'+ machineData.materialNumber + '/' : '');
        let pathPIN = (machineData.productIdentificationNo ? 'pin-'+ machineData.productIdentificationNo + '/' : '');
        path = path.concat(pathSN,pathMN,pathPIN);
        console.log(path);
        history.push({
          pathname: '/support/machine/report/create/' + path,
          state: {
            inventoryId: inventoryId,
            imei: imei
          }
          });
      }
      function imeiPress(){
        history.push({
          pathname: "/support/machine/imei/sn-" + machineData.serialNumber + "/mn-" + machineData.materialNumber,
            state: { machine: machineData}
          });
      }
      if(loading){
        return(<CircularProgress className={classes.loadingCenterInside} />);
      } else if(failedLookup) {
        return(
          <React.Fragment>
            <CssBaseline />
            <Container maxWidth="lg" className={classes.heroContent}>
              <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                  <Paper elevation={3} className={classes.heroPaper}>
                    
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </React.Fragment>
        );
      }else{
        return(
          <React.Fragment>
            <CssBaseline />
            <Container maxWidth="lg" className={classes.heroContent}>
              <Grid container spacing={3}>
                <Grid item lg={8} md={12} sm={12} xs={12}>
                  <Paper elevation={3} className={classes.heroPaper}>
                    {stolen &&
                      <Alert severity="error" variant="filled" style={{marginBottom: '8px'}}>
                        <AlertTitle>Listed as Stolen</AlertTitle>
                        Call Kubota at 800-348-5802
                      </Alert>
                    }
                    <Grid container>
                      <Grid item justifyContent='center' alignContent='center' alignItems='center' style={{height: "100%", margin: "auto", marginLeft: "0px", marginRight: "0px"}} >
                        <Paper elevation={0} className={classes.icon} style={{border: 'solid 1px orange', borderRadius: 15, padding: '4px', display: "flex", justifyContent: "center"}}>
                          <MachineIcon
                            icon={icon}
                          />
                        </Paper>
                      </Grid>
                      <Grid item md={10} style={{marginLeft: '16px'}}>
                        <Typography color="primary" variant="h4" component="h3">{machineData.materialNumber + ' ' + machineData.serialNumber}</Typography>
                        <Typography variant="h6" component="h3">{machineData.materialNumberDescription}</Typography>
                      </Grid>
                    </Grid>
                    
                    {/* <Typography color="primary" variant="h6" component="h3">{"Serial Number: " + machineData.serialNumber + '    ' + "Model Number: " + machineData.materialNumber}</Typography> */}
                    {machineData.productIdentificationNo && 
                      <Typography color="primary" variant="h6" component="h3">{"PIN: " + machineData.productIdentificationNo}</Typography>
                    }
                    {hasKTAC &&
                       <Typography 
                        className={classes.chip}
                        variant="h6" 
                        component="h3">
                          Has KTAC
                      </Typography>
                    }
                    {preOwned &&
                          <Typography 
                            className={classes.chip}
                            variant="h6" 
                            component="h3">
                              Pre-Owned
                          </Typography>
                          
                        }
                        {auctioned &&
                          <Typography 
                            className={classes.chip}
                            variant="h6" 
                            component="h3">
                              Auctioned
                          </Typography>
                        }
                        {scrapped &&
                          <Typography 
                            className={classes.chip}
                            variant="h6" 
                            component="h3">
                              Scrapped
                          </Typography>
                        }
                        {/* {hasTelematics &&
                          <Typography 
                            className={classes.greenChip}
                            variant="h6" 
                            component="h3">
                              Has Telematics
                          </Typography>
                        } */}
                        {donated &&
                          <Typography 
                            className={classes.chip}
                            variant="h6" 
                            component="h3">
                              Donated
                          </Typography>
                        }
                        {modified &&
                          <Typography 
                            className={classes.chip}
                            variant="h6" 
                            component="h3">
                              Modified
                          </Typography>
                        }
                    <Typography variant="h6" component="h2">Customer/Owner</Typography>
                    <Divider />
                    <Typography variant="body1" component="h3">{machineData.endCustomerName}</Typography>
                    <Typography variant="body1" component="h3">{machineData.endCustomerCity + "," + machineData.endCustomerState}</Typography>
                    <br/>

                    <Typography variant="h6" component="h2">Servicing Dealer</Typography>
                    <Divider />
                    <Typography variant="body1" component="h3">{machineData.servicingDealerName}</Typography>
                    <br/>
                    <Tabs
                      value={category}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={handleCategoryChange}
                      aria-label="select article type"
                      wrapped="true"
                      variant="scrollable"
                    >
                      <Tab label="Bulletins" value="Campaigns" />
                      <Tab label="Warranty" value="Warranty" />
                      <Tab label="Manuals" value="Manuals" />
                      <Tab label="Claims" value="Claims" />
                      <Tab label="Cases" value="Cases" />
                      {rUserType.text === 'internal' &&
                        <Tab label="Reports" value="Reports" />
                      }
                    </Tabs>
                    {category == "Campaigns" &&
                      <React.Fragment>
                        {renderCampaigns}
                      </React.Fragment>
                    }
                    {category == "Warranty" &&
                      <React.Fragment>
                        {renderWarranty}
                      </React.Fragment>
                    }
                    {/* {category == "Manuals" &&
                      <>
                        <Container>
                          <Grid container alignItems="center">
                          { manuals && manuals.length > 0 && 
                            manuals.map((result, j) => {
                                  return (
                                    <Grid item xs={12} key={"manualsCoveoResult"+i+j}>
                                        <Results
                                          key={i}
                                          i={j}
                                          result={result}
                                          showAttachments={false}
                                        />
                                    </Grid> 
                                  );
                                })
                          }
                          { (!manuals || (manuals && manuals.length == 0)) && 
                            <Grid item xs={12} key={"manualsPlaceholder"} style={{marginBottom: "8px"}}>
                              <Alert severity="info">No Manuals Found</Alert>
                            </Grid>
                          }
                          </Grid> 
                        </Container>
                        <Divider key={"campaigndivider-" + i} /> 
                      </>
                    } */}
                    {category == "Manuals" && accordionSections &&
                      <>
                        <Container style={{padding: '0px'}}>
                          <Grid container alignItems="center">
                            <ManualAccordions
                              
                            />
                          </Grid> 
                        </Container>
                      </>
                    }
                    {category == "Claims" &&
                      <React.Fragment>
                        {renderClaims}
                      </React.Fragment>
                    }
                    {category == "Cases" &&
                      <React.Fragment>
                        {incidentCases}
                      </React.Fragment>
                    }
                    {rUserType.text === 'internal' &&
                    <React.Fragment>
                      {category == "Reports" &&
                        <React.Fragment>
                          {incidentReports}
                        </React.Fragment>
                      }
                    </React.Fragment>
                    }
                  </Paper>

                </Grid>
                <Grid item lg={4} md={12} sm={12} xs={12}>
                  <Grid container>
                    <Grid item md={12}>
                      <Paper elevation={3} className={classes.heroPaper}>
                        <Typography align="center" variant="h6" component="h2">Choose Activity</Typography>
                        <Divider />
                        <Button onClick={casePress}
                          className={classes.sidebutton}
                          variant="outlined"
                          color="primary"
                          size="large"
                          fullWidth>
                            Create Case
                          </Button>
                          {rUserType.text === 'internal' &&
                            <Button onClick={reportPress}
                            className={classes.sidebutton}
                            variant="outlined"
                            color="primary"
                            size="large"
                            fullWidth>
                              Create Report
                            </Button>
                          }
                        {/* <Typography color="primary" variant="button" align="center" component="h3">Create Report</Typography> */}
                        <br/>
                        <Button onClick={imeiPress}
                          className={classes.sidebutton}
                          variant="outlined"
                          color="primary"
                          size="large"
                          fullWidth>
                            Machine Telematics Install
                        </Button>
                        {/* <Button onClick={() => {
                          setTelematicsModalOpen(true);
                          if(!faultCodes){
                            fetchFaultCodeData
                          }
                        }}
                          className={classes.sidebutton}
                          variant="outlined"
                          color="primary"
                          size="large"
                          disabled={!telematicsData}
                          
                          fullWidth>
                            Telematics Information
                        </Button> */}
                        <Button 
                          onClick={predictionPress}
                          className={classes.sidebutton}
                          variant="outlined"
                          color="primary"
                          size="large"
                          fullWidth>
                            Create Parts Prediction
                        </Button>
                       {rUserType.text === 'external' &&
			<Button 
                          onClick={qualityReportPress}
                          className={classes.sidebutton}
                          variant="outlined"
                          color="primary"
                          size="large"
                          fullWidth>
                            Quality Report
                        </Button>
		       }
                      </Paper>
                    </Grid>
                    {/* { majorComponents && 
                      <Grid item md={12}>
                        <Paper elevation={3} className={classes.heroPaper}>
                            <Typography align="center" variant="h6" component="h2">Major Components</Typography>
                            <Divider />
                            { majorComponents.length > 0 &&
                              <TableContainer style={{marginTop: "8px"}}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                  <TableHead style={{backgroundColor: "#9ca3af"}}>
                                      <TableRow>
                                      <TableCell >Type</TableCell>
                                      <TableCell >Serial</TableCell>
                                      <TableCell ></TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      {majorComponents.map((row, index) => {
                                        return(
                                      <TableRow
                                          key={"Notif" + index}
                                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                          onClick={() => {
                                            setOpenedMajorComponent(majorComponents[index]);
                                          }}
                                          className={classes.majorComponent}
                                      >
                                          <TableCell component="th" scope="row">{row.Type__c}</TableCell>
                                          <TableCell >{(row.WOD_2__Serial_number__c) ? row.WOD_2__Serial_number__c : "N/A"}</TableCell>
                                          <TableCell >
                                            <ArrowForwardIosIcon style={{marginTop: "2px"}}/>
                                          </TableCell>

                                      </TableRow>
                                      )})}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            }
                            { majorComponents.length == 0 &&
                                <Alert
                                  severity="info"
                                  style={{marginTop: "8px"}}
                                >
                                  No Components found
                                </Alert>
                              
                            }
                          </Paper>
                      </Grid>
                    } */}
                  </Grid>
                  
                </Grid>
              </Grid>

              <div>
                <Modal
                  open={(openedMajorComponent) ? true : false}
                  onClose={() => {
                    setOpenedMajorComponent(false);
                  }}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  style={{alignItems:'center', justifyContent:'center', display: 'flex',}}
                >
                  <div style={{margin: 'auto', top: '20%', borderRadius: "10px", border: "1px solid #DC4405", overflow: "hidden"}} className={classes.majorComponentModal}>
                  <Grid container>
                    <Grid item md={12} sm={12} style={{backgroundColor: "#fcede7", padding: "8px"}}> 
                      <Typography style={{color: "#e05a21"}}>
                        <strong>Major Component</strong>
                      </Typography>
                    </Grid>
                    <Grid item md={12} style={{paddingLeft: "16px"}}>
                      <Grid container>
                        <Grid item md={6} style={{marginTop: "4px", marginBottom: "4px"}}>
                          <Typography variant="body2" className={classes.TelematicsMetric}>
                            Type
                          </Typography>
                          <Typography variant="body2">
                            {openedMajorComponent.Type__c}
                          </Typography>
                        </Grid>
                        <Grid item md={6} style={{marginTop: "4px", marginBottom: "4px"}}>
                          <Typography variant="body2" className={classes.TelematicsMetric}>
                            Serial Number
                          </Typography>
                          <Typography variant="body2">
                            {(openedMajorComponent.WOD_2__Serial_number__c) ? openedMajorComponent.WOD_2__Serial_number__c : "N/A"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={12} sm={12} style={{backgroundColor: "#fcede7", padding: "8px"}}> 
                      <Typography style={{color: "#e05a21"}}>
                        <strong>Telematics Information</strong>
                      </Typography>
                    </Grid>
                    <Grid item md={12} style={{paddingLeft: "16px"}}>
                      { openedMajorComponent

                      }
                      <Grid container>
                        <Grid item md={6} style={{marginTop: "4px", marginBottom: "4px"}}>
                          <Typography variant="body2" className={classes.TelematicsMetric}>
                            IMEI Number
                          </Typography>
                          <Typography variant="body2">
                            354045092552931
                          </Typography>
                        </Grid>
                        <Grid item md={6} style={{marginTop: "4px", marginBottom: "4px"}}>
                          <Typography variant="body2" className={classes.TelematicsMetric}>
                            Modem Part Number
                          </Typography>
                          <Typography variant="body2">
                            DCU6700
                          </Typography>
                        </Grid>
                        <Grid item md={6} style={{marginTop: "4px", marginBottom: "4px"}}>
                          <Typography variant="body2" className={classes.TelematicsMetric}>
                            Installed
                          </Typography>
                          {/* <Switch disabled defaultChecked className={classes.disabledSwitch}/> */}
                          <Checkbox checked={true} color="primary" className={classes.disabledSwitch} 
                            style={{
                              margin: "0px", 
                              padding: "2px",
                            }}/>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  </div>
                </Modal>
              </div>

              <div>
                <Modal
                  open={telematicsModalOpen}
                  onClose={() => {
                    setTelematicsModalOpen(false);
                  }}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  style={{alignItems:'center', justifyContent:'center', display: 'flex', padding: "0px"}}
                >
                  <div style={{top: '20%', borderRadius: "10px", border: "1px solid #DC4405", overflow: "hidden"}} className={classes.modal}>
                      <TelematicsModal
                        classes={classes}
                        faultCodes={sampleFaultCodeHistory.data}
                        setFaultCodes={setFaultCodes}
                        setMapsModalOpen={setMapsModalOpen}
                      />
                    <Modal
                      open={mapsModalOpen}
                      onClose={() => {
                        setMapsModalOpen(false);
                      }}
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                      style={{alignItems:'center', justifyContent:'center', display: 'flex', padding: "0px", width: "80%", margin: "auto"}}
                    >
                      {/* <div style={{top: '20%', borderRadius: "10px", border: "1px solid #DC4405", overflow: "hidden"}} className={classes.modal}>
                          <MapsModal
                          />
                      </div> */}
                    </Modal>
                  </div>
                </Modal>
              </div>

              <>
              <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  style={{alignItems:'center', justifyContent:'center', display: 'flex'}}
                >
                  <div style={{margin: 'auto', top: '20%', paddingLeft: '8px', paddingRight:  '8px'}} className={classes.modal}>
                    <p id="simple-modal-description">
                      {modalContent}
                    </p>
                  </div>
                </Modal>
              </>
            </Container>
            <Snackbar
              open={snackbarErrorMessage}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={7000}
              onClose={() => {
                if(snackbarErrorMessage) {
                  setSnackbarErrorMessage('');
                }
              }}
            >
               <Alert
                  onClose={() => {
                    setSnackbarErrorMessage('');
                  }}
                  severity="error"
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                  {snackbarErrorMessage}
                </Alert>
            </Snackbar>
          </React.Fragment>
        )
      }
    }else{
      return(<CircularProgress className={classes.loadingCenterInside} />);
    }
  }

  return (
    <RenderData/>
  );
}

const TelematicsModal = ({telematicsResponse, classes, faultCodes, setFaultCodes, setMapsModalOpen}) => {
  const [filter, setFilter] = useState('All');

  function handleModalFilterChange(v){
    if (!filter || filter === "All") return faultCodes;
    return faultCodes.filter((faultCode) => (faultCode.severity === v));
  }
  return(
    <>
      <Grid container>
        <Grid item md={12} style={{borderRight: "1px solid black", marginBottom: "16px"}}>
          <Grid container>
            <Grid item md={12} sm={12} style={{backgroundColor: "#fcede7", padding: "8px", paddingLeft: '12px'}}> 
              <Typography style={{color: "#e05a21"}}>
                <strong>Last Telematics Report</strong>
              </Typography>
            </Grid>
            <Grid item md={12} style={{paddingLeft: "16px"}}>
              <Grid container>
                <Grid item md={6} style={{marginTop: "4px", marginBottom: "4px"}}>
                  <Typography variant="body2" className={classes.TelematicsMetric}>
                    Last Report
                  </Typography>
                  <Typography variant="body2">
                    1/1/1970 12:00:01 AM
                  </Typography>
                </Grid>
                <Grid item md={6} style={{marginTop: "4px", marginBottom: "4px"}}>
                  <Typography variant="body2" className={classes.TelematicsMetric}>
                    DEF Quality
                  </Typography>
                  <Typography variant="body2">
                    12.34%
                  </Typography>
                </Grid>
                <Grid item md={6} style={{marginBottom: "4px"}}>
                  <Typography variant="body2" className={classes.TelematicsMetric}>
                    Last Location
                  </Typography>
                  <Typography variant="body2">
                    <Link 
                        component="button"
                        onClick={() => {setMapsModalOpen(true)}}
                        underline="always"
                    >
                      12.345691, 33.123454
                    </Link>
                  </Typography>
                </Grid>
                <Grid item md={6} style={{marginBottom: "4px"}}>
                  <Typography variant="body2" className={classes.TelematicsMetric}>
                    DEF Level
                  </Typography>
                  <Typography variant="body2">
                    18%
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12} style={{marginBottom: "4px"}}>
                  <Typography variant="body2" className={classes.TelematicsMetric}>
                    Current Hours
                  </Typography>
                  <Typography variant="body2">
                    1234
                  </Typography>
                </Grid>
                <Grid item md={6} style={{marginBottom: "4px"}}>
                  <Typography variant="body2" className={classes.TelematicsMetric}>
                    Battery Voltage
                  </Typography>
                  <Typography variant="body2">
                    12.34 V
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12 } style={{marginBottom: "0px"}}>
          <Grid container>
            <Grid item md={6} sm={6} style={{backgroundColor: "#fcede7", padding: '4px'}}>
              <Grid container style={{display: "flex", height: '100%'}}>
                <Typography style={{color: "#e05a21", margin: "auto 0", marginLeft: "8px"}}>
                    <strong>Fault Code Report</strong>
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={6} sm={6} style={{backgroundColor: "#fcede7"}}>
              <Grid container direction="row-reverse">
                <Grid item>
                  <Select
                    variant="outlined"
                    style={{marginTop: "4px", marginRight: "16px"}}
                    onChange={(e) => {setFilter(e.target.value)}}
                    value={filter}
                    displayEmpty
                    renderValue={(v) => {
                      if (!v) {
                        return "Fault Severity"
                      }
                      return v;
                    }}
                  >
                    <MenuItem value="All">
                      All
                    </MenuItem>
                    <MenuItem value="High">
                      High
                    </MenuItem>
                    <MenuItem value="Medium">
                      Medium
                    </MenuItem>
                    <MenuItem value="Low">
                      Low
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} style={{paddingLeft: "0px", paddingRight: "0px"}}>
              <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead style={{backgroundColor: "#e05a21"}}>
                      <TableRow >
                      <TableCell className={classes.whiteText}>HR Reading</TableCell>
                      <TableCell className={classes.whiteText}>SPN</TableCell>
                      <TableCell className={classes.whiteText}>FMI</TableCell>
                      <TableCell className={classes.whiteText}>SA</TableCell>
                      <TableCell className={classes.whiteText}>E/P Code</TableCell>
                      <TableCell className={classes.whiteText}>Description</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {handleModalFilterChange(filter).map((row, index) => { 
                        return (
                        <TableRow
                            key={"Notif" + index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                            {row["hrReading"]}
                            </TableCell>
                            <TableCell >{row["spn"]}</TableCell>
                            <TableCell >{row["fmi"]}</TableCell>
                            <TableCell >{row["sa"]}</TableCell>
                            <TableCell >{row["epCode"]}</TableCell>
                            <TableCell >{row["description"]}</TableCell>
                        </TableRow>
                      )})}
                  </TableBody>
                  </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

// const MapsModal = () => {
//   return(
//     <div
//     >
//       <GoogleMap
//       location={{
//         lat: 32.979987002739534,
//         lng: -97.02712848682,
//         address: ""
//       }}
//       zoomLevel={12}
//     />  
//     </div>
    
//   );
// }

const mapStateToProps = state => ({
  rUserType: state.userType,
})

export default connect(mapStateToProps)(MachineDetailsPage);

