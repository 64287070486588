export default function getMimeTypeByExtn(extn) {
  // create a map of extn as key and mimetype as value
  const fileMap = new Map();

  // set values
  fileMap.set('pdf', 'application/pdf');
  fileMap.set('aac', 'audio/aac');
  fileMap.set('midi', 'audio/midi');
  fileMap.set('mpeg', 'audio/mpeg');
  fileMap.set('ogg', 'audio/ogg');
  fileMap.set('opus', 'audio/opus');
  fileMap.set('wav', 'audio/wav');
  fileMap.set('webm', 'audio/webm');
  fileMap.set('avif', 'image/avif');
  fileMap.set('bmp', 'image/bmp');
  fileMap.set('gif', 'image/gif');
  fileMap.set('jpeg', 'image/jpeg');
  fileMap.set('jpg', 'image/jpeg');
  fileMap.set('png', 'image/png');
  fileMap.set('svg', 'image/svg');
  fileMap.set('tiff', 'image/tiff');
  fileMap.set('icon', 'image/icon');
  fileMap.set('webp', 'image/webp');
  fileMap.set('3gpp', 'video/3gpp');
  fileMap.set('3gpp2', 'video/3gpp2');
  fileMap.set('mp2t', 'video/mp2t');
  fileMap.set('mp4', 'video/mp4');
  fileMap.set('mpeg', 'video/mpeg');
  fileMap.set('webm', 'video/webm');
  fileMap.set('msvideo', 'video/msvideo');
  fileMap.set('txt', 'text/plain');
  fileMap.set('csv', 'text/csv');
  fileMap.set('html', 'text/html');
  fileMap.set('zip', 'application/zip');
  fileMap.set('bzip', 'application/zip');
  fileMap.set('rar', 'application/zip');
  fileMap.set('tar', 'application/zip');
  fileMap.set('xls', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  fileMap.set('xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  fileMap.set('ppt', 'application/vnd.ms-powerpoint');
  fileMap.set('pptx', 'application/vnd.ms-powerpoint');
  fileMap.set('rtf', 'application/rtf');
  fileMap.set('vsd', 'application/vnd.visio');
  fileMap.set('vsdx', 'application/vnd.visio');
  fileMap.set('xml', 'application/xml');
  fileMap.set('doc', 'application/msword');
  fileMap.set('docx', 'application/msword');

  // search map
  const mime = fileMap.get(extn.toLowerCase());

  // found
  if (mime) {
      // return value
      return mime;
  } else {
      // return default
      return 'application/octet-stream';
  }
  
}