import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { AppBar, Container } from '@material-ui/core';
import { Toolbar } from '@material-ui/core';
import { Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { render } from 'react-dom';


const useStyles = makeStyles((theme) => ({
  alert: {
    margin: theme.spacing(4, 0, 0),
  }
}));

const NotificationCenter = () => {
    const theme = useTheme();
    const classes = useStyles();
    const [expireDate, setExpireDate] = useState(false);
    const [expireDaysUntil, setExpireDaysUntil] = useState(false);

    const width = useMediaQuery(theme.breakpoints.down(550));

    useEffect(() => {
      if(localStorage.getItem('PEDT') !== null){
        var date = localStorage.getItem('PEDT');
        console.log('Date: ' + date);
        processExpireDate(date);
      }

    }, []);
    
    function processExpireDate(date){
      var newNum = date.toString().match(/.{1,2}/g);
      // console.log(newNum);
      var date = new Date(newNum[0] + newNum[1], newNum[2], newNum[3], newNum[4], newNum[5], newNum[6]);
      // console.log(date);
      var now = new Date();
      var diff = Math.abs(date.getTime() - now.getTime());
      // console.log('Diff: ' + diff);
      var expireDaysUntil = diff / (1000 * 60 * 60 * 24);
      // Format "Full Month DD, YYYY"
      var month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      var expireDate = month[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
  
      // console.log(expireDaysUntil);
      setExpireDate(expireDate);
      setExpireDaysUntil(expireDaysUntil);
  }

    return(
      
        <Container>
          <Grid item xs={12}>
          { expireDate && setExpireDaysUntil <= 160 &&
            <Alert severity="error" className={classes.alert}>Your password will expire on {expireDate}. For directions on reseting your password please review the following knowledge article:
            </Alert>
          }  
          </Grid>
        </Container>
    );
}

export default NotificationCenter;