import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Divider } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import { getAnalytics, logEvent } from "firebase/analytics";
import SalesForceConstants from '../SalesForceConstants';



const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2, 4, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  error: {
    margin: theme.spacing(0, 0, 2),
  },
  submit: {
    margin: theme.spacing(2, 0, 1),
  },
  root: {
    background: "#efefef",
    minWidth: "100%",
    minHeight: "calc(100vh - 121px);",
    display: "flex",
    flexDirection: "column"
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
    width: '90%',
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  form: {
    margin: theme.spacing(0, 0, 5)
  },
}));



const SupportPage = ({ rUserType }) => {
  console.log("Support page user type: ");
  console.log(rUserType);
  const classes = useStyles();
  const history = useHistory();
  const [modal, setModal] = useState(false);

  
  const machineSearchPress = () =>{
    history.push('/support/machine/search');
  }

  const machineIMEISearchPress = () =>{
    history.push('/support/machine/search/imei');
  }

  const dealerLookupPress = () =>{
    history.push('/support/sf/dealer/lookup');
  }

  function handleViewCasesClick(){
    history.push('/support/cases');
  }

  function kUInformationPress(){
    history.push('/support/ku');
  }

  function masterCodeLookupPress(){
    history.push('/support/dcu/masterkey');
  }

  function iframeTestPage() {
    history.push('/support/test/caseDeflect');
  }
  function adminNotifPress(){
    history.push('/support/notifDashboard');
  }
  

  function iframeTestPage2() {
    //@TODO; Dont move to support/test/customInspection
    //Open Modal

    if(Cookies.get('sf_cookie')){
        var networkId = SalesForceConstants.yourekaNetworkID;
        var url = process.env.REACT_APP_SF_DOMAIN + '.my.salesforce.com/secur/frontdoor.jsp?sid='+Cookies.get('sf_cookie')+'&retURL=%2Fservlet%2Fnetworks%2Fswitch%3FnetworkId%3D'+networkId+'%26startURL%3D%2Fs%2Fcustom-inspection';
        window.open(url, "_blank").focus();
        setModal(false);
    }
    // history.push('/support/test/customInspection');
  }
function handleClose() {
  setModal(false);
}

  return (
    <React.Fragment>
      <CssBaseline />
      {modal &&
        <Dialog
          open={modal}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Open a new tab?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are about to open a new tab and leave K-Service. Are you sure?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Deny</Button>
            <Button onClick={iframeTestPage2} autoFocus>
              Allow
            </Button>
          </DialogActions>
        </Dialog>
      }
      {rUserType &&
      <Container maxWidth="lg" className={classes.heroContent} >
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={4}>
          <Paper className={classes.heroPaper} elevation={3} justify="center" xs={12}>

              <Typography component="h1" variant="h5">Support</Typography>
              <Divider />
              
              <Button fullWidth variant="contained" color="primary" size="large" className={classes.submit} onClick={(event) => machineSearchPress()}>Machine Search</Button>
              <Button fullWidth variant="contained" color="primary" size="large" className={classes.submit} onClick={machineIMEISearchPress}>Machine Telematic Install</Button>
              {rUserType && rUserType.text === 'internal' &&
                <Button fullWidth variant="contained" color="primary" size="large" className={classes.submit} onClick={(event) => dealerLookupPress()}>Dealer Lookup</Button>
              }
              {/* {rUserType && rUserType.text === 'dealer' && */}
                <Button fullWidth variant="contained" color="primary" size="large" className={classes.submit} onClick={(event) => handleViewCasesClick()}>View Cases</Button>
              {/* } */}
              <Button fullWidth variant="contained" color="primary" size="large" className={classes.submit} onClick={(event) => kUInformationPress()}>KU Information</Button>
              <Button fullWidth variant="contained" color="primary" size="large" className={classes.submit} onClick={(event) => masterCodeLookupPress()}>Keyless Master Password</Button>
              {rUserType && rUserType.text === 'external' &&
                <Button fullWidth variant="contained" color="primary" size="large" className={classes.submit} onClick={() =>{setModal(true)}}>Product Delivery Inspection</Button>
              }
              { 1 == 0 &&
                <Button fullWidth variant="contained" color="primary" size="large" className={classes.submit} onClick={(event) => adminNotifPress()}>Notification Dashboard</Button>              
              }
              <Button fullWidth variant="contained" color="primary" size="large" className={classes.submit} onClick={() => {history.push('/support/predictions')}}>Predictions History</Button>              
              <Button fullWidth variant="contained" color="primary" size="large" className={classes.submit} onClick={() => {history.push('/support/DealerContacts')}}>My Dealer Contacts</Button>              

            </Paper>
          </Grid>
        </Grid>
      </Container>
      }
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  rUserType: state.userType,
});

export default connect(mapStateToProps)(SupportPage);
