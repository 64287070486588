import React from 'react'
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Cookies from 'js-cookie';
import axios from 'axios';


/*
    Oh boy
    1. Load values for drop down, limit to 5? 10? 40?
    2. On change repopulate values with current search terms
*/



function SFLookupRelationship({component, currentVal, onFieldValueUpdate, itemLabel, i, referenceTo, editValues, classes, ErrorStates}) {
  const apiName = component.fieldInfo.apiName;
  //List of values
  const [currentValues, setCurrentValues] = useState([]);
  //Current selected value
  // const [value, setValue] = React.useState(null);
  //Current Input value
  const [InputValue, setInputValue] = useState(null);

  useEffect(() => {
    // On Component Load we fetch the recent results. Once user begins typing we can switch to type ahead endpoint
    fetchRecents();
  }, []);

  useEffect(() => {
    //On value change
    var test = InputValue;
    console.log("Input is now: " + test);
    if(InputValue && InputValue.length >= 3)
    {
      //Make typeahead fetch using current input
      fetchValues(InputValue)
    }
    else if(!InputValue || InputValue.length < 3)
    {
      fetchRecents();
    }
  }, [InputValue]);

  const fetchValues = (q = false) => {
    console.log('Fetching Values for input: ' + q);
    //Couldn't figure out if you could have function with default param of empty string. Just kept it as default false and fixed it here
    //This is our type ahead q string, if not given assume empty
    if(!q) q = "";
    //Fetch values for the referenceTo object, and put into array, then set state
    // var url = 'https://kubotaservice--ksdev.sandbox.my.salesforce.com/services/data/v54.0/ui-api/lookups/Case/' + apiName + "?searchType=TypeAhead&q=" + q ;//+ component.fieldInfo.apiName;
    var url = process.env.REACT_APP_API + 'salesforce/kservice/Get?param=ui-api/lookups/Case/' + apiName + "?searchType=TypeAhead%26q=" + q;
    var hasFilter = false;
    var controllingField;
    var fieldId;

    if(component.fieldInfo.filteredLookupInfo){
      hasFilter = component.fieldInfo.filteredLookupInfo.dependent;
      controllingField = component.fieldInfo.filteredLookupInfo.controllingFields[0];
    }
    if(editValues[controllingField] && editValues[controllingField].current){
      fieldId = editValues[controllingField].current.id;
      if (hasFilter && fieldId){
        url += '%26dependentFieldBindings='+controllingField+'='+fieldId;
      }
    }
    console.log("New url is: " + url);
    var request = {
      url : url,
      method : 'GET',
      headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + Cookies.get("token"),
          'X-SalesForce-Token' : Cookies.get('sf_cookie'),
          'X-Id-Token' : Cookies.get('idToken')
      }
    }
    axios(request).then((response) => {
      var records = response.data.lookupResults[referenceTo];
      if(records && records.count > 0)
      {
        var jsonArr = []
        records.records.forEach((record) => {jsonArr.push({name: record.fields.Name.value, id: record.fields.Id.value})});
        setCurrentValues(jsonArr);
        console.log("Setting current values as: ");
        console.log(jsonArr);
      }
      else
      {
        setCurrentValues([]);
      }
    }).catch((error) => {
      setCurrentValues([]);
      console.log(error);
    });
    setCurrentValues([]);
  };


  const fetchRecents = () => {
    //Fetch values for the referenceTo object, and put into array, then set state
    // var url = 'https://kubotaservice--ksdev.sandbox.my.salesforce.com/services/data/v54.0/ui-api/lookups/Case/' + apiName ;//+ component.fieldInfo.apiName;
    //var url = process.env.REACT_APP_API + 'salesforce/kservice/Get?param=ui-api/lookups/Case/' + apiName;

    if (!editValues){return};

    var hasFilter = false;
    if(component.fieldInfo.filteredLookupInfo){
      hasFilter = true;
    }
    var url = process.env.REACT_APP_API + 'salesforce/kservice/Get?param=ui-api/lookups/Case/' + apiName;
    var accountId = editValues.AccountId.current;
    
    if (hasFilter && accountId){
      url += '%3FdependentFieldBindings=AccountId='+accountId.id;
    }
    var request = {
      url : url,
      method : 'GET',
      headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + Cookies.get("token"),
          'X-SalesForce-Token' : Cookies.get('sf_cookie')
      }
    }
    axios(request).then((response) => {
      var records = response.data.lookupResults[referenceTo];
      if(records && records.count > 0)
      {
        //Add records to select list
        var jsonArr = [];
        records.records.forEach((record) => {
          //Some objects dont use normal name field. Uses Name__c field.          
          var nameLabel;
          if(!record.fields.hasOwnProperty("Name"))
          {
            if(record.fields.hasOwnProperty("Name__c")) {
              nameLabel = record.fields.Name__c.value;
            }
          }
          else {
            nameLabel = record.fields.Name.value;
          }
        
          jsonArr.push({name: nameLabel, id: record.fields.Id.value})
        
        });
        setCurrentValues(jsonArr);
      }
      else
      {
        //Just give empty array
        setCurrentValues([]);
      }
    }).catch((error) => {
      setCurrentValues([]);
      console.log(error);
    });
  };

  return (
    <Autocomplete
      value={currentVal ? currentVal.name : null}
      className={classes.outlineInput}
      onChange={(event, newValue) => onFieldValueUpdate(component.field, newValue, event)}
      filterOptions={(x) => x}
      // filterOptions={(options, params) => {
      //   console.log("Filtering");
      //   const { inputValue } = params;
      //   const filtered = filterOptions(options, params);
      //   // Suggest the creation of a new value
      //   // const isExisting = options.some((option) => inputValue === option.title);
      //   if (inputValue !== '') {
      //    filtered.push('Add ' + referenceTo);
      //   }
      //   if(inputValue === "cory creig")
      //   {
      //     debugger;
      //   }
      //   return filtered;
      // }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        //If input value changes and no longer =='s the selected value. We need to clear select value.
        //Ex. Typed in Chuck, clicks on Chuck Norris. Input becomes Chuck Norris. If user back spaces and now has Chuck Norri,
        //we no longer have a validInput. Need to clear selected value so user may not submit.
        if(currentVal && newInputValue !== currentVal)
        {
          //Wipe selected value
        }
      }}
      options={currentValues}
      getOptionLabel={(option) => {
        // Options fetched on page load, are an OBJ {name, id}. Options 
        if(typeof option === 'string') { 
          return option;
        }
        else {
          return option.name;
        }
      }}
      renderOption={(props, option) => {
        return (
          <li {...props}>{option.name}</li>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField {...params} label={component.label} error={ErrorStates[component.field]}/>
        );
      }}
    />
  );
}

export default SFLookupRelationship