import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme) => ({
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
}));

function CustomInspection() {
    // Open Youreka Custom Inspection Page
    if(Cookies.get('sf_cookie')){
        var url = 'https://kubotaservice--ksdev.sandbox.my.salesforce.com/secur/frontdoor.jsp?&sid='+Cookies.get('sf_cookie')+'&retURL=%2Fservlet%2Fnetworks%2Fswitch%3FnetworkId%3D0DB8D000000CbxQ%26startURL%3D%2Fs%2Fcustom-inspection';
        window.open(url, '_blank').focus();
    }
    
    const classes = useStyles();
    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="lg" className={classes.heroContent}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <Paper elevation={3} className={classes.heroPaper}>
                            
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            </React.Fragment>
    )
}

export default CustomInspection