import React, {useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Cookies from 'js-cookie';
import axios from 'axios';
import checkCoveoToken from '../helpers/checkCoveoToken';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';



const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(4, 0, 6),
    width: '70%'
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  loadingCenterInside: {
    marginLeft: '46%'
  },
  actionButtons: {
      backgroundColor: "green",
      width: "fit-content"
  },

}));

function CaseDeflection({setStep, DTC, subject, machineDetails, callNextStageAnalytics, callFieldUpdateAnalytics, callCancelOrSolveTicketAnalytics, returnToMachine, uuid}) {
    const classes = useStyles();
    const [displayResults, setDisplayResults] = useState();
    const [resultLoadingArray, setResultLoadingArray] = useState([false, false, false, false, false, false]);
    const [loading, setLoading] = useState();


    useEffect(() => {
        setLoading(true);
        checkCoveoToken()
            .then(() => {
                fetchCaseDeflectResults();
            })
            .catch((e) => {
                console.log("Unable to fetch a Coveo Search Token:");
                console.log(e);
            });
    }, []);

    const fetchCaseDeflectResults = () => {
        //Make request to Case Assist Endpoint
        // var url = "https://platform.cloud.coveo.com/rest/organizations/kubotausanonproduction1uwe4kf4h/caseassists/5e5bb2d6-06ca-4dba-9c0c-31505b16eaff/documents/suggest?numberOfResults=100"
        var url = process.env.REACT_APP_API  + "Coveo/kservice/CaseAssist"
        var test = machineDetails;
        var req = {
            url : url,  
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + Cookies.get("token"),
                'X-Coveo-Token': Cookies.get("CoveoCaseAssistToken"),
                'Content-Type': 'application/json'
            },
            data : {
                "visitorId": uuid,
                "fields": {
                    "subject": {
                        "value": subject
                    }
                },
                "context": {
                    "serial_num": machineDetails.serialNumber,
                    "model_num": machineDetails.modelNumber,
                    "dtc_code_search": "",
                    "dtc_code_selected": DTC,
                    "businessUnits": "KTC-USA",
                    "origin" : "k-service"
                }
            }
        };
        axios(req)
            .then((response) => {
                let filteredOutArray = response.data.documents.filter(isNonSFItem);
                setDisplayResults(filteredOutArray.slice(0,6));
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    }

    

    const isNonSFItem = (x) => {
        if(x.fields.filetype === "SalesforceItem")
            return false;
        else
            return true;
    }
    const handleSubmit = () => {
        callNextStageAnalytics("SECTION_3");
        callFieldUpdateAnalytics();
        setStep(prevState => prevState +1);
    }

    const handlePrevious = () => {
        callNextStageAnalytics("SECTION_1");
        callFieldUpdateAnalytics();
        setStep(prevState => prevState -1);
    }

    const handleCancel = () => {
        callCancelOrSolveTicketAnalytics(false);
        returnToMachine();
    }
    const handleSolved = () => {
        callCancelOrSolveTicketAnalytics(true);
        returnToMachine();
        debugger;
    }

    function ListItemLink(uniqueID, index, filetype, filename) {
        if(filetype === "pdf") {
            if(uniqueID.includes("kits.kubota")) {

                fetchAttachmentFromKits(uniqueID, index, filename);
            } else {
                console.log('PDF not hosted on Kits, aborting');
            }
        } else {
            let tab = window.open();
            tab.location.href = uniqueID;
        }
    }

    function fetchAttachment(attachmentURL) {
        let url = document.createElement('a');
        url.href = attachmentURL;
        debugger;
        // If file attachment URL is Azure
        let adTokenUrl = process.env.REACT_APP_API + 'salesforce/kservice/apexrest?param=ADToken';
        let adTokenReq = {
            method : "GET",
            url: adTokenUrl,
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Accept": "application/json",
                "Authorization": "Bearer " + Cookies.get("token"),
                // "X-SalesForce-Token" : Cookies.get('sf_cookie')
            }
        }
        axios(adTokenReq).then((adTokenRes) => {
        if(adTokenRes.data){
            let adToken = adTokenRes.data;
            console.log(adToken);
            let x_ms_version = '2021-08-06';
            var newURL =  localStorage.getItem("KnowledgeAttachmentBaseURL") + url.href.substring(url.href.indexOf("/Knowledge__kav"));
            previewBlob(newURL, adToken, x_ms_version)
                .then(result => {
                    let fileLink = result;
                    debugger;
                    downloadFileAttachment(fileLink, fileName)
                })
                .catch(error => {
                    console.log(error);
                })
        }
        })
        .catch(error => {
        console.log(error);
        }) 
    }
    function fetchAttachmentFromKits(attachmentURL, index,fileName) {
        let x_ms_version = '2021-08-06';
        const kitToken = Cookies.get("KitToken");
        //Mark Result as Loading, these kits take a while.
        const clone = [...resultLoadingArray];
        clone[index] = true;
        setResultLoadingArray(clone);
        
        previewBlob(attachmentURL, kitToken, x_ms_version)
                .then(result => {
                    let fileLink = result;
                    debugger;
                    downloadFileAttachment(fileLink, fileName)

                    const clone = [...resultLoadingArray];
                    clone[index] = false;
                    setResultLoadingArray(clone);
                })
                .catch(error => {
                    debugger;
                    console.log(error);

                    const clone = [...resultLoadingArray];
                    clone[index] = false;
                    setResultLoadingArray(clone);
                })
    }

    // Function to preview blob
    function previewBlob(uri, token, version) {
        // create headers object      
        const myHeaders = {'Authorization': 'Bearer ' + token};
        // create request options
        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        let mimeType='application/octet-stream'; // init mime type
        // return promise
        return new Promise((resolve, reject) => {
            // fetch request
            fetch(uri, requestOptions)
            .then(response => { 
                const contentType = response.headers.get('content-type'); // get blob mime type
                //mimeType = 'application/octet-stream'; // set to allowed type
                return response.arrayBuffer(); // returns a promise that resolves with a generic, fixed-length raw binary data buffer
            }) 
            .then(result => { // process response     
            // Specify a MIME Type for Blob Objects in LWS
                const file = new Blob([result], {type: 'application/pdf'}); 
                // create a string containing a URL
                const imageUrl = URL.createObjectURL(file);
                // return blob
                resolve(imageUrl);
            })
            .catch(error => { // handle error
                reject(error);
            });
        });
    }

    async function downloadFileAttachment(url, filename, fetchProps) {
        debugger;
        fetchProps = {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get("sf_cookie")
            }
        }
        try {
        const response = await fetch(url, fetchProps);

        if (!response.ok) {
            throw new Error(response);
        }

        const blob = await response.blob();

        // Download the file
        saveAs(blob, filename);

        } catch (error) {
        throw new Error(error);
        }
    }

    // function to get UTC date
    function getUTCDate() {
        // get current date
        let currentDate = new Date();
        // convert to UTC
        const utcDate = currentDate.toUTCString();
        // return string
        return utcDate;
    }

    function Results({ result, index }) { 
        const filetype = result.fields.filetype;
        var filename;
        if(filetype === "pdf") {
            filename = result.fields.filename;
        }
        return(
            <Container maxWidth="lg" className={classes.Result}>
                <ListItem button onClick={() => ListItemLink(result.clickUri, index, filetype, filename)}>
                    <ListItemText
                        primary={
                        <Typography noWrap>
                            {result.fields.title}
                        </Typography>
                        }
                        secondary={
                        <React.Fragment>
                            <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                            >
                            {result.excerpt}
                            </Typography>
                        </React.Fragment>
                        }
                    />
                    <ListItemIcon className={classes.listIcon}>
                        <ArrowForwardIosIcon />
                    </ListItemIcon>
                </ListItem>

                {resultLoadingArray[index] &&
                    <Grid container justifyContent="center">
                        <Grid item xs={12}>
                            <CircularProgress className={classes.loadingCenterInside}/>
                        </Grid>
                    </Grid>
                }
                <Divider />
            </Container>
        )
    }
    return (
        <>
            
                    { loading &&
                        <Container maxWidth="lg" className={classes.heroContent}>
                                <Grid container>
                                    <Grid item md={12} xs={12} align="center">
                                        <CircularProgress />
                                    </Grid>
                                </Grid>
                        </Container>
                    }
                    {/* {!loading &&
                        <Grid container spacing={2} >
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h2" name="scroll-to-results">Results</Typography>
                                {displayResults  &&
                                <List component="nav" aria-label="knowledge search results" className={classes.root}>
                                    {displayResults.map((result, i) => (
                                    <Results
                                        key={i}
                                        index={i}
                                        result={result}
                                    />

                                    ))}
                                </List>
                                }
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item id="LEFT-SIDE-BUTTONS" xs={6}>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <Button
                                                    disabled={false}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handlePrevious}
                                                >
                                                    Previous
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    disabled={false}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleCancel}
                                                >
                                                    Cancel Request
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item id="RIGHT-SIDE-BUTTONS" xs={6}>
                                        <Grid container direction="row-reverse" spacing={2}>
                                            <Grid item>
                                                <Button
                                                    disabled={false}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleSubmit}
                                                >
                                                    Next
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    disabled={false}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleSolved}
                                                >
                                                    Solve Request
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    } */}
                    {!loading &&
                        <Grid container spacing={2} >
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h2" name="scroll-to-results">Problem Suggestions</Typography>
                                {displayResults  &&
                                <List component="nav" aria-label="knowledge search results" className={classes.root}>
                                    {displayResults.map((result, i) => (
                                    <Results
                                        key={i}
                                        index={i}
                                        result={result}
                                    />

                                    ))}
                                </List>
                                }
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={1} direction={"row-reverse"}>

                                    <Grid item>
                                        <Button
                                            disabled={false}
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleSubmit}
                                            endIcon={<ChevronRightIcon/>}
                                        >
                                            Next
                                        </Button>
                                    </Grid>

                                    <Grid item>
                                        <Button
                                            disabled={false}
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSolved}
                                            startIcon={<DoneIcon/>}
                                        >
                                            Solve Request
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disabled={false}
                                            variant="contained"
                                            color="primary"
                                            onClick={handleCancel}
                                            startIcon={<ClearIcon/>}
                                        >
                                            Cancel Request
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disabled={false}
                                            variant="outlined"
                                            color="primary"
                                            onClick={handlePrevious}
                                            startIcon={<ChevronLeftIcon/>}
                                        >
                                            Previous
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
        </>
    )
}

export default CaseDeflection