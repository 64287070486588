import React, { Component } from 'react'
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Hidden from '@material-ui/core';
import Cookies from 'js-cookie';
import Checkbox from '@material-ui/core/Checkbox';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import isEmail from 'validator/lib/isEmail';
import isPhone from 'validator/lib/isMobilePhone';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';


export class FormMachine2Page extends Component {
    
    state = {
        ERRsn: '',
        ERRmn: '',
        ERRktcAssembly: '',
        ERRktcSubAssembly: '',
        ERRktcKeypartName: '',
        ERRktcCaseHours: '',
        ERRktcNumBales: '',
        ERRktcEquipmentAttach: '',
        ERRktcEquipmentAttachSno: '',
        ktcOverwriteCausalPart: 'true',
        ktcInvalidKpad: 'true',
        ktcAssembly: this.props.values.ktcAssembly,
        ktcSubAssembly: this.props.values.ktcSubAssembly,
        ktcKeypartName: this.props.values.ktcKeypartName,
        ktcCaseHours: '',
        loading: true,
        assemblyList: '',
        subAssemblyList: '',
        keyPartList: ''
    }
    assemblyListWithDupes = ''

    componentDidMount() {
        this.setState({
            ktcAssembly : this.props.values.ktcAssembly,
            ktcSubAssembly : this.props.values.ktcSubAssembly,
            ktcKeypartName : this.props.values.ktcKeypartName
        });
        this.loadKpad();
    }



    loadKpad = () => {
        var req = {
            method : "get",
            url : process.env.REACT_APP_API + "Salesforce/kwarranty/GetBookId?MNNumber=" + this.props.machineDetails.modelNumber,
            headers: {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }
        }
        var isError = false;
        axios(req).then((response) => {
            //Can be multiple records containing multiple bookID's
            //Duplicates are possible, used a map to remove duplicates. Just used bookID for key and value
            var bookIDMap = new Map();
            //Iterate each record grabbing non null BookID lists
            response.data.records.forEach((record => {
                if(record.KPAD_Book_Id__c != null)
                {
                //if != null split bookID list and add bookID's to map
                 var bookIDs = record.KPAD_Book_Id__c.split("|");
                 bookIDs.forEach((bookID) => {bookIDMap.set(bookID,bookID)});
                }
            }));
            //String replace for all unqiue bookID's
            bookIDMap.forEach((bookID) => {
                bookIDMap.set(bookID, "100" + bookIDMap.get(bookID).substring(4));
            });
            //Convert map's value list into array
            var validBookIDs = Array.from(bookIDMap.values());
            //Query KPAD/init for each bookID and convert then add response to assemblyList
            

            //Store bookID call promises so you can remove duplicates after they have finished.
            var promises = [];


            
            //AssemblyList that contains dupliactes. Gets cleaned and thrown into state later
            validBookIDs.forEach((bookID) => {
                var kpadReq = {
                    method : "post",
                    url : process.env.REACT_APP_API + "KPAD?param=init",
                    headers : {
                        'Authorization': 'Bearer ' + Cookies.get("token"),
                        'Content-Type': 'application/json'
                    },
                    data : {
                        "dataCD" : "parts",
                        "bookCD" : bookID,
                        "dataTimeStamp" : "",
                        "langCD" : "000001",
                        "userID" : "100COM"
                    }
                };
                
                //push promises to array
                promises.push(axios(kpadReq, {timeout : 10}).then((resp) => {
                    var test = resp;
                    if (!response.data.errorMessage) {
                        var convertedResponse = this.convertAssemblyList(resp);
                        if(this.assemblyListWithDupes === ''){
                            this.assemblyListWithDupes = convertedResponse;
                        }
                        else{
                            this.assemblyListWithDupes = this.assemblyListWithDupes.concat(convertedResponse);
                        }  
                    } else {
                        this.setState({
                            ktcInvalidKpad: 'true'
                        });
                    }

                    this.setState({
                        loading: false
                    });
                }).catch((error) => {
                    isError = true;
                    console.log(error);
                }))
            });
            //Wait for all BookID responses + assembly list building to finish
            Promise.all(promises).then(() => {
                //remove dupes
                //All promises have completed. Now it is time to remove dupes.
                debugger;
                var arrNoDupes = this.assemblyListWithDupes;
                var assemblyDupes = 0;
                var subAssemblyDupes =0;
                var partDupes = 0;

                if(!isError){
                    //REMOVE DUPLICATE ASSEMBLIES
                    //Creating map to store list of assembly and subassembly keys are unique and lookup is fast
                    var assemblyList = new Map();
                    
                    //Iterates through the Assembly list and Removes Duplicates. Needs a while since duplicates are removed during iteration.
                    var index = 0;
                    var assembly;
                    while(index < arrNoDupes.length)
                    {
                        assembly = arrNoDupes[index];
                        //Boolean for whether or not dupe was found. Deteremines which index child dupe removal occurs on
                        //Check if assembly is already in the map
                        if(assemblyList.get(assembly.AssemblyName) === undefined)
                        {
                            //Not found. Add to map
                            assemblyList.set(assembly.AssemblyName, index);
                            index++;
                        }
                        else
                        {
                            //Found, combine both parents subassembly list, then delete the dupe
                            var indexOfParent = assemblyList.get(assembly.AssemblyName);
                            //Dupes Sub assembly list
                            var subAssemList = assembly.SubAssembly;
                            //Existing Parents List
                            var parentSubList = arrNoDupes[indexOfParent].SubAssembly;
                            //Combine the two
                            arrNoDupes[indexOfParent].SubAssembly = parentSubList.concat(subAssemList);
                            //Delete the duplicate
                            arrNoDupes.splice(index,1);
                            //delete arrNoDupes[index];
                            assemblyDupes++;
                        }
                        
                    }

                    //Sub assembly dupe removal. Just need while loop on the level that contains removals
                    var subAssemblyMap = new Map();
                    arrNoDupes.forEach((assembly) => {
                        var index = 0;
                        var subAssembly;
                        while(index < assembly.SubAssembly.length)
                        {
                            subAssembly = assembly.SubAssembly[index];
                            debugger;
                            if(subAssemblyMap.get(subAssembly.SubAssemblyName) === undefined)
                            {
                                //Not found, just add it with its index
                                subAssemblyMap.set(subAssembly.SubAssemblyName,index);
                                index++;
                            }
                            else 
                            {
                                //Found, combine children with already existing SubAssembly, then delete the dupe
                                var indexOfParent = subAssemblyMap.get(subAssembly.SubAssemblyName);
                                //Dupes Sub assembly list
                                var partsList = subAssembly.Parts;
                                //Existing Parents List
                                var parentsPartList = assembly.SubAssembly[indexOfParent].Parts;
                                //Combine the two
                                assembly.SubAssembly[indexOfParent].Parts = parentsPartList.concat(partsList);
                                //Delete the duplicate
                                assembly.SubAssembly.splice(index,1);
                                subAssemblyDupes++;
                            }
                        }
                    });
                

                    //Parts list dupe removal, Same as above. Just 3 levels deep.
                    arrNoDupes.forEach((assembly) => {
                        assembly.SubAssembly.forEach((subAssembly) => {
                            var partsMap = new Map();
                            var index = 0;
                            var part;
                            while(index < subAssembly.Parts.length)
                            {
                                var part = subAssembly.Parts[index];
                                if(partsMap.get(part.partName + part.partID) === undefined)
                                {
                                    //found add to map
                                    partsMap.set(part.partName + part.partID, index);
                                    index++;
                                }
                                else
                                {
                                    //Just remove the dupe : )
                                    subAssembly.Parts.splice(index,1);
                                    partDupes++;
                                }
                            }
                        });
                    });
                    console.log("Done finding dupes\nAssembly Dupes: " + assemblyDupes + ", Subassembly dupes: " + subAssemblyDupes + ", part dupes: " + partDupes);
                }
                //Set needed states
                this.setState({
                        assemblyList: arrNoDupes,
                        loading: false,
                });
                this.setState({
                    ktcInvalidKpad: (isError) ? 'true' : 'false'
                });
                this.validateAutocomplete("ktcInvalidKpad", (isError) ? 'true' : 'false')
            });
        }).catch((error) => {
            isError = true;
             this.setState({
                    ktcInvalidKpad: (isError) ? 'true' : 'false'
            });
            this.validateAutocomplete("ktcInvalidKpad", (isError) ? 'true' : 'false')
            this.setState({
                loading: false
            });
            console.log(error);
        });


    }

    convertAssemblyList = (Response) => { 
        var assemblyList = [];
        Response.data.resultData.forEach( (functionEntry) => {
            var assembly = {
                "AssemblyName" : functionEntry.functionName,
                "AssemblyID" : functionEntry.functionName,
                "SubAssembly" : []
            };
            functionEntry.figData.forEach( (SubAssembly) => {
                var subAssembly = {
                    "SubAssemblyID" : SubAssembly.figNo,
                    "SubAssemblyName" : SubAssembly.figName,
                    "Parts" : []
                };
                SubAssembly.sPartsData.forEach( (part) => {
                    subAssembly.Parts.push({
                        "partName" : part.partsName,
                        "partID" : part.partsNumber
                    });
                } )
                assembly.SubAssembly.push(subAssembly);
            });
            assemblyList.push(assembly);
        } );
        return assemblyList;
    }

    validateAutocomplete = (e, v) => {
        console.log(e);
        console.log(v);
        let error = 'ERR' + e;
        console.log(error);
        this.setState({ [e]: v });
        //Update formStates in Parent(useState hook)
        this.props.setValues(prevState => ({
            ...prevState,
            [e]: v
        }));
        // this.props.values[e] = v
        this.setState({ [error]: '' });
        // IF the value matches anything in the JSON part name list then set the ktcOverwriteCausalPart to false
        if (e == "ktcKeypartName") {
            if (this.state.keyPartList) {
                let list = this.state.keyPartList;
                Object.entries(list).forEach(item => {
                    let name = item[1].partID + " " + item[1].partName;
                    console.log(name);
                    console.log(item);
                    if (name == v) {
                        this.setState({
                            ktcOverwriteCausalPart: 'false'
                        });

                    }
                });
            } else {
                this.setState({
                    ktcOverwriteCausalPart: 'true'
                });
            }
        }

    }

    validate = e => {
        console.log(e.target.name);
        console.log(e.target.value);

        this.setState({ [e.target.name]: e.target.value });


        switch (e.target.name) {
            case 'ktcAssembly':
                if (e.target.value == '') {
                    this.setState({
                        ERRktcAssembly: 'Field is required.',
                    });
                } else {
                    this.setState({
                        ERRktcAssembly: '',
                    });
                }
                break;
            case 'ktcSubAssembly':

                if (e.target.value == '') {
                    this.setState({
                        ERRktcSubAssembly: 'Field is required.',
                    });
                } else {
                    this.setState({
                        ERRktcSubAssembly: '',
                    });
                }
                break;
            case 'ktcKeypartName':

                if (e.target.value == '') {
                    this.setState({
                        ERRktcKeypartName: 'Field is required.',
                    });
                } else {
                    this.setState({
                        ERRktcKeypartName: '',
                    });
                }
                break;
            default:
                break;
        }
    }

    getSubAssembly = (e, v) => {
        console.log('Assembly Set');
        console.log(e.target.name);
        console.log(v.AssemblyName);
        let assemblyList = this.state.assemblyList;
        let temp = [];
        for (let x = 0; x < assemblyList.length; x++) {
            if (assemblyList[x].AssemblyName == v.AssemblyName) {
                temp = [...temp, ...assemblyList[x].SubAssembly];
            }
        }
        console.log(temp);
        this.setState({
            ktcAssembly: v.AssemblyName,
            subAssemblyList: temp,
            ktcSubAssembly: '',
            ktcKeypartName: '',
        });
        //Update formStates in Parent(useState hook)
        this.props.setValues(prevState => ({
            ...prevState,
            ktcAssembly: v.AssemblyName,
            ktcSubAssembly : '',
            ktcKeypartName : ''
        }));
        // this.props.values.ktcAssembly = v.AssemblyName;

    }

    getCausalParts = (e, v) => {
        console.log("sub assembly changed");
        console.log(e.target.name);
        console.log(e.target.value);
        console.log(v);
        let subAssemblyList = this.state.subAssemblyList;
        console.log(subAssemblyList);
        let temp = [];
        for (let x = 0; x < subAssemblyList.length; x++) {
            if (subAssemblyList[x].SubAssemblyName == v.SubAssemblyName) {
                temp = [...temp, ...subAssemblyList[x].Parts];
            }
        }
        console.log(temp);
        this.setState({
            keyPartList: temp,
            ktcSubAssembly : v.SubAssemblyName,
            ktcKeypartName: '',
        });
        //Update formStates in Parent(useState hook)
        this.props.setValues(prevState => ({
            ...prevState,
            ktcSubAssembly : v.SubAssemblyName,
            ktcKeypartName: '',
        }));
        // this.props.values.ktcAssembly = v.AssemblyName;

    }

    handleSubmit = () => {
        const { caseOrReport } = this.props;
        if (caseOrReport === "report") {
            this.props.setStep(3.5);
        }
        else
            this.props.setStep(prevState => prevState +1);
    }

    handlePrevious = () => {
        if(this.props.caseOrReport === 'report') {
            this.props.setStep(prevState => prevState -2);

        } else {
            this.props.setStep(prevState => prevState -1);
            const {callNextStageAnalytics} = this.props;
            callNextStageAnalytics("SECTION_2");
        }
    }

    formatDate() {
        var newDate = Date.format('YYYY-MM-DD') + Time;
        return newDate;
    }
    render() {
        const { values} = this.props;

        const {
            mn,
            sn,
            pin,
            ktcOverwriteCausalPart,
            ktcInvalidKpad,
            ktcAssembly,
            ktcSubAssembly,
            ktcKeypartName,
            ktcCaseHours,
            ERRsn,
            ERRmn,
            ERRktcAssembly,
            ERRktcSubAssembly,
            ERRktcKeypartName,
            ERRktcCaseHours,
            ERRktcNumBales,
            ERRktcEquipmentAttach,
            ERRktcEquipmentAttachSno,
            loading } = this.state;
      
        if (!values.ktcInvalidKpad) {
            values.ktcInvalidKpad = ktcInvalidKpad;
            console.log('set ktcInvalidKpad');
            console.log(values.ktcInvalidKpad);
        }
        if (!values.ktcOverwriteCausalPart) {
            values.ktcOverwriteCausalPart = ktcOverwriteCausalPart;
            console.log('set ktcOverwriteCausalPart');
            console.log(values.ktcOverwriteCausalPart);
            console.log(this.props.values);
        }

        return (
            <React.Fragment>
                <CssBaseline />
                {loading &&
                    <div style={styles.root}>
                        <CircularProgress style={{ marginLeft: '50%' }} />
                    </div>
                }
                {!loading &&
                    <React.Fragment>
                        <Typography variant="h6" gutterBottom>
                            Machine Details
                        </Typography>
                        <Grid container spacing={3} direction="row" justifyContent="flex-end" alignItems="center">
                            <Grid item xs={12} sm={6}>
                                {this.state.ktcInvalidKpad == 'false' &&
                                    <Autocomplete
                                        onChange={(e, v) => { this.getSubAssembly(e, v); this.validateAutocomplete("ktcAssembly", v.AssemblyName) }}
                                        name="ktcAssembly"
                                        id="ktcAssembly"
                                        inputValue={ktcAssembly}
                                        options={this.state.assemblyList}
                                        getOptionLabel={option => option.AssemblyName}
                                        renderInput={params => (<TextField {...params} helperText={ERRktcAssembly === "" ? '' : ERRktcAssembly} error={ERRktcAssembly !== ""} defaultValue={values.ktcAssembly} fullWidth label="Assembly" variant="outlined" />)}
                                    />
                                }
                                {this.state.ktcInvalidKpad == 'true' &&
                                    <TextField
                                        autoFocus
                                        variant="outlined"
                                        id="ktcAssembly"
                                        name="ktcAssembly"
                                        label="Assembly"
                                        fullWidth
                                        autoComplete=""
                                        onChange={(e) => {
                                            this.validateAutocomplete("ktcAssembly", e.currentTarget.value)
                                            this.validate(e);
                                        }}
                                        defaultValue={values.ktcAssembly}
                                        onBlur={(e) => { this.validate(e); }}
                                        error={ERRktcAssembly !== ""}
                                        helperText={ERRktcAssembly === "" ? '' : ERRktcAssembly}
                                    />
                                }

                            </Grid>

                            <Grid item xs={12} sm={6}>

                                {this.state.ktcInvalidKpad == 'false' &&
                                    <Autocomplete
                                        name="ktcSubAssembly"
                                        id="ktcSubAssembly"
                                        inputValue={ktcSubAssembly}
                                        onBlur={(e) => { this.validate(e); }}
                                        onChange={(e, v) => { this.getCausalParts(e, v); this.validateAutocomplete("ktcSubAssembly", v.SubAssemblyName); }}
                                        disabled={!this.state.subAssemblyList.length}
                                        options={this.state.subAssemblyList}
                                        getOptionLabel={option => option.SubAssemblyName}
                                        renderInput={params => (<TextField {...params} helperText={ERRktcSubAssembly === "" ? '' : ERRktcSubAssembly} error={ERRktcSubAssembly !== ""} defaultValue={values.ktcSubAssembly} fullWidth label="Sub Assembly" variant="outlined" />)}
                                    />
                                }

                                {this.state.ktcInvalidKpad == 'true' &&
                                    <TextField
                                        variant="outlined"
                                        id="ktcSubAssembly"
                                        name="ktcSubAssembly"
                                        label="Sub Assembly"
                                        fullWidth
                                        autoComplete=""
                                        onChange={(e) => {
                                            this.validate(e);
                                            this.validateAutocomplete("ktcSubAssembly", e.currentTarget.value)
                                        }}
                                        defaultValue={values.ktcSubAssembly}
                                        onBlur={(e) => { this.validate(e); }}
                                        error={ERRktcSubAssembly !== ""}
                                        helperText={ERRktcSubAssembly === "" ? '' : ERRktcSubAssembly}
                                    />
                                }

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {this.state.ktcInvalidKpad == 'false' &&
                                    <Autocomplete
                                        id="ktcKeypartName"
                                        freeSolo
                                        onInputChange={(e, v) => {
                                            var test = this.props.values;
                                            if(v !== "undefined undefined")
                                            {
                                                this.validateAutocomplete("ktcKeypartName", v);
                                            }
                                        }}
                                        inputValue={values.ktcKeypartName}
                                        defaultValue={values.ktcKeypartName}
                                        disabled={!this.state.keyPartList.length}
                                        options={this.state.keyPartList}
                                        getOptionLabel={(option) => {
                                            var test = typeof(option);
                                            if (typeof(option) == "string") {
                                                return option
                                            } else {
                                                return option.partID + " " + option.partName;
                                            }
                                        }}
                                        renderInput={(params) => {
                                            return(
                                            <TextField
                                                {...params}
                                                fullWidth
                                                label="Causal Part"
                                                variant="outlined"
                                                helperText={ERRktcKeypartName === "" ? '' : ERRktcKeypartName}
                                                error={ERRktcKeypartName !== ""}
                                            />
                                        );}}
                                    />
                                }

                                {this.state.ktcInvalidKpad == 'true' &&
                                    <TextField
                                        variant="outlined"
                                        id="ktcKeypartName"
                                        name="ktcKeypartName"
                                        label="Causal Part"
                                        value={this.state.ktcKeypartName}
                                        fullWidth
                                        autoComplete=""
                                        onChange={(e) => {
                                            this.validate(e);
                                            this.validateAutocomplete("ktcKeypartName", e.currentTarget.value);
                                        }}
                                        defaultValue={values.ktcKeypartName}
                                        onBlur={(e) => { this.validate(e); }}
                                        error={ERRktcKeypartName !== ""}
                                        helperText={ERRktcKeypartName === "" ? '' : ERRktcKeypartName}
                                    />
                                }

                            </Grid>
                            {this.props.caseOrReport == 'report' &&
                            <>
                                <Grid item xs={12} sm={6}>
                                    <TextField 
                                        defaultValue={this.props.values.ktcProbableCauseRemarks}
                                        variant="outlined"
                                        id={"Probable_Cause_Remarks__c"}
                                        name={"Probable_Cause_Remarks__c"}
                                        label={"Parts Comments"}
                                        fullWidth
                                        onChange={ (event) => {
                                            this.props.setValues(prevState => ({
                                                ...prevState,
                                                ktcProbableCauseRemarks:  event.target.value
                                            }))
                                        }}
                                        key={'componentInput1'}
                                        style={{ border: 11 }}
                                    />
                                </Grid>
                            </>
                            }
                            {/* Placeholder for TSC so Failure Date stays on next row */}
                            { this.props.caseOrReport === 'case' &&
                                <Grid item xs={12} sm={6}>
                                </Grid>
                            }
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        inputFormat="MM/DD/YYYY"
                                        className={this.props.styles.halfWidth}
                                        value={this.props.values.ktcFailureDate}
                                        onChange={ (newDate) => {
                                            this.props.setValues(prevState => ({
                                                ...prevState,
                                                ktcFailureDate: newDate.format('YYYY-MM-DD')
                                            }))
                                        }}
                                        label={'Failure Date'}
                                        renderInput={(params) => {
                                            return (
                                                <TextField 
                                                    {...params}
                                                    error = {false}
                                                    variant = {"outlined"} 
                                                />
                                            )
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="ktcInvalidKpad"
                                    name="ktcInvalidKpad"
                                    // label="ktcInvalidKpad"
                                    type="hidden"
                                    value={values.ktcInvalidKpad}
                                // onChange={handleChange} 
                                />
                                <TextField
                                    id="ktcOverwriteCausalPart"
                                    name="ktcOverwriteCausalPart"
                                    // label="ktcOverwriteCausalPart"
                                    type="hidden"
                                    value={values.ktcOverwriteCausalPart}
                                // onChange={handleChange} 
                                />
                            </Grid>    
                            <Grid item md={12} xs={12}>
                                <Grid container direction={"row-reverse"} spacing={1}>
                                    <Grid item>
                                        <Button
                                            disabled={false}
                                            variant="outlined"
                                            color="primary"
                                            onClick={this.handleSubmit}
                                            endIcon={<ChevronRightIcon/>}
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disabled={false}
                                            variant="outlined"
                                            color="primary"
                                            onClick={this.handlePrevious}
                                            startIcon={<ChevronLeftIcon/>}
                                        >
                                            Previous
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

const styles = {
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: 8,
        marginLeft: 12,
    },
    hidden: {
        display: 'none'
    },
    halfWidth: {
        width: "100%",
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: "black"
          }
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "black"
        }
    }
}

export default FormMachine2Page