import { Accordion, AccordionDetails, AccordionSummary, Container, Grid, Paper, Typography } from '@material-ui/core'
import { Alert } from '@mui/material';
import axios from 'axios';

import React, { useEffect } from 'react'
import {useParams} from 'react-router-dom';
import Cookies, { set } from 'js-cookie';

import { useState } from 'react';
import { makeStyles, ThemeProvider, createTheme} from '@material-ui/core/styles';

import { convertPPDSchemaIntoJson } from '../helpers/ppd/convertPPDSchemaIntoJson';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme) => ({
    heroContent: {
      width: "90%",
      padding: theme.spacing(8, 0, 6),
    }
  }));

function PredictionPage() {
    const params = useParams();
    const classes = useStyles();


    const [predictionResults , setPredictionResults] = useState();
    const [fetchError, setFetchError] = useState(false);

    useEffect(() => {
        fetchPrediction();
    }, []);

    const fetchPrediction = () => {
        let recordId = params.param1;
        if(!recordId) {
            setFetchError(true);
            return;
        }
        var req = {
            url: process.env.REACT_APP_API + `salesforce/kservice/apexrest?param=Predictions/fetchPPD?ppd=${recordId}`,
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + Cookies.get("token"),
              'Content-Type': 'application/json'
            },
          }
          axios(req)
            .then((response) => {
              //
              if(Array.isArray(response.data)) {
                if(response.data.length > 0) {
                  //Case has PPD data
                  debugger;
                  let convertedJSON = convertPPDSchemaIntoJson(response.data);
                  debugger;
                  setPredictionResults(convertedJSON);
                } else {
                  //Case has no PPD data. No action
                }
              } else {
                //Return data is not an array. Error
              }
            })
            .catch((error) => {
              //No prediction data
              debugger;
            });
    }


  return (
    <>
        { fetchError &&
            <Container maxWidth="lg" className={classes.heroContent}>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Paper elevation={3} className={classes.heroPaper} justify="center">
                        <Alert severity='error'>
                            <Typography>
                                There was a problem loading this prediction
                            </Typography>
                        </Alert>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
        }
        { !fetchError && predictionResults &&
            <Container maxWidth="lg" className={classes.heroContent}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Paper elevation={3} className={classes.heroPaper} justify="center" style={{padding: '4px'}}>
                            <>
                                <Grid container style={{margin: '8px', marginTop: '16px'}}>
                                    
                                    <Grid item xs={12} >
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Typography>
                                                    <strong>Model: </strong>{predictionResults.modelNumber}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography>
                                                <strong>Serial: </strong>{predictionResults.serialNumber}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    <strong>Symptom: </strong>{predictionResults.symptom}
                                                </Typography>
                                            </Grid>
                                            
                                        </Grid>
                                    </Grid>
                                    
                                    {predictionResults.PartsCombinations.map((prediction, index) => {
                                        debugger;
                                        return(
                                            <>
                                            <Accordion key={"PPDAccordion" + index}>
                                                <AccordionSummary style={{backgroundColor:"#D3D3D3"}} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                                <strong>Prediction #{index+1} </strong>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                <Grid container key={"predictionObj" + index} style={{marginTop: '6px'}}>
                                                    <Grid item xs={12} style={{marginTop: '8px'}}>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Typography noWrap>
                                                                    <strong>Causal Part</strong>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography noWrap>
                                                                    {prediction.causalPart}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    { prediction.requiredParts && prediction.requiredParts.length > 0 &&
                                                        <>
                                                            <Grid item xs={12} style={{marginTop: '4px'}}>
                                                                <Typography>
                                                                    <strong>
                                                                        Required Parts Combination
                                                                    </strong>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid container>
                                                                {prediction.requiredParts.map((requiredPart, index) => {
                                                                    return(
                                                                        <>
                                                                            <Grid item xs={6}>
                                                                                <Typography>
                                                                                    {requiredPart.partNumber} {requiredPart.partDesc}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={6} style={{textAlign: 'center'}}>
                                                                                {requiredPart.qty}
                                                                            </Grid>
                                                                        </>
                                                                    );
                                                                })}
                                                            </Grid>
                                                        </>
                                                    }
                                                    <Grid item xs={12} style={{marginTop: '4px'}}>
                                                        <Typography noWrap>
                                                            <strong>Estimated Labor: </strong> {Math.round(prediction.recommendedJobhours)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{marginTop: '4px', textAlign: 'right', paddingRight: '48px'}}>
                                                        <Typography noWrap>
                                                            <strong>Probability Score: </strong> {prediction.probabilitySimilarPhraseMatch}%
                                                        </Typography>
                                                    </Grid>
                                                    
                                                </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                            </>
                                        );
                                    })}
                                </Grid>
                            </>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

        }
    </>
  )
}

export default PredictionPage