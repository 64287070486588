import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Divider } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(4, 4, 6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  error: {
    margin: theme.spacing(0, 0, 2),
  },
  submit: {
    margin: theme.spacing(2, 0, 1),
  },
  root: {
    background: "#efefef",
    minWidth: "100%",
    minHeight: "calc(100vh - 121px);",
    display: "flex",
    flexDirection: "column"
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
    width: '90%'
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  form: {
    margin: theme.spacing(0, 0, 5)
  },
}));



export const KUInformation = () => {
  const classes = useStyles();
  const history = useHistory();

  function techCertRedirect(){
    history.push('/knowledge/answer?uniqueID=42.17990%24http%3A%2F%2Fwww.salesforce.com%2Forg%3Aorganization%2Farticletype%3AKnowledge%2Farticle%3AkA08B000000Hi8VSAS%2Flanguage%3Aen_US&title=KU%20Quick%20Links');
  }


  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.heroContent} >
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={4}>
            <Paper className={classes.heroPaper} elevation={3} xs={12}>
              {/* <div className={classes.paper}> */}
                <Typography component="h1" variant="h5">Kubota University</Typography>
                <Divider />
                <Button 
                  fullWidth 
                  variant="contained" 
                  color="primary" 
                  size="large" 
                  className={classes.submit} 
                  onClick={() => window.open('https://ktc.myabsorb.com/#/curricula/37a69510-e176-4478-9173-b048126d74a5', '_blank')}
                  >
                    Course: Certified Service Tech
                  </Button>
                <Button 
                  fullWidth 
                  variant="contained" 
                  color="primary" 
                  size="large" 
                  className={classes.submit} 
                  onClick={() => window.open('https://ktc.myabsorb.com/#/catalog/f06abbf9-bfaa-4fd0-b03e-8ab7df7b04ff', '_blank')}
                  >
                    Catalog: Service Courses
                  </Button>
                <Button 
                  fullWidth 
                  variant="contained" 
                  color="primary" 
                  size="large" 
                  className={classes.submit} 
                  onClick={() => window.open('https://d1vy0qa05cdjr5.cloudfront.net/82389a4d-9482-4ed0-a698-97fa48a37271/Public/Resources/Kubota%20Cert%20Guide%20WEB%20VERSION%2005122021.pdf', '_blank')}
                  >
                    Technician Certification
                  </Button>            
              {/* </div> */}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
