import React from 'react'
import { useHistory, useLocation } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import CaptureDTC from '../components/CaptureDTC';
import CaseDeflection from '../components/CaseDeflection';
import FormMachine2Page from './FormMachine2Page';
import FeedAttachments from '../components/FeedAttachments';
import { SFFormTSCPage } from './SFFormTSCPage';
import CaseSuccess from '../components/CaseSuccess';
import Alert from '@material-ui/lab/Alert';
import ContactCapturePage from '../components/ContactCapturePage';
import Cookies from 'js-cookie';
import axios from 'axios';
import getFileIconByExtn from '../helpers/files/getFileIconByExtn';
import getMimeTypeByExtn from '../helpers/files/getMimeTypeByExtn';
import { v4 as uuidv4 } from 'uuid';
import handleNextStageAnalytics from '../helpers/coveo/StageUpdate';
import handleFieldUpdateAnalytics from '../helpers/coveo/FieldUpdate';
import handleCancelOrSolveTicketAnalytics from '../helpers/coveo/ResolveTicket';
import handleCreateTicketAnalytics from '../helpers/coveo/CreateTicket';
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import { app } from '../firebaseConfig';
import checkCoveoToken from '../helpers/checkCoveoToken';
import { CreateForm } from '../components/CreateForm'; 
import CaseDeflectionWithPredictions from '../components/CaseDeflectionWithPredictions';



const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
    width: '90%'
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  heroPaperWithoutSidePadding : {
    padding: theme.spacing(0, 0, 0),
    margin: theme.spacing(0, 0, 4)
  },
  loadingCenterInside: {
    top: '50%',
    marginLeft: '48%',
    left: '-20px'
  },
  error : {
    marginBottom: "20px"
  },
  halfWidth: {
    width: "100%"
    
}
}));

function CaseCreateFlowWrapperWithPrediction() {
    const history = useHistory();
    const classes = useStyles();
    const location = useLocation();
    const [machineDetails, setMachineDetails] = useState({
        serialNumber: '',
        modelNumber: ''
    });
    const [inventoryId, setInventoryId] = useState();
    const [imei, setImei] = useState();
    //For all pages
    const [step, setStep] = useState(0.0);
    const [caseOrReport, setCaseOrReport] = useState('');
    const [submissionError, setSubmissionError] = useState('');
    const [errorBanner, setErrorBanner] = useState('');
    const [failed, setFailed] = useState('');
    const [casePassThrough, setCasePassThrough] = useState();
    //For DTC/Subject Page
    const [DTC, setDTC] = useState('');
    const [subject, setSubject] = useState('');
    const [loading, setLoading] = useState(false);
    //For predictions/Coveo Page
    const [predictionRecordId, setPredictionRecordId] = useState();
    const [predictionResults, setPredictionResults] = useState();

    //For Assembly Page
    const [formStates,setFormStates] = useState({
        ktcOverwriteCausalPart: '',
        ktcInvalidKpad: '',
        ktcAssembly: '',
        ktcSubAssembly: '',
        ktcKeypartName: '',
        ktcFailureDate: '',
        ktcProbableCauseRemarks: ''
    });
    //For Contact Capture(Reports only)
    const [preFillAccountContact, setPreFillAccountContact] = useState();
    const [accountID, setAccountID] = useState();
    const [contactID, setContactID] = useState();

    //For Case Create Component
    const [editValues, setEditValues] = useState();
    const [objectInfo, setObjectInfo] = useState({});
    const [createPayload, setCreatePayload] = useState();
    const [ErrorStates, setErrorStates] = useState([]);
    const [reqFields, setRequiredFields] = useState();
    const [fieldOverrides, setFieldOverrides] = useState();
    const [hiddenSections, setHiddenSections] = useState();
    const [hiddenFields, setHiddenFields] = useState();
    //For Create Report (Non UI-API)
    const [reportFields, setReportFields] = useState();
    //For Feed Attachments
    const [imageState, updateImageState] = useState(false);
    const [formData, updateFormData] = useState('');
    //For SuccessPage
    const [caseID, setCaseID] = useState();
    const [caseNumber, setCaseNumber] = useState();
    //Coveo Analytics
    const [uuid, setUUID] = useState();




    let params = useParams();

    useEffect(() => { 
        //Check if we are a Case or Report route
        debugger;
        if(history.location.pathname.includes("/report/create/")) {
            setCaseOrReport("report");
            fetchSFFieldOverrides("report");
        } else {
            setCaseOrReport("case");
            fetchSFFieldOverrides("case");
        }
        // On component load, load machine details from URL params
        setMachineURLParams(params);
        setUUID(uuidv4());
        //Grab Coveo Tokens
        checkCoveoToken();
        if(location.state) {
            setInventoryId(location.state.inventoryId);
            if(location.state.imei) {
                setMachineDetails(prevState => ({
                    ...prevState,
                    imei : location.state.imei
                }));
            };
        }
    }, []);

    useEffect(() => {
        // Once MachineDetails are entered we can move to next step.
        if (machineDetails.modelNumber && machineDetails.serialNumber && step == 0) {
            setLoading(false);
            setStep(step + 1);
        }
    }, [machineDetails]);

    useEffect(() => {
        //Step changing = user hit prev or next
        setErrorBanner('');        
    }, [step]);



    function createCase(submitCase){
        debugger;
        var localErrorStates = ErrorStates;
        //blank payload
        console.log("Submit Cases: " + submitCase);
        var createPayload = {
            "apiName": "Case",
            "fields": {
                "Origin" : "K-Service App",
                "Inventory__c" : inventoryId
            }
        };
        //Check editValues for new values
        Object.keys(editValues).forEach((field,i) => {
            var values = Object.values(editValues)[i];
            if(values.current != values.default)
            {
                //Construct payload
                if(values.current !== "-None-")
                createPayload.fields[field] = values.current;
            }
            if(values.current === "true" || values.current === "false")
            {
                values.current = (values.current === 'true');
            }
        });

        let preventSubmission = false;
        //Regex Formats
        const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const phoneFormat = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        //Format: Email, Phone, Date, DateTime, Reference
        Object.entries(objectInfo.fields).forEach((field,i) => {
            const apiName = field[0];
            const dataType = field[1].dataType;

            const fieldValue = createPayload.fields[String(apiName)];

            // Update Email error state
            if(dataType === 'Email'){
                if(fieldValue){
                    let isValidEmail = emailFormat.test(String(fieldValue));
                    localErrorStates = {
                        ...localErrorStates,
                        [String(apiName)]: !isValidEmail,
                    };
                    if(!isValidEmail) {
                        console.log('Email Format invalid');
                    }
                }
            }
            // Update Phone error state
            else if(dataType === 'Phone'){
                if(fieldValue){
                    let isValidPhone = phoneFormat.test(String(fieldValue));
                    localErrorStates = {
                        ...localErrorStates,
                        [String(apiName)]: !isValidPhone,
                    };
                    if(!isValidPhone){
                        console.log('Phone Format invalid');
                    }
                }
            }
            // Transform Date field in payload to required format
            else if(dataType === 'Date'){
                if(fieldValue){
                    let currentDate = createPayload.fields[String(apiName)];
                    createPayload.fields[String(apiName)] = String(currentDate).replace(/(..).(..).(....)/, "$3-$1-$2")+'T12:00:00Z';
                }
            }
            // Transform DateTime field in payload to required format
            else if(dataType === 'DateTime'){
                if(fieldValue){
                    let currentDateTime = createPayload.fields[String(apiName)];
                    createPayload.fields[String(apiName)] = String(currentDateTime);
                }
            }
            // Transform Reference
            else if(dataType === 'Reference'){
                if(fieldValue){
                    if(typeof fieldValue === "object") {
                        let currentReference = createPayload.fields[String(apiName)].id;
                        createPayload.fields[String(apiName)] = String(currentReference);
                    }
                }
            }
        });


        //Check Required Fields
        Object.entries(objectInfo.fields).forEach((field,i) => {
            const apiName = field[0];
            if(reqFields.has(String(apiName))){
                const fieldValue = createPayload.fields[String(apiName)];
                if (!fieldValue) { // Value is required but not present
                    // ErrorStates[String(apiName)] = true;
                    let stringApiName = String(apiName);
                    localErrorStates = {
                        ...localErrorStates,
                        [String(apiName)]: true,
                    };
                    console.log(String(apiName), 'is required');
                    preventSubmission = true;
                } else {  //Value is requried an is present. Remove it from errorState if it previously was not entered
                    if (ErrorStates[String(apiName)]) {
                        localErrorStates = {
                            ...localErrorStates,
                            [String(apiName)]: !isValidEmail,
                        };
                    }
                }
            }
        });

        // Prevent Submission if Error
        Object.values(localErrorStates).forEach((field,i) => {
            if (field === true){
                preventSubmission = true;
                console.log('Prevent submission');
                return;
            }
        })
        //Sync ErrorStates with our Local var so UI updates to reflect errors.
        setErrorStates(localErrorStates);


        //Clone Subject field into Description field behind the scenes.

        console.log('Payload before request: ', createPayload);
        //Create Request
        var req = {
            method : 'post',
            url : process.env.REACT_APP_API + 'salesforce/kservice/Post?param=ui-api/records',
            headers : { 
                    'Authorization': 'Bearer ' + Cookies.get("token"),//Middleware token
                    'X-SalesForce-Token' : Cookies.get('sf_cookie'),//SF token for Middleware
                    'X-Id-Token' : Cookies.get('idToken')
                    },
            data : createPayload
        }
        debugger; 
        //Send create POST req
        if(!preventSubmission){
            if(submitCase) {
                setLoading(true);
                axios(req)
                .then((response) => {
                    console.log("Created Successfully");
                    callCreateTicketAnalytics();
                    var caseID = response.data.id;
                    var caseNo = response.data.fields.CaseNumber.value;
                    if (imageState || formData) {
                        handleThreadUpdatesAndImages(caseID)
                            .then(() => {
                                setCaseID(caseID);
                                setCaseNumber(caseNo);
                                setLoading(false);
                                setStep(prevState => prevState+1);
                            })
                            .catch((e) => {
                                //Case was created, but ran into problems attaching image or thread
                                setLoading(false);
                                setSubmissionError(e);
                                console.log(e);
                            });
                    } else {
                        setCaseID(caseID);
                        setCaseNumber(caseNo);
                        setLoading(false);
                        setStep(prevState => prevState+1);
                    }
                })
                .catch((error) => {
                    setSubmissionError(error);
                    setLoading(false);
                    console.log(error);
                });
            } else {
                setStep(prevState => prevState+1);
            }
        } else {
            window.scrollTo(0,0);
        }
    }
    function createReport(submitCase = true){
        debugger;
        var localErrorStates = ErrorStates;
        //blank payload
        console.log("Submit Case: " + submitCase);
        var createPayload = {
            "apiName": "Case",
            "fields": {
                "Origin" : "K-Service App",
                "Inventory__c" : inventoryId
            }
        };
        //Add report fields into payload
        Object.keys(reportFields).forEach((field,i) => {
            var value = Object.values(reportFields)[i];
            //Construct payload
            createPayload.fields[field] = value;
        });

        let preventSubmission = false;

        console.log('Payload before request: ', createPayload);
        //Create Request
        var req = {
            method : 'post',
            url : process.env.REACT_APP_API + 'salesforce/kservice/Post?param=ui-api/records',
            headers : { 
                    'Authorization': 'Bearer ' + Cookies.get("token"),//Middleware token
                    'X-SalesForce-Token' : Cookies.get('sf_cookie'),//SF token for Middleware
                    'X-Id-Token' : Cookies.get('idToken')
                    },
            data : createPayload
        }

        //Send create POST req
        if(!preventSubmission){
            if(submitCase) {
                setLoading(true);
                axios(req)
                .then((response) => {
                    console.log("Created Successfully");
                    callCreateTicketAnalytics();
                    var caseID = response.data.id;
                    debugger;
                    attemptCasePredictionAssociation(caseID, predictionRecordId)
                        .then((response) => {
                            //Association was successfull
                            debugger;
                        })
                        .catch((error) => {
                            console.log('Could not assocation PPD object to the new Case: ', error);
                            setSubmissionError('Could not associate PPD object to the new Case');
                            debugger;
                            return;//Prevent further steps from happening.
                        });
                    var caseNo = response.data.fields.CaseNumber.value
                    if (imageState || formData) {
                        handleThreadUpdatesAndImages(caseID)
                            .then(() => {
                                setCaseID(caseID);
                                setCaseNumber(caseNo);
                                setLoading(false);
                                setStep(prevState => prevState+1);
                            })
                            .catch((e) => {
                                //Case was created, but ran into problems attaching image or thread
                                setLoading(false);
                                setSubmissionError(e);
                                console.log(e);
                            });
                    } else {
                        setCaseID(caseID);
                        setCaseNumber(caseNo);
                        setLoading(false);
                        setStep(prevState => prevState+1);
                    }
                })
                .catch((error) => {
                    setSubmissionError(error);
                    setLoading(false);
                    console.log(error);
                });
            } else {
                setStep(5);
            }
        } else {
            window.scrollTo(0,0);
        }
    }
    function attemptCasePredictionAssociation(caseId, ppdRecordId){
        var req = {
            url: process.env.REACT_APP_API + `salesforce/kservice/sa/Patch?param=ui-api/records/${ppdRecordId}`,
            method: "PATCH",
            headers: {
                'Authorization': 'Bearer ' + Cookies.get("token"),
            },
            data: {
                "fields" : {
                    "Case__c": caseId,
              }
            }
        }
        debugger;
        return axios(req);
    }
    function handleThreadUpdatesAndImages(caseID) {
        function createContentVersions() {
            //returns an array of ContentVersions created from imageState
            return new Promise((resolve, reject) => {
                if(imageState && imageState.length > 0) {
                    var contentVersionArray = [];
                    var promiseArray = [];
                    imageState.forEach((image, index) => {
                        promiseArray.push(new Promise((resolve,reject) => {
                            if(imageState) {
                                const utc = new Date().toJSON().slice(0,10).replace(/-/g,'/');
                                var url;
                                var createPayload;
                                var req;
                                // Post file on Case record
                                // '/services/data/v56.0/sobjects/ContentVersion';
                                url = process.env.REACT_APP_API + 'salesforce/kservice/Post?param=sobjects/ContentVersion';
                
                                createPayload = { 
                                    "Title":utc+": Image Attachment",
                                    "PathOnClient": image.imageName,
                                    "ContentLocation" : 'S',
                                    "FirstPublishLocationId": caseID,
                                    "versionData": image.base64,
                                    "NetworkId": Cookies.get('networkId')
                                };
                
                                req = {
                                    method : 'POST',
                                    url : url,
                                    headers: {
                                        'Content-Type': 'application/json; charset=UTF-8',
                                        'Accept': 'application/json',
                                        'Authorization': 'Bearer ' + Cookies.get("token"),
                                        'X-SalesForce-Token' : Cookies.get('sf_cookie'),
                                        'X-Id-Token' : Cookies.get('idToken')
                                    },
                                    data : createPayload
                                }
                
                                axios(req).then((response) => {
                                    //Throw content version into the array
                                    contentVersionArray.push(response);
                                    resolve();
                                }).catch((error) => {
                                    reject(error);
                                });
                            }
                      }) )
                    });
                    Promise.all(promiseArray)
                        .then(() => {
                            resolve(contentVersionArray);
                        })
                        .catch((error) => {
                            console.log(error);
                            reject(error);
                        })
                } else {
                    //Return empty array because User hasn't uploaded any images
                    resolve([]);
                }
            });
        }
        function createFeedItem(name, caseID) {
            //Creates feed item with thread update
            //Any image uploads depend on this FeedItem
            return new Promise((resolve, reject) => {
                let comment = (formData.hasOwnProperty('thread')) ? formData.thread : ""; 
                let feedItemURL = process.env.REACT_APP_API + 'salesforce/kservice/Post?param=ui-api/records';
                let feedItemPayload = {
                    "apiName": "Feed_Item__c",
                    "fields": {
                    "Body__c": comment,
                    "Case__c": caseID,
                    "Channel__c": "Web",
                    "Name": name,
                    "Parent_Id__c": caseID,
                    "Type__c": "Customer",
                    "Title__c": "Service Web"
                    }
                }
                
                let feedItemReq = {
                    method : 'POST',
                    url : feedItemURL,
                    headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + Cookies.get("token"),
                    'X-SalesForce-Token' : Cookies.get('sf_cookie'),
                    'X-Id-Token' : Cookies.get('idToken')
                    },
                    data : feedItemPayload
                }
                axios(feedItemReq).then((feedItemResponse) => {
                    resolve(feedItemResponse);
                }).catch((error) => {
                    reject(error);
                });
            });
        }
        function createFeedAttachments(contentVersionArray, feedItemID){
            return new Promise((resolve, reject) => {
                var promiseArray = [];
                contentVersionArray.forEach((contentVersionObj) => {
                    //For each image, query file info, then create feed attachment
                    promiseArray.push(new Promise((resolve,reject) => {
                        //Get ContentDocument Id from ContentVersion using ContentVersion Id (response.data.id)
                        let qImg = "SELECT ContentDocumentId, ContentSize, FileExtension, FileType, PathOnClient, VersionDataUrl FROM ContentVersion WHERE id = \'"+contentVersionObj.data.id+"\'";
                        qImg = encodeURIComponent(qImg);
                        //let urlImg = 'https://kubotaservice--ksdev.sandbox.my.salesforce.com' + 
                        //'/services/data/v56.0/query/?q='+qImg;
                        let urlImg = process.env.REACT_APP_API + 'salesforce/kservice/get?param=query/?q=' + qImg;
        
                        let reqImg = {
                            method: "GET",
                            url: urlImg,
                            headers: {
                                'Content-Type': 'application/json; charset=UTF-8',
                                'Accept': 'application/json',
                                'Authorization': 'Bearer ' + Cookies.get("token"),
                                'X-SalesForce-Token' : Cookies.get('sf_cookie'),
                                'X-Id-Token' : Cookies.get('idToken')
                            }
                        };
        
                        axios(reqImg).then((responseImg) => {
                            //Create FeedAttachment
                            let feedAttachmentURL = process.env.REACT_APP_API + 'salesforce/kservice/Post?param=ui-api/records';
                            let feedAttachmentPayload = {
                                "apiName": "Feed_Attachment__c",
                                "fields": {
                                "Attachment_Name__c": responseImg.data.records[0].PathOnClient,
                                "Date__c": new Date().toISOString(),
                                "Feed_Item__c": feedItemID,
                                "Icon__c": getFileIconByExtn(responseImg.data.records[0].FileExtension),
                                "Size__c": responseImg.data.records[0].ContentSize,
                                "Type__c": getMimeTypeByExtn(responseImg.data.records[0].FileType),
                                "Url__c": responseImg.data.records[0].VersionDataUrl
                                }
                            }
                            let feedAttachmentReq = {
                                method : 'POST',
                                url : feedAttachmentURL,
                                headers: {
                                'Content-Type': 'application/json; charset=UTF-8',
                                'Accept': 'application/json',
                                'Authorization': 'Bearer ' + Cookies.get("token"),
                                'X-SalesForce-Token' : Cookies.get('sf_cookie'),
                                'X-Id-Token' : Cookies.get('idToken')
                                },
                                data : feedAttachmentPayload
                            }
                            axios(feedAttachmentReq).then((feedAttachmentResponse) => {
                                resolve(feedAttachmentResponse);
                            }).catch((error) => {
                                reject(error);
                            });
                        }).catch((error) => {
                            reject(error);
                        });
                    }));
                });
                Promise.all(promiseArray)
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error)
                    });
            });
        }
        return new Promise((resolve, reject) => {
            //Main function for handling image uploads
            createContentVersions()
                .then((ContentVersionArray) => {
                    //If no images were uploaded, create a Text Update feed_item, if images present grab the 0th images DoucmentID for the name
                    var name = (ContentVersionArray.length > 0) ? ContentVersionArray[0].ContentDocumentId : "Text Update";
                    var hasImages = (ContentVersionArray.length > 0);
                    createFeedItem(name, caseID)
                        .then((feedItemObj) => {
                            if(hasImages) {
                                var feedItemID = feedItemObj.data.id;
                                createFeedAttachments(ContentVersionArray, feedItemID)
                                    .then(() => {
                                        resolve();
                                    })
                                    .catch((error) => {
                                        console.log("Error creating the feed_attachment objects");
                                        console.log(error);
                                        reject(error);
                                    });
                                console.log(feedItemObj);
                            } else {
                                //If no images, were done
                                resolve();
                            }
                        })
                        .catch((error) => {
                            console.log("Error while creating the feed_item");
                            console.log(error);
                            reject(error);
                        });
                })
                .catch((error) => {
                    console.log("Error while creating ContentVersions")
                    console.log(error);
                    reject(error);
                });

        });
    }

    function setMachineURLParams() {
        const assignParams = (value) => {
            if(value.substring(0,2) == "sn"){
                setMachineDetails(prevState => ({
                    ...prevState,
                    serialNumber : value.substring(3)
                }));
            }

            if(value.substring(0,2) == "mn"){
                setMachineDetails(prevState => ({
                    ...prevState,
                    modelNumber : value.substring(3)
                }));
            }
            if(value.substring(0,3) == "pin"){
                setMachineDetails(prevState => ({
                    ...prevState,
                    pin : value.substring(4)
                }));
            }
            if(value.substring(0,4) == "case"){  
                let caseId = value.substring(5);
                debugger;
                setCasePassThrough(caseId);
            }
        };
        if(params.param1){
            assignParams(params.param1);
        }
        if(params.param2){
            assignParams(params.param2);
        }
        if(params.param3){
            assignParams(params.param3);
        }
    }

    function handleRestart() {
        console.log("restarting");
        setStep(4);
        setSubmissionError('');
    }
    function callNextStageAnalytics (stage) {
        handleNextStageAnalytics(stage, uuid, subject, machineDetails, DTC);
    }
    function callFieldUpdateAnalytics () {
        //machineDetails, dtc_code_selected,  subject, uuid
        handleFieldUpdateAnalytics(machineDetails, DTC, subject, uuid);
    }
    function callCancelOrSolveTicketAnalytics (isSolved) {
        //machineDetails, dtc_code_selected,  subject, uuid
        handleCancelOrSolveTicketAnalytics(machineDetails, DTC, subject, uuid, isSolved);
    }
    function callCreateTicketAnalytics (isSolved) {
        //machineDetails, dtc_code_selected,  subject, uuid
        handleCreateTicketAnalytics(machineDetails, DTC, subject, uuid, isSolved);
    }
    function fetchSFFieldOverrides (caseType) {
        if(caseType === "report") {
            const remoteConfig = getRemoteConfig(app);
            fetchAndActivate(remoteConfig)
            .then(() => {
                var fields = JSON.parse(getValue(remoteConfig, "SF_Report_Field_ReadOnly_Overrides")._value);
                setHiddenSections(fields['hidden-sections']);
                setHiddenFields(fields['hidden-fields']);
                setRequiredFields(fields['required']);
                setFieldOverrides(fields);
            })
            .catch((err) => {
                console.log(e);
            });
        } else {
            const remoteConfig = getRemoteConfig(app);
            fetchAndActivate(remoteConfig)
            .then(() => {
                var fields = JSON.parse(getValue(remoteConfig, "SF_Case_Field_ReadOnly_Overrides")._value);
                setHiddenSections(fields['hidden-sections']);
                setHiddenFields(fields['hidden-fields']);
                setRequiredFields(fields['required']);
                setFieldOverrides(fields);
            })
            .catch((err) => {
                console.log(e);
            });
        }
    }
    function returnToMachine() {
        var url = '/support/machine/details/'
        if(!machineDetails.pin) {
            url += 'sn-' + machineDetails.serialNumber;
            url += '/mn-' + machineDetails.modelNumber;
        }
        history.push(url);
    }
    return (
    <React.Fragment>
                { loading && 
                    <Container maxWidth="lg" className={classes.heroContent}>
                        <Paper elevation={3} className={classes.heroPaper} justify="center">
                            <CircularProgress className={classes.loadingCenterInside} />
                        </Paper>
                    </Container>
                }
                { !submissionError && !loading && step < 6 &&
                        <Container maxWidth="lg" className={classes.heroContent}>
                            { errorBanner && 
                                <Alert severity="error" className={classes.error}>{errorBanner}</Alert>
                            }
                            <Paper elevation={3} className={(step != 4 && step != 4.1) ? classes.heroPaper : classes.heroPaperWithoutSidePadding} justify="center">
                                { step == 1 &&
                                    <CaptureDTC
                                                setStep={setStep}
                                                DTC={DTC}
                                                setDTC={setDTC}
                                                subject={subject}
                                                setSubject={setSubject}
                                                machineDetails={machineDetails}
                                                caseOrReport={caseOrReport}
                                                callNextStageAnalytics={callNextStageAnalytics}
                                                callFieldUpdateAnalytics={callFieldUpdateAnalytics}
                                    />
                                }

                                { step == 2 &&
                                    <CaseDeflectionWithPredictions
                                        setStep={setStep}
                                        DTC={DTC}
                                        subject={subject}
                                        machineDetails={machineDetails}
                                        callNextStageAnalytics={callNextStageAnalytics}
                                        callFieldUpdateAnalytics={callFieldUpdateAnalytics}
                                        callCancelOrSolveTicketAnalytics={callCancelOrSolveTicketAnalytics}
                                        returnToMachine={returnToMachine}
                                        uuid={uuid}
                                        setPredictionRecordId={setPredictionRecordId}
                                        predictionResults={predictionResults}
                                        setPredictionResults={setPredictionResults}
                                        casePassThrough={casePassThrough}
                                    />
                                }

                                { step == 3 &&
                                    <FormMachine2Page
                                        values={formStates}
                                        setValues={setFormStates}
                                        machineDetails={machineDetails}
                                        setStep={setStep}
                                        callNextStageAnalytics={callNextStageAnalytics}
                                        caseOrReport={caseOrReport}
                                        styles={classes}
                                    />
                                }
                                { step == 3.5 &&
                                    <ContactCapturePage
                                        setStep={setStep}
                                        setPreFillAccountContact={setPreFillAccountContact}
                                        setErrorBanner={setErrorBanner}
                                        accountID={accountID} 
                                        setAccountID={setAccountID}
                                        contactID={contactID}
                                        setContactID={setContactID}
                                    />
                                }
                                { step == 4 && caseOrReport == 'case' &&
                                    <CreateForm
                                    step={step}
                                    setStep={setStep}
                                    editValues={editValues}
                                    setEditValues={setEditValues}
                                    objectInfo={objectInfo}
                                    setObjectInfo={setObjectInfo}
                                    DTC={DTC}
                                    subject={subject}
                                    machineDetails={machineDetails}
                                    createCase={createCase}
                                    ErrorStates={ErrorStates}
                                    setErrorStates={setErrorStates}
                                    RequiredFields={reqFields}
                                    setRequiredFields={setRequiredFields}
                                    formStates={formStates}
                                    caseOrReport={caseOrReport}
                                    fieldOverrides={fieldOverrides}
                                    preFillAccountContact={preFillAccountContact}
                                    hiddenSections={hiddenSections}
                                    hiddenFields={hiddenFields}
                                    reportFields={reportFields}
                                    setReportFields={setReportFields}
                                    createReport={createReport}
                                />
                                }
                                { (step == 4 || step == 4.1) && caseOrReport == 'report' &&
                                    <CreateForm
                                        step={step}
                                        setStep={setStep}
                                        editValues={editValues}
                                        setEditValues={setEditValues}
                                        objectInfo={objectInfo}
                                        setObjectInfo={setObjectInfo}
                                        DTC={DTC}
                                        subject={subject}
                                        machineDetails={machineDetails}
                                        createCase={createCase}
                                        ErrorStates={ErrorStates}
                                        setErrorStates={setErrorStates}
                                        RequiredFields={reqFields}
                                        setRequiredFields={setRequiredFields}
                                        formStates={formStates}
                                        caseOrReport={caseOrReport}
                                        fieldOverrides={fieldOverrides}
                                        preFillAccountContact={preFillAccountContact}
                                        hiddenSections={hiddenSections}
                                        hiddenFields={hiddenFields}
                                        reportFields={reportFields}
                                        setReportFields={setReportFields}
                                        createReport={createReport}
                                    />
                                }
                                { step == 5 &&
                                    <FeedAttachments
                                        setStep={setStep}
                                        editValues={editValues}
                                        objectInfo={objectInfo}
                                        imageState={imageState}
                                        updateImageState={updateImageState}
                                        formData={formData}
                                        updateFormData={updateFormData}
                                        createCase={createCase}
                                        createReport={createReport}
                                        caseOrReport={caseOrReport}
                                    />
                                }
                            </Paper>
                        </Container>
                }
                { !submissionError && !loading && step == 6 &&
                    <CaseSuccess
                        caseNumber={caseNumber}
                        caseID={caseID}
                        caseOrReport={caseOrReport}
                    />
                }
        {
            submissionError &&
             <Container maxWidth="sm" className={classes.heroContent}>
                <Paper elevation={3} className={classes.heroPaper} justify="center">
                    <Grid container spacing={2} direction="column" alignItems="center">
                        <Grid item md={12} xs={12}>
                            <div>There has been an error creating your case. Please restart the case creation process</div>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Button
                                disabled={false}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    handleRestart();
                                }}
                            >
                                Restart
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        }
    </React.Fragment>
    )
        
    
}

export default CaseCreateFlowWrapperWithPrediction