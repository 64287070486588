import React, {useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Cookies from 'js-cookie';
import axios from 'axios';
import checkCoveoToken from '../helpers/checkCoveoToken';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import SalesForceConstants from '../SalesForceConstants';
import { Alert } from '@mui/material';
import SaveIcon from '@material-ui/icons/Save';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';



const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(4, 0, 6),
    width: '70%'
  },
  heroPaper: {
    padding: theme.spacing(1, 2, 2),
    margin: theme.spacing(0, 0, 4)
  },
  loadingCenterInside: {
    marginLeft: '46%'
  },
  actionButtons: {
      backgroundColor: "green",
      width: "fit-content"
  },

}));

function CaseDeflectionWithPredictions({setStep, DTC, subject, machineDetails, callNextStageAnalytics, callFieldUpdateAnalytics, callCancelOrSolveTicketAnalytics, returnToMachine, uuid, setPredictionRecordId, predictionResults, setPredictionResults, casePassThrough}) {
    const classes = useStyles();
    const history = useHistory();
    const [displayResults, setDisplayResults] = useState();
    const [resultLoadingArray, setResultLoadingArray] = useState([false, false, false, false, false, false]);
    const [coveoLoading, setCoveoLoading] = useState();
    const [predictionLoading ,setPredictionLoading] = useState();
    const [masterLoading, setMasterLoading] = useState(true);
    const [fetchError, setFetchError] = useState();
    

    useEffect(() => {
        setCoveoLoading(true);
        checkCoveoToken()
            .then(() => {
                fetchCaseDeflectResults();
            })
            .catch((e) => {
                console.log("Unable to fetch a Coveo Search Token:");
                console.log(e);
            });
        //For testing only.
        fetchPartsPredictionData();
        // setPredictionResults({
        //     "symptom": "Customer stated that Engine is only running on one cylinder",
        //     "serialNumber": "10187",
        //     "recordID": "a1V8H000001rbGnUAI",
        //     "PartsCombinations": [
        //       {
        //         "requiredParts": [
        //           {
        //             "recordID": "a1X8H0000003j0iUAA",
        //             "qty": "1",
        //             "partNumber": "1H271-60204",
        //             "partDesc": "ECU,ASSY(V33TE4-CTL)   *"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j0jUAA",
        //             "qty": "1",
        //             "partNumber": "1J774-18930",
        //             "partDesc": "KIT FILTER,DPF NEW (+$542.74 Core)   *"
        //           }
        //         ],
        //         "recordID": "a1W8H000000kwCUUAY",
        //         "recommendedJobhours": "4.7",
        //         "probabilitySimilarPhraseMatch": "67.85",
        //         "causalPartDescription": "ECU,ASSY(V33TE4-CTL)   *",
        //         "causalPart": "1H271-60204"
        //       },
        //       {
        //         "requiredParts": [
        //           {
        //             "recordID": "a1X8H0000003j0kUAA",
        //             "qty": "1",
        //             "partNumber": "1G772-52132",
        //             "partDesc": "GASKET,INJECTION PU."
        //           },
        //           {
        //             "recordID": "a1X8H0000003j0lUAA",
        //             "qty": "1",
        //             "partNumber": "V0521-51940",
        //             "partDesc": "ASSY ELEMENT"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j0mUAA",
        //             "qty": "1",
        //             "partNumber": "1J574-52130",
        //             "partDesc": "GASKET,PUMP SUPPLY"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j0sUAA",
        //             "qty": "5",
        //             "partNumber": "1J550-96730",
        //             "partDesc": "GASKET"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j0tUAA",
        //             "qty": "1",
        //             "partNumber": "RD451-51930",
        //             "partDesc": "O RING"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j0uUAA",
        //             "qty": "1",
        //             "partNumber": "04817-00160",
        //             "partDesc": "O RING"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j0vUAA",
        //             "qty": "4",
        //             "partNumber": "1J770-53590",
        //             "partDesc": "SEAL, INJECTOR"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j0wUAA",
        //             "qty": "4",
        //             "partNumber": "1C020-53810",
        //             "partDesc": "SEAL, INJECTION PIPE"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j12UAA",
        //             "qty": "1",
        //             "partNumber": "77700-10116",
        //             "partDesc": "FUEL TEST PACKAGE   *"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j13UAA",
        //             "qty": "1",
        //             "partNumber": "1G772-14520",
        //             "partDesc": "GASKET, HEAD COVER"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j14UAA",
        //             "qty": "1",
        //             "partNumber": "1K947-43172",
        //             "partDesc": "FILTER, CARTRIDGE"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j15UAA",
        //             "qty": "1",
        //             "partNumber": "1J770-50504",
        //             "partDesc": "PUMP,ASSY(SUPPLY)"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j16UAA",
        //             "qty": "4",
        //             "partNumber": "1J770-53074",
        //             "partDesc": "INJECTOR KIT   ***"
        //           }
        //         ],
        //         "recordID": "a1W8H000000kwCVUAY",
        //         "recommendedJobhours": "21.34",
        //         "probabilitySimilarPhraseMatch": "62.68",
        //         "causalPartDescription": "ASSY PUMP, SUPPLY",
        //         "causalPart": "1J770-50500"
        //       },
        //       {
        //         "requiredParts": [
        //           {
        //             "recordID": "a1X8H0000003j1MUAQ",
        //             "qty": "4",
        //             "partNumber": "1J770-53050",
        //             "partDesc": "ASSY INJECTOR   *"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j1NUAQ",
        //             "qty": "1",
        //             "partNumber": "HH1C0-32430",
        //             "partDesc": "CARTRIDGE, OIL FILTER   *"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j1OUAQ",
        //             "qty": "1",
        //             "partNumber": "1J770-65560",
        //             "partDesc": "CORD, GLOW PLUG"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j1PUAQ",
        //             "qty": "1",
        //             "partNumber": "V0521-99840",
        //             "partDesc": "FILTER,KIT(FUEL)   ***"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j1QUAQ",
        //             "qty": "1",
        //             "partNumber": "1G772-14520",
        //             "partDesc": "GASKET, HEAD COVER"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j1WUAQ",
        //             "qty": "4",
        //             "partNumber": "1J770-53490",
        //             "partDesc": "O-RING"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j1XUAQ",
        //             "qty": "4",
        //             "partNumber": "1C020-53810",
        //             "partDesc": "SEAL, INJECTION PIPE"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j1YUAQ",
        //             "qty": "4",
        //             "partNumber": "1J770-53590",
        //             "partDesc": "SEAL, INJECTOR"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j1ZUAQ",
        //             "qty": "4",
        //             "partNumber": "1J770-53260",
        //             "partDesc": "BUSHING, INJECTOR"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j1aUAA",
        //             "qty": "5",
        //             "partNumber": "1J550-96730",
        //             "partDesc": "GASKET"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j1bUAA",
        //             "qty": "4",
        //             "partNumber": "1G796-53620",
        //             "partDesc": "GASKET"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j1cUAA",
        //             "qty": "3",
        //             "partNumber": "70000-10001",
        //             "partDesc": "OIL, 1 GAL 15W-40"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j1dUAA",
        //             "qty": "4",
        //             "partNumber": "04814-05110",
        //             "partDesc": "O-RING"
        //           },
        //           {
        //             "recordID": "a1X8H0000003j1eUAA",
        //             "qty": "4",
        //             "partNumber": "1G772-65500",
        //             "partDesc": "SEAL, GLOW PLUG"
        //           }
        //         ],
        //         "recordID": "a1W8H000000kwCWUAY",
        //         "recommendedJobhours": "13.61",
        //         "probabilitySimilarPhraseMatch": "62.12",
        //         "causalPartDescription": "ASSY INJECTOR   *",
        //         "causalPart": "1J770-53050"
        //       }
        //     ],
        //     "modelNumber": "SVL75W"
        //   });
    }, []);
    useEffect(() => {
        if(coveoLoading === false){
            if(predictionLoading === false){
                setMasterLoading(false);
            }
        }
    }, [coveoLoading]);
    useEffect(() => {
        if(predictionLoading === false){
            if(coveoLoading === false){
                setMasterLoading(false);
            }
        }
    }, [predictionLoading]);

    const fetchPartsPredictionData = () => {
        setPredictionLoading(true);
        let PPDRequest = {
            url: process.env.REACT_APP_API + `salesforce/kservice/apexrest?param=Predictions/createPrediction?serialNumber=${machineDetails.serialNumber}%26modelNumber=${machineDetails.modelNumber}%26symptom=${subject}`,
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + Cookies.get("token"),
                'Content-Type': 'application/json',
                "X-SalesForce-Token" : Cookies.get('sf_cookie'),
                'X-Id-Token' : Cookies.get('idToken')
            },
        }
        axios(PPDRequest)
            .then((response) => {
                let PPDData = response.data;
                if(PPDData.PartsCombinations.length > 0) {
                    setPredictionResults(PPDData);
                    setPredictionRecordId(PPDData.recordID);
                    setPredictionLoading(false);
                }
            })
            .catch((error) => {
                console.log('Error occured fetching PPD Data: ', error);
                setFetchError(true);
                setPredictionLoading(false);
            });
            
    }
    const fetchCaseDeflectResults = () => {
        //Make request to Case Assist Endpoint
        // var url = "https://platform.cloud.coveo.com/rest/organizations/kubotausanonproduction1uwe4kf4h/caseassists/5e5bb2d6-06ca-4dba-9c0c-31505b16eaff/documents/suggest?numberOfResults=100"
        var url = process.env.REACT_APP_API  + "Coveo/kservice/CaseAssist"
        var test = machineDetails;
        var req = {
            url : url,  
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + Cookies.get("token"),
                'X-Coveo-Token': Cookies.get("CoveoCaseAssistToken"),
                'Content-Type': 'application/json'
            },
            data : {
                "visitorId": uuid,
                "fields": {
                    "subject": {
                        "value": subject
                    }
                },
                "context": {
                    "serial_num": machineDetails.serialNumber,
                    "model_num": machineDetails.modelNumber,
                    "dtc_code_search": "",
                    "dtc_code_selected": DTC,
                    "businessUnits": "KTC-USA",
                    "origin" : "k-service"
                }
            }
        };
        axios(req)
            .then((response) => {
                let filteredOutArray = response.data.documents.filter(isNonSFItem);
                setDisplayResults(filteredOutArray.slice(0,6));
                setCoveoLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setCoveoLoading(false);
            });
    }

    

    const isNonSFItem = (x) => {
        if(x.fields.filetype === "SalesforceItem")
            return false;
        else
            return true;
    }
    const handleSubmit = () => {
        callNextStageAnalytics("SECTION_3");
        callFieldUpdateAnalytics();
        setStep(prevState => prevState +1);
    }
    const handleLinkToCase = () => {
        function attemptCasePredictionAssociation(caseId, ppdRecordId){
            var req = {
                url: process.env.REACT_APP_API + `salesforce/kservice/sa/Patch?param=ui-api/records/${ppdRecordId}`,
                method: "PATCH",
                headers: {
                    'Authorization': 'Bearer ' + Cookies.get("token"),
                },
                data: {
                    "fields" : {
                        "Case__c": caseId,
                  }
                }
            }
            debugger;
            return axios(req);
        }
        // Link the PPD to the already create case, then redirect to case
        attemptCasePredictionAssociation(casePassThrough, predictionResults?.recordID)
            .then(() => {
                debugger;
                history.push(`/support/case/${casePassThrough}`);
            })
            .catch((error) => {
                debugger;
            });
    }

    const handlePrevious = () => {
        callNextStageAnalytics("SECTION_1");
        callFieldUpdateAnalytics();
        setStep(prevState => prevState -1);
    }

    const handleCancel = () => {
        callCancelOrSolveTicketAnalytics(false);
        returnToMachine();
    }
    const handlePrint = () => {
        let partsPredictionObjectRecordId = predictionResults.recordID;
        var networkId = SalesForceConstants.yourekaNetworkID;
        var url = process.env.REACT_APP_SF_DOMAIN + '.my.salesforce.com/secur/frontdoor.jsp?sid='+Cookies.get('sf_cookie')+'&retURL=%2Fservlet%2Fnetworks%2Fswitch%3FnetworkId%3D'+networkId+`%26startURL%3Dapex%2FPartsPDF%3FId%3D${partsPredictionObjectRecordId}`;
        window.open(url, "_blank").focus();

    }
    const handleSolved = () => {
        callCancelOrSolveTicketAnalytics(true);
        returnToMachine();
    }

    function ListItemLink(uniqueID, index, filetype, filename) {
        if(filetype === "pdf") {
            if(uniqueID.includes("kits.kubota")) {

                fetchAttachmentFromKits(uniqueID, index, filename);
            } else {
                console.log('PDF not hosted on Kits, aborting');
            }
        } else {
            let tab = window.open();
            tab.location.href = uniqueID;
        }
    }

    function fetchAttachment(attachmentURL) {
        let url = document.createElement('a');
        url.href = attachmentURL;
        // If file attachment URL is Azure
        let adTokenUrl = process.env.REACT_APP_API + 'salesforce/kservice/apexrest?param=ADToken';
        let adTokenReq = {
            method : "GET",
            url: adTokenUrl,
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Accept": "application/json",
                "Authorization": "Bearer " + Cookies.get("token"),
                // "X-SalesForce-Token" : Cookies.get('sf_cookie')
            }
        }
        axios(adTokenReq).then((adTokenRes) => {
        if(adTokenRes.data){
            let adToken = adTokenRes.data;
            console.log(adToken);
            let x_ms_version = '2021-08-06';
            var newURL =  localStorage.getItem("KnowledgeAttachmentBaseURL") + url.href.substring(url.href.indexOf("/Knowledge__kav"));
            previewBlob(newURL, adToken, x_ms_version)
                .then(result => {
                    let fileLink = result;
                    downloadFileAttachment(fileLink, fileName)
                })
                .catch(error => {
                    console.log(error);
                })
        }
        })
        .catch(error => {
        console.log(error);
        }) 
    }
    function fetchAttachmentFromKits(attachmentURL, index,fileName) {
        let x_ms_version = '2021-08-06';
        const kitToken = Cookies.get("KitToken");
        //Mark Result as Loading, these kits take a while.
        const clone = [...resultLoadingArray];
        clone[index] = true;
        setResultLoadingArray(clone);
        
        previewBlob(attachmentURL, kitToken, x_ms_version)
                .then(result => {
                    let fileLink = result;
                    downloadFileAttachment(fileLink, fileName)

                    const clone = [...resultLoadingArray];
                    clone[index] = false;
                    setResultLoadingArray(clone);
                })
                .catch(error => {
                    console.log(error);

                    const clone = [...resultLoadingArray];
                    clone[index] = false;
                    setResultLoadingArray(clone);
                })
    }

    // Function to preview blob
    function previewBlob(uri, token, version) {
        // create headers object      
        const myHeaders = {'Authorization': 'Bearer ' + token};
        // create request options
        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        let mimeType='application/octet-stream'; // init mime type
        // return promise
        return new Promise((resolve, reject) => {
            // fetch request
            fetch(uri, requestOptions)
            .then(response => { 
                const contentType = response.headers.get('content-type'); // get blob mime type
                //mimeType = 'application/octet-stream'; // set to allowed type
                return response.arrayBuffer(); // returns a promise that resolves with a generic, fixed-length raw binary data buffer
            }) 
            .then(result => { // process response     
            // Specify a MIME Type for Blob Objects in LWS
                const file = new Blob([result], {type: 'application/pdf'}); 
                // create a string containing a URL
                const imageUrl = URL.createObjectURL(file);
                // return blob
                resolve(imageUrl);
            })
            .catch(error => { // handle error
                reject(error);
            });
        });
    }

    async function downloadFileAttachment(url, filename, fetchProps) {
        fetchProps = {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get("sf_cookie")
            }
        }
        try {
        const response = await fetch(url, fetchProps);

        if (!response.ok) {
            throw new Error(response);
        }

        const blob = await response.blob();

        // Download the file
        saveAs(blob, filename);

        } catch (error) {
        throw new Error(error);
        }
    }

    // function to get UTC date
    function getUTCDate() {
        // get current date
        let currentDate = new Date();
        // convert to UTC
        const utcDate = currentDate.toUTCString();
        // return string
        return utcDate;
    }

    function Results({ result, index }) { 
        const filetype = result.fields.filetype;
        var filename;
        if(filetype === "pdf") {
            filename = result.fields.filename;
        }
        return(
            <Container maxWidth="lg" className={classes.Result}>
                <ListItem button onClick={() => ListItemLink(result.clickUri, index, filetype, filename)}>
                    <ListItemText
                        primary={
                        <Typography noWrap>
                            {result.fields.title}
                        </Typography>
                        }
                        secondary={
                        <React.Fragment>
                            <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                            >
                            {result.excerpt}
                            </Typography>
                        </React.Fragment>
                        }
                    />
                    <ListItemIcon className={classes.listIcon}>
                        <ArrowForwardIosIcon />
                    </ListItemIcon>
                </ListItem>

                {resultLoadingArray[index] &&
                    <Grid container justifyContent="center">
                        <Grid item xs={12}>
                            <CircularProgress className={classes.loadingCenterInside}/>
                        </Grid>
                    </Grid>
                }
                <Divider />
            </Container>
        )
    }
    console.log(displayResults);
    return (
        <>
                   
                    { masterLoading &&
                        <Container maxWidth="lg" className={classes.heroContent}>
                                <Grid container>
                                    <Grid item md={12} xs={12} align="center">
                                        <CircularProgress />
                                    </Grid>
                                </Grid>
                        </Container>
                    }
                   
                    {!masterLoading &&
                        <Grid container>
                             <Grid item xs={6} >
                                <Typography variant="h4" component="h2" name="scroll-to-results">Parts Predictions</Typography>
                                {predictionResults  &&
                                    <Grid container style={{marginTop: '8px'}}>
                                        <Grid item xs={11} style={{marginBottom: '8px'}}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Typography>
                                                        <strong>Model: </strong>{predictionResults.modelNumber}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>
                                                    <strong>Serial: </strong>{predictionResults.serialNumber}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        <strong>Symptom: </strong>{predictionResults.symptom}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {predictionResults.PartsCombinations.map((prediction, index) => {
                                            return(
                                                <>
                                                    <Grid item xs={11}> 
                                                        <Grid container key={"predictionObj" + index} style={{marginTop: '6px', border: "1px solid black"}}>
                                                            <Grid item xs={12} style={{borderBottom: '1px solid black', paddingLeft: '4px', backgroundColor: '#DC4405' }}>
                                                                <Typography noWrap style={{color: 'white'}}>
                                                                    <strong>Prediction #{index+1} </strong>
                                                                </Typography>
                                                            </Grid>
                                                            <div style={{marginLeft: '4px'}}>
                                                                <Grid item xs={12} style={{marginTop: '10px'}}>
                                                                    <Grid container>
                                                                        <Grid item xs={12}>
                                                                            <Typography noWrap>
                                                                                <strong>Causal Part</strong>
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Typography noWrap>
                                                                                {prediction.causalPart}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                { prediction.requiredParts && prediction.requiredParts.length > 0 &&
                                                                    <>
                                                                        <Grid item xs={12} style={{marginTop: '4px'}}>
                                                                            <Grid container>
                                                                                <Grid xs={6}>
                                                                                    <Typography><strong>Required Parts Combination</strong></Typography>
                                                                                </Grid>
                                                                                <Grid xs={6}>
                                                                                    <Typography style={{textAlign: 'center'}}><strong>Quantity</strong></Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                           
                                                                        </Grid>
                                                                        
                                                                        <Grid container>
                                                                            {prediction.requiredParts.map((requiredPart, index) => {
                                                                                return(
                                                                                    <>
                                                                                        <Grid item xs={6}>
                                                                                            <Typography>
                                                                                                {requiredPart.partNumber} {requiredPart.partDesc}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={6} style={{textAlign: 'center'}}>
                                                                                            {requiredPart.qty}
                                                                                        </Grid>
                                                                                    </>
                                                                                );
                                                                            })}
                                                                        </Grid>
                                                                    </>
                                                                }
                                                                <Grid item xs={12} style={{marginTop: '4px'}}>
                                                                    <Typography noWrap>
                                                                        <strong>Estimated Labor: </strong> {Math.round(prediction.recommendedJobhours)} hours
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} style={{marginTop: '4px', textAlign: 'right', marginRight: '16px '}}>
                                                                    <Typography noWrap>
                                                                        <strong>Probability Score: </strong> {prediction.probabilitySimilarPhraseMatch}%
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} style={{marginTop: '4px', paddingRight: '0px'}}>
                                                                    <Divider />
                                                                </Grid>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            );
                                        })}
                                    </Grid>
                                }
                                {!predictionResults &&
                                    <Alert severity="warning" style={{marginTop: '8px', marginRight: '2px'}}>No Predictions Found. <br></br>You can click the resubmit button to add additional details.</Alert>
                                }
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4" component="h2" name="scroll-to-results" style={{paddingLeft: '37px'}}>Problem Suggestions</Typography>
                                {displayResults  && displayResults.length > 0 &&
                                    <List component="nav" aria-label="knowledge search results" className={classes.root}>
                                        {displayResults.map((result, i) => (
                                        <Results
                                            key={i}
                                            index={i}
                                            result={result}
                                        />

                                        ))}
                                    </List>
                                }
                                {(!displayResults || displayResults.length == 0) &&
                                    <Alert severity="warning" style={{marginTop: '8px', marginLeft: '2px'}}>No Knowledge Articles found</Alert>
                                }
                            </Grid>
                            

                            <Grid item xs={12} style={{marginTop: '8px'}}>
                                <Grid container spacing={1} direction={"row-reverse"}>

                                    <Grid item>
                                        { casePassThrough &&
                                            <Button
                                                disabled={fetchError}
                                                variant="outlined"
                                                color="primary"
                                                onClick={handleLinkToCase}
                                                endIcon={<SaveIcon/>}
                                            >
                                                Link to Case
                                            </Button>
                                        }
                                        { !casePassThrough && 
                                            <Button
                                                disabled={fetchError}
                                                variant="outlined"
                                                color="primary"
                                                onClick={handleSubmit}
                                                endIcon={<ChevronRightIcon/>}
                                            >
                                                Create Case
                                            </Button>
                                        }
                                        
                                    </Grid>

                                    <Grid item>
                                        <Button
                                            disabled={fetchError}
                                            variant="contained"
                                            color="primary"
                                            onClick={handlePrint}
                                            startIcon={<ClearIcon/>}
                                        >
                                            Print
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disabled={false}
                                            variant="outlined"
                                            color="primary"
                                            onClick={handlePrevious}
                                            startIcon={<ChevronLeftIcon/>}
                                        >
                                            Resubmit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
        </>
    )
}

export default CaseDeflectionWithPredictions