import * as msal from "@azure/msal-browser";

// Azure B2C Configurations
const msalConfig = {
    auth: {
        // clientId: "ba5086fc-0ee9-46b8-b511-b1228ee42085", //Dev
        //clientId: "ccc0574d-9aca-4a5b-b3f9-6e3ec59c0f9f", //Test
        //clientId: "cb02a3d4-3486-4cb0-8cc9-22bdbd758393", //UAT
        clientId: "9a75e008-54d8-4413-bade-5c251433ae62", //Prod

        // authority: "https://kubotalinktest.b2clogin.com/kubotalinktest.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN_JWT", //Test
        //authority: "https://kubotalink.b2clogin.com/kubotalink.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN_JWT", //Dev
        //authority: "https://kubotalinkstage.b2clogin.com/tfp/kubotalinkstage.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN_JWT", //UAT
        authority: "https://login.kubotalink.net/tfp/kubotalinkprod.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN_JWT", //Prod

        //knownAuthorities: ["kubotalink.b2clogin.com"], //Dev
        // knownAuthorities: ["kubotalinktest.b2clogin.com"], //Test
        //knownAuthorities: ["kubotalinkstage.b2clogin.com"], //UAT
        knownAuthorities: ["login.kubotalink.net"], //Prod   

        redirectUri: process.env.REACT_APP_DOMAIN, //Staging
        //redirectUri: "http://localhost:3000", //Local Dev
        
        // postLogoutRedirectUri: process.env.REACT_APP_DOMAIN+'/logout', //Staging Logout
        //postLogoutRedirectUri: 'https://localhost:3000/logout', //Staging Logout
        //postLogoutRedirectUri: "http://localhost:3000", //Local Dev
        //postLogoutRedirectUri: "http://localhost:3000", https%3A%2F%2Fstaging.kservice.app http%3A%2F%2Flocalhost%3A3000 
        //postLogoutRedirectUri: "https://login.microsoftonline.com/c6b6caf6-be06-4f0b-9273-394412dc2ab7/oauth2/v2.0/logout?post_logout_redirect_uri=https://staging.kservice.app"
        postLogoutRedirectUri: "https://kservice.app"+'/logout', //Currently pointing to the prod environment
    }
};
//const secondary_logout_url = "https://login.microsoftonline.com/c6b6caf6-be06-4f0b-9273-394412dc2ab7/oauth2/v2.0/logout?post_logout_redirect_uri=https://staging.kservice.app";
const secondary_logout_url = "https://login.microsoftonline.com/2aa6f393-18d9-48bf-bd62-b2a6a9ff29f3/oauth2/v2.0/logout?post_logout_redirect_uri=https://kservice.app"; //Prod

// Initialize PublicClientApplication object
const msalInstance = new msal.PublicClientApplication(msalConfig);
export{
    msalInstance,
    secondary_logout_url
}
