export default function getFileIconByExtn(extn) {
  // create a map of mime type as key and iconName as value
  const fileMap = new Map();

  // set values
  fileMap.set('pdf', 'doctype:pdf');
  fileMap.set('aac', 'doctype:audio');
  fileMap.set('midi', 'doctype:audio');
  fileMap.set('mpeg', 'doctype:audio');
  fileMap.set('ogg', 'doctype:audio');
  fileMap.set('opus', 'doctype:audio');
  fileMap.set('wav', 'doctype:audio');
  fileMap.set('webm', 'doctype:audio');
  fileMap.set('avif', 'doctype:image');
  fileMap.set('bmp', 'doctype:image');
  fileMap.set('gif', 'doctype:image');
  fileMap.set('jpeg', 'doctype:image');
  fileMap.set('jpg', 'doctype:image');
  fileMap.set('png', 'doctype:image');
  fileMap.set('svg', 'doctype:image');
  fileMap.set('tiff', 'doctype:image');
  fileMap.set('icon', 'doctype:image');
  fileMap.set('webp', 'doctype:image');
  fileMap.set('3gpp', 'doctype:video');
  fileMap.set('3gpp2', 'doctype:video');
  fileMap.set('mp2t', 'doctype:video');
  fileMap.set('mp4', 'doctype:mp4');
  fileMap.set('mpeg', 'doctype:video');
  fileMap.set('webm', 'doctype:video');
  fileMap.set('msvideo', 'doctype:video');
  fileMap.set('txt', 'doctype:txt');    
  fileMap.set('csv', 'doctype:csv');
  fileMap.set('html', 'doctype:html');
  fileMap.set('zip', 'doctype:zip');
  fileMap.set('bzip', 'doctype:zip');
  fileMap.set('rar', 'doctype:zip');
  fileMap.set('tar', 'doctype:zip');
  fileMap.set('xls', 'doctype:excel');
  fileMap.set('xlsx', 'doctype:excel');
  fileMap.set('ppt', 'doctype:ppt');
  fileMap.set('pptx', 'doctype:ppt');
  fileMap.set('rtf', 'doctype:rtf');
  fileMap.set('vsd', 'doctype:visio');
  fileMap.set('vsdx', 'doctype:visio');
  fileMap.set('xml', 'doctype:xml');
  fileMap.set('doc', 'doctype:word');
  fileMap.set('docx', 'doctype:word');

  // search map
  const icon = fileMap.get(extn.toLowerCase());

  // found
  if (icon) {
      // return value
      return icon;
  } else {
      // return default
      return 'doctype:unknown';
  }
  
}